import { gql } from '@apollo/client/core';

export default gql`
  query PgExternalTaxonomyAssessmentAnswer($email: String!, $shareToken: String!) {
    getTaxonomyBusinessActivityDelegation(email: $email, shareToken: $shareToken) {
      type
      userId
      projectName
      activityName
      objective
      objectiveDescription
    }
  }
`;
