import { provideApolloClient, useLazyQuery } from '@vue/apollo-composable';
import {
  type SourceDocumentDownloadQuery,
  type SourceDocumentDownloadQueryVariables,
} from '@/__generated__/types';
import saveFile from '@/utils/helpers/saveFile';
import waitForApolloQuery from '@/utils/helpers/waitForApolloQuery';
import { apolloClient } from '@/api/apollo/client';
import SOURCE_DOCUMENT_DOWNLOAD_QUERY from './sourceDocumentDownload.query';

export function useSourceDocumentDownload() {
  const query = provideApolloClient(apolloClient)(
    () => useLazyQuery<SourceDocumentDownloadQuery, SourceDocumentDownloadQueryVariables>(
      SOURCE_DOCUMENT_DOWNLOAD_QUERY,
      undefined,
      { fetchPolicy: 'network-only' },
    ));

  const getFileData = async (fileName: string, filePath: string) => {
    const requestData: SourceDocumentDownloadQueryVariables = {
      fileName,
      filePath,
    };
    // eslint-disable-next-line no-unused-expressions
    query.load(undefined, requestData) || query.refetch(requestData);
    const result = await waitForApolloQuery(query);
    if (result && !(result instanceof Error)) {
      return result?.downloadRepositorySourceDocument;
    }
  };

  const exportFile = async (fileName: string, filePath: string) => {
    const fileData = await getFileData(fileName, filePath);
    if (fileData) {
      saveFile(fileData.file, fileData.name, fileData.type);
    }
  };

  return {
    loading: query.loading,
    exportFile,
    getFileData,
  };
}
