import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  AddRatingToRecommendationMutation,
  AddRatingToRecommendationMutationVariables,
} from '@/__generated__/types';

export const ADD_USER_TO_RECOMMENDATION = gql`
  mutation AddRatingToRecommendation($recommendationId: String!, $rating: Float!) {
    addRatingToRecommendation(recommendationId: $recommendationId, rating: $rating) {
      _id
      averageRating
    }
  }
`;

type TOptions = UseMutationOptions<AddRatingToRecommendationMutation, AddRatingToRecommendationMutationVariables>

const useAddRatingToRecommendationMutation = (options?: TOptions) => useMutation<
  AddRatingToRecommendationMutation,
  AddRatingToRecommendationMutationVariables
>(ADD_USER_TO_RECOMMENDATION, options);

export default useAddRatingToRecommendationMutation;
