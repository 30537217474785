<script setup lang="ts">
import dayjs from '@/lib/dayjs/config';
import AtAvatar from '@/components/atoms/AtAvatar.vue';
import { getUserName } from '@/utils/helpers/getUserName';
import type { PgKpiQuery } from '@/__generated__/types';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';

const props = defineProps<{
  comment: PgKpiQuery['getEntityKPI']['comments'][number],
}>();
</script>

<template>
  <div class="border border-gray-400 rounded-md px-4 py-2">
    <div class="flex gap-3 items-center">
      <AtAvatar
        class="!h-6 !w-6"
        :userName="getUserName(props.comment.user)"
        :url="props.comment.user.picture?.downloadUrl"
      />
      <span class="font-medium text-sm">
        {{ getUserName(props.comment.user) }}
      </span>
      <time
        class="text-gray-400 text-xs ml-2"
        :datetime="dayjs(props.comment.createdAt).format('YYYY-MM-DD, HH:MM')"
      >
        {{ dayjs(props.comment.createdAt).format('MMMM DD, YYYY HH:MM') }}
      </time>
    </div>
    <MlHtmlContent
      class="ml-9 text-sm"
      :html="props.comment.text"
    />
  </div>
</template>
