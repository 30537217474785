<script setup lang="ts">
import { XIcon } from '@heroicons/vue/solid';
import Datepicker from '@vuepic/vue-datepicker';
import { useI18n } from 'vue-i18n';

const { locale, t } = useI18n();

const props = defineProps<{
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  modelValue: any;
  inline?: boolean
  lastCloseIconHidden?: boolean
  maxYear: number
  minYear: number
}>();

const emit = defineEmits(['update:modelValue', 'removePastDate']);

</script>

<template>
  <div class="relative flex flex-wrap gap-2">
    <Datepicker
      :modelValue="props.modelValue"
      class="datePicker rounded-md"
      textInput
      autoApply
      :enableTimePicker="false"
      :clearable="false"
      :placeholder="t('Select Date')"
      yearPicker
      :yearRange="[props.minYear, props.maxYear]"
      :locale="locale"
      format="yyyy"
      :teleport="true"
      @update:modelValue="emit('update:modelValue', $event)"
    />
    <XIcon
      :icon="XIcon"
      :title="t('Close')"
      class="absolute bottom-[18px] left-[95px] w-3.5 cursor-pointer hover:primary text-gray-400 hover:text-primary"
      :class="{ hidden: lastCloseIconHidden }"
      @click.stop="emit('removePastDate')"
    />
  </div>
</template>
<style scoped>

.datePicker:deep(.dp__overlay_col[aria-disabled="true"]){
  display: none;
}

</style>
