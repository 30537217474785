import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';

export const useRoutesToDataEntry = () => {
  const { currentUser } = useCurrentUser();

  const getPathToLocation = (
    locationId: string,
  ) => {
    const location = currentUser.value?.entity.locations.find((loc) => loc._id === locationId);
    const locationFirstCategory = location?.assignments.categories[0].category ?? '';
    const locationFirstSubcategory = location?.assignments.categories[0].subcategories[0]?.subcategory ?? '';
    return getPathToSubcategory(locationId, locationFirstCategory, locationFirstSubcategory);
  };

  const getPathToCategory = (
    locationId: string,
    category:string,
  ) => {
    const location = currentUser.value?.entity.locations.find((loc) => loc._id === locationId);
    const locationCategory = location?.assignments.categories.find((_category) => _category.category === category);
    const locationFirstSubcategory = locationCategory?.subcategories[0]?.subcategory ?? '';
    return `/projects/${locationId}/data-entry?category=${category}&subcategory=${locationFirstSubcategory}`;
  };

  const getPathToSubcategory = (
    locationId: string,
    category:string,
    subcategory: string,
  ) => `/projects/${locationId}/data-entry?category=${category}&subcategory=${subcategory}`;

  const getPathToDpr = (
    locationId: string,
    category: string,
    subcategory: string,
    dptId: string,
    dprId:string,
  ) => `/projects/${locationId}/data-entry/?category=${category}&subcategory=${subcategory}&questiontype=${dptId}&questionid=${dprId}`;

  return {
    getPathToLocation,
    getPathToDpr,
    getPathToCategory,
    getPathToSubcategory,
  };
};
