import type { TStringOfDigits } from '@/types/types';
import { i18n } from '@/lib/i18n';
import dayjs from '@/lib/dayjs/config';

const { t } = i18n.global;

export const getDPRDateTime = (from?: string | null, to?: string | null) => from ? dayjs(from || to).format('YYYY-MM-DDThh:mm:ssTZD') : '';

export const getDPRTimeStamp = (date?: string | null) => date ? dayjs(date).valueOf().toString() as TStringOfDigits : '';

export enum DprPeriods {
  // Maximum amount of days in given period
  DAILY = 1,
  WEEKLY = 7,
  MONTHLY = 31,
  QUARTERLY = 92,
  BI_ANNUAL = 183,
  YEARLY = 366,
}
export const getDPRDateString = (from?: string | null, to?: string | null) => {
  if (!from) {
    return t('Once');
  }

  const fromDate = dayjs.utc(from || new Date()).startOf('day');

  if (!to) {
    return dayjs(fromDate).format('D MMM YYYY');
  }

  const toDate = dayjs.utc(to).startOf('day');

  const diff = Math.abs(dayjs(fromDate).diff(toDate, 'day'));

  switch (true) {
    case diff <= DprPeriods.DAILY:
      return dayjs(fromDate).format('D MMM YYYY');
    case diff <= DprPeriods.WEEKLY:
      return `${dayjs(fromDate).format('D MMM YYYY')} - ${dayjs(toDate).subtract(dayjs.duration(1).asSeconds()).format('D MMM YYYY')}`;
    case diff <= DprPeriods.MONTHLY:
      return `${dayjs(fromDate).format('MMM YYYY')}`;
    case diff <= DprPeriods.QUARTERLY:
      return `Q${dayjs(fromDate).quarter()} ${dayjs(fromDate).format('YYYY')}`;
    case diff <= DprPeriods.BI_ANNUAL:
      return `Q${dayjs(fromDate).quarter()} ${dayjs(fromDate).format('YYYY')} & Q${dayjs(toDate).subtract(dayjs.duration(1).asSeconds()).quarter()} ${dayjs(fromDate).format('YYYY')}`;
    default:
      return dayjs(fromDate).format('YYYY');
  }
};
