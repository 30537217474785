import { gql } from '@apollo/client/core';

export default gql`
  query GuidanceDocumentDownload($file: LibraryGuidanceDocumentEnum!, $fileType: RepositoryFileTypeEnum!) {
    downloadRepositoryGuidanceDocument(file: $file, fileType: $fileType) {
      file
      name
      type
    }
  }
`;
