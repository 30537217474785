<script setup lang="ts">
import { computed } from 'vue';
import MlDataPointValue, { type TDataPointValueAndType } from '@/components/molecules/MlDataPointValue.vue';
import type { CategoryValue, DataPoint } from '../../types';

type Props = {
  value: CategoryValue;
  dataPointType: DataPoint['dataPointType'];
};

const props = defineProps<Props>();

const dataPointValueAndType = computed<TDataPointValueAndType>(() => ({
  dataPointType: {
    valueDataType: props.dataPointType.valueDataType,
    valueUnit: props.value.originalValueUnit,
    valueUnitDivisor: props.dataPointType.valueUnitDivisor,
    valueDataTypeOptions: props.dataPointType.valueDataTypeOptions,
    summarizingMethod: null,
  },
  value: props.value.originalValue,
}));
</script>

<template>
  <MlDataPointValue
    :dataPointValueAndType="dataPointValueAndType"
  />
</template>
