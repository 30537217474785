import { useRouter } from 'vue-router';
import { ApolloError } from '@apollo/client';
import { notify } from '@kyvg/vue3-notification';
import { useI18n } from 'vue-i18n';
import useDeleteEntityLocationMutation from '@/api/mutations/EntityLocation/deleteEntityLocation.mutation';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';

export function useDeleteProject(redirectOnDelete = true) {
  const router = useRouter();
  const { t } = useI18n();

  const { confirmViaDialog } = useConfirmViaDialog();

  const { mutate } = useDeleteEntityLocationMutation({
    update: (store) => {
      // Refetch projects list after project is deleted.
      store.evict({
        id: 'ROOT_QUERY',
        fieldName: 'entityLocationSummaries',
      });
    },
  });

  return async (projectId: string, projectName: string) => {
    const isConfirmed = await confirmViaDialog({
      title: t('Are you sure you want to delete this project?'),
      text: t(
        'This will automatically delete all open datapoints and questions, all progress made so far and remove users from it. This includes the deletion of all nested projects. Are you sure you want to delete {projectName}?',
        {
          projectName,
        },
      ),
      confirmLabel: t('Delete project'),
      confirmButtonVariant: 'destructive',
    });

    if (isConfirmed) {
      try {
        await mutate({
          deleteEntityLocationInput: {
            _id: projectId,
          },
        });
        if (redirectOnDelete) {
          router.push({
            name: 'projects',
          });
        }
      } catch (error) {
        if (error instanceof ApolloError) {
          notify({
            type: 'error',
            text: t(error.message),
            duration: 10000,
          });
        }
      }
    }
  };
}
