<script setup lang="ts">
import { computed, reactive, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import { CheckboxSize } from '@/components/atoms/AtCheckbox/types';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import type { OgQuestionnaireNameNewQuery } from '@/__generated__/types';
import OG_QUESTIONNAIRE_NAME_QUERY from './OgQuestionnaireName.query';

export type Values = {
  name: string;
  category: string | null;
};

type QuestionnaireNameForm = {
  name: string;
  shouldNest: boolean;
  category: string;
};

type Props = {
  initialName: string;
  initialCategory: string | null;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  previous: [event: Values];
  next: [event: Values];
}>();

const { t } = useI18n();

const { result, loading } = useQuery<OgQuestionnaireNameNewQuery>(OG_QUESTIONNAIRE_NAME_QUERY);

const categories = computed(() => result.value?.getCustomCategoriesWithSubcategoriesForEntity ?? []);

const form = reactive<QuestionnaireNameForm>({
  name: '',
  shouldNest: false,
  category: '',
});
watch([() => props.initialName, () => props.initialCategory], ([newInitialName, newInitialCategory]) => {
  form.name = newInitialName;
  form.shouldNest = !!newInitialCategory;
  form.category = newInitialCategory ?? '';
}, { immediate: true });

const validationRules = computed(() => ({
  name: {
    required: helpers.withMessage(t('Questionnaire name is required.'), required),
  },
  category: {
    requiredIf: helpers.withMessage(t('Category is required.'), requiredIf(() => form.shouldNest)),
  },
}));
const v$ = useVuelidate(validationRules, form);

const isDisabled = computed(() => loading.value);

const categoriesOptions = computed<Record<string, string>>(() => {
  return categories.value.reduce((options, category) => ({
    ...options,
    [category._id]: category.name,
  }), {});
});

function handleSubmit() {
  emit('next', {
    name: form.name,
    category: form.shouldNest ? form.category : null,
  });
}

function handlePreviousClick() {
  emit('previous', {
    name: form.name,
    category: form.shouldNest ? form.category : null,
  });
}
</script>

<template>
  <h4 class="text-lg mb-8">
    {{ t('Give this questionnaire a name') }}
  </h4>

  <form @submit.prevent="handleSubmit">
    <div class="mb-48 pr-48">
      <AtInput
        v-model.trim="form.name"
        wrapperClass="w-96 mb-4"
        :label="t('Name')"
        :errors="v$.name.$errors"
        @blur="v$.name.$touch"
      />
      <template v-if="categories.length > 0">
        <div class="flex items-center space-x-2 mb-1">
          <AtCheckbox
            squared
            :checked="form.shouldNest"
            :size="CheckboxSize.XS"
            @toggleCheckbox="form.shouldNest = !form.shouldNest"
          >
            <template #label>
              <p class="text-sm font-medium text-gray-700">
                {{ t('Nest this questionnaire under:') }}
              </p>
            </template>
          </AtCheckbox>
        </div>
        <MlSelect
          v-model="form.category"
          usePortal
          :disabled="!form.shouldNest"
          :options="categoriesOptions"
          :errors="v$.category.$errors"
          @blur="v$.category.$touch"
        />
      </template>
    </div>
    <div class="flex space-x-4 justify-between items-center">
      <div class="flex-0">
        <AtButton type="button" variant="outline" @click="handlePreviousClick">
          {{ t('Previous') }}
        </AtButton>
      </div>
      <div class="flex-0">
        <AtButton type="submit" :disabled="v$.$invalid || isDisabled">
          {{ t('Next') }}
        </AtButton>
      </div>
    </div>
  </form>
</template>
