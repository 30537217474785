import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { RequestCustomReportMutation, RequestCustomReportMutationVariables } from '@/__generated__/types';

export const REQUEST_CUSTOM_REPORT = gql`
  mutation RequestCustomReport($locationIds: [String!]!, $format: String!, $fileId: String, $fileName: String $description: String) {
    requestCustomReport(locationIds: $locationIds, format: $format, fileId: $fileId, fileName: $fileName, description: $description ) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<RequestCustomReportMutation, RequestCustomReportMutationVariables>

const useRequestCustomReportMutation = (options?: TOptions) => useMutation<
  RequestCustomReportMutation, RequestCustomReportMutationVariables
>(REQUEST_CUSTOM_REPORT, options);

export default useRequestCustomReportMutation;
