<script setup lang="ts">
import { computed, reactive, inject, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import MlDataPointValue from '@/components/molecules/MlDataPointValue.vue';
import { DataPointTypeEmissionTypeEnum } from '@/__generated__/types';
import type { DataPoint, Project } from '../../types';
import { getPerYear } from '../../services/prepareCarbonFootprintData';
import type { Header, TableData } from './types';
import { prepareScopesForTable } from './utils';
import OgCarbonFootprintTableRow from './OgCarbonFootprintTableRow.vue';

type Props = {
  dataPoints: DataPoint[];
  projects: Project[];
}

const props = defineProps<Props>();
const emit = defineEmits(['changeScope2EmissionTypeExcluded']);

const { t } = useI18n();
const adminMode = inject('adminMode', computed(() => false));

const hiddenScopeSections = reactive(new Set<string>());
const hiddenProjects = reactive(new Set<string>());

const { SCOPE_2_MARKET_BASED, SCOPE_2_LOCATION_BASED } = DataPointTypeEmissionTypeEnum;
const scope2TypeSelected = ref<typeof SCOPE_2_MARKET_BASED | typeof SCOPE_2_LOCATION_BASED>(SCOPE_2_LOCATION_BASED);
const scope2TypeNotSelected = computed(
  () => scope2TypeSelected.value === SCOPE_2_MARKET_BASED ? SCOPE_2_LOCATION_BASED : SCOPE_2_MARKET_BASED,
);

watch(scope2TypeNotSelected, () => {
  emit('changeScope2EmissionTypeExcluded', scope2TypeNotSelected.value);
});

const perYear = computed(() => getPerYear(props.dataPoints, props.projects, !adminMode.value, scope2TypeNotSelected.value));

const headers = computed<Header[]>(() => {
  return [
    {
      text: t('Scope'),
      value: 'scope',
      styling: 'w-[150px]',
    },
    {
      text: t('Emission source'),
      value: 'emissionSource',
      styling: 'w-[200px]',
    },
    {
      text: t('Data source'),
      value: 'dataSource',
      styling: '',
    },
    ...perYear.value.years.map((year) => ({
      text: year.year,
      value: year.key,
      styling: '',
    })),
  ];
});

const allTableData = computed<TableData>(() => {
  return prepareScopesForTable(
    perYear.value.scopes,
    perYear.value.total,
    t,
  );
});

// All projects and sublevels which are collapsible should be collapsed initially.
watch(allTableData, (newAllTableData) => {
  hiddenScopeSections.clear();
  hiddenProjects.clear();

  newAllTableData.scopes.forEach((scope) => {
    hiddenScopeSections.add(scope.ifCollapsed.id + scope.id);
    scope.sections.forEach((scopeSection) => {
      hiddenScopeSections.add(scopeSection.id + scope.id);

      scopeSection.sections.forEach((source) => {
        source.rows.forEach((row) => {
          if (row.isCollapsible) {
            hiddenProjects.add(row.id);
          }
        });
      });

      scopeSection.total.rows.forEach((row) => {
        if (row.isCollapsible) {
          hiddenProjects.add(row.id);
        }
      });
    });

    scope.total.rows.forEach((row) => {
      if (row.isCollapsible) {
        hiddenProjects.add(row.id);
      }
    });
  });
}, { immediate: true });

const visibleTableData = computed<TableData>(() => {
  return {
    ...allTableData.value,
    scopes: allTableData.value.scopes.map((scope) => {
      const scopeSections = scope.sections.map((scopeSection) => {
        const sections = scopeSection.sections.map((source) => {
          const rows = source.rows.filter((row) => {
            for (const hiddenProject of hiddenProjects) {
              if (row.keys.has(hiddenProject)) {
                return false;
              }
            }

            return true;
          });
          return {
            ...source,
            span: rows.length,
            rows,
          };
        });

        const totalRows = scopeSection.total.rows.filter((row) => {
          for (const hiddenProject of hiddenProjects) {
            if (row.keys.has(hiddenProject)) {
              return false;
            }
          }

          return true;
        });

        return {
          ...scopeSection,
          span: sections.reduce((totalSpan, section) => totalSpan + section.span, 0) + totalRows.length,
          sections,
          total: {
            ...scopeSection.total,
            span: totalRows.length,
            rows: totalRows,
          },
        };
      });

      const scopeTotalRows = scope.total.rows.filter((row) => {
        for (const hiddenProject of hiddenProjects) {
          if (row.keys.has(hiddenProject)) {
            return false;
          }
        }

        return true;
      });

      return {
        ...scope,
        sections: scopeSections,
        total: {
          ...scope.total,
          span: scopeTotalRows.length,
          rows: scopeTotalRows,
        },
      };
    }),
  };
});

function handleScopeSectionToggle(id: string) {
  if (hiddenScopeSections.has(id)) {
    hiddenScopeSections.delete(id);
  } else {
    hiddenScopeSections.add(id);
  }
}
function handleProjectToggle(id: string) {
  if (hiddenProjects.has(id)) {
    hiddenProjects.delete(id);
  } else {
    hiddenProjects.add(id);
  }
}

</script>

<template>
  <div class="bg-gray-50 rounded-md px-6 py-4 mb-10">
    <h3 class="mb-1 text-sm font-medium">
      {{ t('Corporate carbon footprint') }}
    </h3>
    <h4 class="mb-4 text-sm font-normal text-gray-500">
      {{ t('Scope 1, 2 and 3 emissions expressed in tons of CO2 equivalent (tCO2e)') }}
    </h4>

    <table class="text-left w-full">
      <colgroup>
        <col v-for="header in headers" :key="header.value" :class="header.styling">
      </colgroup>

      <thead class="uppercase text-sm text-gray-400">
        <tr class="bg-gray-200">
          <th
            v-for="header in headers"
            :key="header.value"
            class="p-3 first:rounded-l-md last:rounded-r-md"
          >
            {{ header.text }}
          </th>
        </tr>
      </thead>

      <tbody class="text-xs">
        <template v-for="(scope, scopeIndex) in visibleTableData.scopes" :key="scope.id">
          <tr
            :class="{
              'border-gray-400 border-t-2': scopeIndex > 0,
            }"
          >
            <th class="align-top p-3" :colspan="headers.length">
              <span
                v-if="scope.id.includes(DataPointTypeEmissionTypeEnum.SCOPE_2)"
              >
                {{ t(DataPointTypeEmissionTypeEnum.SCOPE_2) }}:
                <label
                  v-for="scope2Type in [SCOPE_2_LOCATION_BASED, SCOPE_2_MARKET_BASED]"
                  :key="scope2Type"
                  class="justify-start gap-x-2"
                >
                  <input
                    type="radio"
                    :label="scope.name"
                    :checked="scope.id.includes(scope2Type)"
                    class="radio-primary radio radio-xs align-top ml-1"
                    :disabled="!props.dataPoints.find((dp) => dp.dataPointType.emissionSubcategory?.includes(scope2Type === SCOPE_2_LOCATION_BASED ? 'LOCATION-BASED' : 'MARKET-BASED'))"
                    @change.prevent="scope2TypeSelected = scope2TypeSelected === SCOPE_2_LOCATION_BASED
                      ? SCOPE_2_MARKET_BASED
                      : SCOPE_2_LOCATION_BASED"
                  />
                  <span
                    class="ml-1"
                  >
                    {{ scope2Type === SCOPE_2_LOCATION_BASED ? t('location-based') : t('market-based') }}
                  </span>
                </label>
              </span>
              <span v-else>
                {{ scope.name }}
              </span>
            </th>
          </tr>
          <template v-for="scopeSection in scope.sections" :key="scopeSection.id">
            <template v-if="!hiddenScopeSections.has(scopeSection.id + scope.id)">
              <template v-for="(sourceSection, sourceSectionIndex) in scopeSection.sections" :key="sourceSection.id">
                <template v-for="(row, rowIndex) in sourceSection.rows" :key="row.id">
                  <OgCarbonFootprintTableRow
                    :class="{
                      'border-t-2 border-gray-400': sourceSectionIndex === 0 && rowIndex === 0,
                      'border-t border-gray-100': (sourceSectionIndex > 0 && rowIndex === 0) || (rowIndex > 0 && row.isTopLevel),
                    }"
                    :scopeSectionId="scopeSection.id + scope.id"
                    :scopeSectionName="scopeSection.name"
                    :scopeSectionSpan="sourceSectionIndex === 0 && rowIndex === 0 ? scopeSection.span : 0"
                    :isScopeSectionCollapsed="hiddenScopeSections.has(scopeSection.id + scope.id)"
                    :sourceSectionName="sourceSection.name"
                    :sourceSectionSpan="rowIndex === 0 ? sourceSection.span : 0"
                    :projectName="row.name"
                    :projectId="row.id"
                    :isProjectCollapsible="row.isCollapsible"
                    :isProjectTopLevel="row.isTopLevel"
                    :isProjectCollapsed="hiddenProjects.has(row.id)"
                    :years="perYear.years"
                    :values="row.values"
                    @toggleProject="handleProjectToggle"
                    @toggleScope="handleScopeSectionToggle"
                  />
                </template>
              </template>
            </template>
            <OgCarbonFootprintTableRow
              v-else
              class="border-t-2 border-gray-400"
              :scopeSectionId="scopeSection.id + scope.id"
              :scopeSectionName="scopeSection.name"
              :scopeSectionSpan="1"
              :isScopeSectionCollapsed="true"
              :sourceSectionSpan="1"
              :projectName="scopeSection.ifCollapsed.name"
              :projectId="scopeSection.ifCollapsed.id"
              :isProjectCollapsible="false"
              :isProjectTopLevel="true"
              :isProjectCollapsed="false"
              :years="perYear.years"
              :values="scopeSection.ifCollapsed.values"
              @toggleScope="handleScopeSectionToggle"
            />
            <template v-if="!hiddenScopeSections.has(scopeSection.id + scope.id)">
              <template v-for="(row, rowIndex) in scopeSection.total.rows" :key="row.id">
                <OgCarbonFootprintTableRow
                  :class="{
                    'border-t border-gray-400': rowIndex === 0,
                    'border-t border-gray-100': rowIndex > 0 && row.isTopLevel,
                  }"
                  :sourceSectionName="scopeSection.total.name"
                  :sourceSectionSpan="rowIndex === 0 ? scopeSection.total.span : 0"
                  :projectName="row.name"
                  :projectId="row.id"
                  :isProjectCollapsible="row.isCollapsible"
                  :isProjectTopLevel="row.isTopLevel"
                  :isProjectCollapsed="hiddenProjects.has(row.id)"
                  :years="perYear.years"
                  :values="row.values"
                  @toggleProject="handleProjectToggle"
                />
              </template>
            </template>
          </template>
          <template v-if="!hiddenScopeSections.has(scope.ifCollapsed.id + scope.id)">
            <template v-for="(row, rowIndex) in scope.total.rows" :key="row.id">
              <OgCarbonFootprintTableRow
                :class="{
                  'border-t-2 border-gray-400': rowIndex === 0,
                  'border-t border-gray-100': rowIndex > 0 && row.isTopLevel,
                  'font-bold': rowIndex === scope.total.rows.length - 1,
                }"
                :scopeSectionId="scope.ifCollapsed.id + scope.id"
                :scopeSectionName="scope.total.name"
                :scopeSectionSpan="rowIndex === 0 ? scope.total.span : 0"
                :sourceSectionSpan="rowIndex === 0 ? scope.total.span : 0"
                :projectName="row.name"
                :projectId="row.id"
                :isProjectCollapsible="row.isCollapsible"
                :isProjectTopLevel="row.isTopLevel"
                :isProjectCollapsed="hiddenProjects.has(row.id)"
                :years="perYear.years"
                :values="row.values"
                :hasBottomSpacing="rowIndex === scope.total.rows.length - 1"
                @toggleProject="handleProjectToggle"
                @toggleScope="handleScopeSectionToggle"
              />
            </template>
          </template>
          <OgCarbonFootprintTableRow
            v-else
            class="border-t-2 border-gray-400 font-bold"
            :scopeSectionId="scope.ifCollapsed.id + scope.id"
            :scopeSectionName="scope.total.name"
            :scopeSectionSpan="1"
            :isScopeSectionCollapsed="true"
            :sourceSectionSpan="1"
            :projectName="scope.ifCollapsed.name"
            :projectId="scope.ifCollapsed.id + scope.id"
            :isProjectCollapsible="false"
            :isProjectTopLevel="true"
            :isProjectCollapsed="false"
            :years="perYear.years"
            :values="scope.ifCollapsed.values"
            @toggleScope="handleScopeSectionToggle"
          />
        </template>
      </tbody>

      <tfoot class="text-xs font-bold">
        <tr class="border-t-[3px] border-double border-gray-400">
          <th scope="row" class="align-top p-3 uppercase">
            {{ t('Total') }}
          </th>
          <td class="align-top p-3">
            {{ t('All scopes') }}
          </td>
          <td />
          <td v-for="year in perYear.years" :key="year.key" class="align-top p-3">
            <MlDataPointValue
              v-if="visibleTableData.total[year.key]"
              :dataPointValueAndType="visibleTableData.total[year.key]"
            />
            <span v-else>-</span>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>
