import { gql } from '@apollo/client/core';

export default gql`
  query SourceDocumentDownload($fileName: String!, $filePath: String!) {
    downloadRepositorySourceDocument(fileName: $fileName, filePath: $filePath) {
      file
      name
      type
    }
  }
`;
