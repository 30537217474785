<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed, ref, watch } from 'vue';
import sortBy from 'lodash/sortBy';
import type { PgStatisticsQuery } from '@/__generated__/types';
import PG_STATISTICS_QUERY from './PgStatistics.query';

const { result } = useQuery<PgStatisticsQuery>(PG_STATISTICS_QUERY);
const locations = computed(() => sortBy(result.value?.entityLocationSummaries ?? [],
  (entityLocationSummary) => entityLocationSummary.name.toLowerCase()));

const selectedLocation = ref(locations.value[0]?._id ?? '');
watch(locations, () => {
  selectedLocation.value = locations.value[0]?._id ?? '';
});

</script>

<template>
  <div
    class="relative flex"
    data-cy="PgHome"
  >
    <div class="flex-1">
      <router-view name="subview" />
    </div>
  </div>
</template>
