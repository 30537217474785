import * as Vue from 'vue';
import { computed, ref, type Ref, type ShallowRef, shallowRef, watch, watchEffect } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { TiptapCollabProvider } from '@hocuspocus/provider';
import syncedStore, { boxed, enableVueBindings, filterArray, getYjsDoc } from '@syncedstore/core';
import { v4 } from 'uuid';
import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import {
  type DoubleMaterialityIroImpactType, DoubleMaterialityIroRiskAndOpportunityType,
  DoubleMaterialityMandatoryReason,
  type PgDoubleMaterialitySelectionQuery,
  type PgDoubleMaterialitySelectionQueryVariables,
} from '@/__generated__/types';
import STORE_QUERY from './store.query';
import type {
  IroImpact,
  IroRiskAndOpportunity,
  PotentialMagnitude,
  RecommendedESRS,
  Stakeholder,
  StakeholderEngageOption,
  StakeholderOption,
  TimeHorizons,
  Topic,
  TopicsOptions,
} from './types';
import { calculateMaterialityForImpact, calculateMaterialityForRiskAndOpportunity } from './utils';

enableVueBindings(Vue);

export type Store = ReturnType<typeof initializeStore>;

const store = shallowRef<Store>();
const provider = shallowRef<TiptapCollabProvider>();

const isReady = ref(false);

const isNeededDataLoaded = ref(false);
const defaultTopics = shallowRef<PgDoubleMaterialitySelectionQuery['getDoubleMaterialityTopics']>([]);
const dataPointCategories = shallowRef<PgDoubleMaterialitySelectionQuery['getDataPointCategoriesForDoubleMateriality']>([]);

function initializeStore() {
  return syncedStore({
    potentialMagnitude: {} as PotentialMagnitude,
    timeHorizons: {} as TimeHorizons,
    topics: [] as Topic[],
    topicsOptions: {} as TopicsOptions,
    iroImpacts: [] as IroImpact[],
    iroRisksAndOpportunities: [] as IroRiskAndOpportunity[],
    stakeholders: [] as Stakeholder[],
    stakeholderOptions: [] as StakeholderOption[],
    stakeholderEngageOptions: [] as StakeholderEngageOption[],
    recommendedESRS: {} as RecommendedESRS,
  });
}

export function useCreateStore(name: Ref<string>) {
  watchEffect((onCleanup) => {
    if (name.value) {
      store.value = initializeStore();

      provider.value = new TiptapCollabProvider({
        name: `doubleMateriality.${name.value}`,
        appId: 'xy9dj9e6',
        token: 'notoken',
        document: getYjsDoc(store.value),
        onAuthenticated() {
          isReady.value = true;
        },
      });

      onCleanup(() => {
        provider.value?.disconnect();
        provider.value?.destroy();
        store.value = undefined;
        provider.value = undefined;
      });
    }
  });

  const { result, loading } = useQuery<PgDoubleMaterialitySelectionQuery, PgDoubleMaterialitySelectionQueryVariables>(STORE_QUERY);

  watch(result, (newResultValue) => {
    if (newResultValue) {
      defaultTopics.value = newResultValue.getDoubleMaterialityTopics;
      dataPointCategories.value = newResultValue.getDataPointCategoriesForDoubleMateriality;
    }
  }, { immediate: true });
  watch(loading, (newLoadingValue) => {
    isNeededDataLoaded.value = !newLoadingValue;
  }, { immediate: true });
}

export function useStore() {
  if (!store.value) {
    throw new Error('Store is not initialized!');
  }
  return store as ShallowRef<Store>;
}

export function useIsStoreReady() {
  return computed(() => isReady.value && isNeededDataLoaded.value);
}

export function useDefaultTopics() {
  return defaultTopics;
}

function generateId(type: 'topic' | 'iroImpact' | 'iroRiskAndOpportunity' | 'stakeholder') {
  const newId = v4();

  switch (type) {
    case 'topic': {
      if (!(store.value?.topics.map((item) => item._id) || []).includes(newId)) {
        return newId;
      }
      break;
    }
    case 'iroImpact': {
      if (!(store.value?.iroImpacts.map((item) => item._id) || []).includes(newId)) {
        return newId;
      }
      break;
    }
    case 'iroRiskAndOpportunity': {
      if (!(store.value?.iroRisksAndOpportunities.map((item) => item._id) || []).includes(newId)) {
        return newId;
      }
      break;
    }
    case 'stakeholder': {
      if (!(store.value?.stakeholders.map((item) => item._id) || []).includes(newId)) {
        return newId;
      }
      break;
    }
    default:
      throw new Error(`Invalid type ${type}`);
  }

  return generateId(type);
}

function addInitialPotentialMagnitude() {
  if (store.value) {
    store.value.potentialMagnitude.criticallyRelevantFinancialEffectValue = 0;
    store.value.potentialMagnitude.criticallyRelevantFinancialEffectComment = '';
    store.value.potentialMagnitude.criticallyRelevantFinancialEffectDocument = undefined;

    store.value.potentialMagnitude.significantFinancialEffectValue = 0;
    store.value.potentialMagnitude.significantFinancialEffectComment = '';
    store.value.potentialMagnitude.significantFinancialEffectDocument = undefined;

    store.value.potentialMagnitude.relevantFinancialEffectValue = 0;
    store.value.potentialMagnitude.relevantFinancialEffectComment = '';
    store.value.potentialMagnitude.relevantFinancialEffectDocument = undefined;

    store.value.potentialMagnitude.lowFinancialEffectValue = 0;
    store.value.potentialMagnitude.lowFinancialEffectComment = '';
    store.value.potentialMagnitude.lowFinancialEffectDocument = undefined;

    store.value.potentialMagnitude.minorFinancialEffectValue = 0;
    store.value.potentialMagnitude.minorFinancialEffectComment = '';
    store.value.potentialMagnitude.minorFinancialEffectDocument = undefined;

    store.value.potentialMagnitude.almostNoFinancialEffectValue = 0;
    store.value.potentialMagnitude.almostNoFinancialEffectComment = '';
    store.value.potentialMagnitude.almostNoFinancialEffectDocument = undefined;
  }
}

function addInitialTimeHorizons() {
  if (store.value) {
    store.value.timeHorizons.shortTermValue = 0;
    store.value.timeHorizons.shortTermComment = '';
    store.value.timeHorizons.shortTermDocument = undefined;

    store.value.timeHorizons.mediumTermValue = 5;
    store.value.timeHorizons.mediumTermComment = '';
    store.value.timeHorizons.mediumTermDocument = undefined;

    store.value.timeHorizons.longTermValue = 5;
    store.value.timeHorizons.longTermComment = '';
    store.value.timeHorizons.longTermDocument = undefined;
  }
}

function addInitialData() {
  if (store.value) {
    store.value!.iroImpacts.splice(0, store.value.iroImpacts.length);
    store.value!.iroRisksAndOpportunities.splice(0, store.value.iroRisksAndOpportunities.length);

    defaultTopics.value.forEach((defaultTopic) => {
      const topicId = generateId('topic');

      store.value!.topics.push({
        _id: topicId,
        defaultTopicId: defaultTopic._id,
        esrs: defaultTopic.esrs,
        topic: defaultTopic.topic,
        subTopic: '',
        subSubTopic: '',
        timeHorizon: '',
        valueChain: [],
        comment: '',
        document: boxed(null),
      });

      store.value!.topicsOptions[defaultTopic.topic] = defaultTopic.subTopics.map((item) => ({
        subTopic: item.subTopic,
        subSubTopics: [...item.subSubTopics],
      }));

      store.value!.iroImpacts.push({
        _id: generateId('iroImpact'),
        topicId,
        impact: '',
        type: null,
        comment: '',
        document: boxed(null),
        scale: null,
        scope: null,
        irremediability: null,
        likelihood: null,
        scoreComment: '',
        scoreDocument: boxed(null),
      });

      store.value!.iroRisksAndOpportunities.push({
        _id: generateId('iroRiskAndOpportunity'),
        topicId,
        riskAndOpportunity: '',
        type: null,
        comment: '',
        document: boxed(null),
        likelihood: null,
        potentialMagnitude: null,
        scoreComment: '',
        scoreDocument: boxed(null),
      });
    });

    dataPointCategories.value.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        store.value!.recommendedESRS[`${category._id}_${subcategory._id}`] = {
          value: !category.doubleMaterialityTopic
            || subcategory.doubleMaterialityMandatoryReason === DoubleMaterialityMandatoryReason.Regardless,
          materiality: null,
        };
      });
    });

    calculateRecommendedESRS();
  }
}

function addTopic(index: number) {
  if (store.value) {
    const topicId = generateId('topic');

    store.value.topics.splice(index + 1, 0, {
      _id: topicId,
      defaultTopicId: store.value.topics[index].defaultTopicId,
      esrs: store.value.topics[index].esrs,
      topic: store.value.topics[index].topic,
      subTopic: '',
      subSubTopic: '',
      timeHorizon: '',
      valueChain: [],
      comment: '',
      document: boxed(null),
    });

    store.value.iroImpacts.push({
      _id: generateId('iroImpact'),
      topicId,
      impact: '',
      type: null,
      comment: '',
      document: boxed(null),
      scale: null,
      scope: null,
      irremediability: null,
      likelihood: null,
      scoreComment: '',
      scoreDocument: boxed(null),
    });

    store.value.iroRisksAndOpportunities.push({
      _id: generateId('iroRiskAndOpportunity'),
      topicId,
      riskAndOpportunity: '',
      type: null,
      comment: '',
      document: boxed(null),
      likelihood: null,
      potentialMagnitude: null,
      scoreComment: '',
      scoreDocument: boxed(null),
    });

    calculateRecommendedESRS();
  }
}

function changeTopic(index: number, topic: string) {
  if (store.value) {
    store.value.topics[index].topic = topic;
    store.value.topics[index].esrs = defaultTopics.value.find((item) => item.topic === topic)?.esrs ?? '';
    store.value.topics[index].subTopic = '';
    store.value.topics[index].subSubTopic = '';

    calculateRecommendedESRS();
  }
}

function removeTopic(index: number) {
  if (store.value) {
    const topicId = store.value.topics[index]._id;
    store.value.topics.splice(index, 1);
    filterArray(store.value.iroImpacts, (iroImpact) => iroImpact.topicId !== topicId);
    filterArray(
      store.value.iroRisksAndOpportunities,
      (iroRiskAndOpportunity) => iroRiskAndOpportunity.topicId !== topicId,
    );

    calculateRecommendedESRS();
  }
}

function changeSubTopic(index: number, subTopic: string) {
  if (store.value) {
    store.value.topics[index].subTopic = subTopic;
    store.value.topics[index].subSubTopic = '';

    calculateRecommendedESRS();
  }
}

function createSubTopic(index: number, subTopic: string) {
  store.value?.topicsOptions[store.value.topics[index].topic]?.push({
    subTopic,
    subSubTopics: [],
  });

  calculateRecommendedESRS();
}

function createSubSubTopic(index: number, subSubTopic: string) {
  store
    .value
    ?.topicsOptions[store.value.topics[index].topic]
    ?.find((item) => item.subTopic === store.value?.topics[index].subTopic)
    ?.subSubTopics
    .push(subSubTopic);

  calculateRecommendedESRS();
}

function addIroImpact(index: number, topicId: string) {
  if (store.value) {
    store.value.iroImpacts.splice(index + 1, 0, {
      _id: generateId('iroImpact'),
      topicId,
      impact: '',
      type: null,
      comment: '',
      document: boxed(null),
      scale: null,
      scope: null,
      irremediability: null,
      likelihood: null,
      scoreComment: '',
      scoreDocument: boxed(null),
    });

    calculateRecommendedESRS();
  }
}

function removeIroImpact(index: number) {
  if (store.value) {
    // Don't remove the last IRO impact for given topic.
    const { topicId } = store.value.iroImpacts[index];

    if (store.value.iroImpacts.filter((iroImpact) => iroImpact.topicId === topicId).length > 1) {
      store.value.iroImpacts.splice(index, 1);
    }

    calculateRecommendedESRS();
  }
}

function changeIroImpact(index: number, iroImpact: string) {
  if (store.value) {
    store.value.iroImpacts[index].impact = iroImpact.trim();
    if (!store.value.iroImpacts[index].impact) {
      changeIroImpactType(index, null);
    }

    calculateRecommendedESRS();
  }
}

function changeIroImpactType(index: number, type: DoubleMaterialityIroImpactType | null) {
  if (store.value) {
    store.value.iroImpacts[index].type = type;
    store.value.iroImpacts[index].scale = null;
    store.value.iroImpacts[index].scope = null;
    store.value.iroImpacts[index].irremediability = null;
    store.value.iroImpacts[index].likelihood = null;

    calculateRecommendedESRS();
  }
}

function addIroRiskAndOpportunity(index: number, topicId: string) {
  if (store.value) {
    store.value.iroRisksAndOpportunities.splice(index + 1, 0, {
      _id: generateId('iroRiskAndOpportunity'),
      topicId,
      riskAndOpportunity: '',
      type: null,
      comment: '',
      document: boxed(null),
      likelihood: null,
      potentialMagnitude: null,
      scoreComment: '',
      scoreDocument: boxed(null),
    });

    calculateRecommendedESRS();
  }
}

function removeIroRiskAndOpportunity(index: number) {
  if (store.value) {
    // Don't remove the last IRO risk and opportunity for given topic.
    const { topicId } = store.value.iroRisksAndOpportunities[index];

    if (store.value.iroRisksAndOpportunities.filter((iroRiskAndOpportunity) => iroRiskAndOpportunity.topicId === topicId).length > 1) {
      store.value.iroRisksAndOpportunities.splice(index, 1);
    }

    calculateRecommendedESRS();
  }
}

function changeIroRiskAndOpportunityRiskAndOpportunity(index: number, text: string) {
  if (store.value) {
    store.value.iroRisksAndOpportunities[index].riskAndOpportunity = text.trim();

    calculateRecommendedESRS();
  }
}

function changeIroRiskAndOpportunityType(index: number, type: DoubleMaterialityIroRiskAndOpportunityType | null) {
  if (store.value) {
    store.value.iroRisksAndOpportunities[index].type = type;

    calculateRecommendedESRS();
  }
}

function addDefaultStakeholderOptions() {
  [
    'Employees',
    'Customers',
    'Suppliers and Vendors',
    'Shareholders and owners',
    'Board of directors',
    'Local communities',
    'Environmental advocacy groups',
    'NGOs',
    'Unions and labor organization',
    'Local authorities and municipalities',
    'Government and regulatory authorities',
    'Industry regulators',
    'Foundations and philanthropic organizations',
    'Civil society',
    'Creditors',
    'Debt holders',
    'Competitors',
    'Industry associations and trade groups',
    'Nature',
    'Media and press',
    'Insurance companies',
    'Shareholder advocacy groups',
    'Retired employees',
    'Families of employees',
    'Legal advisors and law firms',
    'Educational institutions',
    'Auditors and accounting firms',
    'Investment analysts',
    'Investment funds and institutional investors',
    'Asset managers',
    'Startups and incubators',
    'Strategic partners and alliances',
    'Professional associations',
  ].forEach((option) => {
    store.value?.stakeholderOptions.push(option);
  });
}

function addDefaultStakeholderEngageOptions() {
  [
    'Surveys',
    'Interviews',
    'Focus groups',
    'Workshops',
    'Suggestion boxes',
    'Company wide meetings with open forums',
    'Webinars',
    'Other',
  ].forEach((option) => {
    store.value?.stakeholderEngageOptions.push(option);
  });
}

function addInitialStakeholders() {
  for (let i = 0; i < 3; i += 1) {
    addStakeholder();
  }
}

function addStakeholder() {
  if (store.value) {
    store.value.stakeholders.push({
      _id: generateId('stakeholder'),
      stakeholder: null,
      description: '',
      reasons: '',
      engage: null,
    });
  }
}

function removeStakeholder(index: number) {
  if (store.value) {
    store.value.stakeholders.splice(index, 1);
  }
}

function createStakeholderOption(option: string) {
  if (store.value) {
    store.value.stakeholderOptions.push(option);
  }
}

function createStakeholderEngageOption(option: string) {
  if (store.value) {
    store.value.stakeholderEngageOptions.push(option);
  }
}

function resetStore() {
  if (store.value) {
    (Object.keys(store.value.potentialMagnitude) as Array<keyof PotentialMagnitude>).forEach((key) => {
      delete store.value!.potentialMagnitude[key];
    });
    (Object.keys(store.value.timeHorizons) as Array<keyof TimeHorizons>).forEach((key) => {
      delete store.value!.timeHorizons[key];
    });
    store.value.topics.splice(0, store.value.topics.length);
    (Object.keys(store.value.topicsOptions) as Array<keyof TopicsOptions>).forEach((key) => {
      delete store.value!.topicsOptions[key];
    });
    store.value.iroImpacts.splice(0, store.value.iroImpacts.length);
    store.value.iroRisksAndOpportunities.splice(0, store.value.iroRisksAndOpportunities.length);
    store.value.stakeholders.splice(0, store.value.stakeholders.length);
    store.value.stakeholderOptions.splice(0, store.value.stakeholderOptions.length);
    store.value.stakeholderEngageOptions.splice(0, store.value.stakeholderEngageOptions.length);
    (Object.keys(store.value.recommendedESRS) as Array<keyof RecommendedESRS>).forEach((key) => {
      delete store.value!.recommendedESRS[key];
    });

    calculateRecommendedESRS();
  }
}

function calculateRecommendedESRS() {
  if (store.value && Object.keys(store.value.recommendedESRS ?? {}).length > 0) {
    const topicCategoryMap = dataPointCategories.value.reduce<Record<string, string>>((acc, item) => {
      if (item.doubleMaterialityTopic) {
        acc[item.doubleMaterialityTopic._id] = item._id;
      }

      return acc;
    }, {});
    const topicDefaultTopicMap = store.value.topics.reduce<Record<string, string>>((acc, topic) => {
      acc[topic._id] = topic.defaultTopicId;
      return acc;
    }, {});

    const impactsPerCategory = groupBy(store.value.iroImpacts, (iroImpact) => topicCategoryMap[topicDefaultTopicMap[iroImpact.topicId]]);
    const risksAndOpportunitiesPerCategory = groupBy(
      store.value.iroRisksAndOpportunities,
      (iroRisksAndOpportunities) => topicCategoryMap[topicDefaultTopicMap[iroRisksAndOpportunities.topicId]],
    );

    const impactsMaterialityPerCategory = mapValues(
      impactsPerCategory,
      (impacts) => impacts.reduce((acc, iroImpact) => {
        return acc || !!calculateMaterialityForImpact(
          iroImpact.scale,
          iroImpact.scope,
          iroImpact.irremediability,
          iroImpact.likelihood,
          iroImpact.type,
        );
      }, false),
    );
    const risksAndOpportunitiesMaterialityPerCategory = mapValues(
      risksAndOpportunitiesPerCategory,
      (risksAndOpportunities) => risksAndOpportunities.reduce((acc, iroRiskAndOpportunity) => {
        return acc || !!calculateMaterialityForRiskAndOpportunity(
          iroRiskAndOpportunity.likelihood,
          iroRiskAndOpportunity.potentialMagnitude,
        );
      }, false),
    );

    dataPointCategories.value.forEach((category) => {
      category.subcategories.forEach((subcategory) => {
        let newValue: boolean;

        switch (subcategory.doubleMaterialityMandatoryReason) {
          case DoubleMaterialityMandatoryReason.Regardless:
            newValue = true;
            break;
          case DoubleMaterialityMandatoryReason.TopicIsMaterial:
          case DoubleMaterialityMandatoryReason.DueToSustainabilityMatter:
            newValue = !!impactsMaterialityPerCategory[category._id] || !!risksAndOpportunitiesMaterialityPerCategory[category._id];
            break;
          case DoubleMaterialityMandatoryReason.DueToFinancialMateriality:
            newValue = !!risksAndOpportunitiesMaterialityPerCategory[category._id];
            break;
          default:
            newValue = store.value!.recommendedESRS[`${category._id}_${subcategory._id}`]!.value;
        }

        store.value!.recommendedESRS[`${category._id}_${subcategory._id}`] = {
          value: newValue,
          materiality: newValue,
        };
      });
    });
  }
}

export function useStoreMethods() {
  return {
    addInitialPotentialMagnitude,
    addInitialTimeHorizons,
    addInitialData,
    addTopic,
    changeTopic,
    removeTopic,
    changeSubTopic,
    createSubTopic,
    createSubSubTopic,
    addIroImpact,
    removeIroImpact,
    changeIroImpact,
    changeIroImpactType,
    addIroRiskAndOpportunity,
    removeIroRiskAndOpportunity,
    changeIroRiskAndOpportunityRiskAndOpportunity,
    changeIroRiskAndOpportunityType,
    addDefaultStakeholderOptions,
    addDefaultStakeholderEngageOptions,
    addInitialStakeholders,
    addStakeholder,
    removeStakeholder,
    createStakeholderOption,
    createStakeholderEngageOption,
    resetStore,
    calculateRecommendedESRS,
  };
}
