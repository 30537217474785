type SortItem<T> = T;

function processValue(value: string) {
  return value.includes('_') ? value.split('_').slice(1).join('_') : value;
}

function getValueForSort<T>(item: SortItem<T>, propertyName: keyof SortItem<T>) {
  return processValue(String(item[propertyName] ?? ''));
}

export function sortArrayByProperty<T>(array: SortItem<T>[], propertyName: keyof SortItem<T>) {
  return array.sort((a, b) => getValueForSort(a, propertyName)
    .localeCompare(getValueForSort(b, propertyName)));
}
