<script setup lang="ts">
import { computed, ref, type HTMLAttributes } from 'vue';
import {
  ArcElement, BarElement, LineElement, PointElement,
  CategoryScale, LinearScale, TimeScale, TimeSeriesScale,
  Legend, Title, Tooltip, Chart,
  type ChartOptions, LineController, BarController,
} from 'chart.js';
import AnnotationPlugin from 'chartjs-plugin-annotation';
import DataLabels, { type Context } from 'chartjs-plugin-datalabels';
import DoughnutLabel from 'chartjs-plugin-doughnutlabel-rebourne';
import { Bar, Pie, Line } from 'vue-chartjs';
import type { PartialDeep } from 'type-fest';
import merge from 'lodash/merge';
import { StarIcon } from '@heroicons/vue/solid';
import { CogIcon } from '@heroicons/vue/outline';
import { useI18n } from 'vue-i18n';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import 'chartjs-adapter-dayjs-3';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import { type TUniversalChartData, SupportedChartType } from './types';

const { formatNumber } = useFormatNumber();

Chart.register(
  ArcElement,
  BarElement,
  LineElement,
  CategoryScale,
  TimeScale,
  TimeSeriesScale,
  LinearScale,
  Tooltip,
  Title,
  Legend,
  PointElement,
  AnnotationPlugin,
  LineController,
  BarController,
  DataLabels,
  DoughnutLabel,
);

Chart.defaults.elements.point.radius = 0;

const charts = {
  [SupportedChartType.BAR]: Bar,
  [SupportedChartType.LINE]: Line,
  [SupportedChartType.PIE]: Pie,
};

type TProps = {
  type: 'pie' | 'bar' | 'line';
  chartData: PartialDeep<TUniversalChartData>;
  chartClass?: HTMLAttributes['class'],
  wrapperClass?: HTMLAttributes['class'],
  title?: string;
  pinned?: boolean;
  chartOptions?: ChartOptions;
  loading?: boolean
  badges?: string[];
  showStarIcon?: boolean
}

const props = defineProps<TProps>();
const emit = defineEmits(['update:pinned']);

const { t } = useI18n();

const chartRoot = ref();

const chartDataModifiers = computed(() => {
  return props.chartData;
});

const defaultOptions: ChartOptions = {
  maintainAspectRatio: false,
  elements: {
    line: { tension: 0.1 },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: { display: props.chartOptions?.indexAxis !== 'y', position: 'top' },
    tooltip: {
      callbacks: {
        label: (context) => {
          const y = (context.raw as {y: number})?.y;

          if (typeof y === 'number') {
            return `${context.dataset.label}: ${formatNumber(Number(y)) ?? ''}`;
          }

          if (typeof context.raw === 'number') {
            return `${context.label}: ${formatNumber(Number(context.raw)) ?? ''}`;
          }

          return context.label;
        },
      },
    },
  },

  scales: {
    x: {
      display: props.type !== SupportedChartType.PIE,
      offset: true,
      ticks: {
        padding: 7,
        minRotation: props.chartOptions?.indexAxis !== 'y' ? 45 : 0,
        maxRotation: props.chartOptions?.indexAxis !== 'y' ? 45 : 0,
      },
      grid: { display: false },
      time: {
        displayFormats: {
          month: 'MMM',
        },
      },
    },
    y: {
      display: props.type !== SupportedChartType.PIE,
      ticks: {
        padding: 10,
        ...(props.chartOptions?.indexAxis !== 'y' ? { callback: (value) => formatNumber(value) } : {}),
      },
      grid: { drawBorder: false, drawTicks: false },
      beginAtZero: true,
    },
  },
};

const defaultPieOptions = merge({}, defaultOptions, {
  cutout: '60%',
  plugins: {
    legend: {
      display: true,
      position: 'right',
      labels: {
        usePointStyle: true,
      },
    },
    datalabels: {
      display: true,
      color: 'white',
      formatter: (value: number, context: Context) => {
        const percent = Math.round((value / (context.chart.getDatasetMeta(0) as { total: number }).total) * 100);

        if (percent < 20) return ''; // will look weird if there is not enough space
        return `${percent} %`;
      },
    },
    tooltip: {
      callbacks: {
        label: (context: {label: string}) => {
          return `${context.label}`;
        },
      },
    },
  },
});

const mergedChartData = computed(() => ({ ...merge({}, chartDataModifiers.value, props.chartData) }));

const mergedOptions = computed(() => ({ ...merge({}, props.type === 'pie' ? defaultPieOptions : defaultOptions, props.chartOptions) }));
</script>

<template>
  <div
    ref="chartRoot"
    class="relative rounded-md bg-gray-50 py-4"
    :class="props.wrapperClass"
  >
    <div class="mb-4 px-4">
      <div class="flex items-center">
        <h4 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
          <slot name="title">
            {{ t(props.title || '') }}
          </slot>
        </h4>

        <button
          v-if="props.showStarIcon"
          type="submit"
          class="chart-button ml-auto"
          :disabled="loading"
          @click="emit('update:pinned', !props.pinned)"
        >
          <StarIcon
            class="w-4"
            :class="{
              'text-amber-500': props.pinned,
              'text-gray-500': !props.pinned,
            }"
          />
        </button>
        <MlMenu
          v-if="$slots.menuItems"
          placement="bottom-end"
        >
          <button
            class="chart-button ml-2"
            data-cy="MenuButtonChartDatePicker"
            type="submit"
          >
            <CogIcon class="text-gray-500" />
          </button>
          <template #menuItems>
            <slot name="menuItems" />
          </template>
        </MlMenu>
      </div>
      <AtBadge
        v-for="badgeText in props.badges"
        :key="badgeText"
        class="mr-1 !bg-white py-0 text-xs font-light"
        type="neutral"
      >
        {{ badgeText }}
      </AtBadge>
    </div>
    <p
      v-if="!mergedChartData.datasets?.length"
      class="flex h-48 items-center justify-center"
    >
      {{ t('No data within your selected timeframe.') }}
    </p>
    <component
      :is="charts[props.type]"
      v-else
      class="h-48 pl-2 pr-4"
      :chartData="mergedChartData as any"
      :chartOptions="mergedOptions as any"
      :chartClass="chartClass"
      v-bind="$attrs"
    />
  </div>
</template>

<style lang="postcss" scoped>
.chart-button {
  @apply w-6 h-6 p-1 rounded-md bg-gray-200 hover:bg-gray-200;
}
</style>
