<script setup lang="ts">
// import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
// import { CheckboxSize } from '@/components/atoms/AtCheckbox/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
// import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import MlDialog from '@/components/molecules/MlDialog.vue';

// TODO Handle "Save this questionnaire as a template" feature.

const props = defineProps<{
  project: string;
  category: string;
  subcategory: string;
}>();

const emit = defineEmits<{
  close: [];
}>();

const { t } = useI18n();
const router = useRouter();

// const saveAsTemplate = ref(false);

function handleCancel() {
  emit('close');
}

function handleFinishClick() {
  router.push({
    name: 'home',
  });
}

function handleGoToClick() {
  router.push({
    name: 'projectsProjectDataEntry',
    params: {
      project: props.project,
    },
    query: {
      category: props.category,
      subcategory: props.subcategory,
    },
  });
}
</script>

<template>
  <MlDialog
    isOpen
    :closeOnOutside="false"
    class="!bg-blue-50 border border-blue-600 max-w-xl !px-16 !pb-10"
    @close="handleCancel"
  >
    <template #title>
      {{ t('Success!') }}
    </template>

    <template #default>
      <div class="pt-6">
        <p class="mb-10 text-sm">
          {{ t('Your custom questionnaire has been created and all the persons you shared it with will shortly be notified.') }}
        </p>
      </div>

      <!--      <div class="mb-24">-->
      <!--        <AtCheckbox-->
      <!--          squared-->
      <!--          :checked="saveAsTemplate"-->
      <!--          :size="CheckboxSize.XS"-->
      <!--          @toggleCheckbox="saveAsTemplate = !saveAsTemplate"-->
      <!--        >-->
      <!--          <template #label>-->
      <!--            <p class="text-sm font-medium text-gray-700">-->
      <!--              {{ t('Save this questionnaire as a template') }}-->
      <!--            </p>-->
      <!--          </template>-->
      <!--        </AtCheckbox>-->
      <!--      </div>-->

      <div class="flex space-x-4 justify-between items-center">
        <div class="flex-0">
          <AtButton type="button" variant="outline" @click="handleFinishClick">
            {{ t('Finish and close') }}
          </AtButton>
        </div>
        <div class="flex-0">
          <AtButton type="button" @click="handleGoToClick">
            {{ t('Go to data entry') }}
          </AtButton>
        </div>
      </div>
    </template>
  </MlDialog>
</template>
