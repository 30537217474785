import { gql } from '@apollo/client/core';

export default gql`
  query OgConnectGoalStep {
    getTargets {
      _id
      name
      number
      isRecommended

      suggestedDataPointTypes {
        _id
        friendlyName
        question
        name
        entity {
          _id
        }
        activeReportingFramework {
          _id
          category
          subcategory
        }
        valueDataType
        valueUnit
        valueUnitDivisor
      }

      goal {
        _id
        name
        description
        reportingFramework
      }
    }

    getEsgDataPointTypes {
      _id
      friendlyName
      question
      name
      entity {
        _id
      }
      activeReportingFramework {
        _id
        category
        subcategory
      }
      valueDataType
      valueUnit
      valueUnitDivisor
    }
  }
`;
