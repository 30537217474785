<script setup lang="ts">
import type { Component } from 'vue';
import { ChevronRightIcon } from '@heroicons/vue/outline';

const props = withDefaults(defineProps<{
  as?: string | Component;
  submenuItem?: boolean;
  noClose?: boolean;
}>(), {
  as: 'button',
});
</script>

<template>
  <component
    :is="props.as"
    v-close-popper="!props.noClose"
    class="text-gray-700 flex w-full min-w-[5rem] max-w-4xl items-center break-words px-4 py-2 text-sm first:rounded-t-md last:rounded-b-md hover:bg-gray-200 disabled:pointer-events-none disabled:opacity-50"
  >
    <slot />
    <ChevronRightIcon
      v-if="props.submenuItem"
      class="relative -right-4 ml-auto h-6 w-6"
    />
  </component>
</template>
