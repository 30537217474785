import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  LibraryGuidanceDocumentEnum,
  TagColorEnum,
} from '@/__generated__/types';
import { useGuidanceDocumentDownload } from './useGuidanceDocumentDownload';

export function useGuidances() {
  const { t } = useI18n();

  const guidances = computed(() => [
    {
      key: LibraryGuidanceDocumentEnum.PRODUCT_AND_CONTENT_UPDATES,
      title: t(LibraryGuidanceDocumentEnum.PRODUCT_AND_CONTENT_UPDATES),
      htmlDescription: t('<p>This change log tracks and documents all updates to the platform. Any modifications, such as content updates, design changes, or new features, are detailed here.</p>'),
      download: useGuidanceDocumentDownload(LibraryGuidanceDocumentEnum.PRODUCT_AND_CONTENT_UPDATES),
      badges: [{
        text: t('Codio updates'),
        type: TagColorEnum.EMERALD,
      }],
    },
    {
      key: LibraryGuidanceDocumentEnum.DOUBLE_MATERIALITY_ANALYSIS,
      title: t(LibraryGuidanceDocumentEnum.DOUBLE_MATERIALITY_ANALYSIS),
      htmlDescription: t('<p>This explains the process and usage of double materiality assessment module (the DMA), selecting your topics, evaluating IRO (impacts, risks and opportunities) and involving stakeholders. You end up with DMA matrix.</p>'),
      download: useGuidanceDocumentDownload(LibraryGuidanceDocumentEnum.DOUBLE_MATERIALITY_ANALYSIS),
      badges: [{
        text: t('Content knowledge'),
        type: TagColorEnum.BLUE,
      }],
    },
    {
      key: LibraryGuidanceDocumentEnum.HOW_TO_CUSTOMIZE_THE_PLATFORM,
      title: t(LibraryGuidanceDocumentEnum.HOW_TO_CUSTOMIZE_THE_PLATFORM),
      htmlDescription: t('<p>This is technical guidance and explains, among other things, how to:<br><li>set up a new project or a new workspace,</li><li>create a custom datapoint/question or a whole questionnaire,</li><li>set up notifications, deadlines and more,</li><li>invite team members and new users.</li></p>'),
      download: useGuidanceDocumentDownload(LibraryGuidanceDocumentEnum.HOW_TO_CUSTOMIZE_THE_PLATFORM),
      badges: [{
        text: t('User Manual'),
        type: TagColorEnum.ROSE,
      }],
    },
    {
      key: LibraryGuidanceDocumentEnum.ESRS_CSRD_MODULE,
      title: t(LibraryGuidanceDocumentEnum.ESRS_CSRD_MODULE),
      htmlDescription: t('This is a content guidance on the structure of ESRS, including its strategic, governance, impacts, risks and opportunities as well as metrics and targets requirements. We will address the MDR chapters, as well as all other structural topics.'),
      download: useGuidanceDocumentDownload(LibraryGuidanceDocumentEnum.ESRS_CSRD_MODULE),
      badges: [{
        text: t('Content knowledge'),
        type: TagColorEnum.BLUE,
      }],
    },
    {
      key: LibraryGuidanceDocumentEnum.CARBON_ACCOUNTING_MODULE,
      title: t(LibraryGuidanceDocumentEnum.CARBON_ACCOUNTING_MODULE),
      htmlDescription: t('Here we explain how to use the CO2 module, calculate your company emissions and provide an overview of emission factor sources. You will find explanations on market/location based calculations and many other relevant questions.'),
      download: useGuidanceDocumentDownload(LibraryGuidanceDocumentEnum.CARBON_ACCOUNTING_MODULE),
      badges: [{
        text: t('User Manual'),
        type: TagColorEnum.ROSE,
      }, {
        text: t('Content knowledge'),
        type: TagColorEnum.BLUE,
      }],
    },
    {
      key: LibraryGuidanceDocumentEnum.EU_TAXONOMY,
      title: t(LibraryGuidanceDocumentEnum.EU_TAXONOMY),
      htmlDescription: t('This document explains the structure and demands of the EU Taxonomy, and all the steps an assessment requires - from business activities, substantial contribution, DNSH and minimum safeguards.'),
      download: useGuidanceDocumentDownload(LibraryGuidanceDocumentEnum.EU_TAXONOMY),
      badges: [{
        text: t('User Manual'),
        type: TagColorEnum.ROSE,
      }, {
        text: t('Content knowledge'),
        type: TagColorEnum.BLUE,
      }],
    },
    {
      key: LibraryGuidanceDocumentEnum.ESRS_CSRD_DEFINITIONS,
      title: t(LibraryGuidanceDocumentEnum.ESRS_CSRD_DEFINITIONS),
      htmlDescription: t('<p>These are main definitions regarding ESRS standards, and here you can search through any guidance EFRAG offers. If there is a question you find unclear, definition should be in this document.</p>'),
      download: useGuidanceDocumentDownload(LibraryGuidanceDocumentEnum.ESRS_CSRD_DEFINITIONS),
      badges: [{
        text: t('Content knowledge'),
        type: TagColorEnum.BLUE,
      }],
    },
  ]);

  return {
    guidances,
  };
}
