const storageKey = 'X-Active-Entity';

let activeEntity: string | null = window.localStorage.getItem(storageKey);

export function getActiveEntity() {
  return activeEntity;
}

export function getActiveEntityHeaders(): Record<string, string> {
  const value = getActiveEntity();
  if (!value) {
    return {};
  }

  return {
    'X-Active-Entity': value,
  };
}

export function setActiveEntity(newActiveEntity: string) {
  activeEntity = newActiveEntity;
  window.localStorage.setItem(storageKey, newActiveEntity);
}

export function removeActiveEntity() {
  activeEntity = null;
  window.localStorage.removeItem(storageKey);
}
