<script setup lang="ts">
const year = new Date().getFullYear();
const env = import.meta.env.VITE_APP_ENV;
const isProdEnv = env === 'production';
</script>

<template>
  <footer
    class="mx-auto max-w-7xl px-4 py-3 sm:px-6 md:flex md:items-center md:justify-between lg:px-8"
  >
    <p class="w-full text-center text-xs text-gray-400">
      &copy; {{ year }} Codio Impact
      <template v-if="!isProdEnv">
        | {{ env?.toUpperCase() }}
      </template>
    </p>
  </footer>
</template>
