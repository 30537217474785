import { SummarizingMethodEnum } from '@/__generated__/types';
import type { DataPoint, PreparedPerDateSublevelItem } from '../types';

type ProjectItem = {
  dataPoints: Record<string, { value?: number }> | null;
  sublevels: ProjectItem[] | null;
};
type ProjectCategoriesItem = {
  categories: PreparedPerDateSublevelItem['categories'];
  sublevels: ProjectCategoriesItem[] | null;
};
type SummarizeCategory = {
  unit: DataPoint['valueUnit'];
  isUncategorized: boolean;
  isFullTotal: boolean;
  values: Record<string, { sum: number, count: number }>;
};

export function summarize(projects: ProjectItem[], byDate: string) {
  let sumOfValues = 0;
  let valuesCount = 0;

  const getValue = (items: ProjectItem[] | null) => {
    if (!items) {
      return;
    }

    items.forEach((item) => {
      if (item.dataPoints && Object.prototype.hasOwnProperty.call(item.dataPoints, byDate)) {
        sumOfValues += item.dataPoints[byDate].value ?? 0;
        valuesCount += 1;
      }
      getValue(item.sublevels);
    });
  };
  getValue(projects);

  return [sumOfValues, valuesCount] as const;
}

export function summarizeCategories(projects: ProjectCategoriesItem[], dates: { key: string }[]) {
  const result: Record<string, SummarizeCategory> = {};

  const getValue = (items: ProjectCategoriesItem[] | null) => {
    if (!items) {
      return;
    }

    items.forEach((item) => {
      item.categories.forEach((category) => {
        if (!result[category.name]) {
          result[category.name] = {
            unit: Object.values(category.values)[0]?.valueUnit ?? null,
            isUncategorized: category.isUncategorized,
            isFullTotal: false,
            values: {},
          };
        }
        if (result[category.name]) {
          dates.forEach((date) => {
            if (Object.prototype.hasOwnProperty.call(category.values, date.key)) {
              if (!Object.prototype.hasOwnProperty.call(result[category.name].values, date.key)) {
                result[category.name].values[date.key] = {
                  sum: category.values[date.key].value,
                  count: 1,
                };
              } else {
                result[category.name].values[date.key].sum += category.values[date.key].value;
                result[category.name].values[date.key].count += 1;
              }
            }
          });
        }
      });
      getValue(item.sublevels);
    });
  };
  getValue(projects);

  if (Object.keys(result).length > 0) {
    const isFullTotalResult: SummarizeCategory = {
      unit: null,
      isUncategorized: true,
      isFullTotal: true,
      values: {},
    };
    Object.keys(result).forEach((category) => {
      if (result[category]) {
        isFullTotalResult.unit = result[category].unit;
        Object.keys(result[category].values).forEach((date) => {
          if (result[category].values[date]) {
            if (isFullTotalResult.values[date]) {
              isFullTotalResult.values[date].sum += result[category].values[date].sum;
              isFullTotalResult.values[date].count += result[category].values[date].count;
            } else {
              isFullTotalResult.values[date] = {
                sum: result[category].values[date].sum,
                count: result[category].values[date].count,
              };
            }
          }
        });
      }
    });
    result.IS_FULL_TOTAL = isFullTotalResult;
  }

  return result;
}

export function calculate(sum: number, count: number, method: SummarizingMethodEnum) {
  return method === SummarizingMethodEnum.AVERAGE ? sum / count : sum;
}
