import { DataPointTypeValueUnitEnum } from '@/__generated__/types';

export const units: Record<string, {longFormat: string, shortFormat: string}> = {
  [DataPointTypeValueUnitEnum.LITER]: {
    longFormat: 'Liter (l)',
    shortFormat: 'l',
  },
  [DataPointTypeValueUnitEnum.MEGA_LITER]: {
    longFormat: 'MegaLiter (Ml)',
    shortFormat: 'Ml',
  },
  [DataPointTypeValueUnitEnum.CUBIC_METER]: {
    longFormat: 'Cubic meter (m3)',
    shortFormat: 'm3',
  },
  [DataPointTypeValueUnitEnum.KILOGRAM]: {
    longFormat: 'Kilogram (kg)',
    shortFormat: 'kg',
  },
  [DataPointTypeValueUnitEnum.TONS]: {
    longFormat: 'Ton (t)',
    shortFormat: 't',
  },
  [DataPointTypeValueUnitEnum.KILOWATTHOURS]: {
    longFormat: 'Kilowatt-hour (kWh)',
    shortFormat: 'kWh',
  },
  [DataPointTypeValueUnitEnum.MEGAWATTHOURS]: {
    longFormat: 'Megawatt-hour (MWh)',
    shortFormat: 'MWh',
  },
  [DataPointTypeValueUnitEnum.KILOWATT]: {
    longFormat: 'Kilowatt (kW)',
    shortFormat: 'kW',
  },
  [DataPointTypeValueUnitEnum.METER]: {
    longFormat: 'Meter (m)',
    shortFormat: 'm',
  },
  [DataPointTypeValueUnitEnum.KILOMETER]: {
    longFormat: 'Kilometer (km)',
    shortFormat: 'km',
  },
  [DataPointTypeValueUnitEnum.HOURS]: {
    longFormat: 'Hour (h)',
    shortFormat: 'h',
  },
  [DataPointTypeValueUnitEnum.DAYS]: {
    longFormat: 'Day (d)',
    shortFormat: 'd',
  },
  [DataPointTypeValueUnitEnum.EUR]: {
    longFormat: 'Euro (EUR)',
    shortFormat: 'EUR',
  },
  [DataPointTypeValueUnitEnum.GBP]: {
    longFormat: 'British pound sterling (GBP)',
    shortFormat: 'GBP',
  },
  [DataPointTypeValueUnitEnum.CHF]: {
    longFormat: 'Swiss Franc (CHF)',
    shortFormat: 'CHF',
  },
  [DataPointTypeValueUnitEnum.USD]: {
    longFormat: 'US Dollar (USD)',
    shortFormat: 'USD',
  },
};

export const getUnitFormats = (unit: string): {longFormat: string, shortFormat: string} => {
  if (!units[unit]) {
    return { longFormat: '', shortFormat: '' };
  }
  return units[unit];
};
