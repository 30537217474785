import { gql } from '@apollo/client/core';

export default gql`
  query getRestrictionsForOrganization {
    restrictions {
        _id
        numberOfWorkspaces
        numberOfProjects
        numberOfTopics
        numberOfAdmins
        numberOfManagers
        numberOfUsers
        numberOfExportsPerYearPerType
        numberOfQuestionnaires

        supportedTopics
        supportedStandards
        restrictedFeatures
    }
    }
`;
