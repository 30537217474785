import { gql } from '@apollo/client/core';

export default gql`
  query OgProjectHeader($projectId: String!) {
    entityLocationSummaries {
      _id
      name
      parent
      isHeadquarters
      hasChildren
      assignments {
        _id
        categories {
          _id
          category
          isEnabled
          subcategories {
            _id
            subcategory
          }
        }
      }
    }
    
    getCategoriesWithSubcategories(locationId: $projectId) {
      _id
      slug
      name
      organization {
        _id
      }
      location {
        _id
      }
      entity {
        _id
      }
      subcategories {
        _id
        slug
        name
      }
    }
  }
`;
