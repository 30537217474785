<script setup lang="ts">
import { computed } from 'vue';
import { avatarColors } from '@/styles/theme';
import stringToHashNumber from '@/utils/helpers/stringToHashNumber';

const props = defineProps<{
  url?: string | null,
  userName?: string,
}>();

const palette = Object.values(avatarColors);
const userColor = computed(() => palette[stringToHashNumber(props.userName ?? '') % palette.length]);
</script>

<template>
  <div
    class="h-full w-full shrink-0 rounded-full"
    :class="{ placeholder: !props.url }"
    :title="props.userName"
  >
    <img
      v-if="props.url"
      :src="props.url"
      class="h-full w-full rounded-full object-cover"
      alt="avatar"
    >
    <div
      v-else
      class="flex h-full items-center justify-center rounded-full"
      :style="{ backgroundColor: userColor.DEFAULT, color: userColor.text }"
    >
      <slot>
        {{ props.userName?.[0] }}
      </slot>
    </div>
  </div>
</template>
