import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { UpdateRestrictionMutation, UpdateRestrictionMutationVariables } from '@/__generated__/types';

export const UPDATE_RESTRICTION = gql`
  mutation UpdateRestriction($restrictionInput: RestrictionInput!) {
    updateRestriction(restrictionInput: $restrictionInput) {
      _id
      numberOfQuestionnaires
      restrictedFeatures
    }
  }
`;

type TOptions = UseMutationOptions<UpdateRestrictionMutation, UpdateRestrictionMutationVariables>

const useUpdateRestrictionMutation = (options?: TOptions) => useMutation<
  UpdateRestrictionMutation,
  UpdateRestrictionMutationVariables
>(UPDATE_RESTRICTION, options);

export default useUpdateRestrictionMutation;
