import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';

import type { DeleteDataPointRequestDelegationMutation, DeleteDataPointRequestDelegationMutationVariables } from '@/__generated__/types';

const DELETE_DATAPOINTREQUEST_DELEGATION_MUTATION = gql`
  mutation DeleteDataPointRequestDelegation($dataPointRequestId: String!) {
    deleteDataPointRequestDelegation(dataPointRequestId: $dataPointRequestId) {
      _id
      delegations {
        _id
      }
    }
  }
`;

type TOptions = UseMutationOptions<DeleteDataPointRequestDelegationMutation, DeleteDataPointRequestDelegationMutationVariables>

const useDeleteDataPointRequestDelegation = (options?: TOptions) => useMutation<
  DeleteDataPointRequestDelegationMutation,
  DeleteDataPointRequestDelegationMutationVariables
>(DELETE_DATAPOINTREQUEST_DELEGATION_MUTATION, options);

export default useDeleteDataPointRequestDelegation;
