<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useLazyQuery } from '@vue/apollo-composable';
import { computed, ref, watch } from 'vue';
import { DownloadIcon } from '@heroicons/vue/outline';
import useEmissionFactorsCsv from '@/utils/composables/useEmissionFactorsCsv/useEmissionFactorsCsv';
import MlCard from '@/components/molecules/MlCard.vue';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlModal from '@/components/molecules/MlModal.vue';
import { useReportingPeriod } from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import OgDateRangeInput from '@/components/organisms/OgDateRangeInput.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import { type PgAuditTrailGetHistoryQuery, type PgAuditTrailGetHistoryQueryVariables } from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import saveFile from '@/utils/helpers/saveFile';
import AUDIT_TRAIL_QUERY from './AuditTrail.GetHistory.query';

const { t } = useI18n();

const { currentUser } = useCurrentUser();

const isAuditModalOpen = ref(false);
const { dateRange, formatForBE } = useReportingPeriod();

const emissionFactorsCsv = useEmissionFactorsCsv();

const selectedEntities = ref<string[]>([]);
const entitiesOptions = computed(() => currentUser.value?.entities.reduce((acc, curr) => {
  acc[curr._id.toString()] = String(curr.name);
  return acc;
}, {} as Record<string, string>));

const selectedCategories = ref<string[]>([]);

const { result: csvResult, loading, refetch, load } = useLazyQuery<PgAuditTrailGetHistoryQuery, PgAuditTrailGetHistoryQueryVariables>(AUDIT_TRAIL_QUERY, {}, { fetchPolicy: 'network-only' });

const downloadAuditTrail = async () => {
  const variables: PgAuditTrailGetHistoryQueryVariables = {
    // categories: selectedCategories.value,
    entityIds: selectedEntities.value,
    ...formatForBE(),
  };

  load(undefined, variables);
  return refetch(variables);
};

watch(csvResult, () => {
  saveFile(csvResult.value?.getHistoryAsXSLX ?? '', 'codio_audit_export.xlsx', 'application/vnd.ms-excel;charset=utf-8');
});
</script>
<template>
  <div class="pt-5 px-6 grid grid-cols-1 gap-6 xl:grid-cols-5">
    <div class="xl:col-span-3">
      <h2 class="text-lg font-medium leading-6 text-gray-900 sm:truncate mb-3">
        {{ t('Audit') }}
      </h2>
      <p class="mb-10 text-gray-500 text-sm">
        {{ t('Find all documentation of your team and download a complete audit trail.') }}
      </p>

      <div class="mb-12 flex gap-6">
        <MlCard
          :title="t('Audit Trail')"
          :description="t('Get your company’s complete changelog on every single datapoint and its history as Excel file.')"
          :buttonIcon="DownloadIcon"
          :buttonText="t('Download audit trail')"
          @buttonClick="isAuditModalOpen = true"
        />
        <MlCard
          :title="t('Emission Factors')"
          :description="t('Get the complete list of emission factors that are used to calculate the CO2 footprint of your company.')"
          :buttonIcon="DownloadIcon"
          :buttonText="t('Download emission factors')"
          :loading="emissionFactorsCsv.loading.value"
          @buttonClick="emissionFactorsCsv.download()"
        />
      </div>
    </div>

    <MlModal
      :isRevealed="isAuditModalOpen"
      class="w-96 min-w-[24rem] overflow-visible"
      @close="isAuditModalOpen = false"
    >
      <AtHeading type="h2" class="mb-3 border-b pb-2">
        {{ t('Download audit trail') }}
      </AtHeading>

      <div>
        <OgDateRangeInput
          v-model="dateRange"
          class="my-2"
        />

        <MlSelect
          v-model="selectedEntities"
          :label="t('Workspaces')"
          :options="entitiesOptions"
          class="my-2"
          multiple
        />

        <MlSelect
          v-if="false"
          v-model="selectedCategories"
          :label="t('Categories')"
          class="my-2"
          multiple
        />
      </div>

      <div
        class="modal-action flex items-end justify-between align-bottom"
      >
        <AtButton
          variant="outline"
          @click="isAuditModalOpen = false"
        >
          {{ t('Cancel') }}
        </AtButton>
        <AtButton
          :loading="loading"
          :icon="DownloadIcon"
          @click="downloadAuditTrail"
        >
          {{ t('Download') }}
        </AtButton>
      </div>
    </MlModal>
  </div>
</template>
