<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import MlModal from '@/components/molecules/MlModal.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import useSuspendDataPointTypeByEntityMutation from '@/api/mutations/DataPointType/suspendDataPointTypeByEntity.mutation';
import useSuspendDataPointTypeByLocationMutation from '@/api/mutations/DataPointType/suspendDataPointTypeByLocation.mutation';
import type { Project } from '../../../types';

const { t } = useI18n();
const { currentUser } = useCurrentUser();
const props = defineProps<Props>();
type Props = {
  isShown: boolean;
  project: Project;
  dataPointTypeId: string;
}

const emit = defineEmits<Emits>();
type Emits = {
  (e: 'cancel'): void
  (e : 'confirm'): void
};

const { mutate: suspendByLocation, loading: suspendByLocationLoading } = useSuspendDataPointTypeByLocationMutation({
  update: (cache) => {
    cache.evict({ fieldName: 'getMyDataPointRequests' });
    cache.evict({ fieldName: 'entityLocationSummaries' });
  },
});

const { mutate: suspendByEntity, loading: suspendByEntityLoading } = useSuspendDataPointTypeByEntityMutation({
  update: (cache) => {
    cache.evict({ fieldName: 'getMyDataPointRequests' });
    cache.evict({ fieldName: 'entityLocationSummaries' });
  },
});

async function handleSuspendByLocation() {
  try {
    await suspendByLocation({
      locationId: props.project._id,
      dataPointTypeId: props.dataPointTypeId,
    });
    emit('confirm');
    notify({ type: 'success', text: t('The datapoint was archived in {locationName}', { locationName: props.project.name }) });
  } catch (err) {
    notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
  }
}
async function handleSuspendByEntity() {
  try {
    await suspendByEntity({
      dataPointTypeId: props.dataPointTypeId,
    });
    emit('confirm');
    notify({ type: 'success', text: t('The datapoint was archived accross {entityName}', { entityName: currentUser.value?.entity.name }) });
  } catch (err) {
    notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
  }
}

</script>

<template>
  <MlModal :isRevealed="props.isShown" class="max-w-xl" @close="emit('cancel')">
    <h2 class="text-xl mb-3 border-b pb-2">
      {{ t('Apply to all projects?') }}
    </h2>
    <p class="mb-3 text-sm">
      {{ t('Would you like to archive this data point in all projects in this workspace?') }}
    </p>
    <div class="mt-2 grid grid-cols-4 gap-x-3">
      <div class="flex items-center justify-between col-span-4 mt-5">
        <AtButton
          variant="outline"
          :disabled="suspendByLocationLoading"
          :loading="suspendByLocationLoading"
          @click="handleSuspendByLocation"
        >
          {{ t('No, apply only to this project') }}
        </AtButton>
        <AtButton
          :disabled="suspendByEntityLoading"
          :loading="suspendByEntityLoading"
          @click="handleSuspendByEntity"
        >
          {{ t('Yes, apply to all') }}
        </AtButton>
      </div>
    </div>
  </MlModal>
</template>
