<script setup lang="ts">
import { useI18n } from 'vue-i18n';

type TItem = { label?: string, value: string, [key: string]: unknown };

const props = defineProps<{
  items: TItem[],
  modelValue?: TItem,
  scrollable?: boolean,
}>();

const emit = defineEmits<TEmits>();
type TEmits = {
  (e: 'update:modelValue', selectedItem: TItem): void
}

const { t } = useI18n();

const activeClass = 'bg-[#E9EFFD] text-primary rounded-full';
</script>

<template>
  <div class="max-h-screen min-w-[159px]">
    <slot />
    <nav
      class="flex max-h-[360px] flex-col overflow-y-auto overflow-x-hidden"
      data-cy="MlSidebarTabs-items"
    >
      <template
        v-for="item in items"
        :key="item.value"
      >
        <slot
          name="tab"
          v-bind="{ item, activeClass }"
        >
          <button
            class="break-words rounded-md p-2 text-left text-gray-900 hover:text-primary"
            :class="{ [activeClass]: item.value === props.modelValue?.value }"
            type="submit"
            @click="emit('update:modelValue', item)"
          >
            <slot
              name="tabContent"
              v-bind="{ item, activeClass }"
            >
              {{ t(item.label ?? item.value) }}
            </slot>
          </button>
        </slot>
      </template>
    </nav>
  </div>
</template>
