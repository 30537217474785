import { gql } from '@apollo/client/core';

export default gql`
  query OgSetupKpiStep {
    getOwnUser {
      _id

      entity {
        _id

        locations {
          _id
          name
        }
      }
    }

    getTeamUsers {
      _id
      firstName
      lastName
    }
  }
`;
