<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute, useRouter } from 'vue-router';
import useRestrictions from '@/utils/composables/useRestrictions/useRestrictions';
import { RestrictionsFeaturesEnum } from '@/__generated__/types';
import OgRestrictionsLanding from '@/components/organisms/OgRestrictionsLanding.vue';
import MlTabs from '@/components/molecules/MlTabs/MlTabs.vue';
import { HeadingType } from '@/components/atoms/AtHeading/types';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';

const {
  restrictions,
  loading: loadingRestrictions,
} = useRestrictions();

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const { locale } = useI18n();

const tabs = computed(() => ([
  { name: t('Overview'), value: 'carbonFootprintCalculator' },
  { name: t('Sources'), value: 'carbonFootprintSources' },
]));
</script>

<template>
  <template v-if="restrictions?.restrictedFeatures.includes(RestrictionsFeaturesEnum.CALCULATORS) && !loadingRestrictions">
    <OgRestrictionsLanding>
      <template #pageTitle>
        {{ t('Calculators') }}
      </template>
      <template #title>
        {{ t('Ad-hoc CO2 Calculator') }}
      </template>
      <template #text>
        {{ t('Get an instant indication of CO2eq emissions with our simple calculator.') }}
      </template>
      <template #image>
        <img
          v-if="locale === 'de'"
          alt="Calculators"
          src="@/assets/images/carbonfootprintcalculator-en.png"
        />
        <img
          v-else
          alt="Calculators"
          src="@/assets/images/carbonfootprintcalculator-en.png"
        >
      </template>
    </OgRestrictionsLanding>
  </template>
  <div v-else class="px-6 pt-5">
    <AtHeading :type="HeadingType.H1">
      {{ t('Carbon footprint calculator') }}
    </AtHeading>

    <p class="text-sm text-gray-500 mb-5">
      {{ t('Calculate carbon emissions of your projects.') }}
    </p>

    <MlTabs
      class="mt-5"
      :modelValue="String(route.name)"
      :tabs="tabs"
      isEdgeToEdge
      @update:modelValue="router.push({ name: $event.value, query: $event.query })"
    />

    <section class="mt-5">
      <router-view />
    </section>
  </div>
</template>
