<script lang="ts" setup>
import { computed, ref, watch } from 'vue';
import { Command } from 'vue-command-palette';
import { useMagicKeys } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { SearchIcon } from '@heroicons/vue/outline';
import { useRouter } from 'vue-router';
import { routes } from '@/router/routes';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import type { UserRole } from '@/__generated__/types';
import { hasRequiredRole } from '@/utils/helpers/hasRequiredRole';
import useSwitchActiveEntity from '@/utils/composables/useSwitchActiveEntity';
import { useGuidances } from '../pages/PgLibrary/composables/useGuidances';
import MlDialog from './MlDialog.vue';

const { t } = useI18n();

const { guidances } = useGuidances();

const { currentUser, isAdminOrSuperAdmin } = useCurrentUser();
const router = useRouter();
const isMacOs = computed(() => navigator.userAgent.indexOf('Mac') !== -1);
const keys = useMagicKeys();
const CmdK = keys[`${isMacOs.value ? 'Meta' : 'Ctrl'}+K`];
const visible = ref(false);
const { switchActiveEntity } = useSwitchActiveEntity();
watch(CmdK, (v) => {
  if (v) {
    visible.value = !visible.value;
  }
});

defineOptions({ inheritAttrs: false });

const locations = currentUser.value?.entity.locations.map((location) => location);
const filteredRoutes = computed(() => routes.filter((route) => route.meta && route.meta.showInCommandPalette && (!route?.meta?.roleFrom
  || isAdminOrSuperAdmin.value || hasRequiredRole(route.meta?.roleFrom, currentUser.value?.role as UserRole)),
));
const searchTerm = ref('');
</script>

<template>
  <div class="p-4">
    <div class="flex gap-3 cursor-pointer hover:text-primary items-center group relative" v-bind="$attrs" @click.stop="visible = !visible">
      <SearchIcon
        class="w-6 mr-1 shrink-0"
      />
      <slot />
    </div>
    <MlDialog
      :isOpen="visible"
      class="!p-0 !rounded-xl !bg-transparent"
      closeOnOutside
      :hasCloseButton="false"
      @close="visible = false"
    >
      <Command theme="vercel">
        <Command.Input
          v-model="searchTerm"
          class="min-w-[40dvw]"
          :placeholder="t('Type a command or search...')"
        />
        <Command.List class="w-full">
          <Command.Empty>{{ t('No results found.') }} </Command.Empty>
          <Command.Group :heading="t('Guidances')" class="mt-2">
            <Command.Item
              v-for="guidance in guidances"
              :key="guidance.key"
              class="ml-2 cursor-pointer hover:text-primary peer"
              :data-value="`${t('Guidances')} ${guidance.title} ${guidance.htmlDescription}`"
              @select="router.push({
                name: 'libraryGuidances',
                query: { guidanceKey: guidance.key },
              }); visible = false"
            >
              {{ t(guidance.title) }}
            </Command.Item>
            <Command.Separator class="peer-aria-hidden:hidden" />
          </Command.Group>
          <Command.Group :heading="t('Pages')" class="mt-2">
            <Command.Item
              v-for="route in filteredRoutes"
              :key="route.name"
              class="ml-2 cursor-pointer hover:text-primary peer"
              :data-value="`${t('Pages')} ${t(route.name?.toString() ?? '')}`"
              @select="router.push({ name: route.name }); visible = false"
            >
              {{ t(route.name?.toString() ?? '') }}
            </Command.Item>
            <Command.Separator class="peer-aria-hidden:hidden" />
          </Command.Group>
          <Command.Group :heading="t('Workspaces')" class="mt-2">
            <Command.Item
              v-for="entity in currentUser?.entities"
              :key="entity._id"
              class="ml-2 cursor-pointer hover:text-primary peer"
              :data-value="`${t('Workspaces')} ${entity.name}`"
              @select="switchActiveEntity(entity._id); visible = false"
            >
              {{ entity.name }}
            </Command.Item>
            <Command.Separator class="peer-aria-hidden:hidden" />
          </Command.Group>
          <Command.Group :heading="t('Projects')" class="mt-2 peer">
            <Command.Item
              v-for="location in locations"
              :key="location._id"
              class="ml-2 cursor-pointer hover:text-primary peer"
              :data-value="`${t('Projects')} ${location.name}`"
              @select="router.push(`/projects/${location._id}/data-entry`); visible = false"
            >
              {{ location.name }}
            </Command.Item>
            <Command.Separator class="peer-aria-hidden:hidden" />
          </Command.Group>
        </Command.List>
      </Command>
    </MlDialog>
  </div>
</template>
