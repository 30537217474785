import { gql } from '@apollo/client/core';

export default gql`
  query MlTaxonomyProjectModal {
    getTeamUsers {
      _id
      firstName
      lastName
    }
  }
`;
