<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import type { PgDataEntryUploadQuery } from '@/__generated__/types';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import OgS3FilePicker from '@/components/molecules/MlFilePicker/OgS3FilePicker.vue';
import useUploadedFileForReview from '@/api/mutations/RepositoryFile/uploadedFileForReview.mutation';
import PG_DATA_ENTRY_UPLOAD_QUERY from './PgDataEntryUpload.query';

const { t } = useI18n();

const { result } = useQuery<PgDataEntryUploadQuery>(PG_DATA_ENTRY_UPLOAD_QUERY);
const { mutate } = useUploadedFileForReview();

const showUpload = ref(true);

const handleFileUploaded = async (repositoryFileId?: string | null) => {
  if (!repositoryFileId) {
    return;
  }

  if (!selectedLocation.value) {
    // eslint-disable-next-line no-console
    console.log('Unable to proceed: No project selected.');
    return;
  }

  await mutate({ repositoryFileId, locationId: selectedLocation.value });

  showUpload.value = false;
};

const locationOptions = computed(() => result.value?.entityLocationSummaries
  .reduce<Record<string, string>>((acc, { _id, name }) => ({ ...acc, [_id]: name }), {}) ?? {});
const selectedLocation = ref<string>();

watch(locationOptions, () => {
  [selectedLocation.value] = Object.keys(locationOptions.value);
}, { immediate: true });
</script>

<template>
  <div class="pt-5 px-6 flex flex-col">
    <div class="flex-1">
      <!-- Page title & actions -->
      <h1 class="text-lg font-medium leading-6 text-gray-900 sm:truncate">
        {{ t('Data Entry') }}
      </h1>

      <div class="mb-5">
        <p class="my-5">
          {{ t('Before uploading a file, please make sure to select the respective location. Please note that your file will be sent to Codio Impact for manual review and processing.') }}
        </p>

        <MlSelect
          v-model="selectedLocation"
          wrapperClass="w-64"
          title=""
          data-cy="MlSelectLocation"
          :options="locationOptions"
        />
      </div>

      <div v-if="showUpload">
        <OgS3FilePicker @update:modelValue="handleFileUploaded($event?._id)" />
      </div>
      <div v-else>
        <AtHeading type="h3">
          {{ t('Thanks! Your file has been sent for processing.') }}
        </AtHeading>
      </div>
    </div>
  </div>
</template>
