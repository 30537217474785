export enum ReportType {
  CO2 = 'CO2',
  ESG = 'ESG',
  EMISSION_FACTORS = 'EMISSION_FACTORS',
  CUSTOM = 'CUSTOM',
}

export enum ModalState {
  SELECT = 'select',
  REQUEST = 'request',
  SUCCESS = 'success',
  NO_DATA = 'noData',
}

export type TProgress = {
  id: string,
  name: string,
  pending: number,
  accepted: number
  overDue: number
  total: number,
  open: number,
}
