import { gql } from '@apollo/client/core';

export default gql`
  query PgDataRoomFilters {
    getTaxonomyDocumentsFilters {
      projects {
        _id
        name
      }
      addedBy {
        _id
        firstName
        lastName
      }
      dates {
        from
        to
      }
      dataPoints {
        _id
        name
      }
    }
  }
`;
