import { gql } from '@apollo/client/core';

export default gql`
  query OgCreateEntityModal {
    getAllTeamsUsers {
      _id
      firstName
      lastName
      entities {
        _id
      }
    }
  }
`;
