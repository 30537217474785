import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  AddUserToRecommendationMutation,
  AddUserToRecommendationMutationVariables,
} from '@/__generated__/types';

export const ADD_USER_TO_RECOMMENDATION = gql`
  mutation AddUserToRecommendation($recommendationId: String!) {
    addUserToRecommendation(recommendationId: $recommendationId) {
      _id
      utilizationCount 
    }
  }
`;

type TOptions = UseMutationOptions<AddUserToRecommendationMutation, AddUserToRecommendationMutationVariables>

const useAddUserToRecommendationMutation = (options?: TOptions) => useMutation<
  AddUserToRecommendationMutation,
  AddUserToRecommendationMutationVariables
>(ADD_USER_TO_RECOMMENDATION, options);

export default useAddUserToRecommendationMutation;
