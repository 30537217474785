import { gql } from '@apollo/client/core';

export default gql`
  query TmLibrarySources {
    getRepositorySourceFiles {
      _id
      ...tmLibrarySourcesFields
    }
  }

  fragment tmLibrarySourcesFields on RepositoryFile{
    name
    filename
    filepath
    module
    category
    language
    date
  }
`;
