<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { XIcon } from '@heroicons/vue/outline';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import type { TDataTableFilter, TServerDataTableHeader } from './types';

type Props = {
  modelValue: TDataTableFilter;
  headers: TServerDataTableHeader[];
  selectedFilters: string[];
  sortedOptions: boolean,
};

const props = defineProps<Props>();

const emit = defineEmits<{
  remove: [index: number];
  'update:modelValue': [value: TDataTableFilter];
}>();

const { t } = useI18n();

const filterFieldOptions = computed<Record<string, string>>(() => {
  return props
    .headers
    .filter((header) => {
      if (!header.filterable) {
        return false;
      }

      if (props.modelValue.field === header.value) {
        return true;
      }

      return !props.selectedFilters.includes(header.value);
    })
    .reduce((acc, header) => ({
      ...acc,
      [header.value]: header.text,
    }), {});
});
const filterValueOptions = computed<Record<string, string>>(() => {
  const header = props.headers.find((item) => item.value === props.modelValue.field);

  return header?.filterable ? header.filterOptions : {};
});

function handleFieldChange(value: Props['modelValue']['field']) {
  emit('update:modelValue', {
    ...props.modelValue,
    field: value,
    criteria: [],
  });
}

function handleCriteriaChange(value: Props['modelValue']['criteria']) {
  emit('update:modelValue', {
    ...props.modelValue,
    criteria: value,
  });
}
</script>

<template>
  <div
    class="mb-6 flex items-center gap-4"
  >
    <MlSelect
      :modelValue="modelValue.field"
      wrapperClass="mr-3 w-56"
      :options="filterFieldOptions"
      @update:modelValue="handleFieldChange"
    />
    <MlSelect
      :modelValue="modelValue.criteria"
      wrapperClass="w-56"
      :options="filterValueOptions"
      :disabled="!modelValue.field"
      multiple
      :sortedOptions="props.sortedOptions"
      @update:modelValue="handleCriteriaChange"
    />
    <AtIconButton
      :title="t('Remove')"
      :icon="XIcon"
      @click="$emit('remove', 0)"
    />
  </div>
</template>
