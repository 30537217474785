import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UpdatePinnedDataPointTypesMutationVariables,
  UpdatePinnedDataPointTypesMutation,
} from '@/__generated__/types';

export const UPDATE_PINNED_DATA_POINT_TYPES = gql`
  mutation UpdatePinnedDataPointTypes($updateUserInput: UpdateUserInput!) {
    updateUser(user: $updateUserInput) {
      _id
      pinnedDataPointTypes {
        _id
        entity {
          _id
        }
        dataPointType {
          _id
          name
          friendlyName
          defaultChartType
          valueUnit

          reportingFrameworks {
            _id
            framework
            groups
          }
        }
      }
    }
  }
`;

type TOptions = UseMutationOptions<UpdatePinnedDataPointTypesMutation, UpdatePinnedDataPointTypesMutationVariables>

const useUpdatePinnedDataPointTypesMutation = (options?: TOptions) => useMutation<
  UpdatePinnedDataPointTypesMutation,
  UpdatePinnedDataPointTypesMutationVariables
>(UPDATE_PINNED_DATA_POINT_TYPES, options);

export default useUpdatePinnedDataPointTypesMutation;
