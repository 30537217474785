<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import { ExclamationIcon } from '@heroicons/vue/solid';
import { auth0 } from '@/api/auth/auth';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

const email = ref(route.query.email as string);
const password = ref('');
const errorMessage = ref('');
const isLoading = ref(false);

const next = (e: Event) => {
  if (!(e.target as HTMLFormElement).reportValidity()) {
    return;
  }

  isLoading.value = true;

  auth0.login({
    realm: 'Username-Password-Authentication',
    username: email.value,
    password: password.value,
    redirectUri: `${document.location.origin}/loginCallback`,
  }, (err) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      errorMessage.value = err.error_description!;
    }

    isLoading.value = false;
  });

  return false;
};

const requestResetPassword = () => {
  auth0.changePassword({
    connection: 'Username-Password-Authentication',
    email: email.value,
  }, (err) => {
    if (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      errorMessage.value = err.error_description!;

      return;
    }

    notify({ type: 'success', text: t("If an account exists for that email address, we've sent you an email.") });
    errorMessage.value = '';
  });

  return false;
};

const signup = () => router.push({ name: 'signup', query: { email: email.value } });

</script>

<template>
  <div
    id="box-4"
    class="box mt-8"
  >
    <div>
      <div>
        <form
          @submit.prevent="next"
        >
          <div
            v-if="errorMessage"
            class="rounded-md bg-red-50 p-4"
          >
            <div class="flex">
              <div class="shrink-0">
                <ExclamationIcon
                  class="h-5 w-5 text-red-400"
                  aria-hidden="true"
                />
              </div>
              <div class="ml-3">
                <div class="text-sm text-red-700">
                  <p>{{ t(errorMessage) }}</p>
                </div>
              </div>
            </div>
          </div>

          <div class="">
            <div class="my-2">
              <label
                for="password-login-email"
                class="block text-sm font-medium text-gray-700"
              >{{ t('Email address') }}</label>
              <div class="mt-1">
                <input
                  id="password-login-email"
                  v-model="email"
                  name="password-login-email"
                  type="email"
                  placeholder="max@mustermann.de"
                  autocomplete="email"
                  required
                  class="block w-full appearance-none rounded-md border border-gray-400 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                >
              </div>
            </div>

            <div
              id="password-box"
              class="my-2"
            >
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
              >{{ t('Password') }}</label>
              <div class="mt-1">
                <input
                  id="password"
                  v-model="password"
                  name="password"
                  type="password"
                  autocomplete="current-password"
                  required
                  class="block w-full appearance-none rounded-md border border-gray-400 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                >
              </div>
            </div>
          </div>

          <div class="mt-8 text-base">
            <AtButton
              :loading="isLoading"
              class="w-full"
            >
              {{ t('Sign in') }}
            </AtButton>
          </div>

          <div class="mb-3 mt-5">
            <a
              class="cursor-pointer text-blue-600"
              @click="requestResetPassword"
            >
              {{ t('Forgot your password?') }}
            </a>
          </div>

          <div>
            <a
              class="cursor-pointer text-base text-blue-600"
              @click="signup"
            >
              {{ t('No account? Sign up') }}
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>
