import { gql } from '@apollo/client/core';

export default gql`
  query Settings {
    getOwnUser {
      _id
      entity {
        _id
        reportingFrameworks

        entitySettings {
          _id
          companyName
          industry
          locationOfHeadquarter
          nameOfHeadquarter
          numberOfEmployees
          picture {
            _id
            downloadUrl
          }
          reportingPeriods {
            _id
            from
            to
            deadline
            status
          }
        }

        locations {
          _id
          isHeadquarters
        }
      }
    }
  }
`;
