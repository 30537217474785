import { computed } from 'vue';
import { DataPointTypeValueUnitEnum } from '@/__generated__/types';

export const getUnitsOptions = (originalUnit: DataPointTypeValueUnitEnum) => {
  switch (originalUnit) {
    case DataPointTypeValueUnitEnum.LITER:
    case DataPointTypeValueUnitEnum.MEGA_LITER:
    case DataPointTypeValueUnitEnum.CUBIC_METER:
      return volumeUnitsOptions.value;
    case DataPointTypeValueUnitEnum.KILOGRAM:
    case DataPointTypeValueUnitEnum.TONS:
    case DataPointTypeValueUnitEnum.METRIC_TONS:
      return weightUnitsOptions.value;
    case DataPointTypeValueUnitEnum.KILOWATTHOURS:
    case DataPointTypeValueUnitEnum.MEGAWATTHOURS:
      return powerTimeUnitsOptions.value;
    case DataPointTypeValueUnitEnum.METER:
    case DataPointTypeValueUnitEnum.KILOMETER:
      return distanceUnitsOptions.value;
    case DataPointTypeValueUnitEnum.HOURS:
    case DataPointTypeValueUnitEnum.DAYS:
      return timeUnitsOptions.value;
    default:
      return units.value;
  }
};
export const timeUnitsOptions = computed(() => {
  return [
    {
      key: 'recommended',
      label: 'Recommended linear units',
      options: [
        {
          label: 'Hours',
          value: DataPointTypeValueUnitEnum.HOURS,
          unitCode: 'h',
        },
        {
          label: 'Days',
          value: DataPointTypeValueUnitEnum.DAYS,
          unitCode: 'd',
        },
      ] },
    {
      key: 'non-linear',
      label: 'All other non-linear units',
      options: [
        {
          label: 'Meter (m)',
          value: DataPointTypeValueUnitEnum.METER,
          unitCode: 'm',
        },
        {
          label: 'Kilometer (km)',
          value: DataPointTypeValueUnitEnum.KILOMETER,
          unitCode: 'km',
        },
        {
          label: 'Liters (l)',
          value: DataPointTypeValueUnitEnum.LITER,
          unitCode: 'l',
        },
        {
          label: 'Megaliters (MgL)',
          value: DataPointTypeValueUnitEnum.MEGA_LITER,
          unitCode: 'MgL',
        },
        {
          label: 'Cubic meter (m³)',
          value: DataPointTypeValueUnitEnum.CUBIC_METER,
          unitCode: 'm³',
        },
        {
          label: 'Kilogram (kg)',
          value: DataPointTypeValueUnitEnum.KILOGRAM,
          unitCode: 'kg',
        },
        {
          label: 'Tonne (t)',
          value: DataPointTypeValueUnitEnum.TONS,
          unitCode: 't',
        },
        {
          label: 'Metric tons (t)',
          value: DataPointTypeValueUnitEnum.METRIC_TONS,
          unitCode: 't',
        },
        {
          label: 'Kilowatt (kW)',
          value: DataPointTypeValueUnitEnum.KILOWATT,
          unitCode: 'kW',
        },
        {
          label: 'Kilowatt Hours (kWh)',
          value: DataPointTypeValueUnitEnum.KILOWATTHOURS,
          unitCode: 'kWh',
        },
        {
          label: 'Megawatt Hours (MWh)',
          value: DataPointTypeValueUnitEnum.MEGAWATTHOURS,
          unitCode: 'MWh',
        },
        {
          label: 'EUR',
          value: DataPointTypeValueUnitEnum.EUR,
          unitCode: 'EUR',
        },
        {
          label: 'GBP',
          value: DataPointTypeValueUnitEnum.GBP,
          unitCode: 'GBP',
        },
        {
          label: 'CHF',
          value: DataPointTypeValueUnitEnum.CHF,
          unitCode: 'CHF',
        },
        {
          label: 'USD',
          value: DataPointTypeValueUnitEnum.USD,
          unitCode: 'USD',
        },
        {
          label: 'tCO2eq',
          value: DataPointTypeValueUnitEnum.TCO2EQ,
          unitCode: 'tCO2eq',
        },
        {
          label: 'Production unit',
          value: DataPointTypeValueUnitEnum.PRODUCTION_UNIT,
          unitCode: 'Pr. Unit',
        },
        {
          label: 'Square meter (m2)',
          value: DataPointTypeValueUnitEnum.SQUARE_METER,
          unitCode: 'm2',
        },
      ] },
  ];
});
export const distanceUnitsOptions = computed(() => {
  return [
    {
      key: 'recommended',
      label: 'Recommended linear units',
      options: [
        {
          label: 'Meter (m)',
          value: DataPointTypeValueUnitEnum.METER,
          unitCode: 'm',
        },
        {
          label: 'Kilometer (km)',
          value: DataPointTypeValueUnitEnum.KILOMETER,
          unitCode: 'km',
        },
      ] },
    {
      key: 'non-linear',
      label: 'All other non-linear units',
      options: [
        {
          label: 'Liters (l)',
          value: DataPointTypeValueUnitEnum.LITER,
          unitCode: 'l',
        },
        {
          label: 'Megaliters (MgL)',
          value: DataPointTypeValueUnitEnum.MEGA_LITER,
          unitCode: 'MgL',
        },
        {
          label: 'Cubic meter (m³)',
          value: DataPointTypeValueUnitEnum.CUBIC_METER,
          unitCode: 'm³',
        },
        {
          label: 'Kilogram (kg)',
          value: DataPointTypeValueUnitEnum.KILOGRAM,
          unitCode: 'kg',
        },
        {
          label: 'Tonne (t)',
          value: DataPointTypeValueUnitEnum.TONS,
          unitCode: 't',
        },
        {
          label: 'Metric tons (t)',
          value: DataPointTypeValueUnitEnum.METRIC_TONS,
          unitCode: 't',
        },
        {
          label: 'Kilowatt (kW)',
          value: DataPointTypeValueUnitEnum.KILOWATT,
          unitCode: 'kW',
        },
        {
          label: 'Kilowatt Hours (kWh)',
          value: DataPointTypeValueUnitEnum.KILOWATTHOURS,
          unitCode: 'kWh',
        },
        {
          label: 'Megawatt Hours (MWh)',
          value: DataPointTypeValueUnitEnum.MEGAWATTHOURS,
          unitCode: 'MWh',
        },
        {
          label: 'Hours',
          value: DataPointTypeValueUnitEnum.HOURS,
          unitCode: 'h',
        },
        {
          label: 'Days',
          value: DataPointTypeValueUnitEnum.DAYS,
          unitCode: 'd',
        },
        {
          label: 'EUR',
          value: DataPointTypeValueUnitEnum.EUR,
          unitCode: 'EUR',
        },
        {
          label: 'GBP',
          value: DataPointTypeValueUnitEnum.GBP,
          unitCode: 'GBP',
        },
        {
          label: 'CHF',
          value: DataPointTypeValueUnitEnum.CHF,
          unitCode: 'CHF',
        },
        {
          label: 'USD',
          value: DataPointTypeValueUnitEnum.USD,
          unitCode: 'USD',
        },
        {
          label: 'tCO2eq',
          value: DataPointTypeValueUnitEnum.TCO2EQ,
          unitCode: 'tCO2eq',
        },
        {
          label: 'Production unit',
          value: DataPointTypeValueUnitEnum.PRODUCTION_UNIT,
          unitCode: 'Pr. Unit',
        },
        {
          label: 'Square meter (m2)',
          value: DataPointTypeValueUnitEnum.SQUARE_METER,
          unitCode: 'm2',
        },
      ] },
  ];
});

export const powerTimeUnitsOptions = computed(() => {
  return [
    {
      key: 'recommended',
      label: 'Recommended linear units',
      options: [
        {
          label: 'Kilowatt Hours (kWh)',
          value: DataPointTypeValueUnitEnum.KILOWATTHOURS,
          unitCode: 'kWh',
        },
        {
          label: 'Megawatt Hours (MWh)',
          value: DataPointTypeValueUnitEnum.MEGAWATTHOURS,
          unitCode: 'MWh',
        },
      ] },
    {
      key: 'non-linear',
      label: 'All other non-linear units',
      options: [
        {
          label: 'Liters (l)',
          value: DataPointTypeValueUnitEnum.LITER,
          unitCode: 'l',
        },
        {
          label: 'Megaliters (MgL)',
          value: DataPointTypeValueUnitEnum.MEGA_LITER,
          unitCode: 'MgL',
        },
        {
          label: 'Cubic meter (m³)',
          value: DataPointTypeValueUnitEnum.CUBIC_METER,
          unitCode: 'm³',
        },
        {
          label: 'Kilogram (kg)',
          value: DataPointTypeValueUnitEnum.KILOGRAM,
          unitCode: 'kg',
        },
        {
          label: 'Tonne (t)',
          value: DataPointTypeValueUnitEnum.TONS,
          unitCode: 't',
        },
        {
          label: 'Metric tons (t)',
          value: DataPointTypeValueUnitEnum.METRIC_TONS,
          unitCode: 't',
        },
        {
          label: 'Kilowatt (kW)',
          value: DataPointTypeValueUnitEnum.KILOWATT,
          unitCode: 'kW',
        },
        {
          label: 'Meter (m)',
          value: DataPointTypeValueUnitEnum.METER,
          unitCode: 'm',
        },
        {
          label: 'Kilometer (km)',
          value: DataPointTypeValueUnitEnum.KILOMETER,
          unitCode: 'km',
        },
        {
          label: 'Hours',
          value: DataPointTypeValueUnitEnum.HOURS,
          unitCode: 'h',
        },
        {
          label: 'Days',
          value: DataPointTypeValueUnitEnum.DAYS,
          unitCode: 'd',
        },
        {
          label: 'EUR',
          value: DataPointTypeValueUnitEnum.EUR,
          unitCode: 'EUR',
        },
        {
          label: 'GBP',
          value: DataPointTypeValueUnitEnum.GBP,
          unitCode: 'GBP',
        },
        {
          label: 'CHF',
          value: DataPointTypeValueUnitEnum.CHF,
          unitCode: 'CHF',
        },
        {
          label: 'USD',
          value: DataPointTypeValueUnitEnum.USD,
          unitCode: 'USD',
        },
        {
          label: 'tCO2eq',
          value: DataPointTypeValueUnitEnum.TCO2EQ,
          unitCode: 'tCO2eq',
        },
        {
          label: 'Production unit',
          value: DataPointTypeValueUnitEnum.PRODUCTION_UNIT,
          unitCode: 'Pr. Unit',
        },
        {
          label: 'Square meter (m2)',
          value: DataPointTypeValueUnitEnum.SQUARE_METER,
          unitCode: 'm2',
        },
      ] },
  ];
});
export const volumeUnitsOptions = computed(() => {
  return [
    {
      key: 'recommended',
      label: 'Recommended linear units',
      options: [
        {
          label: 'Liters (l)',
          value: DataPointTypeValueUnitEnum.LITER,
          unitCode: 'l',
        },
        {
          label: 'Megaliters (MgL)',
          value: DataPointTypeValueUnitEnum.MEGA_LITER,
          unitCode: 'MgL',
        },
        {
          label: 'Cubic meter (m³)',
          value: DataPointTypeValueUnitEnum.CUBIC_METER,
          unitCode: 'm³',
        },
      ] },
    {
      key: 'non-linear',
      label: 'All other non-linear units',
      options: [
        {
          label: 'Kilogram (kg)',
          value: DataPointTypeValueUnitEnum.KILOGRAM,
          unitCode: 'kg',
        },
        {
          label: 'Tonne (t)',
          value: DataPointTypeValueUnitEnum.TONS,
          unitCode: 't',
        },
        {
          label: 'Metric tons (t)',
          value: DataPointTypeValueUnitEnum.METRIC_TONS,
          unitCode: 't',
        },
        {
          label: 'Kilowatt (kW)',
          value: DataPointTypeValueUnitEnum.KILOWATT,
          unitCode: 'kW',
        },
        {
          label: 'Kilowatt Hours (kWh)',
          value: DataPointTypeValueUnitEnum.KILOWATTHOURS,
          unitCode: 'kWh',
        },
        {
          label: 'Megawatt Hours (MWh)',
          value: DataPointTypeValueUnitEnum.MEGAWATTHOURS,
          unitCode: 'MWh',
        },
        {
          label: 'Meter (m)',
          value: DataPointTypeValueUnitEnum.METER,
          unitCode: 'm',
        },
        {
          label: 'Kilometer (km)',
          value: DataPointTypeValueUnitEnum.KILOMETER,
          unitCode: 'km',
        },
        {
          label: 'Hours',
          value: DataPointTypeValueUnitEnum.HOURS,
          unitCode: 'h',
        },
        {
          label: 'Days',
          value: DataPointTypeValueUnitEnum.DAYS,
          unitCode: 'd',
        },
        {
          label: 'EUR',
          value: DataPointTypeValueUnitEnum.EUR,
          unitCode: 'EUR',
        },
        {
          label: 'GBP',
          value: DataPointTypeValueUnitEnum.GBP,
          unitCode: 'GBP',
        },
        {
          label: 'CHF',
          value: DataPointTypeValueUnitEnum.CHF,
          unitCode: 'CHF',
        },
        {
          label: 'USD',
          value: DataPointTypeValueUnitEnum.USD,
          unitCode: 'USD',
        },
        {
          label: 'tCO2eq',
          value: DataPointTypeValueUnitEnum.TCO2EQ,
          unitCode: 'tCO2eq',
        },
        {
          label: 'Production unit',
          value: DataPointTypeValueUnitEnum.PRODUCTION_UNIT,
          unitCode: 'Pr. Unit',
        },
        {
          label: 'Square meter (m2)',
          value: DataPointTypeValueUnitEnum.SQUARE_METER,
          unitCode: 'm2',
        },
      ] },
  ];
});

export const weightUnitsOptions = computed(() => {
  return [
    {
      key: 'recommended',
      label: 'Recommended linear units',
      options: [
        {
          label: 'Kilogram (kg)',
          value: DataPointTypeValueUnitEnum.KILOGRAM,
          unitCode: 'kg',
        },
        {
          label: 'Tonne (t)',
          value: DataPointTypeValueUnitEnum.TONS,
          unitCode: 't',
        },
        {
          label: 'Metric tons (t)',
          value: DataPointTypeValueUnitEnum.METRIC_TONS,
          unitCode: 't',
        },
      ] },
    {
      key: 'non-linear',
      label: 'All other non-linear units',
      options: [
        {
          label: 'Liters (l)',
          value: DataPointTypeValueUnitEnum.LITER,
          unitCode: 'l',
        },
        {
          label: 'Megaliters (MgL)',
          value: DataPointTypeValueUnitEnum.MEGA_LITER,
          unitCode: 'MgL',
        },
        {
          label: 'Cubic meter (m³)',
          value: DataPointTypeValueUnitEnum.CUBIC_METER,
          unitCode: 'm³',
        },
        {
          label: 'Kilowatt (kW)',
          value: DataPointTypeValueUnitEnum.KILOWATT,
          unitCode: 'kW',
        },
        {
          label: 'Kilowatt Hours (kWh)',
          value: DataPointTypeValueUnitEnum.KILOWATTHOURS,
          unitCode: 'kWh',
        },
        {
          label: 'Megawatt Hours (MWh)',
          value: DataPointTypeValueUnitEnum.MEGAWATTHOURS,
          unitCode: 'MWh',
        },
        {
          label: 'Meter (m)',
          value: DataPointTypeValueUnitEnum.METER,
          unitCode: 'm',
        },
        {
          label: 'Kilometer (km)',
          value: DataPointTypeValueUnitEnum.KILOMETER,
          unitCode: 'km',
        },
        {
          label: 'Hours',
          value: DataPointTypeValueUnitEnum.HOURS,
          unitCode: 'h',
        },
        {
          label: 'Days',
          value: DataPointTypeValueUnitEnum.DAYS,
          unitCode: 'd',
        },
        {
          label: 'EUR',
          value: DataPointTypeValueUnitEnum.EUR,
          unitCode: 'EUR',
        },
        {
          label: 'GBP',
          value: DataPointTypeValueUnitEnum.GBP,
          unitCode: 'GBP',
        },
        {
          label: 'CHF',
          value: DataPointTypeValueUnitEnum.CHF,
          unitCode: 'CHF',
        },
        {
          label: 'USD',
          value: DataPointTypeValueUnitEnum.USD,
          unitCode: 'USD',
        },
        {
          label: 'tCO2eq',
          value: DataPointTypeValueUnitEnum.TCO2EQ,
          unitCode: 'tCO2eq',
        },
        {
          label: 'Production unit',
          value: DataPointTypeValueUnitEnum.PRODUCTION_UNIT,
          unitCode: 'Pr. Unit',
        },
        {
          label: 'Square meter (m2)',
          value: DataPointTypeValueUnitEnum.SQUARE_METER,
          unitCode: 'm2',
        },
      ] },
  ];
});

export const units = computed(() => {
  return [
    {
      key: 'non-linear',
      label: 'All units',
      options: [
        {
          label: 'Kilogram (kg)',
          value: DataPointTypeValueUnitEnum.KILOGRAM,
          unitCode: 'kg',
        },
        {
          label: 'Tonne (t)',
          value: DataPointTypeValueUnitEnum.TONS,
          unitCode: 't',
        },
        {
          label: 'Metric tons (t)',
          value: DataPointTypeValueUnitEnum.METRIC_TONS,
          unitCode: 't',
        },
        {
          label: 'Liters (l)',
          value: DataPointTypeValueUnitEnum.LITER,
          unitCode: 'l',
        },
        {
          label: 'Megaliters (MgL)',
          value: DataPointTypeValueUnitEnum.MEGA_LITER,
          unitCode: 'MgL',
        },
        {
          label: 'Cubic meter (m³)',
          value: DataPointTypeValueUnitEnum.CUBIC_METER,
          unitCode: 'm³',
        },
        {
          label: 'Kilowatt (kW)',
          value: DataPointTypeValueUnitEnum.KILOWATT,
          unitCode: 'kW',
        },
        {
          label: 'Kilowatt Hours (kWh)',
          value: DataPointTypeValueUnitEnum.KILOWATTHOURS,
          unitCode: 'kWh',
        },
        {
          label: 'Megawatt Hours (MWh)',
          value: DataPointTypeValueUnitEnum.MEGAWATTHOURS,
          unitCode: 'MWh',
        },
        {
          label: 'Meter (m)',
          value: DataPointTypeValueUnitEnum.METER,
          unitCode: 'm',
        },
        {
          label: 'Kilometer (km)',
          value: DataPointTypeValueUnitEnum.KILOMETER,
          unitCode: 'km',
        },
        {
          label: 'Hours',
          value: DataPointTypeValueUnitEnum.HOURS,
          unitCode: 'h',
        },
        {
          label: 'Days',
          value: DataPointTypeValueUnitEnum.DAYS,
          unitCode: 'd',
        },
        {
          label: 'EUR',
          value: DataPointTypeValueUnitEnum.EUR,
          unitCode: 'EUR',
        },
        {
          label: 'GBP',
          value: DataPointTypeValueUnitEnum.GBP,
          unitCode: 'GBP',
        },
        {
          label: 'CHF',
          value: DataPointTypeValueUnitEnum.CHF,
          unitCode: 'CHF',
        },
        {
          label: 'USD',
          value: DataPointTypeValueUnitEnum.USD,
          unitCode: 'USD',
        },
        {
          label: 'tCO2eq',
          value: DataPointTypeValueUnitEnum.TCO2EQ,
          unitCode: 'tCO2eq',
        },
        {
          label: 'Production unit',
          value: DataPointTypeValueUnitEnum.PRODUCTION_UNIT,
          unitCode: 'Pr. Unit',
        },
        {
          label: 'Square meter (m2)',
          value: DataPointTypeValueUnitEnum.SQUARE_METER,
          unitCode: 'm2',
        },
      ],
    },
  ];
});
