<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import { useStore } from '../../store';
import AtCard from '../../../../../atoms/AtCard.vue';
import OgDefineRisksAndOpportunities from './OgDefineRisksAndOpportunities/OgDefineRisksAndOpportunities.vue';

const { t } = useI18n();
const router = useRouter();
const store = useStore();

const showDefineCard = ref(true);
const showScoreCard = ref(true);

const isStartDefineDisabled = computed(() => store.value.topics.length === 0);
const isStartScoreDisabled = computed(() => store.value.iroRisksAndOpportunities
  .every((iroRiskAndOpportunity) => iroRiskAndOpportunity.riskAndOpportunity.trim().length === 0));

function handleDefineStartClick() {
  showDefineCard.value = false;
}

function handleScoreStartClick() {
  router.push({
    name: 'doubleMaterialityIROsScoreRisksAndOpportunities',
  });
}
</script>

<template>
  <div v-if="showDefineCard && showScoreCard" class="flex-1 flex flex-col">
    <p class="mb-5">
      {{ t("Financial materiality (also called outside-in perspective) focuses on how sustainability matters impact the company's financial performance and future viability.") }}
    </p>
    <div class="flex space-x-11 items-stretch">
      <AtCard
        :title="t('Define your risks and opportunities')"
        :action="t('Start')"
        :disabled="isStartDefineDisabled"
        @click="handleDefineStartClick"
      >
        <p class="mb-6">
          {{ t('This is where you define all risks and opportunities that sustainability matter pose to your company’s performance and future viability.') }}
        </p>
        <p>
          {{ t('You can only do this once your list of sustainability matters has one or more topics.') }}
        </p>
      </AtCard>
      <AtCard
        :title="t('Score your risks and opportunities')"
        :action="t('Start')"
        :disabled="isStartScoreDisabled"
        @click="handleScoreStartClick"
      >
        <p class="mb-6">
          {{ t('This is where you score all risks and opportunities that sustainability matters pose to your company’s performance and future viability.') }}
        </p>
        <p>
          {{ t('You can only do this once you defined your risks and opportunities.') }}
        </p>
      </AtCard>
    </div>
    <div class="mt-auto flex items-center justify-between">
      <AtButton
        type="button"
        variant="outline"
        @click="router.push({ name: 'doubleMaterialityIROsImpacts' })"
      >
        {{ t('Go back') }}
      </AtButton>
      <AtButton
        type="button"
        @click="router.push({ name: 'doubleMaterialityIROsScoreActualNegative' })"
      >
        {{ t('Next') }}
      </AtButton>
    </div>
  </div>

  <OgDefineRisksAndOpportunities v-else-if="!showDefineCard" />
</template>
