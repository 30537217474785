<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import groupBy from 'lodash/groupBy';
import { useStore } from '../../store';
import OgChart, { type DataItem } from './OgChart.vue';

function riskAndOpportunityNotEmpty(
  value: { potentialMagnitude: number | null, likelihood: number | null },
): value is { potentialMagnitude: number, likelihood: number } {
  return typeof value.potentialMagnitude === 'number' && typeof value.likelihood === 'number';
}

const { t } = useI18n();
const store = useStore();

const data = computed<DataItem[]>(() => {
  const risksAndOpportunitiesByTopic = groupBy(store.value.iroRisksAndOpportunities, (riskAndOpportunity) => riskAndOpportunity.topicId);
  return Object
    .entries(risksAndOpportunitiesByTopic)
    .map(([topicId, riskAndOpportunities]) => {
      const topic = store.value.topics.find((item) => item._id === topicId)!;

      const { potentialMagnitude, likelihood } = riskAndOpportunities
        .map((riskAndOpportunity) => ({
          potentialMagnitude: riskAndOpportunity.potentialMagnitude,
          likelihood: riskAndOpportunity.likelihood,
        }))
        .reduce<{ potentialMagnitude: number | null, likelihood: number | null }>(
          (acc, item) => {
            if (typeof item.potentialMagnitude === 'number' && typeof item.likelihood === 'number') {
              if (typeof acc.potentialMagnitude !== 'number' || item.potentialMagnitude > acc.potentialMagnitude) {
                acc.potentialMagnitude = item.potentialMagnitude;
              }

              if (typeof acc.likelihood !== 'number' || item.likelihood > acc.likelihood) {
                acc.likelihood = item.likelihood;
              }
            }

            return acc;
          },
          { potentialMagnitude: null, likelihood: null },
        );

      return {
        sustainabilityMatter: t(topic.subSubTopic) || t(topic.subTopic) || '',
        likelihood,
        potentialMagnitude,
      };
    })
    .filter<{ potentialMagnitude: number, likelihood: number, sustainabilityMatter: string }>((item) => riskAndOpportunityNotEmpty(item))
    .map((item) => ({
      label: item.sustainabilityMatter,
      x: item.likelihood,
      y: item.potentialMagnitude,
    }));
});
</script>

<template>
  <OgChart
    type="financialMateriality"
    :data="data"
  />
</template>
