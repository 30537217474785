import { gql } from '@apollo/client/core';

export default gql`
  query OgCustomInsightFormDataPointTypesA($withExistingDataPoints: Boolean) {
    getNumericDataPointTypes(withExistingDataPoints: $withExistingDataPoints) {
      _id
      name
      friendlyName
      refreshInterval
      question
      valueUnit
      activeReportingFramework {
        _id
        subcategory
        category
      }
      reportingFrameworks {
        _id
        subcategory
        category
      }
    }

    getAvailableSubcategories {
      _id
      category
      subcategory
    }
  }
`;
