<script setup lang="ts">
import useFormatNumber from '@/utils/composables/useFormatNumber';
import { formatToSymbolNumber } from '@/utils/helpers/formatToSymbolNumber';

const { formatNumber } = useFormatNumber();
const props = defineProps<Props>();
type Props = {
  type?: 'aligned' | 'eligible' | 'total'
  turnover: number
  value: number
};

</script>

<template>
  <div class="font-semibold flex items-center">
    <div
      class="w-2 h-2 rounded mr-1.5"
      :class="{
        'bg-teal-600': props.type === 'aligned',
        'bg-orange-400': props.type === 'eligible',
        'bg-transparent': props.type === 'total',
      }"
    />
    <span>
      {{ formatNumber(value === 0 ? 0 : value / props.turnover * 100) }}%
    </span>
  </div>
  <div class="ml-3.5">
    {{ formatToSymbolNumber(value, 2) }} EUR
  </div>
</template>
