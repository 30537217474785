<script setup lang="ts">
// TODO: rework using <Transition>, so animation can work with v-if

import { useMounted, onClickOutside } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { XIcon } from '@heroicons/vue/outline';
import { ref } from 'vue';

const isMounted = useMounted();

type Props = {
  isRevealed: boolean;
  hasCloseButton?: boolean;
  overlayDismiss?: boolean;
  closeOnOutside?: boolean,
  teleportTo?: string;
};

const props = withDefaults(defineProps<Props>(), {
  hasCloseButton: true,
  overlayDismiss: true,
  closeOnOutside: true,
  teleportTo: '#app',
});

const emit = defineEmits<TEmits>();
type TEmits = {
  (e: 'update:isRevealed', isRevealed: boolean): void
  (e: 'close'): void
};

const { t } = useI18n();

const closeModal = () => {
  emit('update:isRevealed', false);
  emit('close');
};

const modalBoxRef = ref<HTMLElement>();
onClickOutside(modalBoxRef, closeModal);

defineOptions({ inheritAttrs: false });
</script>

<template>
  <Teleport
    v-if="isMounted"
    :to="teleportTo"
  >
    <input
      class="modal-toggle"
      type="checkbox"
      :checked="props.isRevealed"
    >
    <div
      class="modal"
      data-cy="MlModal"
      @click.self="overlayDismiss && closeModal"
    >
      <div
        ref="modalBoxRef"
        class="modal-box relative grid w-fit rounded-lg max-w-4xl"
        v-bind="$attrs"
      >
        <XIcon
          v-if="hasCloseButton"
          :title="t('Close')"
          class="fixed right-2 top-2 h-5 hover:text-primary cursor-pointer"
          @click="closeModal"
        />
        <slot />
      </div>
    </div>
  </Teleport>
</template>
