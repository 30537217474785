import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UnfollowDataPointRequestMutation,
  UnfollowDataPointRequestMutationVariables,
} from '@/__generated__/types';

export const UNFOLLOW_DATAPOINT_REQUEST = gql`
  mutation UnfollowDataPointRequest($dataPointRequestId: String!) {
    unfollowDataPointRequest(dataPointRequestId: $dataPointRequestId) {
      _id
      followers {
        _id
      }
    }
  }
`;

type TOptions = UseMutationOptions<UnfollowDataPointRequestMutation, UnfollowDataPointRequestMutationVariables>

const useUnfollowDataPointRequestMutation = (options?: TOptions) => useMutation<
  UnfollowDataPointRequestMutation,
  UnfollowDataPointRequestMutationVariables
>(UNFOLLOW_DATAPOINT_REQUEST, options);

export default useUnfollowDataPointRequestMutation;
