import { gql } from '@apollo/client/core';

export default gql`
  query OgDateRangePicker {
    getOwnUser {
      _id
      entity {
        _id
        entitySettings {
          _id
          activeReportingPeriod {
            _id
            from
            to
          }
        }
      }
    }
  }
`;
