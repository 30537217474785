import { gql } from '@apollo/client/core';

export default gql`
  query DoubleMaterialityDocumentDownload($file: DoubleMaterialityDownloadDocumentEnum!) {
    downloadDoubleMaterialityDocument(file: $file) {
      file
      name
      type
    }
  }
`;
