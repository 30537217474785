<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import { RouterLink } from 'vue-router';
import type { OgDataPointTypeSelectQuery } from '@/__generated__/types';
import { useCategoryTranslate } from '@/utils/composables/useCategoryTranslate/useCategoryTranslate';
import MlSelectLevel3 from '@/components/molecules/MlSelectLevel3/MlSelectLevel3.vue';
import MlAutocomplete from '@/components/molecules/MlAutocomplete.vue';
import OG_DATA_POINT_TYPE_SELECT from './OgDataPointTypeSelect.query';

export type OgDataPointTypeSelectModelValue = ArrayElement<OgDataPointTypeSelectQuery['getNumericDataPointTypes']>;

const props = defineProps<{
  modelValue?: OgDataPointTypeSelectModelValue,
  multipleValue?: OgDataPointTypeSelectModelValue[],
  customOnly?: boolean,
  multiple?: boolean,
  invalid?: boolean,
  selectClassWrapper?: string,
  autocompleteClassWrapper?: string,
  withExistingDataPoints?: boolean,
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', dataPointType?: OgDataPointTypeSelectModelValue): void,
  (e: 'update:multipleValue', dataPointTypes?: OgDataPointTypeSelectModelValue[]): void,
}>();

const translateCategory = useCategoryTranslate();

const { result } = useQuery<OgDataPointTypeSelectQuery>(OG_DATA_POINT_TYPE_SELECT, {
  withActiveReportingFramework: true,
  withExistingDataPoints: !!props.withExistingDataPoints,
}, {
  fetchPolicy: 'network-only',
});

const selectedSubcategory = ref('');

const dataPointTypes = computed(() => props.customOnly
  ? result.value?.getNumericDataPointTypes.filter(({ entity }) => !!entity?._id) ?? []
  : result.value?.getNumericDataPointTypes ?? [],
);

const subcategoryOptions = computed(() => {
  const subcategoryMap = dataPointTypes.value.reduce<Record<string, Set<string>>>((acc, dpt) => {
    const { category, subcategory } = dpt.activeReportingFramework;

    if (!category || !subcategory) return acc;

    acc[category] ??= new Set();
    acc[category].add(subcategory);

    return acc;
  }, {});

  return (result.value?.getCategoriesWithSubcategories || []).map((category) => ({
    id: category._id,
    title: translateCategory(category.slug),
    children: category.subcategories
      .filter((subcat) => subcategoryMap[category.slug]?.has(subcat.slug))
      .sort((a, b) => a.orderPosition - b.orderPosition)
      .map((subcat) => ({
        id: subcat.slug,
        title: translateCategory(category.slug, subcat.slug),
      })),
  }));
});

const dataPointTypeOptions = computed(() => dataPointTypes.value
  .reduce<Record<string, Record<string, string>>>((acc, dpt) => {
    const { category, subcategory } = dpt.activeReportingFramework;

    if (!category || !subcategory) return acc;

    acc[subcategory] ??= {};
    acc[subcategory][dpt._id] = dpt.friendlyName;

    return acc;
  }, {}) || {},
);

const selectedDataPointType = ref(props.modelValue?._id ?? ''); // workaround in case of one way binding
const handleSelectDataPointType = (id: string) => {
  const dpt = dataPointTypes.value.find(({ _id }) => _id === id);

  emit('update:modelValue', dpt);
  selectedDataPointType.value = id;
};
const selectedDataPointTypesMultiple = ref<string[]>(props.multipleValue?.map(({ _id }) => _id) ?? []);
const handleSelectDataPointTypeMultiple = (ids: string[]) => {
  const dpts = dataPointTypes.value.filter(({ _id }) => ids.includes(_id));

  emit('update:multipleValue', dpts);
  selectedDataPointTypesMultiple.value = ids;
};

const { t } = useI18n();
</script>

<template>
  <div class="flex gap-4">
    <div v-if="!dataPointTypes.length" class="text-sm text-gray-500 py-2 mb-1">
      <p>
        {{ t('No data points available') }}
      </p>
      <p class="mt-2">
        {{ t('If you wish to create a new custom data point, please go to your') }}
        <RouterLink :to="{ name: 'projects' }" class="text-info underline">
          {{ t('Projects.') }}
        </RouterLink>
      </p>
    </div>
    <template v-else>
      <MlSelectLevel3
        v-model="selectedSubcategory"
        :class="{ 'ring-2 ring-error': props.invalid && !selectedSubcategory }"
        :label="t('Topic')"
        size="lg"
        :placeholder="t('Select topic ')"
        :items="subcategoryOptions"
        :wrapperClass="props.selectClassWrapper"
      />

      <MlAutocomplete
        :modelValue="props.multiple ? selectedDataPointTypesMultiple : selectedDataPointType"
        :class="{ 'ring-2 ring-error': props.invalid }"
        :label="t('Datapoint')"
        :placeholder="props.modelValue ? '' : t('Select datapoint')"
        :options="dataPointTypeOptions[selectedSubcategory]"
        :disabled="!selectedSubcategory"
        :multiple="props.multiple"
        :wrapperClass="props.autocompleteClassWrapper"
        sortedOptions
        @update:modelValue="props.multiple ? handleSelectDataPointTypeMultiple($event) : handleSelectDataPointType($event)"
      />
    </template>
  </div>
</template>
