import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { CreateInsightMutation, CreateInsightMutationVariables } from '@/__generated__/types';

export const CREATE_INSIGHT = gql`
  mutation CreateInsight($createInsightInput: CreateInsightInput!) {
    createInsight(createInsightInput: $createInsightInput) {
      _id
      
      reportingFrameworks {
        _id
        category
        subcategory
      }
      
      name
      question
      refreshInterval
      entity {
        _id
      }
    }
  }
`;

type TOptions = UseMutationOptions<CreateInsightMutation, CreateInsightMutationVariables>

const useCreateInsightMutation = (options?: TOptions) => useMutation<
  CreateInsightMutation,
  CreateInsightMutationVariables
>(CREATE_INSIGHT, options);

export default useCreateInsightMutation;
