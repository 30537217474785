import { gql } from '@apollo/client/core';

export default gql`
  query PdfReport(
    $isAdmin: Boolean!,
    $reportType: PdfReportTypeEnum!,
    $dataPointTypeIds: [String!],
    $dateFrom: String!,
    $dateTo: String!,
    $projectId: String,
    $workspaceIds: [String!]
    $scope2EmissionTypeExcluded: DataPointTypeEmissionTypeEnum,
  ) {
    getDataPointsEncodedPDFReport(
      reportType: $reportType,
      dataPointTypeIds: $dataPointTypeIds,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      projectId: $projectId
      scope2EmissionTypeExcluded: $scope2EmissionTypeExcluded
    ) @skip(if: $isAdmin)

    getDataPointsEncodedAdminPDFReport(
      reportType: $reportType,
      dataPointTypeIds: $dataPointTypeIds,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      workspaceIds: $workspaceIds,
      scope2EmissionTypeExcluded: $scope2EmissionTypeExcluded
    ) @include(if: $isAdmin)
  }
`;
