import saveFile from './saveFile';

const writeReport = (encodedData: string, lastName: string | null | undefined, dataPointTypeNames: string[], format: 'docx' | 'xlsx') => {
  const contentType = format === 'docx' ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : 'application/vnd.ms-excel;charset=utf-8';
  saveFile(encodedData, `esg_report${lastName ? `_${lastName}` : ''}${dataPointTypeNames.length === 1 ? `_${dataPointTypeNames[0]}` : ''}.${format}`, contentType);
};
const generateReport = (
  encodedReport: string,
  lastName: string | null | undefined,
  format: 'docx' | 'xlsx',
  dataPointTypeNames: string[] = [],
) => {
  writeReport(encodedReport, lastName, dataPointTypeNames, format);
};

export default generateReport;
