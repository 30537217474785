<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { email, helpers, requiredIf } from '@vuelidate/validators';
import { notify } from '@kyvg/vue3-notification';
import { type PgCustomQuestionnaireShareQuery, UserRole } from '@/__generated__/types';
import useUpdateUserSubcategoryAssignmentsMutation from '@/api/mutations/User/updateUserSubcategoryAssignments.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
// import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import type { CustomQuestionnaire } from '../../types';
import PG_CUSTOM_QUESTIONNAIRE_SHARE_QUERY from './PgCustomQuestionnaireShare.query';
import OgSuccessDialog from './OgSuccessDialog.vue';

// TODO Handle "Share externally" feature.

type ShareForm = {
  internal: {
    [UserRole.SUPERADMIN]: string;
    [UserRole.ADMIN]: string;
    [UserRole.AUDITOR]: string;
    [UserRole.MANAGER]: string;
    [UserRole.CONTRIBUTOR]: string;
  };
  external: {
    email: string;
  };
};

type TeamMembersByRole = Record<UserRole, Record<string, string>>;

type Props = {
  isLoading: boolean;
  customQuestionnaire: CustomQuestionnaire | null;
};

const props = defineProps<Props>();

const { t } = useI18n();
const router = useRouter();

const showSuccessDialog = ref(false);

const { result } = useQuery<PgCustomQuestionnaireShareQuery>(PG_CUSTOM_QUESTIONNAIRE_SHARE_QUERY);

const { mutate: updateAssignments, loading: savingAssignments } = useUpdateUserSubcategoryAssignmentsMutation({
  update: (store) => {
    store.evict({
      fieldName: 'getTeamUsers',
    });
  },
});

const form = reactive<ShareForm>({
  internal: {
    [UserRole.SUPERADMIN]: '',
    [UserRole.ADMIN]: '',
    [UserRole.AUDITOR]: '',
    [UserRole.MANAGER]: '',
    [UserRole.CONTRIBUTOR]: '',
  },
  external: {
    email: '',
  },
});
const validationRules = computed(() => {
  return {
    external: {
      email: {
        requiredIf: requiredIf(() => form.external.email.length > 0),
        email: helpers.withMessage(t('Invalid email'), email),
      },
    },
  };
});
const v$ = useVuelidate(validationRules, form);

watch(
  [
    () => result.value?.getTeamUsers,
    () => props.customQuestionnaire?.subcategory?.slug,
    () => props.customQuestionnaire?.initiallyCreatedForEntityLocation._id,
  ],
  ([newTeamMembers, questionnaireSubcategory, projectId]) => {
    if (newTeamMembers) {
      newTeamMembers.forEach((teamMember) => {
        teamMember.assignments.subcategories
          .filter((subcategory) => subcategory.subcategory === questionnaireSubcategory
            && subcategory.location._id === projectId
            && Object.prototype.hasOwnProperty.call(form.internal, subcategory.role))
          .forEach((subcategory) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (form.internal as any)[subcategory.role] = teamMember._id;
          });
      });
    }
  },
  { immediate: true },
);

const roles = computed(() => [
  UserRole.SUPERADMIN,
  UserRole.ADMIN,
  UserRole.MANAGER,
  UserRole.CONTRIBUTOR,
  UserRole.AUDITOR,
] as const);
const teamMembersByRole = computed(() => {
  return (result.value?.getTeamUsers || []).reduce<TeamMembersByRole>(
    (acc, teamMember) => {
      acc[teamMember.role][teamMember._id] = teamMember.firstName && teamMember.lastName
        ? `${teamMember.firstName} ${teamMember.lastName}`
        : teamMember.email;

      return acc;
    },
    {
      [UserRole.SUPERADMIN]: {},
      [UserRole.ADMIN]: {},
      [UserRole.MANAGER]: {},
      [UserRole.CONTRIBUTOR]: {},
      [UserRole.NONE]: {},
      [UserRole.AUDITOR]: {},
    },
  );
});

function handlePreviousClick() {
  router.push({
    name: 'projectsCustomQuestionnaireEditQuestionnaire',
  });
}

async function handleFinishClick() {
  v$.value.$touch();

  if (v$.value.$error) {
    return;
  }

  if (props.customQuestionnaire) {
    const projectId = props.customQuestionnaire?.initiallyCreatedForEntityLocation._id;
    const subcategorySlug = props.customQuestionnaire?.subcategory?.slug;
    if (!projectId || !subcategorySlug) {
      return;
    }

    try {
      await updateAssignments({
        subcategoryAssignments: {
          userSubcategoryAssignmentInputs: Object.entries(form.internal)
            .filter(([, assigneeId]) => !!assigneeId)
            .map(([role, assigneeId]) => ({
              assigneeId,
              role: role as UserRole,
              location: projectId,
              subcategory: subcategorySlug,
            })),
        },
      });

      showSuccessDialog.value = true;
    } catch {
      notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
    }
  }
}
</script>

<template>
  <div class="">
    <h1 class="text-lg leading-5 font-medium mb-4">
      {{ t('Assign questionnaire internally') }}
    </h1>
    <p class="text-sm mb-6">
      {{ t('Assign team members to answer your questions and define roles, or forward the questionnaire to people outside your organization.') }}
    </p>

    <div class="mb-12">
      <div
        v-for="role in roles"
        :key="role"
        class="flex items-center gap-14 px-1 py-5 border-b last:border-b-0 border-b-gray-200"
      >
        <div class="flex-none w-44">
          <div
            class="rounded-md px-4 py-2 w-full font-bold text-sm uppercase"
            :class="{
              'text-white bg-blue-600': form.internal[role],
              'text-[#9CA3AF] bg-gray-200': !form.internal[role],
            }"
          >
            {{ t(role) }}
          </div>
        </div>
        <div class="flex-1">
          <MlSelect
            v-model="form.internal[role]"
            allowSelectNone
            wrapperClass="max-w-[288px]"
            :placeholder="t('Assign reporter')"
            :options="teamMembersByRole[role]"
          />
        </div>
      </div>
    </div>

    <!--    <h1 class="text-lg leading-5 font-medium mb-4">-->
    <!--      {{ t('Share questionnaire externally') }}-->
    <!--    </h1>-->
    <!--    <p class="text-sm mb-10">-->
    <!--      {{ t('Forward the questionnaire to people outside your organization.') }}-->
    <!--    </p>-->
    <!--    <div class="flex gap-14 px-1">-->
    <!--      <div class="flex-none w-44">-->
    <!--        <div-->
    <!--          class="rounded-md px-4 py-2 w-full font-bold text-sm uppercase"-->
    <!--          :class="{-->
    <!--            'text-white bg-blue-600': form.external.email.length > 0,-->
    <!--            'text-[#9CA3AF] bg-gray-200': form.external.email.length === 0,-->
    <!--          }"-->
    <!--        >-->
    <!--          {{ t('Recipient') }}-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      <div class="flex-1">-->
    <!--        <AtInput-->
    <!--          v-model="form.external.email"-->
    <!--          wrapperClass="max-w-[288px]"-->
    <!--          type="email"-->
    <!--          :placeholder="t('Enter email address of the recipient')"-->
    <!--          :errors="v$.external.email.$errors"-->
    <!--        />-->
    <!--      </div>-->
    <!--    </div>-->
  </div>

  <div class="flex items-center justify-between gap-4 pb-8">
    <AtButton type="button" variant="outline" @click="handlePreviousClick">
      {{ t('Previous') }}
    </AtButton>
    <AtButton type="button" :loading="savingAssignments" @click="handleFinishClick">
      {{ t('Finish and share the questionnaire') }}
    </AtButton>
  </div>

  <OgSuccessDialog
    v-if="showSuccessDialog"
    :project="customQuestionnaire?.initiallyCreatedForEntityLocation._id ?? ''"
    :category="customQuestionnaire?.category?.slug ?? ''"
    :subcategory="customQuestionnaire?.subcategory?.slug ?? ''"
    @close="showSuccessDialog = false"
  />
</template>
