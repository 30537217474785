<script setup lang="ts">
import { computed, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import { MAXIMUM_NESTING_DEPTH } from '@/constants/projects';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import { CheckboxSize } from '@/components/atoms/AtCheckbox/types';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import type { Project } from '../types';

export type Values = {
  name: string;
  parent: string | null;
};

type ProjectForm = {
  name: string;
  shouldNest: boolean;
  parent: string;
};

type Props = {
  projects: Project[];
  existingProjectsNames: string[];
  initialName: string;
  initialParent: string | null;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  cancel: [];
  next: [event: Values];
}>();

const { t } = useI18n();

const form = reactive<ProjectForm>({
  name: '',
  shouldNest: false,
  parent: '',
});
watch([() => props.initialName, () => props.initialParent], ([newInitialName, newInitialParent]) => {
  form.name = newInitialName;
  form.shouldNest = !!newInitialParent;
  form.parent = newInitialParent ?? '';
}, { immediate: true });

const validationRules = computed(() => {
  const existingNames = new Set(props.existingProjectsNames);

  return {
    name: {
      required: helpers.withMessage(t('Project name is required.'), required),
      unique: helpers.withMessage(
        t('A project with this name already exists.'),
        (name: string) => !existingNames.has(name),
      ),
    },
    parent: {
      requiredIf: helpers.withMessage(t('Project is required.'), requiredIf(() => form.shouldNest)),
    },
  };
});
const v$ = useVuelidate(validationRules, form);
const availableProjects = computed(() => props.projects.filter((project) => project.depth < MAXIMUM_NESTING_DEPTH));
const isNestable = computed(() => availableProjects.value.length > 0);
const projectsOptions = computed(() => availableProjects.value.reduce((acc, item) => ({
  ...acc,
  [item._id]: item.name,
}), {}));

function handleSubmit() {
  emit('next', {
    name: form.name,
    parent: form.shouldNest ? form.parent : null,
  });
}

function handleCancelClick() {
  emit('cancel');
}
</script>

<template>
  <h4 class="text-lg mb-8">
    {{ t('Add new project') }}
  </h4>

  <form @submit.prevent="handleSubmit">
    <div class="mb-48 pr-48">
      <AtInput
        v-model="form.name"
        wrapperClass="w-96 mb-4"
        :label="t('Name')"
        :errors="v$.name.$errors"
        @blur="v$.name.$touch"
      />
      <div v-if="isNestable" class="flex items-center space-x-2 mb-1">
        <AtCheckbox
          squared
          :checked="form.shouldNest"
          :size="CheckboxSize.XS"
          @toggleCheckbox="form.shouldNest = !form.shouldNest"
        >
          <template #label>
            <p class="text-sm font-medium text-gray-700">
              {{ t('Nest project under:') }}
            </p>
          </template>
        </AtCheckbox>
      </div>
      <MlSelect
        v-if="isNestable"
        v-model="form.parent"
        usePortal
        :disabled="!form.shouldNest"
        :options="projectsOptions"
        :errors="v$.parent.$errors"
        @blur="v$.parent.$touch"
      />
    </div>

    <div class="flex space-x-4 justify-between items-center">
      <div class="flex-0">
        <AtButton type="button" variant="outline" @click="handleCancelClick">
          {{ t('Cancel') }}
        </AtButton>
      </div>
      <div class="flex-0">
        <AtButton type="submit" :disabled="v$.$invalid">
          {{ t('Next') }}
        </AtButton>
      </div>
    </div>
  </form>
</template>
