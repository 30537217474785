<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import MlModal from '@/components/molecules/MlModal.vue';

const props = defineProps<{
  isRevealed: boolean,
}>();

const emit = defineEmits<{
  (e: 'update:isRevealed', isRevealed: boolean): void
  (e: 'topDown'): void
  (e: 'bottomUp'): void
}>();

const { t } = useI18n();
</script>

<template>
  <MlModal
    class="w-[90%] max-w-[83rem] p-20"
    :isRevealed="props.isRevealed"
    @update:isRevealed="emit('update:isRevealed', $event)"
  >
    <h3 class="font-medium text-xl">
      {{ t('Select approach') }}
    </h3>
    <p> {{ t('Select the approach with which you want to create your goal.') }}</p>
    <div class="divider" />
    <div class="flex gap-6 mb-12">
      <button
        class="flex flex-col bg-gray-200 rounded-lg p-6 max-w-[26rem] text-left ring-primary hover:ring-1"
        @click="emit('topDown'), emit('update:isRevealed', false)"
      >
        <h4 class="font-medium text-lg">
          {{ t('Top down approach') }}
        </h4>
        <p class="mt-5">
          {{ t('Start by choosing an overarching goal from the Sustainable Development Goals, followed by an underlying target and, finally, datapoint(s) from your projects.') }}
        </p>
      </button>

      <button
        class="flex flex-col bg-gray-200 rounded-lg p-6 max-w-[26rem] text-left ring-primary hover:ring-1"
        @click="emit('bottomUp'), emit('update:isRevealed', false)"
      >
        <h4 class="font-medium text-lg">
          {{ t('Bottom-up approach') }}
        </h4>
        <p class="mt-5">
          {{ t('Start by choosing a data point from one of your projects, and then tie it to more general targets and Sustainable Development Goals.') }}
        </p>
      </button>
    </div>
  </MlModal>
</template>
