import { gql } from '@apollo/client/core';

export default gql`
  query PgLoginCallback($token: String!) {
    getLoginWorkspaces(token: $token) {
      isNewUser
      workspaces
    }
  }
`;
