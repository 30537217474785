import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { UpdateEntityReportingFrameworksMutation, UpdateEntityReportingFrameworksMutationVariables } from '@/__generated__/types';

export const UPDATE_ENTITY_REPORTING_FRAMEWORKS = gql`
  mutation UpdateEntityReportingFrameworks($updateEntityReportingFrameworksInput: UpdateEntityReportingFrameworksInput!) {
    updateEntityReportingFrameworks(updateEntityReportingFrameworksInput: $updateEntityReportingFrameworksInput) {
      _id
      reportingFrameworks
    }
  }
`;

type TOptions = UseMutationOptions<UpdateEntityReportingFrameworksMutation, UpdateEntityReportingFrameworksMutationVariables>

const useUpdateEntityReportingFrameworks = (options?: TOptions) => useMutation<
  UpdateEntityReportingFrameworksMutation,
  UpdateEntityReportingFrameworksMutationVariables
>(UPDATE_ENTITY_REPORTING_FRAMEWORKS, options);

export default useUpdateEntityReportingFrameworks;
