<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import { useMounted } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { CogIcon, SearchIcon } from '@heroicons/vue/outline';
import {
  GetStartedStatusEnum,
  type OgProjectSidebarQuery,
  type OgProjectSidebarQueryVariables,
  RestrictionsFeaturesEnum,
  UserRole,
} from '@/__generated__/types';
import { useCategoryTranslate } from '@/utils/composables/useCategoryTranslate/useCategoryTranslate';
import useRestrictions from '@/utils/composables/useRestrictions/useRestrictions';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import AtPageNavTeleport from '@/components/organisms/OgPageNav/AtPageNavTeleport.vue';
import MlSidebarTabs, { type TItem } from '@/components/molecules/MlSidebarTabs.vue';
import TmSettingsMateriality from '@/components/pages/PgSettings/TmSettingsMateriality/TmSettingsMateriality.vue';
import { TabName } from '@/components/pages/PgSettings/TmSettingsMateriality/types';
import OgCategoriesManagement from '@/components/pages/PgProjects/PgProject/OgCategoriesManagement/OgCategoriesManagement.vue';
import OgCreateQuestionnaire from './OgCreateQuestionnaire/OgCreateQuestionnaire.vue';
import OG_PROJECT_SIDEBAR_QUERY from './OgProjectSidebar.query';

type Props = {
  projectId: string;
};

const props = defineProps<Props>();

const router = useRouter();
const route = useRoute();
const { t } = useI18n();
const translateCategory = useCategoryTranslate();
const isMounted = useMounted();
const { isAdminOrSuperAdmin } = useCurrentUser();
const CARBON_ACCOUNTING = 'carbon_accounting';

const { restrictions } = useRestrictions();

const queryVariables = computed<OgProjectSidebarQueryVariables>(() => ({
  projectId: props.projectId,
}));
const { result } = useQuery<OgProjectSidebarQuery, OgProjectSidebarQueryVariables>(OG_PROJECT_SIDEBAR_QUERY, queryVariables);

const searchBy = ref('');

const modalCategory = ref<string | null>(null);

const showCreateQuestionnaireDialog = ref(false);

const isCarbonAccountingEnabled = computed(() => !restrictions
  .value
  ?.restrictedFeatures
  .includes(RestrictionsFeaturesEnum.CARBON_ACCOUNTING));

const hasGetStartedTopics = computed(() => route.query.getstarted === GetStartedStatusEnum.TOPICS.toLowerCase());

const selectedCategory = computed(() => ({
  value: route.query.category && !Array.isArray(route.query.category) ? route.query.category : '',
}));
const selectedSubcategory = computed(() => ({
  value: route.query.subcategory && !Array.isArray(route.query.subcategory) ? route.query.subcategory : '',
}));
const activeProject = computed(() => result.value?.entityLocationSummary || null);
const allProjects = computed(() => result.value?.entityLocationSummaries);
const isCategoriesManagementModalOpen = ref(false);
const toggleCategoriesManagementModal = (isOpen: boolean) => {
  isCategoriesManagementModalOpen.value = isOpen;
};

const assignmentsSubcategories = computed(() => result.value?.getUserAssignedDataPointRequestsSubcategories ?? []);

// Sidebar items
const categories = computed(() => {
  const userAssignments = new Set(assignmentsSubcategories.value
    .filter((item) => item.location._id === activeProject.value?._id)
    .map((item) => item.subcategory));

  const subcategoriesWithOrder = (result.value?.getCategoriesWithSubcategoriesForUser ?? []).flatMap((category) => {
    return category.subcategories.map((subcategory) => ({
      ...subcategory,
      category: category.slug,
      orderPosition: subcategory.orderPosition,
    }));
  });

  return (result.value?.getCategoriesWithSubcategoriesForUser ?? [])
    .filter((category) => {
      const projectAssignedCategory = activeProject.value?.assignments?.categories
        .find((assignedCategory) => assignedCategory.category === category.slug);
      const isCategoryEnabledInProject = projectAssignedCategory != null && projectAssignedCategory.isEnabled;
      const hasEnabledUserSubcategoryAssignments = isAdminOrSuperAdmin.value || (projectAssignedCategory?.subcategories ?? [])
        .some((subcategoryAssignment) => userAssignments.has(subcategoryAssignment.subcategory));

      return isCategoryEnabledInProject || hasEnabledUserSubcategoryAssignments;
    })
    .map((item) => {
      const subcategories = (
        activeProject
          .value
          ?.assignments
          ?.categories
          .find((projectAssignedCategory) => projectAssignedCategory.category === item.slug)
          ?.subcategories
          .filter((subcategory) => isAdminOrSuperAdmin.value || userAssignments.has(subcategory.subcategory))
        ?? []
      );
      const orderedSubcategories = subcategories.map((subcategory) => {
        const matchingSubcategory = subcategoriesWithOrder
          .find((subcategoryWithOrder) => subcategoryWithOrder.slug === subcategory.subcategory);

        return {
          ...subcategory,
          orderPosition: matchingSubcategory?.orderPosition,
        };
      });
      orderedSubcategories.sort((subcategoryA, subcategoryB) => (subcategoryA.orderPosition || 0) - (subcategoryB.orderPosition || 0));

      return {
        ...item,
        subcategories: orderedSubcategories,
      };
    })
    .map((categoryAssignment) => {
      const projectCategoryAssignment = activeProject.value?.assignments?.categories
        .find((assignedCategory) => assignedCategory.category === categoryAssignment.slug);

      const isEnabled = !!projectCategoryAssignment?.isEnabled;

      return {
        _id: categoryAssignment._id,
        label: translateCategory(categoryAssignment.slug),
        name: translateCategory(categoryAssignment.slug),
        value: categoryAssignment.slug,
        category: categoryAssignment.slug,
        isCustomCategory:
          categoryAssignment.location != null
          || categoryAssignment.entity != null
          || categoryAssignment.organization != null,
        createdBy: {
          firstName: categoryAssignment.createdBy?.firstName,
          lastName: categoryAssignment.createdBy?.lastName,
        },
        createdAt: categoryAssignment.createdAt,
        isEnabled,
        childs: categoryAssignment.subcategories.map((subcategory) => ({
          label: translateCategory(categoryAssignment.slug, subcategory.subcategory),
          value: subcategory.subcategory,
          ...(subcategory.subcategory.startsWith('3_')) ? {
            class: 'ml-3',
          } : {},
        })),
      };
    });
});
const filteredSidebarItems = computed<TItem[]>(() => {
  const searchValue = searchBy.value.trim().toLowerCase();

  return categories.value.filter((item) => {
    if (!searchValue) {
      return true;
    }

    if (item.label?.toLowerCase().includes(searchValue)) {
      return true;
    }

    return item.childs?.some((child) => child.label?.toLowerCase().includes(searchValue));
  }).filter((s) => (s.childs != null && s.childs.length > 0) || s.isEnabled);
});

function handleSidebarClick(event: TItem) {
  router.push({
    name: 'projectsProjectDataEntry',
    params: {
      project: props.projectId,
    },
    query: {
      category: event.value,
      subcategory: event.category === CARBON_ACCOUNTING && !isCarbonAccountingEnabled.value ? [] : event.childValue,
    },
  });
}

watchEffect(() => {
  if (selectedCategory.value.value === '') {
    const firstCategoryWithActiveSubcategory = categories.value.find((c) => c.childs?.length && (c.isCustomCategory || c.isEnabled));

    if (firstCategoryWithActiveSubcategory) {
      router.push({
        query: {
          category: firstCategoryWithActiveSubcategory.category,
          subcategory: firstCategoryWithActiveSubcategory.childs[0].value,
        } });
    }
  }
});

function handleSettingsClicked(value: TItem['value']) {
  modalCategory.value = value;
}

function handleSettingsClose() {
  modalCategory.value = null;
}

function handleCreateQuestionnaire() {
  toggleCategoriesManagementModal(false);
  showCreateQuestionnaireDialog.value = true;
}

function handleCreateQuestionnaireClose() {
  showCreateQuestionnaireDialog.value = false;
}
</script>

<template>
  <AtPageNavTeleport v-if="isMounted">
    <MlSidebarTabs
      class="border-r h-full"
      scrollable
      :items="filteredSidebarItems"
      :modelValue="selectedCategory"
      :childModelValue="selectedSubcategory"
      :allExpanded="!!searchBy"
      @update:modelValue="handleSidebarClick"
    >
      <h2
        class="mt-3 ml-4 py-2 text-lg font-medium leading-6 text-gray-900 sm:truncate flex"
      >
        {{ t('Topics') }}
        <CogIcon
          v-rolefrom="UserRole.ADMIN"
          class="w-4 cursor-pointer ml-2 text-gray-400"
          @click="toggleCategoriesManagementModal(true)"
        />
      </h2>
      <div class="px-4 mt-4">
        <AtInput v-model="searchBy" type="text" :placeholder="t('Search')">
          <template #icon>
            <SearchIcon />
          </template>
        </AtInput>
      </div>
      <div class="h-3" />
      <template #tabContentAfterLabel="{ item }">
        <template v-if="item.value !== CARBON_ACCOUNTING || isCarbonAccountingEnabled">
          <div class="text-gray-400 float-right flex mt-1 absolute right-4 top-2">
            <a
              v-rolefrom="UserRole.ADMIN"
              :title="t('Settings')"
              aria-label="Cog icon"
              @click.stop="handleSettingsClicked(item.value)"
            >
              <CogIcon
                class="w-4 cursor-pointer"
                :class="{
                  'text-primary animate-bounce': hasGetStartedTopics,
                }"
              />
            </a>
          </div>
        </template>
      </template>
    </MlSidebarTabs>
  </AtPageNavTeleport>

  <TmSettingsMateriality
    v-if="modalCategory && activeProject"
    :category="modalCategory"
    :defaultTab="TabName.TOPICS"
    :isShown="!!modalCategory"
    :project="activeProject"
    :numberOfAvailableTopics="restrictions?.numberOfTopics"
    @close="handleSettingsClose"
  />
  <OgCategoriesManagement
    :isShown="isCategoriesManagementModalOpen"
    :categories="categories"
    :locationId="activeProject?._id"
    :locationName="activeProject?.name"
    :projects="allProjects ?? []"
    @createQuestionnaire="handleCreateQuestionnaire"
    @close="toggleCategoriesManagementModal(false)"
  />
  <OgCreateQuestionnaire
    v-if="showCreateQuestionnaireDialog && activeProject"
    :projectId="activeProject._id"
    @cancel="handleCreateQuestionnaireClose"
  />
</template>
