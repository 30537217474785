import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';

import type { UpdateTaxonomyAssessmentSectorsMutation, UpdateTaxonomyAssessmentSectorsMutationVariables } from '../../../__generated__/types';

const UPDATE_TAXONOMY_ASSESSMENT_SECTORS_MUTATION = gql`
  mutation updateTaxonomyAssessmentSectors($assessmentId: String!, $sectorIds: [String!]! ) {
    updateTaxonomyAssessmentSectors(assessmentId: $assessmentId, sectorIds: $sectorIds) {
      _id
      sectors {
        _id
        name
      }
      
      businessActivities {
        _id
      }
    }
  }
`;

type TOptions = UseMutationOptions<UpdateTaxonomyAssessmentSectorsMutation, UpdateTaxonomyAssessmentSectorsMutationVariables>

const useUpdateTaxonomyAssessmentSectors = (options?: TOptions) => useMutation<
  UpdateTaxonomyAssessmentSectorsMutation,
  UpdateTaxonomyAssessmentSectorsMutationVariables
>(UPDATE_TAXONOMY_ASSESSMENT_SECTORS_MUTATION, options);

export default useUpdateTaxonomyAssessmentSectors;
