import { gql } from '@apollo/client/core';

const USE_PIN_CHART_ADMIN_QUERY = gql`
  query UsePinChartAdmin {
    getOwnUser {
      _id
      entity {
        _id
        entitySettings {
          _id
          dashboardPinnedDataPointTypes {
            _id
            dataPointType {
              _id
            }
          }
          overviewPinnedDataPointTypes {
            _id
            dataPointType {
              _id
            }
          }
        }
      }
    }
  }
`;

export default USE_PIN_CHART_ADMIN_QUERY;
