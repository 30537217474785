import { gql } from '@apollo/client/core';

export default gql`
  query TmSettingsProfile {
    entityLocationSummaries {
      _id
      name
      isHeadquarters
    }
  }
`;
