import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  UnapproveDataPointRequestsMutation,
  UnapproveDataPointRequestsMutationVariables,
} from '@/__generated__/types';

export const UNAPPROVE_DATAPOINT_REQUESTS = gql`
  mutation UnapproveDataPointRequests(
    $unapproveDataPointRequestsInput: UnapproveDataPointRequestsInput!
  ) {
    unapproveDataPointRequests(UnapproveDataPointRequestsInput: $unapproveDataPointRequestsInput) {
      _id
      status
      displayStatus
      childs {
        _id
        status
        displayStatus
      }
    }
  }
`;

type TOptions = UseMutationOptions<UnapproveDataPointRequestsMutation, UnapproveDataPointRequestsMutationVariables>

const useUnapproveDataPointRequestsMutation = (options?: TOptions) => useMutation<
  UnapproveDataPointRequestsMutation,
  UnapproveDataPointRequestsMutationVariables
>(UNAPPROVE_DATAPOINT_REQUESTS, options);

export default useUnapproveDataPointRequestsMutation;
