import { useI18n } from 'vue-i18n';
import type { TDataPointItem } from '@/components/pages/PgStatistics/PgResults/types';

const projectHeader = (isAdminMode: boolean) => {
  const { t } = useI18n();
  return [{
    text: isAdminMode ? t('Workspace') : t('Project'),
    value: 'project',
    sortable: true,
    filterable: true,
    width: 125,
  }];
};

export function calculateHeaders({ items, isAdminMode } : {
    items: TDataPointItem[];
    isAdminMode: boolean;
}) {
  return [
    ...projectHeader(isAdminMode),
    ...items
      .reduce((acc: TDataPointItem[], _item: TDataPointItem) => {
        if (!acc.find((el) => el.header.value === _item.header.value)) acc.push(_item);
        return acc;
      }, [])
      .sort((a, b) => Number(a.header.sortingValue) > Number(b.header.sortingValue) ? -1 : 1)
      .map((__item: TDataPointItem) => __item.header),
  ];
}
