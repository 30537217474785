<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const model = defineModel<boolean | null>();

const { t } = useI18n();

function handleChange(value: boolean) {
  model.value = model.value === value ? null : value;
}
</script>

<template>
  <div class="flex space-x-6">
    <label class="flex-0 flex flex-col items-center select-none">
      <span class="mb-0.5">{{ t('Yes') }}</span>
      <input
        type="checkbox"
        class="checkbox-xs  rounded-sm checkbox-primary checkbox"
        :checked="model === true"
        @change.prevent="handleChange(true)"
      >
    </label>
    <label class="flex-0 flex flex-col items-center select-none">
      <span class="mb-0.5">{{ t('No') }}</span>
      <input
        type="checkbox"
        class="checkbox-xs  rounded-sm checkbox-primary checkbox"
        :checked="model === false"
        @change.prevent="handleChange(false)"
      >
    </label>
  </div>
</template>

<style scoped lang="postcss">

</style>
