import { nextTick, computed, getCurrentInstance, ref, type Ref } from 'vue';

const useStatefulProp = <T>(value: T, event: string) => {
  const { emit } = getCurrentInstance() ?? {};

  const statefulProp = ref(value) as Ref<T>;

  return computed({
    get: () => statefulProp.value,
    set: (v) => {
      statefulProp.value = v;
      nextTick(() => emit?.(event, v));
    },
  });
};

export default useStatefulProp;
