import { gql } from '@apollo/client/core';

export default gql`
  query useReportingPeriod($isExternal: Boolean!) {
    getOwnUser @skip(if: $isExternal){
      _id

      entity {
        _id

        entitySettings {
          _id

          activeReportingPeriod {
            _id
            from
            to
          }
        }
      }
    }
  }
`;
