import { ApolloError, type OperationVariables } from '@apollo/client';
import { type UseQueryReturn } from '@vue/apollo-composable';

/**
 * Allow to be possible to await query fetching (including lazy loading and refetching).
 * It resolves with data or an error.
 * @see [https://github.com/vuejs/apollo/issues/1486]
 */
export default function waitForApolloQuery<TResult, TVariables extends OperationVariables>({
  onResult,
  onError,
}: Pick<UseQueryReturn<TResult, TVariables>, 'onResult' | 'onError'>) {
  return new Promise<TResult | ApolloError | undefined>((res) => {
    const { off: offResult } = onResult((result) => {
      res(result.data);
      setTimeout(offResult, 1);
    });
    const { off: offError } = onError((error) => {
      res(error);
      setTimeout(offError, 1);
    });
  });
}
