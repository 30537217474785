<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { type PgTaxonomyAssessmentQuery, TaxonomyAssessmentStatusEnum } from '@/__generated__/types';
import useUpdateTaxonomyAssessment from '@/api/mutations/Taxonomy/updateTaxonomyAssessment.mutation';
import useUpdateTaxonomyAssessmentBusinessActivity from '@/api/mutations/Taxonomy/updateTaxonomyAssessmentBusinessActivity.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import OgActivityItem from './OgActivityItem.vue';

const props = defineProps<{
  assessment: PgTaxonomyAssessmentQuery['getTaxonomyAssessment'];
  taxonomyCoreDataMinimumSafeguards: boolean | null;
}>();

const { t } = useI18n();
const router = useRouter();

const { mutate: updateTaxonomyAssessment } = useUpdateTaxonomyAssessment();
const { mutate: updateTaxonomyAssessmentBusinessActivity } = useUpdateTaxonomyAssessmentBusinessActivity();

const isSaving = ref(false);

const items = computed(() => props
  .assessment
  .businessActivities
  .filter((businessActivity) => businessActivity.activityFit > 0)
  .map((businessActivity) => ({
    _id: businessActivity._id,
    businessActivity,
  })));

const show = ref<string | null>(items.value[0]?._id ?? null);

const canContinue = computed(() => items
  .value
  .every((item) => (['circular', 'adaptation', 'mitigation', 'pollution', 'biodiversity', 'water'] as const)
    .every((key) => item.businessActivity.doesNotHarm[key] !== null)));

function handleToggleItem(id: string) {
  show.value = show.value === id ? null : id;
}

function handleSavedItem(id: string) {
  const currentItemIndex = items.value.findIndex((item) => item._id === id);
  const nextItem = items.value[currentItemIndex + 1];
  show.value = nextItem?._id ?? null;
}

function handleBackClick() {
  router.push({ name: 'taxonomyAssessmentSubstantialContributionActivityFit' });
}

async function handleContinueClick() {
  isSaving.value = true;

  const result = await updateTaxonomyAssessment({
    assessmentId: props.assessment._id,
    input: {
      status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_MINIMUM_SAFEGUARDS,
    },
  }, { update: (store) => { store.evict({ fieldName: 'getTaxonomyProjects' }); store.evict({ fieldName: 'getTaxonomyAssessment' }); } });

  const isAssessmentCompleted = !result?.data?.updateTaxonomyAssessment.businessActivities.some(
    (activity) => {
      const { circular, adaptation, mitigation, pollution, biodiversity, water } = activity.doesNotHarm;
      return circular && adaptation && pollution && biodiversity && water && mitigation;
    },
  );

  if (isAssessmentCompleted) {
    await updateTaxonomyAssessment({
      assessmentId: props.assessment._id,
      input: {
        status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED,
      },
    }, { update: (store) => { store.evict({ fieldName: 'getTaxonomyProjects' }); store.evict({ fieldName: 'getTaxonomyAssessment' }); } });
    router.push({ name: 'taxonomyAssessmentDocuments' });
  } else if (props.taxonomyCoreDataMinimumSafeguards) {
    const businessActivities = props.assessment.businessActivities
      .filter((businessActivity) => (['circular', 'adaptation', 'mitigation', 'pollution', 'biodiversity', 'water'] as const).every((key) => !!businessActivity.doesNotHarm[key]));

    for await (const businessActivity of businessActivities) {
      await updateTaxonomyAssessmentBusinessActivity({
        assessmentId: props.assessment._id,
        businessActivityId: businessActivity._id,
        input: {
          minimumStandardsRespected: true,
          minimumStandardsRespectedComment: null,
          minimumStandardsRespectedFileId: null,
        },
      });
    }

    await updateTaxonomyAssessment({
      assessmentId: props.assessment._id,
      input: {
        status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED,
      },
    }, {
      update: (store) => {
        store.evict({ fieldName: 'getTaxonomyProjects' });
        store.evict({ fieldName: 'getTaxonomyAssessment' });
      },
    });

    router.push({
      name: 'taxonomyAssessmentDocuments',
    });
  } else {
    router.push({ name: 'taxonomyAssessmentMinimumSafeguardsChecklist' });
  }

  isSaving.value = false;
}
</script>

<template>
  <div>
    <p class="font-semibold mb-4">
      {{ t('Do your activities comply with the ‘do not significant harm’ (DNSH) criteria?') }}
    </p>
    <p class="mb-8">
      {{ t('You will find the conditions for compliance if you click on each activity. Once expanded, you need to confirm conditions for each of the five environmental objectives by ticking the “Yes” box next to them, and saving the result in the end.') }}
    </p>
    <div>
      <OgActivityItem
        v-for="item of items"
        :key="item._id"
        :assessmentId="assessment._id"
        :businessActivity="item.businessActivity"
        :isExpended="show === item._id"
        @toggle="handleToggleItem"
        @saved="handleSavedItem"
      />
    </div>
  </div>

  <div class="flex justify-between pt-10">
    <AtButton type="button" :disabled="isSaving" variant="outline" @click="handleBackClick">
      {{ t('Back') }}
    </AtButton>

    <AtButton type="button" :loading="isSaving" :disabled="!canContinue" @click="handleContinueClick">
      {{ t('Continue') }}
    </AtButton>
  </div>
</template>
