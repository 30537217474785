import { gql } from '@apollo/client/core';

export default gql`
  query PgDoubleMaterialitySelection {
    getDoubleMaterialityTopics {
      _id
      esrs
      topic
      subTopics {
        subTopic
        subSubTopics
      }
    }
    
    getDataPointCategoriesForDoubleMateriality {
      _id
      name
      doubleMaterialityTopic {
        _id
      }
      subcategories {
        _id
        name
        doubleMaterialityMandatoryReason
      }
    }
  }
`;
