<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import { useStore } from '../../store';
import AtCard from '../../../../../atoms/AtCard.vue';
import OgDefineImpacts from './OgDefineImpacts/OgDefineImpacts.vue';

const { t } = useI18n();
const router = useRouter();
const store = useStore();

const showDefineCard = ref(true);
const showScoreCard = ref(true);

const isStartDefineDisabled = computed(() => store.value.topics.length === 0);
const isStartScoreDisabled = computed(() => store.value.iroImpacts
  .every((iroImpact) => iroImpact.impact.trim().length === 0 && !iroImpact.type));

function handleDefineStartClick() {
  showDefineCard.value = false;
}

function handleScoreStartClick() {
  router.push({
    name: 'doubleMaterialityIROsScoreActualNegative',
  });
}
</script>

<template>
  <div v-if="showDefineCard && showScoreCard" class="flex-1 flex flex-col">
    <p class="mb-5">
      {{ t('The impact materiality (also called inside-out perspective) assesses actual and potential, negative and positive impacts your organization has on sustainability matters.') }}
    </p>
    <div class="flex space-x-11 items-stretch">
      <AtCard
        :title="t('Define your impacts')"
        :action="t('Start')"
        :disabled="isStartDefineDisabled"
        @click="handleDefineStartClick"
      >
        <p class="mb-6">
          {{ t('This is where you define all actual and potential, positive and negative impacts that your company has on sustainability matters.') }}
        </p>
        <p>
          {{ t('You can only do this once your list of sustainability matters has one or more topics.') }}
        </p>
      </AtCard>
      <AtCard
        :title="t('Score your impacts')"
        :action="t('Start')"
        :disabled="isStartScoreDisabled"
        @click="handleScoreStartClick"
      >
        <p class="mb-6">
          {{ t('This is where you score all actual and potential, positive and negative impacts that your company has on sustainability matters.') }}
        </p>
        <p>
          {{ t('You can only do this once you defined your impacts.') }}
        </p>
      </AtCard>
    </div>
    <div class="mt-auto flex items-center justify-between">
      <AtButton
        type="button"
        variant="outline"
        @click="router.push({ name: 'doubleMaterialityTopicsSelection' })"
      >
        {{ t('Go back') }}
      </AtButton>
      <AtButton
        type="button"
        @click="router.push({ name: 'doubleMaterialityIROsRisksAndOpportunities' })"
      >
        {{ t('Next') }}
      </AtButton>
    </div>
  </div>

  <OgDefineImpacts v-else-if="!showDefineCard" />
</template>
