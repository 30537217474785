<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { UploadIcon } from '@heroicons/vue/outline';
import type { TmLibraryUploadsQuery, RepositoryFile } from '@/__generated__/types';
import useGenerateFileDownloadTokenMutation from '@/api/mutations/RepositoryFile/generateFileDownloadToken.mutation';
import OgS3FilePicker from '@/components/molecules/MlFilePicker/OgS3FilePicker.vue';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import fileImg from '@/assets/file.svg';
import OgDataTableControls from '@/components/organisms/OgDataTable/OgDataTableControls.vue';
import TM_LIBRARY_UPLOADS_QUERY from './TmLibraryUploads.query';

const router = useRouter();

const adminMode = inject('adminMode', computed(() => false));

const { result, loading: queryLoading, refetch } = useQuery<TmLibraryUploadsQuery>(TM_LIBRARY_UPLOADS_QUERY, () => ({
  adminMode: adminMode.value,
}), {
  fetchPolicy: 'network-only',
});

const { t } = useI18n();

const { mutate: generateFileDownloadURLMutation } = useGenerateFileDownloadTokenMutation();

const downloadFile = async (file: Pick<RepositoryFile, '_id'>) => {
  const mutationResult = await generateFileDownloadURLMutation({
    repositoryFileId: file._id,
  });

  window.open(mutationResult?.data?.generateFileDownloadToken);
};

const repositoryFiles = computed(() => (adminMode.value
  ? result.value?.getEntityRepositoryFilesAdmin
  : result.value?.getEntityRepositoryFiles)
  ?? []);

const headers = computed(() => [
  ...(adminMode.value ? [
    { text: t('Workspace'), value: 'entity', sortable: true, filterable: true },
  ] : [
  ]),
  { text: t('Download'), value: 'download' },
]);

const items = computed(() => repositoryFiles.value.map((file) => {
  return {
    ...(adminMode.value ? {
      entity: t(file.entity?.name ?? ''),
    } : {}),
    fileName: file.filename,
    onFileClick: () => {
      if (!file._id) return;
      return downloadFile(file);
    },
  };
}));

const filteredAndSortedItems = ref(items.value);
watch(items, () => { filteredAndSortedItems.value = items.value; });

const uploadedFile = ref();

watch(uploadedFile, () => {
  refetch();
  uploadedFile.value = null;
  isFilePickerShown.value = false;
});

const isFilePickerShown = ref(false);
// const OgS3FilePickerRef = useTemplateRef<HTMLElement>('OgS3FilePicker');
// onClickOutside(OgS3FilePickerRef, () => {
//   if (isFilePickerShown.value) {
//     isFilePickerShown.value = false;
//   }
// });

</script>

<template>
  <div>
    <div class="flex w-full justify-end">
      <OgS3FilePicker
        v-show="isFilePickerShown"
        v-model="uploadedFile"
        :showRecentFiles="false"
        class="absolute z-200 bg-white"
        repositoryFileType="evidence"
        :multiple="true"
      />
    </div>

    <template v-if="!queryLoading">
      <MlEmptyStateCard
        v-if="!items.length"
        :title="t('No files yet')"
        :description="t('Start by entering data and uploading proof documents.')"
        :buttonText="t('Enter data')"
        @buttonClick="router.push({ name: 'projects' })"
      >
        <img
          class="mb-7"
          :src="fileImg"
          alt="Graph"
        >
      </MlEmptyStateCard>

      <template v-else>
        <Teleport defer to="#libraryLayoutControls">
          <div class="flex items-end">
            <button
              variant="text"
              class="text-sm flex align-bottom items-end mb-1 text-primary font-medium cursor-pointer"
              @click.stop="isFilePickerShown = !isFilePickerShown"
            >
              <UploadIcon class="h-4 mb-0.5" />
              <span class="ml-1">
                {{ t('Upload') }}
              </span>
            </button>
            <OgDataTableControls
              v-if="items.length > 0"
              v-model="filteredAndSortedItems"
              :headers="headers"
              :items="items"
            />
          </div>
        </Teleport>
        <OgDataTable
          :headers="headers"
          controlsHidden
          variant="dark-gray"
          wrapperClass="!pt-0"
          :items="filteredAndSortedItems"
        >
          <template #item-download="{ fileName, onFileClick }">
            <AtButton
              variant="link"
              data-cy="dataPointFile"
              @click="onFileClick"
            >
              {{ fileName }}
            </AtButton>
          </template>
        </OgDataTable>
      </template>
    </template>
  </div>
</template>
