import { gql } from '@apollo/client/core';

export default gql`
  query TmDashboard(
    $adminMode: Boolean!, 
    $isExternal: Boolean!, 
    $entityId: String, 
    $shareToken: String, 
    $sharePassword: String,
    $dateFrom: String, 
    $dateTo: String,
  ) {
    getReportingProgress(
      adminMode: $adminMode,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
    ) @skip(if: $isExternal) {
      ...reportingProgressFragment
    }

    getEmissions(
      adminMode: $adminMode,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
    ) @skip(if: $isExternal) {
      ...emissionsFragment
    }

    getEmissionsExternal(
      adminMode: $adminMode,
      entityId: $entityId,
      shareToken: $shareToken, 
      sharePassword: $sharePassword,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
    ) @include(if: $isExternal) {
      ...emissionsFragment
    }

    getReportingProgressExternal(
      adminMode: $adminMode,
      entityId: $entityId,
      shareToken: $shareToken, 
      sharePassword: $sharePassword,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
    ) @include(if: $isExternal) {
      ...reportingProgressFragment
    }

    getAvailableDataPointsGroupedByType {
      _id
      dataPointType {
        _id
        name
        friendlyName
        valueUnit
        valueUnitDivisor
        valueDataType
        summarizingMethod
        reportingFrameworks {
          _id
          framework
          groups
          category
          subcategory
        }
      }
    }


  }

  fragment emissionsFragment on EmissionsDto {
    _id
    scope
    category
    value
  }

  fragment reportingProgressFragment on DataPointRequestReportingProgressDto{
    pending
    open
    accepted
    rejected
    overDue
    total
    entity {
      _id
      name
      
      entitySettings {
        _id
        companyName
      }
    }
    
    location {
      _id
      name
    }
  }

`;
