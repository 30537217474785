import { gql } from '@apollo/client/core';

export default gql`
  query TmOverviewView(
    $adminMode: Boolean!
    $isExternal: Boolean!
    $isAdminOrSuperAdmin: Boolean!
    $dateFrom: String
    $dateTo: String
    $assignedOnly: Boolean
  ) {
    getTeamUsers {
      _id
      firstName
      lastName
      jobTitle
      picture {
        _id
        downloadUrl
      }
    }

    getEntityAssignments @include(if: $isAdminOrSuperAdmin) {
      assignee {
        _id
        firstName
        lastName
        email
      }
      location {
        _id
        name
      }
      category
      subcategory
      statuses
    }

    getEmissions(adminMode: $adminMode, dateFrom: $dateFrom, dateTo: $dateTo)
      @skip(if: $isExternal) {
      ...TmOverviewEmissionsFragment
    }

    getReportingProgress(
      adminMode: $adminMode
      dateFrom: $dateFrom
      dateTo: $dateTo
      assignedOnly: $assignedOnly
    ) @skip(if: $isExternal) {
      ...TmOverviewReportingProgressFragment
    }

    getEntityReportingProgress(
      adminMode: $adminMode
      dateFrom: $dateFrom
      dateTo: $dateTo
    ) @skip(if: $isExternal) {
      ...TmOverviewReportingProgressFragment
    }

    getCategories {
      _id
      slug
    }
  }

  fragment TmOverviewEmissionsFragment on EmissionsDto {
    _id
    scope
    category
    value
  }

  fragment TmOverviewReportingProgressFragment on DataPointRequestReportingProgressDto {
    pending
    open
    accepted
    rejected
    overDue
    total
    entity {
      _id
      name
    }

    location {
      _id
      name
      assignments {
        _id
        categories {
          _id
          category
        }
      }
    }
  }
`;
