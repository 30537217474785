<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import { UserRole, type TmSettingsProfileQuery } from '@/__generated__/types';
import OgProfilePictureManager from '@/components/organisms/OgProfilePictureManager.vue';
import OgModal from '@/components/organisms/OgModal.vue';
import TM_SETTINGS_PROFILE_QUERY from './TmSettingsProfile.query';

const { currentUser, currentUserPictureUrl, currentUserName, updateCurrentUser, loading: currentUserLoading } = useCurrentUser();
const { t } = useI18n();

const { result } = useQuery<TmSettingsProfileQuery>(TM_SETTINGS_PROFILE_QUERY);
const locations = computed(() => result.value?.entityLocationSummaries ?? []);

const userModel = reactive({
  firstName: currentUser.value?.firstName,
  lastName: currentUser.value?.lastName,
  email: currentUser.value?.email,
  jobTitle: currentUser.value?.jobTitle,
  jobDepartment: currentUser.value?.jobDepartment,
  picture: currentUser.value?.picture?._id || null,
});

watch(currentUser, () => {
  userModel.firstName = currentUser.value?.firstName;
  userModel.lastName = currentUser.value?.lastName;
  userModel.email = currentUser.value?.email;
  userModel.jobTitle = currentUser.value?.jobTitle;
  userModel.jobDepartment = currentUser.value?.jobDepartment;
  userModel.picture = currentUser.value?.picture?._id || null;
});

const hasUserChanged = computed(() => userModel.firstName !== currentUser.value?.firstName
  || userModel.lastName !== currentUser.value?.lastName
  || userModel.email !== currentUser.value?.email
  || userModel.jobTitle !== currentUser.value?.jobTitle
  || userModel.jobDepartment !== currentUser.value?.jobDepartment
  || userModel.picture !== currentUser.value?.picture?._id,
);

const updateUser = () => updateCurrentUser(userModel);

const updatePicture = (picture: { id: string; url: string }) => {
  if (!picture.id) return;
  updateCurrentUser({ picture: picture.id });
};

const removePicture = (id: null) => {
  updateCurrentUser({ picture: id });
};

const deleteAccountConfirmationInputs = ref({
  email: '',
});

const isDeleteAccountDisabled = computed(() => {
  return deleteAccountConfirmationInputs.value.email !== currentUser.value?.email;
});

const isDeleteAccountModalOpen = ref(false);

function closeModal() {
  isDeleteAccountModalOpen.value = false;
}

function openDeleteAccountModal() {
  isDeleteAccountModalOpen.value = true;
}

</script>

<template>
  <div class="pt-5 px-6">
    <form
      class="divide-y-gray-200 space-y-8 divide-y lg:pr-0"
      @submit.prevent
    >
      <section
        class="divide-y-gray-200 grid grid-cols-1 gap-y-6 sm:grid-cols-7 sm:gap-x-6"
      >
        <div class="sm:col-span-3">
          <h2 class="text-xl font-medium text-gray-900">
            {{ t('Profile') }}
          </h2>
          <p class="mt-3 text-sm text-gray-500">
            {{ t('Manage and edit your own profile account.') }}
          </p>
        </div>
        <div class="flex justify-end sm:col-span-4">
          <AtButton
            class="mt-2 h-9"
            :loading="currentUserLoading"
            data-cy="AtButtonSaveChanges"
            :disabled="!hasUserChanged"
            @click.prevent="updateUser"
          >
            {{ t('Save changes') }}
          </AtButton>
        </div>
        <OgProfilePictureManager
          class="sm:col-span-7"
          :pictureUrl="currentUserPictureUrl ?? ''"
          :userName="currentUserName"
          @pictureRemoved="removePicture"
          @pictureUploaded="updatePicture"
        />
        <AtInput
          v-model="userModel.firstName"
          wrapperClass="sm:col-span-2"
          labelClass="text-gray-900"
          :label="t('First name')"
          data-cy="AtInputFirstName"
          name="first-name"
          autocomplete="given-name"
        />

        <AtInput
          v-model="userModel.lastName"
          wrapperClass="sm:col-span-2"
          labelClass="text-gray-900"
          :label="t('Last name')"
          data-cy="AtInputLastName"
          name="last-name"
          autocomplete="family-name"
        />

        <AtInput
          v-model="userModel.email"
          type="email"
          wrapperClass="sm:col-span-2"
          labelClass="text-gray-900"
          data-cy="AtInputEmail"
          :label="t('Email')"
          name="email"
        />

        <AtInput
          v-model="userModel.jobTitle"
          wrapperClass="sm:col-span-2"
          labelClass="text-gray-900"
          data-cy="AtInputJobTitle"
          :label="t('Job Title')"
          name="job-title"
        />

        <AtInput
          v-model="userModel.jobDepartment"
          wrapperClass="sm:col-span-2"
          labelClass="text-gray-900"
          data-cy="AtInputJobDepartment"
          :label="t('Department')"
          name="department"
        />
      </section>
      <section
        v-if="false"
        class="divide-y-gray-200 space-y-8 px-6 pb-9"
      >
        <div class="grid grid-cols-1 gap-y-6 sm:grid-cols-7 sm:gap-x-6">
          <p class="mt-9 text-sm text-gray-500">
            {{ t('Danger zone') }}
          </p>
        </div>
        <AtButton
          variant="destructive"
          disabled
          @click.stop="openDeleteAccountModal"
        >
          {{ t('Delete Account') }}
        </AtButton>

        <OgModal :isOpen="isDeleteAccountModalOpen">
          <template #title>
            {{ t('Delete account') }}
          </template>
          <template #description>
            {{ t('This action cannot be undone. This will permanently delete your entire account.') }}
            <span v-rolefrom="UserRole.ADMIN">
              {{ t('All locations and entities will be deleted.') }}
            </span>
          </template>
          <template #default>
            <ul
              v-rolefrom="UserRole.ADMIN"
              class="mb-4"
            >
              <li
                v-for="location in locations"
                :key="location._id"
                class="text-red-500"
              >
                {{ location.name }}
              </li>
            </ul>
            <form class="grid gap-[18px] sm:grid-cols-4">
              <AtInput
                v-model="deleteAccountConfirmationInputs.email"
                type="email"
                required
                wrapperClass="sm:col-span-3"
                labelClass="text-gray-900"
                :label="t('Email')"
                name="email"
              />
            </form>
            <div class="flex justify-end pt-8">
              <AtButton
                class="mr-4"
                variant="destructive"
                :disabled="isDeleteAccountDisabled"
                @click="closeModal"
              >
                {{ t('Permanently delete account') }}
              </AtButton>
              <AtButton
                variant="text"
                @click="closeModal"
              >
                {{ t('Cancel') }}
              </AtButton>
            </div>
          </template>
        </OgModal>
      </section>
    </form>
  </div>
</template>
