import { gql } from '@apollo/client/core';

export default gql`
  query TmPendingDataPointRequests(
    $status: DataPointRequestStatusEnum!,
    $offset: Int!,
    $limit: PaginationLimitEnum!
    $sortBy: String!
    $sortOrder: PaginationSortingEnum!,
    $searchBy: String!,
    $filterBy: [DataPointRequestFilterByInput!]!
  ) {
    getCategories {
      _id
      slug
    }
    getPaginatedApprovalDataPointRequests(
      status: $status,
      offset: $offset,
      limit: $limit,
      sortBy: $sortBy,
      sortOrder: $sortOrder,
      searchBy: $searchBy,
      filterBy: $filterBy
    ) {
      totalCount
      items {
        _id
        status
        value
        displayValue
        delegations {
          user {
            _id
            email
          }
        }
        displayValueUnit
        valueSource {
          name
          originalValueUnit
          originalValue
        }

        comment
        dueDate
        file {
          _id
          filename
          filesize
        }
        assignee {
          _id
          firstName
          lastName
          email
        }
        from
        to
        rejectReason
        createdAt
        updatedAt
        location {
          _id
          name
        }
        dataPointTypeFlat {
          category
          subcategory
        }
        dataPointType {
          _id
          name
          friendlyName
          refreshInterval
          question
          valueDataType
          valueUnit
          valueUnitDivisor
          valueDataTypeOptions
          questionHelp
          activeReportingFramework {
            _id
            category
            subcategory
            framework
            groups
          }
          reportingFrameworks {
            _id
            framework
            groups
          }
          summarizingMethod
        }
      }
    }
  }
`;

export const RECENTLY_APPROVED_DATAPOINTS = gql`
  query TmPendingDataPointRequestsRecentlyApproved($dataPointTypeIds: [String!]!, $locationId: String!) {
    getDataPointsByTypeAndLocation(dataPointTypeIds: $dataPointTypeIds, locationId: $locationId) {
      _id
      value
      displayValue
      displayValueUnit
      from
      to

      valueSource {
        name
        originalValue
        originalValueUnit
      }

      dataPointType {
        _id
        friendlyName
        valueUnit
        valueUnitDivisor
        valueDataType
        valueDataTypeOptions
        activeReportingFramework {
          _id
          category
          subcategory
        }
        summarizingMethod
      }
    }
  }
`;
