<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 28 28"
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      stroke-width="0.5"
      d="M4 3h1.99a2 2 0 0 1 1.994 1.85L7.99 5v18a2 2 0 0 1-1.851 1.994l-.15.006H4a2 2 0 0 1-1.994-1.85L2 23V5a2 2 0 0 1 1.85-1.995zh1.99zm6.995 0h1.995a2 2 0 0 1 1.994 1.85l.006.15v18a2 2 0 0 1-1.851 1.994l-.15.006h-1.994A2 2 0 0 1 9 23.15L8.995 23V5a2 2 0 0 1 1.85-1.995zh1.995zm9.135 2.026c.844 0 1.616.538 1.893 1.36l.046.157l3.86 15.483a2 2 0 0 1-1.312 2.384l-.144.041l-1.963.49q-.244.06-.485.06a2 2 0 0 1-1.893-1.36l-.046-.158L16.226 8a2 2 0 0 1 1.313-2.383l.143-.042l1.963-.489q.244-.06.485-.06M5.99 4.5H4a.5.5 0 0 0-.492.41L3.5 5v18c0 .244.178.45.41.492L4 23.5h1.99c.245 0 .45-.178.492-.41L6.49 23V5a.5.5 0 0 0-.41-.492zm7 0h-1.995a.5.5 0 0 0-.492.41l-.008.09v18c0 .244.177.45.41.492l.09.008h1.995c.245 0 .45-.178.492-.41l.008-.09V5a.5.5 0 0 0-.41-.492zm7.14 2.026l-.061.004l-.062.011l-1.962.49a.5.5 0 0 0-.378.517l.014.088l3.86 15.484c.065.26.297.38.484.38l.062-.003l.06-.013l1.963-.489a.5.5 0 0 0 .378-.516l-.014-.09l-3.86-15.484a.5.5 0 0 0-.484-.379"
    />
  </svg>
</template>
