import { ReportingFrameworkEnum, type DataPointTypeReportingFramework } from '@/__generated__/types';

export const getDisplayedFrameworks = (
  activeReportingFramework: Partial<DataPointTypeReportingFramework>,
  reportingFrameworks: Partial<DataPointTypeReportingFramework>[],
) => {
  const { ESRS, GRI, DNK, CUSTOM, GHG, DERIVED } = ReportingFrameworkEnum;
  const alwaysShown = reportingFrameworks?.filter((rf) => rf.framework && [ESRS, GRI, CUSTOM, GHG, DERIVED].includes(rf.framework)) ?? [];

  switch (activeReportingFramework?.framework) {
    case ESRS:
    case GRI:
      return alwaysShown;
    case DNK:
      return [...alwaysShown, activeReportingFramework];
    case CUSTOM:
      return reportingFrameworks;
    default:
      return [];
  }
};
