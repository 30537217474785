<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { auth0 } from '@/api/auth/auth';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

const { t } = useI18n();
const route = useRoute();
const router = useRouter();

enum availableMethods {
  EMAIL,
  PASSWORD,
}
const selectedMethod = ref<availableMethods | ''>('');
const isLoading = ref(false);

const next = (e: Event) => {
  if (!(e.target as HTMLFormElement).reportValidity()) {
    return;
  }
  isLoading.value = true;

  const email = route.query.email as string;

  if (!email) {
    return router.push({ name: 'loginStart' });
  }

  switch (selectedMethod.value) {
    case availableMethods.EMAIL:
      auth0.passwordlessStart({
        connection: 'email',
        send: 'code',
        email,
      }, (err) => {
        if (err) {
          // eslint-disable-next-line no-console
          console.error(err);
          return;
        }

        router.push({ name: 'loginPasswordlessCode', query: { email } });
      });
      break;
    case availableMethods.PASSWORD:
      router.push({ name: 'loginPassword', query: { email } });
      break;
    default:
      isLoading.value = false;
      break;
  }
};

</script>

<template>
  <div
    id="box-2"
    class="box mt-8"
  >
    <div>
      <form
        @submit.prevent="next"
      >
        <div class="cols-1 grid gap-3">
          <div
            id="login-method-passwordless"
            class="grid cursor-pointer grid-cols-5 items-center gap-4  rounded-md border-2 border-[#F9F9FB] p-2 shadow-md"
            :class="{ 'border-blue-600': selectedMethod === availableMethods.EMAIL }"
            @click="selectedMethod = availableMethods.EMAIL"
          >
            <div class="col-span-1">
              <div class="border-1 ml-2 inline-block rounded-full bg-[#F5F5F5] p-3">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 17L8 1M10 17L14 1M3 6H17M1 12H15"
                    stroke="#2563EB"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>

            <div class="col-span-3 my-3">
              <p><b class="text-[#111827]">{{ t('Code sent via Email') }}</b></p>
              <span class="text-sm text-[#9CA3AF]">{{ t('Log in passwordless with a 6 digit code.') }}</span>
            </div>

            <svg
              width="9"
              height="16"
              viewBox="0 0 9 16"
              fill="none"
              class="col-span-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.999999 1L8 8L1 15"
                stroke="#2563EB"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <div
            id="login-method-password"
            class="grid cursor-pointer grid-cols-5 items-center gap-4 rounded-md border-2 border-[#F9F9FB] p-2 shadow-md"
            :class="{ 'border-blue-600': selectedMethod === availableMethods.PASSWORD }"
            @click="selectedMethod = availableMethods.PASSWORD"
          >
            <div class="col-span-1">
              <div class="border-1 ml-2 inline-block rounded-full bg-[#F5F5F5] p-3">
                <svg
                  width="18"
                  height="20"
                  viewBox="0 0 18 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 13V15M3 19H15C16.1046 19 17 18.1046 17 17V11C17 9.89543 16.1046 9 15 9H3C1.89543 9 1 9.89543 1 11V17C1 18.1046 1.89543 19 3 19ZM13 9V5C13 2.79086 11.2091 1 9 1C6.79086 1 5 2.79086 5 5V9H13Z"
                    stroke="#2563EB"
                    stroke-width="2"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>

            <div class="col-span-3 my-3">
              <p><b class="text-[#111827]">{{ t('Using password') }}</b></p>
              <span class="text-sm text-[#9CA3AF]">{{ t('Log in with your own password.') }}</span>
            </div>

            <svg
              width="9"
              height="16"
              viewBox="0 0 9 16"
              fill="none"
              class="col-span-1"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.999999 1L8 8L1 15"
                stroke="#2563EB"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>

        <div class="mt-8">
          <AtButton
            :loading="isLoading"
            class="w-full"
          >
            {{ t('Continue') }}
          </AtButton>
        </div>
      </form>
    </div>
  </div>
</template>
