import { gql } from '@apollo/client/core';

export default gql`
  query TmStepTopics {

    getCategoriesWithSubcategories{
      _id
      name
      slug
      framework
      subcategories {
        _id
        name
        slug
        orderPosition
      }
    }
    
    getOwnUser {
      _id
      role
      entity {
        _id
        
        locations {
          _id
          isHeadquarters

          assignments {
            _id
            categories {
              _id
              category
              subcategories {
                _id
                subcategory
              }
            }
          }
        }
      }
      assignments {
        _id
        subcategories {
          subcategory
        }
      }
    }
  }
`;
