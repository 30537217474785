import { computed } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import type { UsePinChartAdminQuery, UsePinChartAdminQueryVariables } from '@/__generated__/types';
import useUpdateEntitySettingsMutation from '@/api/mutations/EntitySettings/updateEntitySettings.mutation';

import USE_PIN_CHART_ADMIN_QUERY from './usePinChartAdmin.query';

const usePinChartAdmin = (props?: {type?: 'dashboard' | 'overview'}) => {
  const { result, refetch } = useQuery<UsePinChartAdminQuery, UsePinChartAdminQueryVariables>(USE_PIN_CHART_ADMIN_QUERY);

  const { mutate: updatePinnedDataPointTypes, loading } = useUpdateEntitySettingsMutation({
    update: (store) => {
      store.evict({ fieldName: 'getPinnedDataPointsAdmin' });
    },
  });

  const pinnedDataPointTypes = computed(
    () => {
      if (props?.type === 'dashboard') {
        return result.value?.getOwnUser.entity.entitySettings.dashboardPinnedDataPointTypes.map(
          ({ dataPointType }) => ({ dataPointType: dataPointType._id }),
        ) ?? [];
      }
      return result.value?.getOwnUser.entity.entitySettings.overviewPinnedDataPointTypes.map(
        ({ dataPointType }) => ({ dataPointType: dataPointType._id }),
      ) ?? [];
    },
  );
  const pinnedChartIDs = computed(
    () => pinnedDataPointTypes.value
      .map(({ dataPointType }) => dataPointType) ?? [],
  );

  const pinChart = (dataPointTypeId: string) => {
    const updatePinnedDataPointTypesInput = () => {
      return props?.type === 'dashboard' ? {
        dashboardPinnedDataPointTypes: [
          ...pinnedDataPointTypes.value,
          { dataPointType: dataPointTypeId },
        ],
      } : {
        overviewPinnedDataPointTypes: [
          ...pinnedDataPointTypes.value,
          { dataPointType: dataPointTypeId },
        ],

      };
    };

    return updatePinnedDataPointTypes({
      updateEntitySettingsInput: {
        ...updatePinnedDataPointTypesInput(),
      },
    });
  };

  const unpinChart = (dataPointTypeId: string) => {
    const pinnedDataPointTypesResult = pinnedDataPointTypes.value.filter(({ dataPointType }) => dataPointType !== dataPointTypeId);

    const pinnedDatapointsTypes = props?.type === 'dashboard'
      ? { dashboardPinnedDataPointTypes: pinnedDataPointTypesResult }
      : { overviewPinnedDataPointTypes: pinnedDataPointTypesResult };

    return updatePinnedDataPointTypes({
      updateEntitySettingsInput: {
        ...pinnedDatapointsTypes,
      },
    });
  };

  const togglePinChart = (dataPointTypeId: string) => {
    pinnedChartIDs.value.includes(dataPointTypeId) ? unpinChart(dataPointTypeId) : pinChart(dataPointTypeId);
    refetch();
  };

  return {
    pinnedChartIDs,
    pinChart,
    unpinChart,
    togglePinChart,
    loading,
  };
};

export default usePinChartAdmin;
