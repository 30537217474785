<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import OgS3FilePicker from './OgS3FilePicker.vue';

const { t } = useI18n();

const MAX_FILE_SIZE_IN_MB = 10;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
const FILE_TYPES = ['application/pdf', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel', 'text/csv'];

function handleFilesValidation(files: File[]) {
  if (files.some((fil) => !FILE_TYPES.includes(fil.type))) {
    return t('Upload failed. Only .pdf, .csv, .xls and .xslx files can be uploaded.');
  }

  if (files.some((file) => file.size > MAX_FILE_SIZE_IN_BYTES)) {
    return t('Upload failed. Maximum allowed file size is {maxSizeInMB} MB.', {
      maxSizeInMB: MAX_FILE_SIZE_IN_MB,
    });
  }

  return null;
}
</script>

<template>
  <OgS3FilePicker
    :description="t('{fileTypes} up to {maxSizeInMB} MB.', { fileTypes: 'PDF, CSV, XLS, XLSX', maxSizeInMB: MAX_FILE_SIZE_IN_MB })"
    :validate="handleFilesValidation"
    :accept="FILE_TYPES.join(', ')"
  />
</template>
