<script setup lang="ts">
import { computed, watch, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import { XIcon } from '@heroicons/vue/solid';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import { type UpsertDataPointInput, DataPoint_Type } from '@/__generated__/types';
import AtTooltipIcon from '@/components/atoms/AtTooltipIcon.vue';
import MlDataPointValue from '@/components/molecules/MlDataPointValue.vue';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';
import { getDPRDateString } from '@/utils/helpers/dprDates';
import MlCommentBox from '@/components/molecules/MlCommentBox.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useUpsertDataPointMutation from '@/api/mutations/DataPoint/upsertDataPoint.mutation';
import { getPerDate } from '../../services/prepareStandardData';
import type { DataPoint, PreparedPerDataPointTypeItem, DateData } from '../../types';
import type { CommentsPerDate, Header } from './types';
import { prepareForTable } from './utils';
import MlCategoryValue from './MlCategoryValue.vue';

type Props = {
  dataPointType: PreparedPerDataPointTypeItem['type'];
  frameworks: PreparedPerDataPointTypeItem['frameworks'];
  allDataPoints: PreparedPerDataPointTypeItem['allDataPoints'];
  summaryDataPoints: PreparedPerDataPointTypeItem['summaryDataPoints'];
  projects: PreparedPerDataPointTypeItem['dataPointsPerProject'];
};
const props = defineProps<Props>();

const { t } = useI18n();

const showCategories = computed(() => props.allDataPoints.some((dataPoint) => dataPoint.valueSource && dataPoint.valueSource.length > 0));
const perDate = computed(() => getPerDate(props.projects, props.dataPointType, showCategories.value));
const activeSummaryBoxes = ref<Record<string, boolean>>({});
const upsertDataPointLoadingKeys = ref<Record<string, boolean>>({});

const { mutate: upsertDataPoint, error: upsertDataPointError, loading: upsertDataPointLoading } = useUpsertDataPointMutation();
type SummaryFormData = { _id?: string, value: string };
const summaryFormsData = ref<Record<string, SummaryFormData>>({});
watch([perDate, () => props.summaryDataPoints], () => {
  perDate.value.dates.forEach((date) => {
    const summaryDataPoint = props.summaryDataPoints?.find((dp) => dp.from === date.from && dp.to === date.to);
    if (activeSummaryBoxes.value[date.key]) return;
    summaryFormsData.value[date.key] = {
      /* TODO: double check why _id field was removed from getQualitativeDataPointsByProjectAndDateRangeAdmin query
      * at the moment handleUpsertSummaryDatapoint is probably broken
      * ticket is created here: https://github.com/codioimpact/codioimpact-app/issues/2286
      */
      // _id: summaryDataPoint?._id,
      value: summaryDataPoint?.value ?? '',
    };
  });
}, { immediate: true });

const headers = computed<Header[]>(() => {
  return [
    {
      text: t('Workspace'),
      value: 'entity',
      styling: 'w-[125px]',
    },
    {
      text: t('Projects'),
      value: 'project',
      styling: 'w-[125px]',
    },
    ...(showCategories.value ? [{
      text: t('Categories'),
      value: 'categories',
      styling: 'w-[200px]',
    }] : []),
    ...perDate.value.dates.map((date) => ({
      text: getDPRDateString(date.from ? date.from.toString() : null, date.to ? date.to.toString() : null),
      value: date.key,
      styling: 'w-[350px]',
    })),
  ];
});

const allTableData = computed(() => prepareForTable(
  perDate.value.projects,
  showCategories.value,
  t,
));

const comments = computed<CommentsPerDate[]>(() => {
  const map: Record<string, DataPoint[]> = {};
  props.allDataPoints
    .filter((dataPoint) => !!dataPoint.comment)
    .forEach((dataPoint) => {
      const date = getDPRDateString(dataPoint.from, dataPoint.to);
      if (!map[date]) {
        map[date] = [];
      }
      map[date].push(dataPoint);
    });

  return Object.entries(map).map(([date, dataPoints]) => ({
    date,
    comments: dataPoints.map((dataPoint) => ({
      name: dataPoint.entity?.name ?? '',
      text: dataPoint.comment ?? '',
    })),
  }));
});

async function handleUpsertSummaryDatapoint(
  summary: SummaryFormData,
  date: DateData) {
  try {
    upsertDataPointLoadingKeys.value[date.key] = true;
    const locationIds = props.projects.map((location) => location.id);
    const upsertDataPointInput: UpsertDataPointInput = {
      _id: summary._id,
      value: summary.value,
      locationIds,
      dataPointTypeId: props.dataPointType._id,
      type: DataPoint_Type.SUMMARY,
      from: date.from,
      to: date.to,
    };

    if (summary._id) { // fast update with mutation _id (no refetch)
      await upsertDataPoint({ upsertDataPointInput });
    } else { // create with slower refetch
      await upsertDataPoint({ upsertDataPointInput },
        { update: (store) => {
          store.evict({ fieldName: 'getSummaryDataPointsByProjectAndDateRangeAdmin' }); //
        } });
    }

    activeSummaryBoxes.value[date.key] = false;
    upsertDataPointLoadingKeys.value[date.key] = false;
    notify({ type: 'success', text: t('Summary has been created for data point: {dataPointTypeName}', { dataPointTypeName: t(props.dataPointType.friendlyName) }) });
  } catch (err) {
    if (upsertDataPointError.value) notify({ type: 'error', text: t(upsertDataPointError.value?.message) });
    upsertDataPointLoadingKeys.value[date.key] = false;
    // eslint-disable-next-line no-console
    console.error(err);
  }
}
</script>

<template>
  <div
    class="mb-6 grid overflow-auto rounded-md bg-gray-50 p-4"
  >
    <div class="flex justify-between mb-10">
      <div class="relative">
        <h3 class="max-w-[75vw] py-2 text-sm font-medium sm:max-w-lg">
          {{ t(dataPointType.friendlyName) }}
        </h3>
        <h4 class="text-sm font-normal text-gray-500">
          {{ t(dataPointType.question) }}
          <AtTooltipIcon
            v-if="dataPointType.questionHelp"
            class="cursor-pointer"
            :triggers="['click', 'touch']"
            :delay="0"
            autoHide
            :tooltip="t(dataPointType.questionHelp)"
          />
          <AtBadge
            v-for="framework in frameworks"
            :key="framework.framework"
            class="mr-2 py-0 text-xs font-light"
            type="neutral"
          >
            <span v-if="framework?.framework">{{ t(framework?.framework) }}</span><template v-if="framework?.groups?.length">
              -
            </template>
            <template v-for="(group, i) in framework?.groups">
              {{ group }}
              <template v-if="i + 1 < (framework?.groups?.length ?? 0)">
                ,
              </template>
            </template>
          </AtBadge>
        </h4>
      </div>
    </div>

    <table class="text-left w-full table-fixed">
      <colgroup>
        <col v-for="header in headers" :key="header.value" :class="header.styling">
      </colgroup>
      <thead class="uppercase text-sm text-gray-400">
        <tr class="bg-gray-200">
          <th v-for="header in headers" :key="header.value" class="p-3 first:rounded-l-md last:rounded-r-md">
            {{ header.text }}
          </th>
          <th />
        </tr>
      </thead>

      <tbody class="text-xs">
        <template v-for="(project, projectIndex) in allTableData.projects" :key="project.id">
          <tr
            v-for="(row, rowIndex) in project.rows"
            :key="rowIndex"
            :class="{
              'border-t border-gray-100': rowIndex > 0,
            }"
          >
            <th
              v-if="rowIndex === 0"
              scope="row"
              class="align-top p-3 font-normal break-words"
              :class="{
                'border-t border-gray-400': rowIndex === 0 && projectIndex > 0 && !!props.projects.find(
                  (_project) => _project.id === project.id
                    && (projectIndex ? props.projects[projectIndex - 1].entityId !== _project.entityId : true)),
              }"
              :rowspan="project.rows.length"
            >
              {{ props.projects.find(
                (_project) => _project.id === project.id
                  && (projectIndex ? props.projects[projectIndex - 1].entityId !== _project.entityId : true))?.entityName }}
            </th>
            <th
              v-if="rowIndex === 0"
              scope="row"
              class="align-top p-3 font-normal break-words"
              :class="{ 'border-t border-gray-400': rowIndex === 0 && projectIndex > 0 }"
              :rowspan="project.rows.length"
            >
              {{ project.name }}
            </th>
            <td
              v-if="showCategories"
              class="align-top p-3 break-words"
              :class="{ 'border-t border-gray-400': rowIndex === 0 && projectIndex > 0 }"
            >
              {{ row.categoryName }}
            </td>
            <td
              v-for="date in perDate.dates"
              :key="date.key"
              class="align-top p-3"
              :class="{ 'border-t border-gray-400': rowIndex === 0 && projectIndex > 0 }"
            >
              <template v-if="showCategories">
                <MlCategoryValue
                  v-if="row.categoryValues[date.key]"
                  :value="row.categoryValues[date.key]"
                  :dataPointType="dataPointType"
                />
                <span v-else>-</span>
              </template>
              <template v-else>
                <VTooltip
                  :triggers="['touch', 'click']"
                  :autoHide="false"
                  placement="right-start"
                  class="shrink-0 align-baseline flex"
                  :class="{ 'cursor-pointer hover:text-gray-500': row.values[date.key].value.length > 120 }"
                >
                  <MlDataPointValue
                    v-if="row.values[date.key]"
                    :dataPointValueAndType="row.values[date.key]"
                    :useTooltip="true"
                  />
                  <span v-else>-</span>
                  <template #popper="{ hide }">
                    <XIcon class="w-4 absolute -top-2 -right-2 cursor-pointer ml-auto" @click="hide()" />
                    <MlHtmlContent
                      :html="row.values[date.key].value"
                      class="p-2"
                    />
                  </template>
                </VTooltip>
              </template>
            </td>
          </tr>
        </template>
      </tbody>

      <tfoot class="text-xs">
        <tr class="border-t-[3px] border-double border-gray-400">
          <th class="align-top p-3 font-semibold">
            {{ t('Summaries') }}
          </th>
          <td />
          <td v-if="showCategories" />
          <td v-for="date in perDate.dates" :key="date.key" class="align-top p-3">
            <MlCommentBox
              v-model="summaryFormsData[date.key].value"
              iconSize="sm"
              :isOpen="activeSummaryBoxes[date.key]"
              :buttonContent="!!props.summaryDataPoints?.find((dp) => dp.from === date.from && dp.to === date.to) ? t('Edit summary') : t('Compose summary')"
              isBlueButton
              :isEditing="!!props.summaryDataPoints?.find((dp) => dp.from === date.from && dp.to === date.to)"
              :placeholder="t(
                `Summarize for {date}`,
                { date: `${getDPRDateString(date.from ? date.from.toString() : null, date.to ? date.to.toString() : null)}` })"
              :isOpenOnMounted="false"
              @isCommentBoxOpen="activeSummaryBoxes[date.key] = $event"
            />
            <AtButton
              v-if="activeSummaryBoxes[date.key]"
              class="mt-2"
              :loading="upsertDataPointLoading && upsertDataPointLoadingKeys[date.key]"
              @click="handleUpsertSummaryDatapoint(summaryFormsData[date.key], date);"
            >
              {{ t('Submit') }}
            </AtButton>

            <MlHtmlContent
              v-if="!activeSummaryBoxes[date.key]
                && !!summaryDataPoints?.find((summaryDp) => summaryDp.from === date.from && summaryDp.to === date.to)"
              class="col-auto line-clamp-4 hover:line-clamp-none"
              :html="summaryDataPoints.find((summaryDp) => summaryDp.from === date.from && summaryDp.to === date.to)?.value"
            />
          </td>
        </tr>
      </tfoot>
    </table>
    <div v-for="commentsPerDate of comments" :key="commentsPerDate.date" class="mb-1">
      <span class="ml-2 text-xs">{{ commentsPerDate.date }}</span>
      <div v-for="(comment, commentIndex) of commentsPerDate.comments" :key="commentIndex" class="ml-2 text-xs">
        <span class="col-auto mr-2 whitespace-nowrap font-semibold">{{ comment.name }}:</span>
        <MlHtmlContent class="col-auto line-clamp-4 hover:line-clamp-none" :html="comment.text" />
      </div>
    </div>
  </div>
</template>
