<script setup lang="ts">
import { onMounted } from 'vue';
import { auth } from '@/api/auth/auth';

onMounted(() => {
  auth.removeToken();
});
</script>

<template>
  <div />
</template>
