import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';

import type { DelegateDataPointRequestsMutation, DelegateDataPointRequestsMutationVariables } from '@/__generated__/types';

const DELEGATE_DATAPOINTREQUESTS_MUTATION = gql`
  mutation DelegateDataPointRequests($delegateDataPointRequestsInput: DelegateDataPointRequestsInput!, $emailAddresses: [String!]! ) {
    delegateDataPointRequests(delegateDataPointRequestsInput: $delegateDataPointRequestsInput, emailAddresses: $emailAddresses) {
      _id
      delegations {
        _id
        shareToken
        user {
          _id
          email
        }
        reminder {
          _id
          sendAt
        }
      }
    }
  }
`;

type TOptions = UseMutationOptions<DelegateDataPointRequestsMutation, DelegateDataPointRequestsMutationVariables>

const useDelegateDataPointRequestsMutation = (options?: TOptions) => useMutation<
  DelegateDataPointRequestsMutation,
  DelegateDataPointRequestsMutationVariables
>(DELEGATE_DATAPOINTREQUESTS_MUTATION, options);

export default useDelegateDataPointRequestsMutation;
