import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  GenerateFileUploadTokenMutation,
  GenerateFileUploadTokenMutationVariables,
} from '@/__generated__/types';

export const GENERATE_FILE_UPLOAD_TOKEN = gql`
  mutation GenerateFileUploadToken {
    generateFileUploadToken {
      _id
      preSignedUrl
      secretUpdateToken
    }
  }
`;

type TOptions = UseMutationOptions<GenerateFileUploadTokenMutation, GenerateFileUploadTokenMutationVariables>

const useGenerateFileUploadTokenMutation = (options?: TOptions) => useMutation<
  GenerateFileUploadTokenMutation,
  GenerateFileUploadTokenMutationVariables
>(GENERATE_FILE_UPLOAD_TOKEN, options);

export default useGenerateFileUploadTokenMutation;
