<script setup lang="ts">
import PgAdminHomeDashboard from '@/components/pages/PgAdmin/Home/Dashboard.vue';
import { router } from '@/router';

const password = window.localStorage.getItem('sharePassword');

if (!password) {
  router.push(`/external/login${window.location.search}`);
}

</script>

<template>
  <div>
    <div class="absolute top-0 left-0 w-full p-1.5 bg-blue-600 text-white text-center z-50">
      <i18n-t keypath="Welcome to Codio Impact. If you have an account, {logIn}">
        <template #logIn>
          <router-link to="/login" class="underline">
            log in
          </router-link>
        </template>
      </i18n-t>
    </div>

    <PgAdminHomeDashboard />
  </div>
</template>
