import { provideApolloClient, useLazyQuery } from '@vue/apollo-composable';
import { computed, ref } from 'vue';
import {
  DataPointRequestStatusEnum,
  type TeamUserDprsQuery,
  type TeamUserDprsQueryVariables,
  UserRole } from '@/__generated__/types';
import waitForApolloQuery from '@/utils/helpers/waitForApolloQuery';
import { useReportingPeriod } from '@/utils/composables/useReportingPeriod/useReportingPeriod';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import { apolloClient } from '@/api/apollo/client';
import TEAM_USERS_DPRS_QUERY from './TeamUserDprs.query';

export const useFetchDprs = () => {
  const { formatForBE } = useReportingPeriod();
  const { currentUser } = useCurrentUser();

  const dprs = ref<TeamUserDprsQuery['getTeamUserDataPointRequests']>([]);
  const limit = ref(5);
  const canFetchMore = computed(() => dprs.value.length === limit.value);

  const query = provideApolloClient(apolloClient)(
    () => useLazyQuery<TeamUserDprsQuery, TeamUserDprsQueryVariables>(
      TEAM_USERS_DPRS_QUERY,
      undefined,
      { fetchPolicy: 'network-only' },
    ));

  const fetchDprs = async (
    teamUserId: string,
    locationId: string,
    category?: string,
    subcategory?: string,
    status?: DataPointRequestStatusEnum,
  ) => {
    const variables: TeamUserDprsQueryVariables = {
      teamUserId,
      locationId,
      ...(status ? { statusFilter: status } : {}),
      ...(category ? { categoryFilter: [category] } : {}),
      ...(category ? { categoryFilter: [category] } : {}),
      ...(subcategory ? { subcategoryFilter: [subcategory] } : {}),
      limit: limit.value,
      sortedByUpdate: true,
      assignedOnly: currentUser.value?.role !== UserRole.SUPERADMIN,
      includeChilds: true,
      ...formatForBE(),
    };
    // eslint-disable-next-line no-unused-expressions
    query.load(undefined, variables) || query.refetch(variables);
    const result = await waitForApolloQuery(query);
    if (result && !(result instanceof Error)) {
      dprs.value = result?.getTeamUserDataPointRequests ?? [];
    }
  };

  const fetchMoreDprs = async (
    increment: number,
    teamUserId: string,
    locationId: string,
    category?: string,
    subcategory?: string,
    status?: DataPointRequestStatusEnum,
  ) => {
    limit.value += increment;
    return fetchDprs(teamUserId, locationId, category, subcategory, status);
  };

  const resetDprs = () => {
    dprs.value = [];
    limit.value = 5;
  };

  return {
    dprs,
    resetDprs,
    fetchDprs,
    fetchMoreDprs,
    canFetchMore,
    isLoadingDprs: query.loading,
  };
};
