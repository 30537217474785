import { gql } from '@apollo/client/core';

export default gql`
  query OgCustomDataPointTypeForm($dataPointTypeId: String, $isUpdate: Boolean!) {
    getEmissionsDataPointTypes {
      _id
      emissionType
      emissionCategory
      emissionSubcategory
    }

    getDataPointType (dataPointTypeId: $dataPointTypeId) @include(if: $isUpdate) {
      _id
      friendlyName
      name
      question
      questionHelp
      refreshInterval
      valueDataType
      valueDataTypeOptions
      valueUnit
      emissionSubcategory
      emissionFactors {
        _id
        factor
        year
      }
      reportingFrameworks {
        _id
        framework
        groups
      }
      allowUploadProof
    }
  }
`;
