<script setup lang="ts">
import { computed, ref } from 'vue';
import { CalendarIcon } from '@heroicons/vue/outline';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import OgDateRangePicker, { type OgDateRangePickerModelValue } from '@/components/organisms/OgDateRangePicker/OgDateRangePicker.vue';

type Props = {
  modelValue: OgDateRangePickerModelValue;
  isFull?: boolean;
};

const props = withDefaults(
  defineProps<Props>(),
  {
    isFull: false,
  },
);
const emit = defineEmits<{
  'update:modelValue': [value: OgDateRangePickerModelValue],
}>();

const text = computed(() => `${props.modelValue.from.format('DD/MM/YYYY')} - ${props.modelValue.to.format('DD/MM/YYYY')}`);

const menu = ref<InstanceType<typeof MlMenu>>();
function handleUpdate(value: OgDateRangePickerModelValue) {
  emit('update:modelValue', value);
  // Close menu when range is selected.
  menu.value?.menu?.hide();
}
</script>

<template>
  <MlMenu ref="menu" :class="{ 'w-full': isFull }" :overflowPadding="16">
    <button
      type="button"
      class="chart-button flex items-center whitespace-nowrap rounded-md border border-gray-400 px-3 py-2 text-sm"
      :class="{ 'w-full': isFull }"
      data-cy="MenuButtonChartDatePicker"
    >
      <CalendarIcon class="mr-2 h-4 w-5 text-gray-500" />
      <span class="text-gray-500 truncate">
        {{ text }}
      </span>
    </button>
    <template #menuItems>
      <OgDateRangePicker
        class="flex w-full p-4"
        inline
        v-bind="$attrs"
        :modelValue="props.modelValue"
        @update:modelValue="handleUpdate"
      />
    </template>
  </MlMenu>
</template>
