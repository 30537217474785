import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { DelegateTaxonomyBusinessActivityMutation, DelegateTaxonomyBusinessActivityMutationVariables } from '@/__generated__/types';

export const DELEGATE_TAXONOMY_BUSINESS_ACTIVITY_MUTATION = gql`
  mutation DelegateTaxonomyBusinessActivity(
    $assessmentId: String!,
    $businessActivityId: String!,
    $email: String!,
    $data: DelegateTaxonomyAssessmentBusinessActivityInput!,
  ) {
    delegateBusinessActivity(assessmentId: $assessmentId, businessActivityId: $businessActivityId, email: $email, data: $data) {
      _id
      substantialContributionDelegation {
        _id
        user {
          _id
          firstName
          lastName
          email
        }
        shareToken
        objective
      }
      doesNotHarm {
        adaptationDelegation {
          _id
          user {
            _id
            firstName
            lastName
            email
          }
          shareToken
          objective
        }
        biodiversityDelegation {
          _id
          user {
            _id
            firstName
            lastName
            email
          }
          shareToken
          objective
        }
        circularDelegation {
          _id
          user {
            _id
            firstName
            lastName
            email
          }
          shareToken
          objective
        }
        mitigationDelegation {
          _id
          user {
            _id
            firstName
            lastName
            email
          }
          shareToken
          objective
        }
        pollutionDelegation {
          _id
          user {
            _id
            firstName
            lastName
            email
          }
          shareToken
          objective
        }
        waterDelegation {
          _id
          user {
            _id
            firstName
            lastName
            email
          }
          shareToken
          objective
        }
      }
      minimumStandardsDelegation {
        _id
        user {
          _id
          firstName
          lastName
          email
        }
        shareToken
      }
    }
  }
`;

type TOptions = UseMutationOptions<DelegateTaxonomyBusinessActivityMutation, DelegateTaxonomyBusinessActivityMutationVariables>

const useDelegateTaxonomyBusinessActivity = (options?: TOptions) => useMutation<
  DelegateTaxonomyBusinessActivityMutation,
  DelegateTaxonomyBusinessActivityMutationVariables
>(DELEGATE_TAXONOMY_BUSINESS_ACTIVITY_MUTATION, options);

export default useDelegateTaxonomyBusinessActivity;
