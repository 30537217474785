<script setup lang="ts">
import { computed, reactive, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import useVuelidate from '@vuelidate/core';
import { helpers, maxLength, required } from '@vuelidate/validators';
import { notify } from '@kyvg/vue3-notification';
import uniqueId from 'lodash/uniqueId';
import maxBy from 'lodash/maxBy';
import { EntityReportingPeriodStatusEnum, type SettingsQuery } from '@/__generated__/types';
import dayjs from '@/lib/dayjs/config';
import useUpdateEntitySettingsMutation from '@/api/mutations/EntitySettings/updateEntitySettings.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import OgCompanyPicture from '@/components/organisms/OgCompanyPicture.vue';
import MlCompanyNameInput from '@/components/molecules/MlCompanyInputs/MlCompanyNameInput.vue';
import MlIndustrySelect from '@/components/molecules/MlCompanyInputs/MlIndustrySelect.vue';
import MlHeadquartersInput from '@/components/molecules/MlCompanyInputs/MlHeadquartersInput.vue';
import MlHeadquarterNameInput from '@/components/molecules/MlCompanyInputs/MlHeadquarterNameInput.vue';
import MlEmployeeNumberSelect from '@/components/molecules/MlCompanyInputs/MlEmployeeNumberSelect.vue';
import OgSettingsReportingPeriod from './OgSettingsReportingPeriod.vue';
import SETTINGS_QUERY from './Settings.query';

type EntitySettings = SettingsQuery['getOwnUser']['entity']['entitySettings'];
type ReportedFrameworks = Required<SettingsQuery>['getOwnUser']['entity']['reportingFrameworks'];

type SettingsFormData = {
  companyName: string;
  industry: string;
  locationOfHeadquarter: string;
  nameOfHeadquarter: string;
  numberOfEmployees: string;
  picture: { id: string; url: string } | null;
  standards: ReportedFrameworks;
  reportingPeriods: {
    id: string;
    period: [string, string];
    deadline: string;
    isCompleted: boolean;
    toBeDeleted: boolean;
  }[];
};

const { t } = useI18n();

const { result } = useQuery<SettingsQuery>(SETTINGS_QUERY);
const entitySettings = computed<EntitySettings>(() => result.value?.getOwnUser.entity.entitySettings ?? {
  _id: '',
  companyName: '',
  industry: '',
  locationOfHeadquarter: '',
  nameOfHeadquarter: '',
  numberOfEmployees: '',
  picture: null,
  reportingPeriods: [],
});
const reportingFrameworksSelected = computed<ReportedFrameworks>(() => result.value?.getOwnUser.entity.reportingFrameworks ?? []);
const formData = reactive<SettingsFormData>({
  companyName: entitySettings.value.companyName,
  industry: entitySettings.value.industry,
  locationOfHeadquarter: entitySettings.value.locationOfHeadquarter,
  nameOfHeadquarter: entitySettings.value.nameOfHeadquarter,
  numberOfEmployees: entitySettings.value.numberOfEmployees,
  reportingPeriods: entitySettings.value.reportingPeriods.map((reportingPeriod) => ({
    id: reportingPeriod._id,
    period: [reportingPeriod.from, reportingPeriod.to],
    deadline: reportingPeriod.deadline,
    isCompleted: reportingPeriod.status === EntityReportingPeriodStatusEnum.COMPLETED,
    toBeDeleted: false,
  })),
  picture: entitySettings.value.picture ? {
    id: entitySettings.value.picture._id,
    url: entitySettings.value.picture.downloadUrl,
  } : null,
  standards: reportingFrameworksSelected.value,
});
watch(entitySettings, (newEntitySettings) => {
  formData.companyName = newEntitySettings.companyName;
  formData.industry = newEntitySettings.industry;
  formData.locationOfHeadquarter = newEntitySettings.locationOfHeadquarter;
  formData.nameOfHeadquarter = newEntitySettings.nameOfHeadquarter;
  formData.numberOfEmployees = newEntitySettings.numberOfEmployees;
  formData.picture = newEntitySettings.picture ? {
    id: newEntitySettings.picture._id,
    url: newEntitySettings.picture.downloadUrl,
  } : null;
  formData.reportingPeriods = newEntitySettings.reportingPeriods.map((reportingPeriod) => ({
    id: reportingPeriod._id,
    period: [reportingPeriod.from, reportingPeriod.to],
    deadline: reportingPeriod.deadline,
    isCompleted: reportingPeriod.status === EntityReportingPeriodStatusEnum.COMPLETED,
    toBeDeleted: false,
  }));
  v$.value.$reset();
});
watch(reportingFrameworksSelected, (newReportingFrameworksSelected) => {
  formData.standards = newReportingFrameworksSelected;
  v$.value.standards.$reset();
});

const v$ = useVuelidate(
  {
    companyName: {
      required: helpers.withMessage('Please fill out this field.', required),
    },
    industry: {
      required: helpers.withMessage('Please select the industry your company operates in.', required),
    },
    locationOfHeadquarter: {
      required: helpers.withMessage('Please fill out this field.', required),
    },
    nameOfHeadquarter: {
      required: helpers.withMessage('Please fill out this field.', required),
    },
    numberOfEmployees: {
      required: helpers.withMessage('Please fill out this field.', required),
    },
    standards: {
      required: helpers.withMessage('Please select at least one standard.', required),
      max: helpers.withMessage('You cannot select DNK, GRI and ESRS. Please choose only two standards or contact us for help.', maxLength(2)),
    },
    reportingPeriods: {
      $each: helpers.forEach({
        period: {
          noOverlapping: helpers.withMessage(t('Reporting periods must not overlap.'), ((
            value: [string, string],
            _: unknown,
            index: number,
            form: SettingsFormData,
          ) => {
            // Make sure no periods are overlapping.
            return form.reportingPeriods.filter((item, itemIndex) => itemIndex !== index).every((item) => {
              if (dayjs(value[1]).isBefore(dayjs(item.period[0]))) {
                return true;
              }

              if (dayjs(value[0]).isAfter(dayjs(item.period[1]))) {
                return true;
              }

              return false;
            });
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          }) as any),
        },
        deadline: {
          afterPeriod: helpers.withMessage(t('Deadline must be after reporting period.'), (value: string, siblings: SettingsFormData['reportingPeriods'][number]) => {
            // Make sure deadline is after reporting period.
            return dayjs(value).isAfter(dayjs(siblings.period[1]), 'day');
          }),
        },
      }),
    },
    picture: {},
  },
  formData,
);

const updateEntitySettingsMutation = useUpdateEntitySettingsMutation({
  update: (store) => {
    store.evict({
      fieldName: 'getOwnUser',
    });
  },
});

const isSaving = computed(() => updateEntitySettingsMutation.loading.value);

const existingReportingPeriods = computed(() => formData.reportingPeriods.filter((reportingPeriod) => !reportingPeriod.toBeDeleted));

function updatePicture(picture: { id: string; url: string}) {
  if (picture.id) {
    formData.picture = picture;

    v$.value.picture.$touch();
  }
}
function removePicture() {
  formData.picture = null;
}

async function updateCompany() {
  v$.value.$touch();

  if (v$.value.$error) {
    return;
  }

  try {
    await updateEntitySettingsMutation.mutate(
      {
        updateEntitySettingsInput: {
          companyName: formData.companyName,
          industry: formData.industry,
          locationOfHeadquarter: formData.locationOfHeadquarter,
          nameOfHeadquarter: formData.nameOfHeadquarter,
          numberOfEmployees: formData.numberOfEmployees,
          picture: formData.picture?.id ?? undefined,
          reportingPeriods: formData.reportingPeriods.map((reportingPeriod) => ({
            _id: reportingPeriod.id.startsWith('created_') ? null : reportingPeriod.id,
            from: dayjs(reportingPeriod.period[0]).toDate(),
            to: dayjs(reportingPeriod.period[1]).toDate(),
            deadline: dayjs(reportingPeriod.deadline).toDate(),
            isCompleted: reportingPeriod.isCompleted,
            toBeDeleted: reportingPeriod.toBeDeleted,
          })),
        },
      },
    );
    notify({ type: 'success', text: t('Changes have been saved.') });
  } catch (error) {
    notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
  }
}

function handleMarkingReportingPeriodAsCompleted(id: string) {
  const reportingPeriod = formData.reportingPeriods.find((item) => item.id === id);
  if (reportingPeriod) {
    reportingPeriod.isCompleted = true;
  }
}

function handleDeleteReportingPeriod(id: string) {
  if (id.startsWith('created_')) {
    formData.reportingPeriods = formData.reportingPeriods.filter((item) => item.id !== id);
  } else {
    const reportingPeriod = formData.reportingPeriods.find((item) => item.id === id);
    if (reportingPeriod) {
      reportingPeriod.toBeDeleted = true;
    }
  }
}

function handleAddNewPeriodClick() {
  const maxDate = maxBy(formData.reportingPeriods, (reportingPeriod) => reportingPeriod.period[1]);
  if (maxDate) {
    const date = dayjs.utc(maxDate.period[1]).add(1, 'day');

    formData.reportingPeriods.push({
      id: uniqueId('created_'),
      period: [date.toISOString(), date.add(1, 'year').subtract(1, 'day').toISOString()],
      deadline: date.add(1, 'year').add(11, 'months').toISOString(),
      isCompleted: false,
      toBeDeleted: false,
    });
  }
}
</script>

<template>
  <form class="px-6 pt-5" @submit.prevent="updateCompany">
    <section class="pb-10 border-b mb-8">
      <div class="flex justify-between gap-x-4 w-full mb-6">
        <div>
          <h2 class="text-xl font-medium text-gray-900 mb-2">
            {{ t('Company') }}
          </h2>
          <p class="text-sm text-gray-500">
            {{ t('Manage and edit your company account.') }}
          </p>
        </div>
        <div>
          <AtButton
            class="mt-2 h-9"
            :loading="isSaving"
            data-cy="AtButtonSaveChanges"
            :disabled="v$.error"
          >
            {{ t('Save changes') }}
          </AtButton>
        </div>
      </div>
      <div class="mb-6">
        <OgCompanyPicture
          :pictureUrl="formData.picture?.url || ''"
          :companyName="entitySettings.companyName"
          @pictureUploaded="updatePicture"
          @pictureRemoved="removePicture"
        />
      </div>
      <div class="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-5 2xl:grid-cols-6 gap-6 w-full">
        <div class="md:col-start-1">
          <MlCompanyNameInput v-model="formData.companyName" :errors="v$.companyName.$errors" />
        </div>
        <div class="md:col-start-2 md:col-span-2">
          <MlIndustrySelect v-model="formData.industry" :errors="v$.industry.$errors" />
        </div>
        <div class="md:col-start-1">
          <MlHeadquartersInput v-model="formData.locationOfHeadquarter" :errors="v$.locationOfHeadquarter.$errors" />
        </div>
        <div class="md:col-start-2">
          <MlHeadquarterNameInput v-model="formData.nameOfHeadquarter" :errors="v$.nameOfHeadquarter.$errors" />
        </div>
        <div class="md:col-start-3">
          <MlEmployeeNumberSelect v-model="formData.numberOfEmployees" :errors="v$.numberOfEmployees.$errors" />
        </div>
      </div>
    </section>
    <section>
      <h3 class="text-sm font-medium text-gray-700 mb-1">
        {{ t('Reporting period and data collection deadlines') }}
      </h3>
      <p class="text-sm text-gray-700 mb-6">
        {{ t('You can inspect current and add more reporting periods, as well as set data collection deadlines for each of them.') }}
      </p>
      <template
        v-for="(reportingPeriod, index) in formData.reportingPeriods"
        :key="reportingPeriod.id"
      >
        <OgSettingsReportingPeriod
          v-if="!reportingPeriod.toBeDeleted"
          :id="reportingPeriod.id"
          v-model:period="reportingPeriod.period"
          v-model:deadline="reportingPeriod.deadline"
          :isCompleted="reportingPeriod.isCompleted"
          :existingReportingPeriods="existingReportingPeriods"
          :periodError="(v$.reportingPeriods.$error && v$.reportingPeriods.$each.$message[index]?.[0]) || null"
          :deadlineError="(v$.reportingPeriods.$error && v$.reportingPeriods.$each.$message[index]?.[1]) || null"
          @complete="handleMarkingReportingPeriodAsCompleted"
          @delete="handleDeleteReportingPeriod"
        />
      </template>
      <div>
        <button type="button" class="text-sm text-gray-400 hover:text-blue-600" @click="handleAddNewPeriodClick">
          {{ t('+ add new period') }}
        </button>
      </div>
    </section>
  </form>
</template>
