import { gql } from '@apollo/client/core';

export default gql`
  query OgCustomInsightForm($previewInsightInput: PreviewInsightInput!) {
    previewInsight(previewInsightInput: $previewInsightInput) {
      _id
      value
      from
      to
      location {
        _id
        name
      }
      entity{
        _id
        name
      }
      dataPointType {
        _id
        name
        friendlyName
        question
        questionHelp
        valueUnit
        formular
        targetAction
        formularDependencies {
          _id
          name
          friendlyName
        }
        valueUnitDivisor
        valueDataType
        refreshInterval
        activeReportingFramework {
          _id
          framework
          groups
          subcategory
        }
        reportingFrameworks {
          _id
          framework
          groups
          subcategory
        }
      }
    }
  }
`;
