import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  CreateEntityKpiCommentMutation,
  CreateEntityKpiCommentMutationVariables,
} from '@/__generated__/types';

export const CREATE_ENTITY_KPI_COMMENT = gql`
  mutation CreateEntityKPIComment($createEntityKPICommentInput: CreateEntityKPICommentInput!) {
    createEntityKPIComment(comment: $createEntityKPICommentInput) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<CreateEntityKpiCommentMutation, CreateEntityKpiCommentMutationVariables>

const useCreateEntityCommentKPIMutation = (options?: TOptions) => useMutation<
  CreateEntityKpiCommentMutation,
  CreateEntityKpiCommentMutationVariables
>(CREATE_ENTITY_KPI_COMMENT, options);

export default useCreateEntityCommentKPIMutation;
