<script setup lang="ts">
import { computed, reactive } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import type { OgQuestionnaireNameQuery } from '@/__generated__/types';
import useSetCustomQuestionnaireNameMutation from '@/api/mutations/CustomQuestionnaire/setQuestionnaireName.mutation';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import { CheckboxSize } from '@/components/atoms/AtCheckbox/types';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlDialog from '@/components/molecules/MlDialog.vue';
import OG_QUESTIONNAIRE_NAME_QUERY from './OgQuestionnaireName.query';

type NameQuestionnaireForm = {
  name: string;
  shouldNest: boolean;
  category: string;
};

type Props = {
  customQuestionnaireId: string;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  saved: [],
  cancel: [],
}>();

const { t } = useI18n();

const { result, loading: isLoading } = useQuery<OgQuestionnaireNameQuery>(OG_QUESTIONNAIRE_NAME_QUERY);

const { mutate: setQuestionnaireName, loading: isSaving } = useSetCustomQuestionnaireNameMutation({
  update: (store) => {
    store.evict({
      fieldName: 'getCustomQuestionnaire',
    });
  },
});

const categories = computed(() => result.value?.getCustomCategoriesWithSubcategoriesForEntity ?? []);

const form = reactive<NameQuestionnaireForm>({
  name: '',
  shouldNest: false,
  category: '',
});

const validationRules = computed(() => ({
  name: {
    required: helpers.withMessage(t('Questionnaire name is required.'), required),
  },
  category: {
    requiredIf: helpers.withMessage(t('Category is required.'), requiredIf(() => form.shouldNest)),
  },
}));
const v$ = useVuelidate(validationRules, form);

const isDisabled = computed(() => isLoading.value || isSaving.value);

const categoriesOptions = computed<Record<string, string>>(() => {
  return categories.value.reduce((options, category) => ({
    ...options,
    [category._id]: category.name,
  }), {});
});

function handleCancel() {
  emit('cancel');
}

async function handleSubmit() {
  try {
    await setQuestionnaireName({
      customQuestionnaireId: props.customQuestionnaireId,
      inputData: {
        name: form.name,
        category: form.category || null,
      },
    });
    notify({ type: 'success', text: t('Questionnaire name has been set.') });
    emit('saved');
  } catch (error) {
    notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
  }
}
</script>

<template>
  <MlDialog
    isOpen
    :closeOnOutside="false"
    @close="handleCancel"
  >
    <template #title>
      {{ t('Give this questionnaire a name') }}
    </template>

    <template #default="{ close }">
      <form class="pt-6" @submit.prevent="handleSubmit">
        <div class="mb-6">
          <AtInput
            v-model.trim="form.name"
            wrapperClass="w-96 mb-4"
            :label="t('Name')"
            :errors="v$.name.$errors"
            @blur="v$.name.$touch"
          />
          <template v-if="categories.length > 0">
            <div class="flex items-center space-x-2 mb-1">
              <AtCheckbox
                squared
                :checked="form.shouldNest"
                :size="CheckboxSize.XS"
                @toggleCheckbox="form.shouldNest = !form.shouldNest"
              >
                <template #label>
                  <p class="text-sm font-medium text-gray-700">
                    {{ t('Nest this questionnaire under:') }}
                  </p>
                </template>
              </AtCheckbox>
            </div>
            <MlSelect
              v-model="form.category"
              usePortal
              :disabled="!form.shouldNest"
              :options="categoriesOptions"
              :errors="v$.category.$errors"
              @blur="v$.category.$touch"
            />
          </template>
        </div>
        <div class="flex space-x-4 justify-between items-center">
          <div class="flex-0">
            <AtButton type="button" variant="outline" @click="close">
              {{ t('Cancel') }}
            </AtButton>
          </div>
          <div class="flex-0">
            <AtButton type="submit" :disabled="v$.$invalid || isDisabled">
              {{ t('Next') }}
            </AtButton>
          </div>
        </div>
      </form>
    </template>
  </MlDialog>
</template>
