/* eslint-disable @typescript-eslint/no-explicit-any */
import { VueRenderer } from '@tiptap/vue-3';
import tippy from 'tippy.js';
import type { SuggestionQuery } from '@/__generated__/types';
import { getUserName } from '@/utils/helpers/getUserName';
import MentionList from './MentionList.vue';

export default {
  items: async ({ editor, query }: any) => {
    const users = editor.extensionStorage.teamUsersMentionList as SuggestionQuery['getTeamUsers'][number][] ?? [];
    return users
      .filter((item) => getUserName(item).split(' ').join('_').toLowerCase().startsWith(query.toLowerCase()))
      .map((item) => ({
        _id: item._id,
        userName: getUserName(item).split(' ').join('_'),
      }));
  },

  render: () => {
    let component: any;
    let popup: any;

    return {
      onStart: (props: any) => {
        component = new VueRenderer(MentionList, {
          // using vue 2:
          // parent: this,
          // propsData: props,
          // using vue 3:
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props: any) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props: any) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};
