import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { DeleteEntityLocationMutation, DeleteEntityLocationMutationVariables } from '@/__generated__/types';

export const DELETE_ENTITY_LOCATION = gql`
  mutation DeleteEntityLocation($deleteEntityLocationInput: DeleteEntityLocationInput!) {
    deleteEntityLocation(entityLocation: $deleteEntityLocationInput)
  }
`;

type TOptions = UseMutationOptions<DeleteEntityLocationMutation, DeleteEntityLocationMutationVariables>

const useDeleteEntityLocationMutation = (options?: TOptions) => useMutation<
  DeleteEntityLocationMutation,
  DeleteEntityLocationMutationVariables
>(DELETE_ENTITY_LOCATION, options);

export default useDeleteEntityLocationMutation;
