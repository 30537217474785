<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import { computed, onMounted, watch, ref } from 'vue';
import { notify } from '@kyvg/vue3-notification';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import {
  type PgNotificationSettingsQuery,
} from '@/__generated__/types';
import useUpdateEntitySettingsMutation from '@/api/mutations/EntitySettings/updateEntitySettings.mutation';
import AtSwitch from '@/components/atoms/AtSwitch/AtSwitch.vue';
import PG_NOTIFICATION_SETTINGS from './PgNotificationSettings.query';

const { t } = useI18n();

const { result, refetch } = useQuery<PgNotificationSettingsQuery>(PG_NOTIFICATION_SETTINGS);

const entitySettings = computed(() => result.value?.getCurrentEntitySettings);
const notificationSettings = computed(() => entitySettings.value?.notificationSettings);

const {
  mutate: updateEntitySettingsMutation,
  error: updateEntitySettingsError,
  loading: updateEntitySettingsLoading,
} = useUpdateEntitySettingsMutation({
  refetchQueries: [{ query: PG_NOTIFICATION_SETTINGS }],
});
const defaultNotificationSettings = {
  _id: '',
  approvedDatapoints: false,
  goals: false,
  newProject: false,
  openApprovals: false,
  openDatapoints: false,
  rejectedDatapoints: false,
  recommendationsVisibility: false,
};

const entityNotificationSettingsFormData = ref<typeof defaultNotificationSettings>(defaultNotificationSettings);
watch(notificationSettings, () => {
  entityNotificationSettingsFormData.value = {
    _id: notificationSettings.value?._id ?? '',
    approvedDatapoints: !!notificationSettings.value?.approvedDatapoints,
    goals: !!notificationSettings.value?.goals,
    newProject: !!notificationSettings.value?.newProject,
    openApprovals: !!notificationSettings.value?.openApprovals,
    openDatapoints: !!notificationSettings.value?.openDatapoints,
    rejectedDatapoints: !!notificationSettings.value?.rejectedDatapoints,
    recommendationsVisibility: !!notificationSettings.value?.recommendationsVisibility,
  };
});

const updateEntitySettingsInput = computed(() => ({ notificationSettings: entityNotificationSettingsFormData.value }));

const updateNotificationSettings = async () => {
  try {
    await updateEntitySettingsMutation({ updateEntitySettingsInput: updateEntitySettingsInput.value });
    notify({ text: t('Settings have been updated.'), type: 'success' });
  } catch (e) {
    notify({ text: updateEntitySettingsError.value?.message, type: 'error' });
  }
};

onMounted(refetch);
</script>

<template>
  <div class="px-6 py-5 border-b mb-5">
    <header class="mb-5">
      <h1 class="text-xl font-medium text-gray-900 mb-3">
        {{ t('Notifications') }}
      </h1>
      <p class="text-sm text-gray-500">
        {{ t('Manage and edit your email preferences.') }}
      </p>
    </header>
    <form class="text-sm mb-8" @submit.prevent="updateNotificationSettings">
      <fieldset class="mb-5">
        <legend class="font-semibold mb-2">
          {{ t('Team notifications') }}
        </legend>
        <p class="mb-2">
          {{ t('Choose which notifications you and your team members on this platform receive.') }}
        </p>
        <AtCheckbox
          :checked="entityNotificationSettingsFormData.approvedDatapoints"
          @toggleCheckbox="entityNotificationSettingsFormData.approvedDatapoints = !entityNotificationSettingsFormData?.approvedDatapoints"
        >
          <template #label>
            <i18n-t keypath="{option} - Get notified when someone approved your submitted datapoint.">
              <template #option>
                <span class="font-medium">
                  {{ t('Approved datapoints') }}
                </span>
              </template>
            </i18n-t>
          </template>
        </AtCheckbox>
        <AtCheckbox
          :checked="entityNotificationSettingsFormData.rejectedDatapoints"
          @toggleCheckbox="entityNotificationSettingsFormData.rejectedDatapoints = !entityNotificationSettingsFormData.rejectedDatapoints"
        >
          <template #label>
            <i18n-t keypath="{option} - Get notified when someone rejected your submitted datapoint.">
              <template #option>
                <span class="font-medium">
                  {{ t('Rejected datapoints') }}
                </span>
              </template>
            </i18n-t>
          </template>
        </AtCheckbox>
        <!-- <AtCheckbox
          :checked="updateEntityNotificationSettingsInput.openDatapoints"
          @toggle-checkbox="updateEntityNotificationSettingsInput.openDatapoints = !updateEntityNotificationSettingsInput.openDatapoints"
        >
          <template #label>
            <i18n-t keypath="{option} - Get reminded when datapoints are due soon.">
              <template #option>
                <span class="font-medium">
                  {{ t('Open datapoints') }}
                </span>
              </template>
            </i18n-t>
          </template>
        </AtCheckbox>
        <AtCheckbox
          :checked="updateEntityNotificationSettingsInput.openApprovals"
          @toggle-checkbox="updateEntityNotificationSettingsInput.openApprovals = !updateEntityNotificationSettingsInput.openApprovals"
        >
          <template #label>
            <i18n-t keypath="{option} - Get reminded when open datapoints need to be approved by you.">
              <template #option>
                <span class="font-medium">
                  {{ t('Open approvals') }}
                </span>
              </template>
            </i18n-t>
          </template>
        </AtCheckbox> -->
      </fieldset>
      <fieldset class="mb-5">
        <legend class="font-semibold mb-2">
          {{ t('Admin notifications') }}
        </legend>
        <p class="mb-2">
          {{ t('Choose which emails you and other admins on this platform receive.') }}
        </p>
        <!-- <AtCheckbox
          :checked="updateEntityNotificationSettingsInput.goals"
          @toggle-checkbox="updateEntityNotificationSettingsInput.goals = !updateEntityNotificationSettingsInput.goals"
        >
          <template #label>
            <i18n-t keypath="{option} - Get notified about goal updates (created, deleted) and comments.">
              <template #option>
                <span class="font-medium">
                  {{ t('Goals') }}
                </span>
              </template>
            </i18n-t>
          </template>
        </AtCheckbox> -->
        <AtCheckbox
          :checked="entityNotificationSettingsFormData.newProject"
          @toggleCheckbox="entityNotificationSettingsFormData.newProject = !entityNotificationSettingsFormData.newProject"
        >
          <template #label>
            <i18n-t keypath="{option} - Get notified when someone created a new project.">
              <template #option>
                <span class="font-medium">
                  {{ t('New project') }}
                </span>
              </template>
            </i18n-t>
          </template>
        </AtCheckbox>
      </fieldset>

      <AtButton
        :loading="updateEntitySettingsLoading"
      >
        {{ t('Save changes') }}
      </AtButton>
    </form>
    <div class="divider" />

    <form class="text-sm" @submit.prevent="updateNotificationSettings">
      <fieldset class="mb-5">
        <legend class="font-semibold mb-2">
          {{ t('Visibility of all internal recommendations') }}
        </legend>
        <p class="mb-2">
          {{ t("Your team members can view each other's responses under the Data Entry Recommendation tab. This involves all responses including sensitive topics such as health, safety and human resources.") }}
        </p>
        <AtSwitch
          :modelValue="entityNotificationSettingsFormData.recommendationsVisibility"
          :optionA="true"
          :optionB="false"
          wrapperClass="mt-2"
          @update:modelValue="entityNotificationSettingsFormData
            .recommendationsVisibility = ($event as typeof entityNotificationSettingsFormData.recommendationsVisibility);"
        >
          <template #optionA>
            {{ t('On') }}
          </template>
          <template #optionB>
            {{ t('Off') }}
          </template>
        </AtSwitch>
      </fieldset>
    </form>
  </div>
  <!-- <section class="text-sm">
    <h3 class="font-semibold mb-2">
      {{ t('Notify everyone') }}
    </h3>
    <p class="mb-3">
      {{ t('Send an email to all {entity} employees using Codio Impact to remind them to enter data. This is useful when your reporting data is approaching.',
            { entity: entitySettings?.companyName }) }}
    </p>
    <AtButton @click="sendNotifications">
      {{ t('Remind everyone by email') }}
    </AtButton>
  </section> -->
</template>
