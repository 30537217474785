<script setup lang="ts">

import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { DocumentDuplicateIcon } from '@heroicons/vue/outline';
import { useQuery } from '@vue/apollo-composable';
import { notify } from '@kyvg/vue3-notification';
import MlModal from '@/components/molecules/MlModal.vue';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import DashboardShareModalQuery from '@/components/pages/PgAdmin/Home/Dashboard/DashboardShareModal.query';
import useUpdateEntitySettingsMutation from '@/api/mutations/EntitySettings/updateEntitySettings.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

const { result } = useQuery(DashboardShareModalQuery);
const { mutate } = useUpdateEntitySettingsMutation();

const shareToken = computed(() => result.value?.getCurrentEntity.entitySettings.shareToken);
const sharePassword = computed(() => result.value?.getCurrentEntity.entitySettings.sharePassword);

const sharePasswordModel = ref('');

// eslint-disable-next-line no-return-assign
watch(sharePassword, () => sharePasswordModel.value = sharePassword.value);

const entityId = computed(() => result.value?.getCurrentEntity._id ?? '');

const isRevealed = ref(false);
const copiedToClipboard = ref(false);
const passwordSaved = ref(false);
const link = computed(() => `${import.meta.env.VITE_APP_URL}/external/dashboard?shareToken=${shareToken.value}&entity=${entityId.value}`);

const { t } = useI18n();
const showModal = () => {
  isRevealed.value = true;
};

const copyUrlToClipboard = () => {
  navigator.clipboard.writeText(link.value);
  copiedToClipboard.value = true;

  // eslint-disable-next-line no-return-assign
  setTimeout(() => copiedToClipboard.value = false, 100);
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
const savePassword = () => {
  if (isEmptyPassword.value) {
    notify({ type: 'error', text: t('Password must be provided.') });
  }
  passwordSaved.value = true;

  // eslint-disable-next-line no-return-assign
  setTimeout(() => passwordSaved.value = false, 100);

  return mutate({ updateEntitySettingsInput: { sharePassword: sharePasswordModel.value } },
    { refetchQueries: [{ query: DashboardShareModalQuery }] },
  );
};

const isEmptyPassword = computed(() => !sharePasswordModel.value);

</script>

<template>
  <slot :showModal="showModal" />

  <MlModal
    :isRevealed="isRevealed"
    class="w-96"
    @close="isRevealed = false"
  >
    <AtHeading type="h2" class="mb-3 border-b pb-2">
      {{ t('Share viewing access') }}
    </AtHeading>
    <p class="mb-3 text-sm">
      {{ t('To allow people to view this page, set a password and share the link below.') }}
    </p>
    <div class="mt-2 grid grid-cols-4 gap-3">
      <div class="col-span-3">
        <AtInput v-model="sharePasswordModel" :label="t('Choose password')" :placeholder="t('Enter a safe password')" class="mb-3" />
      </div>
      <div class="flex items-center">
        <AtButton variant="link" class="mt-2" :loading="passwordSaved" @click="savePassword">
          {{ t('Set password') }}
        </AtButton>
      </div>
      <div class="col-span-3">
        <AtInput v-model="link" :label="t('Copy link')" :disabled="true" />
      </div>
      <div class="mt-5 flex cursor-pointer items-center text-gray-400">
        <AtButton
          variant="link"
          :loading="copiedToClipboard"
          :disabled="isEmptyPassword"
          @click="copyUrlToClipboard"
        >
          <DocumentDuplicateIcon class="w-6 cursor-pointer" />
        </AtButton>
      </div>
    </div>
  </MlModal>
</template>
