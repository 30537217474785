<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { UPGRADE_EMAIL } from '@/constants/upgradeEmail';
import OgConfirmationDialog from '../OgConfirmationDialog.vue';

defineEmits(['closeModal']);

defineProps<{
  title: string,
  text: string
  isRevealed: boolean,
  teleportTo?: string,
}>();

const { t } = useI18n();

const redirectToMail = () => {
  window.location.href = `mailto:${UPGRADE_EMAIL}`;
};
</script>

<template>
  <OgConfirmationDialog
    v-if="isRevealed"
    :isRevealed="isRevealed"
    :title="title"
    :text="text"
    :confirmLabel="t('Contact us')"
    modalClass="!max-w-xl"
    :teleportTo="teleportTo"
    @cancel="$emit('closeModal')"
    @confirm="(isConfirmed) => {
      if (isConfirmed) {
        redirectToMail()
      }
      $emit('closeModal')
    }"
  />
</template>
