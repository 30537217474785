import groupBy from 'lodash/groupBy';
import mapValues from 'lodash/mapValues';
import { getDisplayedFrameworks } from '@/utils/helpers/getDisplayedFrameworks';
import type {
  DataPointQualitativeAdmin,
  DataPointSummaryAdmin,
  PreparedPerDataPointTypeItem,
  Project,
} from '../types';
import { sortDataPointTypes } from './prepareData';
import { getProjectsTrees } from './projectsTree';
import { getDataPointsProjectsTrees } from './dataPointsProjectsTrees';

export function getPerDataPointType(
  allDataPoints: DataPointQualitativeAdmin[],
  projects: Project[],
  summaryDataPoints: DataPointSummaryAdmin[],
) {
  const projectsTrees = getProjectsTrees(projects);

  const dataPointsPerDataPointTypes = groupBy(
    allDataPoints,
    (dataPoint) => dataPoint.dataPointType._id,
  );
  const preparedPerDataPointTypes = mapValues(dataPointsPerDataPointTypes, (dataPoints) => {
    const { dataPointType } = dataPoints[0];

    const dataPointsPerProject = getDataPointsProjectsTrees(projectsTrees, dataPoints, true);

    const frameworks = getDisplayedFrameworks(
      dataPointType.activeReportingFramework,
      dataPointType.reportingFrameworks,
    );
    const filteredSummaryDataPoints = summaryDataPoints.filter((dp) => dp.dataPointType._id === dataPointType._id);

    return {
      type: dataPointType,
      frameworks,
      allDataPoints: dataPoints,
      summaryDataPoints: filteredSummaryDataPoints,
      dataPointsPerProject,
    } as PreparedPerDataPointTypeItem;
  });

  return sortDataPointTypes(Object.values(preparedPerDataPointTypes));
}
