import { gql } from '@apollo/client/core';

export default gql`
  query TmSectorActivities {
    getTaxonomySectors {
      _id
      name
      
      activities {
        _id
        name
        number
      }
    }
  }
`;
