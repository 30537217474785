<script setup lang="ts">
import { computed, h, reactive, toValue, watch } from 'vue';
import { ApolloError } from '@apollo/client';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { notify } from '@kyvg/vue3-notification';
import { PlusIcon, XIcon } from '@heroicons/vue/outline';
import {
  DataPointTypeRefreshIntervalEnum,
  DataPointTypeValueUnitEnum,
  ReportingFrameworkEnum,
  type UpsertCustomQuestionnaireQuestionInput,
  ValueDataTypeEnum,
} from '@/__generated__/types';
import useDeleteCustomQuestionnaireQuestionMutation from '@/api/mutations/CustomQuestionnaire/deleteQuestion.mutation';
import useUpsertCustomQuestionnaireQuestionMutation from '@/api/mutations/CustomQuestionnaire/upsertQuestion.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import AtInfoBox from '@/components/atoms/AtInfoBox/AtInfoBox.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import AtToggleButton from '@/components/atoms/AtToggleButton.vue';
import MlAutocomplete from '@/components/molecules/MlAutocomplete.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import MlTextarea from '@/components/molecules/MlTextarea.vue';
import SplitInput from '@/components/atoms/SplitInput.vue';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import type { CustomQuestionnaireQuestion } from '../../types';
import { CreateDatapointTypeReportingFrameworkEnum } from '../TmCustomDataPointTypeModal/types';
import TargetQuestionTooltip from '../TmCustomDataPointTypeModal/OgCustomDataPointTypeForm/tooltip/targetQuestion.tooltip.vue';
import OgQuestionPreview from './OgQuestionPreview.vue';
import type { QuestionForm } from './types';
import { calculateFrameworks, isTypeForm } from './utils';

type Props = {
  customQuestionnaireId: string;
  question: CustomQuestionnaireQuestion | null;
  isDisabled: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  saved: [event: 'created' | 'updated'];
  deleted: [];
}>();

const { t } = useI18n();

const { confirmViaDialog } = useConfirmViaDialog();
const { mutate: upsertQuestion, loading: isSaving } = useUpsertCustomQuestionnaireQuestionMutation();
const { mutate: deleteQuestion } = useDeleteCustomQuestionnaireQuestionMutation({
  update: (store) => {
    store.evict({
      fieldName: 'getCustomQuestionnaire',
    });
  },
});

const form = reactive<QuestionForm>({
  type: ValueDataTypeEnum.TEXT,
  title: '',
  question: '',
  questionHelp: '',
  refreshInterval: DataPointTypeRefreshIntervalEnum.MONTHLY,
  framework: undefined,
  frameworkGroup: '',
  allowUploadProof: false,
  valueUnit: DataPointTypeValueUnitEnum.NUMBER,
  options: [{ option: '' }, { option: '' }],
  unitGroup: DataPointTypeValueUnitEnum.NUMBER,
});
watch(() => props.question, (newQuestion) => {
  if (newQuestion) {
    form.type = newQuestion.type;
    form.title = newQuestion.title;
    form.question = newQuestion.question;
    form.questionHelp = newQuestion.questionHelp;
    form.refreshInterval = newQuestion.refreshInterval;
    form.framework = newQuestion.frameworks?.[0] ?? undefined;
    form.frameworkGroup = newQuestion.frameworkGroup ?? '';
    form.allowUploadProof = newQuestion.allowUploadProof;

    if (newQuestion.extraOptions?.__typename === 'CustomQuestionnaireQuestionNumericOptions') {
      form.valueUnit = newQuestion.extraOptions.valueUnit;
    } else if (newQuestion.extraOptions?.__typename === 'CustomQuestionnaireQuestionChoiceOptions') {
      form.options = newQuestion.extraOptions.options.map((option) => ({ option }));
    } else if (newQuestion.extraOptions?.__typename === 'CustomQuestionnaireQuestionNumericSplitOptions') {
      form.unitGroup = newQuestion.extraOptions.unitGroup;
    }
  }
}, { immediate: true });

const validationRules = computed(() => {
  const rules: Record<string, unknown> = {
    title: {
      required: helpers.withMessage(t('Title is required.'), required),
    },
    question: {
      required: helpers.withMessage(t('Question is required.'), required),
    },
  };

  if (isTypeForm.choice(form.type)) {
    rules.options = {
      allUnique: (value: { option: string }[]) => value.length === (new Set(value.map((item) => item.option)).size),
      $each: helpers.forEach({
        option: { required },
      }),
    };
  }

  return rules;
});
const v$ = useVuelidate(validationRules, form);

const refreshIntervalOptions = computed(() => ({
  [DataPointTypeRefreshIntervalEnum.ONCE]: t('once'),
  [DataPointTypeRefreshIntervalEnum.DAILY]: t('every day'),
  [DataPointTypeRefreshIntervalEnum.WEEKLY]: t('every week'),
  [DataPointTypeRefreshIntervalEnum.MONTHLY]: t('every month'),
  [DataPointTypeRefreshIntervalEnum.QUARTERLY]: t('every quarter'),
  [DataPointTypeRefreshIntervalEnum.HALF_YEARLY]: t('every 6 months'),
  [DataPointTypeRefreshIntervalEnum.YEARLY]: t('every year'),
}));
const frameworkOptions = computed(() => ({
  [ReportingFrameworkEnum.GRI]: t('GRI'),
  [ReportingFrameworkEnum.ESRS]: t('ESRS (CSRD)'),
  [ReportingFrameworkEnum.DNK]: t('DNK'),
  [ReportingFrameworkEnum.TCFD]: t('TCFD'),
  [ReportingFrameworkEnum.GHG]: t('GHG'),
  [CreateDatapointTypeReportingFrameworkEnum.CERTIFICATION_DISCLOSURE]: t('Certification/disclosure requirements (CDP, EcoVadis, EMAS)'),
  [ReportingFrameworkEnum.CUSTOMER_REQUIREMENT]: t('Customer requirement'),
  [ReportingFrameworkEnum.INTERNAL_REQUIREMENT]: t('Internal requirement'),
  [ReportingFrameworkEnum.OTHER]: t('Other'),
}));
const questionTypeOptions = computed(() => {
  return [
    ValueDataTypeEnum.TEXT,
    ValueDataTypeEnum.TEXT_LONG,
    ValueDataTypeEnum.NUMERIC,
    ValueDataTypeEnum.CHOICE,
    ValueDataTypeEnum.MULTIPLE_CHOICE,
    ValueDataTypeEnum.NUMERIC_SPLIT,
    ValueDataTypeEnum.TEXT_SPLIT,
  ].reduce((acc, item) => {
    let label;
    switch (item) {
      case ValueDataTypeEnum.TEXT:
        label = t('Text answer');
        break;
      case ValueDataTypeEnum.TEXT_LONG:
        label = t('Long text answer');
        break;
      case ValueDataTypeEnum.NUMERIC:
        label = t('Numeric');
        break;
      case ValueDataTypeEnum.CHOICE:
        label = t('Choice');
        break;
      case ValueDataTypeEnum.MULTIPLE_CHOICE:
        label = t('Multiple choice');
        break;
      case ValueDataTypeEnum.NUMERIC_SPLIT:
        label = t('Numeric split');
        break;
      case ValueDataTypeEnum.TEXT_SPLIT:
        label = t('Text split');
        break;
      // TODO Add others.
      default:
        label = t('TODO');
        break;
    }

    return {
      ...acc,
      [item]: label,
    };
  }, {});
});
const valueUnitOptions = computed(() => ({
  [DataPointTypeValueUnitEnum.NUMBER]: t('Number'),
  [DataPointTypeValueUnitEnum.TONS]: t('Ton (t)'),
  [DataPointTypeValueUnitEnum.HOURS]: t('Hours'),
  [DataPointTypeValueUnitEnum.LITER]: t('Liter (l)'),
  [DataPointTypeValueUnitEnum.KILOMETER]: t('Kilometer (km)'),
  [DataPointTypeValueUnitEnum.CUBIC_METER]: t('Cubic Meter'),
  [DataPointTypeValueUnitEnum.KILOGRAM]: t('Kilogram (kg)'),
  [DataPointTypeValueUnitEnum.KILOWATT]: t('Kilowatt (kW)'),
  [DataPointTypeValueUnitEnum.KILOWATTHOURS]: t('Kilowatt Hours (kWh)'),
  [DataPointTypeValueUnitEnum.EUR]: t('Euro (€)'),
  [DataPointTypeValueUnitEnum.PRODUCTION_UNIT]: t('Production units'),
  [DataPointTypeValueUnitEnum.PERCENT]: t('Percentage (%)'),
}));
const unitGroupOptions = computed(() => ({
  [DataPointTypeValueUnitEnum.NUMBER]: t('Number'),
  [DataPointTypeValueUnitEnum.LITER]: t('Liter (l)'),
  [DataPointTypeValueUnitEnum.KILOGRAM]: t('Kilogram (kg)'),
  [DataPointTypeValueUnitEnum.KILOWATTHOURS]: t('Kilowatt Hours (kWh)'),
  [DataPointTypeValueUnitEnum.KILOWATT]: t('Kilowatt (kW)'),
  [DataPointTypeValueUnitEnum.METER]: 'Meter (m)',
  [DataPointTypeValueUnitEnum.HOURS]: t('Hours'),
  [DataPointTypeValueUnitEnum.EUR]: t('Euro (€)'),
}));
const evidenceUploadOptions = computed(() => [
  {
    value: true,
    label: t('Yes'),
  },
  {
    value: false,
    label: t('No'),
  },
]);

const isFormDisabled = computed(() => props.isDisabled || isSaving.value);

const choicePreviewOptions = computed(() => form.options.reduce((acc, item) => ({
  ...acc,
  [item.option]: item.option,
}), {}));
const numericSplitPreviewInputComponent = computed(() => h(SplitInput, {
  class: 'w-full',
  component: h(AtInput, {
    type: 'number',
    min: 0,
    unit: form.unitGroup,
  }),
  categoryNames: ['', ''],
  isSmall: true,
}));
const textSplitPreviewInputComponent = computed(() => h(SplitInput, {
  class: 'w-full',
  component: h(AtInput, {
    type: 'text',
    min: 0,
    unit: form.unitGroup,
  }),
  categoryNames: ['', ''],
  isSmall: true,
}));

async function handleSubmit() {
  v$.value.$touch();

  if (v$.value.$error) {
    return;
  }

  const formData = toValue(form);

  const questionData: UpsertCustomQuestionnaireQuestionInput = {
    type: form.type,
    title: formData.title,
    question: formData.question,
    questionHelp: formData.questionHelp,
    refreshInterval: formData.refreshInterval,
    frameworks: calculateFrameworks(formData.framework),
    frameworkGroup: formData.frameworkGroup || null,
    allowUploadProof: formData.allowUploadProof,
    extraOptions: null,
  };

  if (props.question) {
    questionData._id = props.question._id;
  }

  if (isTypeForm.numeric(form.type)) {
    questionData.extraOptions = {
      valueUnit: formData.valueUnit,
    };
  }

  if (isTypeForm.choice(form.type)) {
    questionData.extraOptions = {
      options: formData.options.map((item) => item.option),
    };
  }

  if (isTypeForm.numericSplit(form.type)) {
    questionData.extraOptions = {
      unitGroup: formData.unitGroup,
    };
  }

  try {
    await upsertQuestion(
      {
        customQuestionnaireId: props.customQuestionnaireId,
        questionData,
      },
      {
        update: (store) => {
          store.evict({
            fieldName: 'getCustomQuestionnaire',
          });
        },
      },
    );
    if (props.question) {
      notify({ type: 'success', text: t('Question has been updated.') });
      emit('saved', 'updated');
    } else {
      notify({ type: 'success', text: t('Question has been created.') });
      emit('saved', 'created');
    }
  } catch {
    notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
  }
}

async function handleDeleteClick() {
  if (props.question) {
    const isConfirmed = await confirmViaDialog({
      title: t('Are you sure you want to delete this question?'),
      confirmLabel: t('Delete question'),
      confirmButtonVariant: 'destructive',
    });

    if (isConfirmed) {
      try {
        await deleteQuestion({
          customQuestionnaireId: props.customQuestionnaireId,
          customQuestionnaireQuestionId: props.question._id,
        });
        emit('deleted');
      } catch (error) {
        if (error instanceof ApolloError) {
          notify({
            type: 'error',
            text: t(error.message),
            duration: 10000,
          });
        }
      }
    }
  }
}

function handleRemoveOption(index: number) {
  form.options.splice(index, 1);
}

function handleAddOption() {
  form.options.push({
    option: '',
  });
}
</script>

<template>
  <form @submit.prevent="handleSubmit">
    <div class="flex items-center gap-3 p-4 border-b">
      <div class="w-10 h-10 rounded-md bg-[#d9d9d9]" />
      <AtInput
        v-model.trim="form.title"
        type="text"
        :placeholder="`${t('Enter a short title of the question.')}*`"
        class="bg-gray-50 border-gray-50"
        wrapperClass="w-full"
        :disabled="isFormDisabled"
        :errors="v$.title.$errors"
      />
    </div>

    <div class="px-4 py-6">
      <AtInfoBox
        :content="t('Note: Your custom data point will be displayed in the language in which it is written.')"
      />
      <AtInput
        v-model.trim="form.question"
        wrapperClass="mb-6"
        type="text"
        :placeholder="t('Write your data point as an easily understandable question.')"
        :label="t('Question/KPI')"
        :tooltip="TargetQuestionTooltip"
        :disabled="isFormDisabled"
        :errors="v$.question.$errors"
      />
      <MlTextarea
        v-model="form.questionHelp"
        :initialContent="question?.questionHelp"
        type="text"
        :placeholder="t('Add more context to your data point.')"
        :label="t('Help text (optional)')"
        wrapperClass="mb-6"
        :disabled="isFormDisabled"
      />
      <MlSelect
        v-model="form.refreshInterval"
        wrapperClass="mb-6"
        :label="t('Refresh interval')"
        :options="refreshIntervalOptions"
        sortedOptions
        :disabled="isFormDisabled"
      />
      <div class="flex items-center gap-4 mb-6">
        <MlSelect
          v-model="form.framework"
          wrapperClass="flex-1 w-1/2"
          :options="frameworkOptions"
          sortedOptions
          allowSelectNone
          :label="t('Framework (optional)')"
          :disabled="isFormDisabled"
        />
        <AtInput
          v-model.trim="form.frameworkGroup"
          wrapperClass="flex-1 w-1/2"
          type="text"
          :placeholder="t('Write framework group reference')"
          :label="t('Framework number (optional)')"
          :disabled="isFormDisabled"
        />
      </div>
      <MlSelect
        v-model="form.type"
        wrapperClass="mb-6"
        :label="`${t('Question type')}*`"
        :options="questionTypeOptions"
        sortedOptions
        :disabled="isFormDisabled"
      />

      <MlSelect
        v-if="isTypeForm.numeric(form.type)"
        v-model="form.valueUnit"
        wrapperClass="mb-6"
        :label="t('Value unit')"
        :options="valueUnitOptions"
        sortedOptions
        :disabled="isFormDisabled"
      />

      <MlSelect
        v-if="isTypeForm.numericSplit(form.type)"
        v-model="form.unitGroup"
        wrapperClass="mb-6"
        :label="t('Value unit')"
        :options="unitGroupOptions"
        sortedOptions
        :disabled="isFormDisabled"
      />

      <div v-if="isTypeForm.choice(form.type)" class="mb-6">
        <div
          v-for="(option, optionIndex) in form.options"
          :key="optionIndex"
          class="flex items-center gap-4 mb-2"
        >
          <AtInput
            v-model="option.option"
            wrapperClass="flex-1"
            :label="t('Option {number}', { number: optionIndex + 1 })"
            :class="{ 'border-2 !border-error': v$.options.$error && (v$.options.allUnique.$invalid || v$.options.$each.$message[optionIndex]?.[0]) }"
            :disabled="isFormDisabled"
          />
          <AtIconButton
            v-if="form.options.length > 2"
            type="button"
            class="mt-5 text-gray-500"
            :icon="XIcon"
            :title="t('Close')"
            :disabled="isFormDisabled"
            @click="handleRemoveOption(optionIndex)"
          />
        </div>
        <AtButton
          type="button"
          class="rounded border-2 border-primary text-primary"
          variant="outline"
          :icon="PlusIcon"
          :disabled="isFormDisabled"
          @click="handleAddOption()"
        >
          {{ t('Add Option') }}
        </AtButton>
      </div>

      <div class="flex items-center justify-between gap-4 mb-6">
        <p class="text-sm font-medium text-gray-700">
          {{ t('Request evidence upload') }}:
        </p>
        <AtToggleButton
          v-model="form.allowUploadProof"
          :options="evidenceUploadOptions"
        />
      </div>

      <div class="flex gap-2 items-center" :class="question ? 'justify-between' : 'justify-end'">
        <AtButton v-if="question" type="button" :disabled="isFormDisabled" @click="handleDeleteClick">
          {{ t('Delete') }}
        </AtButton>
        <AtButton type="submit" :disabled="v$.$error || isFormDisabled">
          <template v-if="!question">
            {{ t('Create') }}
          </template>
          <template v-else>
            {{ t('Update') }}
          </template>
        </AtButton>
      </div>
    </div>
  </form>

  <OgQuestionPreview
    :question="form.question"
    :refreshInterval="form.refreshInterval"
    :framework="form.framework"
    :frameworkGroup="form.frameworkGroup"
  >
    <template v-if="isTypeForm.text(form.type)">
      <AtInput v-if="form.type === ValueDataTypeEnum.TEXT" class="w-full" />
      <MlTextarea v-else class="w-full" :hideMenueBar="true" />
    </template>
    <AtInput
      v-else-if="isTypeForm.numeric(form.type)"
      class="w-full"
      type="number"
      :min="0"
      :unit="form.valueUnit"
    />
    <template v-else-if="isTypeForm.choice(form.type)">
      <MlSelect
        v-if="form.type === ValueDataTypeEnum.CHOICE"
        class="w-full"
        sortedOptions
        :options="choicePreviewOptions"
      />
      <MlAutocomplete
        v-else
        multiple
        type="select"
        sortedOptions
        :options="choicePreviewOptions"
      />
    </template>
    <component
      :is="isTypeForm.numericSplit(form.type) ? numericSplitPreviewInputComponent : textSplitPreviewInputComponent"
      v-else-if="isTypeForm.numericSplit(form.type) || isTypeForm.textSplit(form.type)"
    />
  </OgQuestionPreview>
</template>
