<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import { PlusIcon } from '@heroicons/vue/solid';
import useCompleteCustomQuestionnaireMutation from '@/api/mutations/CustomQuestionnaire/completeQuestionnaire.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import type { CustomQuestionnaire, CustomQuestionnaireQuestion } from '../../types';
import OgQuestionForm from './OgQuestionForm.vue';

type Props = {
  isLoading: boolean;
  customQuestionnaire: CustomQuestionnaire | null;
};

const props = defineProps<Props>();

const { t } = useI18n();
const router = useRouter();

const { loading: isCompleting, mutate: completeQuestionnaire } = useCompleteCustomQuestionnaireMutation({
  update: (store) => {
    store.evict({
      id: 'ROOT_QUERY',
      fieldName: 'getAllCategoriesForEntity',
    });
  },
});

const questions = computed<CustomQuestionnaireQuestion[]>(() => props.customQuestionnaire?.questions ?? []);

// -1 means new question is being created, numbers equal or above 0 mean index of the question in questions array, null means there are no questions.
const activeQuestion = ref<number | null>(questions.value.length > 0 ? 0 : null);

watch([questions, activeQuestion], ([newQuestionsValue, newActiveQuestionValue]) => {
  if (newActiveQuestionValue === null) {
    activeQuestion.value = newQuestionsValue.length >= 1 ? 0 : -1;
  }
});

function handleQuestionClick(index: number) {
  activeQuestion.value = index;
}

function handleAddQuestionClick() {
  activeQuestion.value = -1;
}

async function handleQuestionnaireCompletedClick() {
  if (props.customQuestionnaire) {
    try {
      await completeQuestionnaire({
        customQuestionnaireId: props.customQuestionnaire._id,
      });
      router.push({
        name: 'projectsCustomQuestionnaireShare',
      });
    } catch {
      notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
    }
  }
}

function handleQuestionSaved(event: 'created' | 'updated') {
  if (event === 'created') {
    activeQuestion.value = questions.value.length;
  }
}

function handleQuestionDeleted() {
  // If there were at least 2 questions before deleting, set active question to the 0, otherwise to -1.
  activeQuestion.value = questions.value.length > 1 ? 0 : -1;
}
</script>

<template>
  <div class="flex h-full">
    <div class="flex-initial w-96 border-r pt-6 pr-4 flex flex-col gap-4">
      <div class="flex-1">
        <h1 class="text-lg leading-5 font-medium mb-6">
          {{ t('Questions') }}
        </h1>
        <div class="overflow-y-scroll h-[70vh] mb-5">
          <button
            v-for="(question, questionIndex) in questions"
            :key="questionIndex"
            type="button"
            class="px-4 py-3 rounded-md border flex gap-2 items-center mb-3 cursor-pointer text-left w-full"
            :class="{
              'border-blue-600 bg-blue-50': activeQuestion === questionIndex,
              'border-gray-400 hover:border-blue-600 hover:bg-blue-50': activeQuestion !== questionIndex,
            }"
            @click="handleQuestionClick(questionIndex)"
          >
            <span class="flex-none w-10 h-10 rounded-md bg-[#d9d9d9]" />
            <span class="block flex-1 w-[calc(100%-12rem)]">
              <span class="truncate text-sm mb-1 block">
                {{ question.question }}
              </span>
              <span class="text-xs block">
                {{ question.title }}
              </span>
            </span>
          </button>
        </div>
        <button
          type="button"
          class="px-4 py-3 rounded-md border flex gap-2 items-center text-left w-full disabled:pointer-events-none disabled:text-inactive-text"
          :class="{
            'opacity-50': isLoading,
            'cursor-pointer': !isLoading,
            'border-blue-600 bg-blue-50': activeQuestion === -1,
            'border-gray-400': activeQuestion !== -1,
            'hover:border-blue-600 hover:bg-blue-50': activeQuestion !== -1 && !isLoading,
          }"
          :disabled="isLoading"
          @click="handleAddQuestionClick"
        >
          <span class="flex-none w-10 h-10 rounded-md bg-[#d9d9d9] flex items-center justify-center">
            <PlusIcon class="h-6 w-6" />
          </span>
          <span class="block flex-1 w-[calc(100%-12rem)]">
            <span class="truncate text-sm mb-1 block">
              {{ t('Add question') }}
            </span>
            <span class="text-xs block">
              {{ t('Text answer, Yes/No, Multiple choice...') }}
            </span>
          </span>
        </button>
      </div>
      <div class="flex-0 border-t pt-4 flex items-center justify-center">
        <AtButton :disabled="isLoading" :loading="isCompleting" @click="handleQuestionnaireCompletedClick">
          {{ t('Questionnaire completed') }}
        </AtButton>
      </div>
    </div>
    <div class="flex-1 border-r">
      <OgQuestionForm
        v-if="customQuestionnaire && (activeQuestion !== null && (activeQuestion === -1 || questions[activeQuestion]))"
        :key="activeQuestion"
        :customQuestionnaireId="customQuestionnaire._id"
        :question="questions[activeQuestion] ?? null"
        :isDisabled="isCompleting"
        @saved="handleQuestionSaved"
        @deleted="handleQuestionDeleted"
      />
    </div>
    <div class="flex-initial w-96 bg-gray-50 px-4 py-6">
      <h1 class="text-lg leading-5 font-medium mb-6">
        {{ t('Preview') }}
      </h1>
      <div
        v-if="activeQuestion !== null"
        id="customQuestionnairePreviewSection"
        class="rounded-lg mx-2 px-4 pt-4 pb-6 bg-white border border-blue-600 shadow-md"
      />
    </div>
  </div>
</template>
