import { gql } from '@apollo/client/core';

export default gql`
  query PgCustomQuestionnaireShare {
    getTeamUsers {
      _id
      firstName
      lastName
      email
      role
      assignments {
        _id
        subcategories {
          subcategory
          role
          location {
            _id
          }
        }
      }
    }
  }
`;
