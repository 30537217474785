import { gql } from '@apollo/client/core';

export default gql`
  query OgCreateQuestionnaire {
    getCustomCategoriesWithSubcategoriesForEntity {
      _id
      name
    }

    getOrganizationQuestionnairesCount
  }
`;
