<script setup lang="ts">
import { RouterLink } from 'vue-router';
import { useI18n } from 'vue-i18n';
import InformationCircleIcon from '@heroicons/vue/solid/InformationCircleIcon';

defineProps<{
  type: 'assessment' | 'results' | 'projects';
}>();

defineEmits<{
  coreData: [];
}>();

const { t } = useI18n();
</script>

<template>
  <div class="w-full flex space-x-2 items-center rounded border px-2 py-0.5 border-orange-400 bg-orange-50 text-gray-500">
    <InformationCircleIcon class="h-5 w-5 text-orange-400" />
    <div class="flex-1">
      <i18n-t
        v-if="type === 'results' || type === 'projects'"
        keypath="You did not enter the financial values necessary for calculation of the results. You can do that in core data by clicking {here}, or individually in each project."
      >
        <template #here>
          <RouterLink
            v-if="type === 'results'"
            :to="{ name: 'taxonomyProjects', hash: '#coreData' }"
            class="text-primary cursor-pointer"
          >
            {{ t('here') }}
          </RouterLink>
          <button v-else class="bg-transparent text-primary outline-0 border-0" @click="$emit('coreData')">
            {{ t('here') }}
          </button>
        </template>
      </i18n-t>
      <i18n-t
        v-else
        keypath="You did not enter the financial values necessary for calculation of the results. You can do that in core data by clicking {here}, or in project data by clicking left on this screen."
      >
        <template #here>
          <RouterLink :to="{ name: 'taxonomyProjects', hash: '#coreData' }" class="text-primary cursor-pointer">
            {{ t('here') }}
          </RouterLink>
        </template>
      </i18n-t>
    </div>
  </div>
</template>
