import { gql } from '@apollo/client/core';

export default gql`
  query OgProjects {

    getNumberOfLocations
    
    getDelegatedSubcategories {
      subcategory
      role
      location {
        _id
        createdAt
      }
    }

    entityLocationSummaries {
      _id
      name
      parent
      isHeadquarters
      hasChildren
      progress
      hasParentInfo
      depth
      createdAt
      assignees {
        _id
        firstName
        lastName
        email
        assignments {
          _id
          subcategories {
            role
            subcategory
          }
        }
        picture {
          _id
          downloadUrl
        }
      }
      suspensions {
        datapointtypes {
          _id
          name
          friendlyName
          question
          questionHelp

          activeReportingFramework {
            _id
            framework
            groups
            category
            subcategory
          }
        }
      }
      assignments {
        _id
        categories {
          _id
          category
          subcategories {
            _id
            subcategory
          }
        }
      }
    }
  }
`;
