<script setup lang="ts">
import { InfoBoxType } from './types';

type TProps = {
  content?: string;
  type?: InfoBoxType;
}
const props = withDefaults(defineProps<TProps>(), {
  content: '',
  type: InfoBoxType.Normal,
});

const classObject: Record<InfoBoxType, string> = {
  [InfoBoxType.Normal]: 'border-primary bg-blue-50',
  [InfoBoxType.Warning]: 'border-orange-400 bg-orange-50',
};
</script>

<template>
  <div class="mb-2 max-w-fit rounded border px-3 py-1.5 text-xs text-gray-700" :class="classObject[props.type]">
    {{ props.content }}
    <slot />
  </div>
</template>
