import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';

import type { CreateTaxonomyAssessmentMutation, CreateTaxonomyAssessmentMutationVariables } from '../../../__generated__/types';

const CREATE_TAXONOMY_ASSESSMENT_MUTATION = gql`
  mutation CreateTaxonomyAssessment($projectId: String!) {
    createTaxonomyAssessment( projectId: $projectId) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<CreateTaxonomyAssessmentMutation, CreateTaxonomyAssessmentMutationVariables>

const useCreateTaxonomyAssessmentMutation = (options?: TOptions) => useMutation<
  CreateTaxonomyAssessmentMutation,
  CreateTaxonomyAssessmentMutationVariables
>(CREATE_TAXONOMY_ASSESSMENT_MUTATION, options);

export default useCreateTaxonomyAssessmentMutation;
