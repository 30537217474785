<script setup lang="ts">

import { computed, ref, watch, inject } from 'vue';
import { useI18n } from 'vue-i18n';
import { CheckCircleIcon, DocumentDuplicateIcon, LightBulbIcon } from '@heroicons/vue/outline';
import { notify } from '@kyvg/vue3-notification';
import { useQuery } from '@vue/apollo-composable';
import { PlusIcon, XIcon } from '@heroicons/vue/solid';
import dayjs, { type Dayjs } from '@/lib/dayjs/config';
import MlModal from '@/components/molecules/MlModal.vue';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useDelegateDataPointRequestsMutation from '@/api/mutations/DataPointRequest/delegateDataPointRequests.mutation';
import MlAutocomplete from '@/components/molecules/MlAutocomplete.vue';
import { ValueDataTypeEnum, type MlDelegateQuestionModalQuery } from '@/__generated__/types';
import AtInfoBox from '@/components/atoms/AtInfoBox/AtInfoBox.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import type { TQuestionDataTableItem } from '../../../types';
import ML_DELEGATE_QUESTION_MODAL_QUERY from './MlDelegateQuestionModal.query';

const { t } = useI18n();

const props = defineProps<Props>();
type Props = {
  isShown: boolean
  items: TQuestionDataTableItem[];
}

const emit = defineEmits<Emits>();
type Emits = {
  (e: 'cancel'): void
};

const { result } = useQuery<MlDelegateQuestionModalQuery>(ML_DELEGATE_QUESTION_MODAL_QUERY);
const {
  mutate: delegateDataPointRequests,
  loading: delegateDataPointRequestsLoading,
  error: delegateDataPointRequestsError } = useDelegateDataPointRequestsMutation();

const copiedToClipboard = ref(false);
const isCopying = ref(true);
const forwardingLink = ref('');
const emailAddresses = ref<string[]>([]);
const forwardingDateMessage = ref('');
const delegations = computed(() => props.items[0]?.dpr.delegations ?? []);

const teamUsers = computed(() => result.value?.getTeamUsers ?? []);
const teamUsersNames = computed(
  () => teamUsers.value.reduce((acc, curr) => ({ ...acc, [curr.email]: `${curr.firstName} ${curr.lastName}` }), {}),
);

function addEmailAddressToOptions(email: string) {
  if (emailAddressOptions.value[email]) return;
  emailAddressOptions.value[email] = email;
}
const emailAddressOptions = ref<Record<string, string>>({});
watch(teamUsersNames, () => {
  delegations.value.forEach((delegation) => addEmailAddressToOptions(delegation.user.email));
  emailAddressOptions.value = {
    ...emailAddressOptions.value,
    ...teamUsersNames.value,
  };
}, { immediate: true });

const copyUrlToClipboard = () => {
  navigator.clipboard.writeText(forwardingLink.value);
  copiedToClipboard.value = true;

  setTimeout(() => {
    copiedToClipboard.value = false;
    isCopying.value = true;
  }, 100);
  isCopying.value = false;
};

watch(() => [props.items[0]?.dpr.delegations, props.isShown], () => {
  forwardingDateMessage.value = delegations.value[0]?.reminder?.sendAt ? getForwardingDateMessage(dayjs.utc(delegations.value[0]?.reminder?.sendAt)) : '';
  emailAddresses.value = delegations.value.map((delegation) => delegation.user.email);
  forwardingLink.value = getForwardingLink(delegations.value[0]?.shareToken);
});

const getForwardingDateMessage = (sendAt: Dayjs) => t('The data point has been forwarded/assigned {sendAt}.', { sendAt: sendAt.fromNow() });

const getForwardingLink = (shareToken?: string) => shareToken ? `${import.meta.env.VITE_APP_URL}/external-data-entry/login/${shareToken}` : '';

const dataEntrySelectMode = inject('dataEntrySelectMode', ref(false));

async function forwardDataPointRequest() {
  if (isEmptyEmailAddress.value) {
    notify({ type: 'error', text: t('email address must be provided.') });
    return;
  }

  try {
    const res = await delegateDataPointRequests({
      delegateDataPointRequestsInput: { dataPointRequestIds: props.items.map((item) => item.dpr._id ?? '') },
      emailAddresses: recipientMode.value === 'singleRecipient' ? [emailAddresses.value[0]] : emailAddresses.value,
    },
    { update: (store) => { store.evict({ fieldName: 'getMyDataPointRequests' }); } });
    const delegatedDprs = [...res?.data?.delegateDataPointRequests ?? []]
      .sort(
        (a, b) => ((a.delegations ?? [])[0]?.reminder?.sendAt ?? new Date()) - ((b.delegations ?? [])[0]?.reminder?.sendAt ?? new Date()));
    if (delegatedDprs?.length) {
      forwardingDateMessage.value = getForwardingDateMessage(dayjs((delegatedDprs[0].delegations ?? [])[0]?.reminder?.sendAt));
      forwardingLink.value = getForwardingLink((delegatedDprs[0].delegations ?? [])[0]?.shareToken);
    }
    notify({ type: 'success', text: t('The data point was successfully forwarded/assigned.') });
  } catch (err) {
    if (delegateDataPointRequestsError.value) {
      notify({ type: 'error', text: t(delegateDataPointRequestsError.value.message) });
    } else {
      notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
    }
  }
}
const isEmptyEmailAddress = computed(() => !emailAddresses.value[0]);

function addElement() {
  emailAddressCount.value += 1;
}

function removeElement(index: number) {
  emailAddresses.value.splice(index, 1);
  emailAddressCount.value -= 1;
}

const emailAddressCount = ref(delegations.value.length > 1 ? delegations.value.length : 1);

type RecipientMode = 'singleRecipient' | 'multipleRecipients';
const recipientMode = ref<RecipientMode>(delegations.value.length > 1 ? 'multipleRecipients' : 'singleRecipient');
const { TEXT, TEXT_LONG, NUMERIC, TEXT_SPLIT, NUMERIC_SPLIT, EMISSIONS_SPLIT } = ValueDataTypeEnum;
const canAggregate = computed(
  () => [TEXT, TEXT_LONG, NUMERIC, TEXT_SPLIT, NUMERIC_SPLIT, EMISSIONS_SPLIT].includes(props.items[0]?.dpr.dataPointType.valueDataType));
const recipientModeOptions = computed(() => ({
  singleRecipient: t('Single recipient'),
  ...(canAggregate.value ? { multipleRecipients: t('Multiple recipients') } : {}),
}) as Record<RecipientMode, string>);

watch(() => recipientMode.value, () => {
  if (recipientMode.value === 'multipleRecipients' && emailAddresses.value.length >= 2) {
    emailAddressCount.value = emailAddresses.value.length;
  }
  if (recipientMode.value === 'multipleRecipients' && emailAddresses.value.length <= 2) {
    emailAddressCount.value = 2;
  }
  if (recipientMode.value === 'singleRecipient') {
    emailAddressCount.value = 1;
  }
});

const isInternalDelegation = computed(
  () => Object.keys(teamUsersNames.value).find((teamUserEmail) => emailAddresses.value.includes(teamUserEmail)));

</script>

<template>
  <MlModal :isRevealed="props.isShown" class="max-w-xl" @close="emit('cancel')">
    <AtHeading type="h2" class="mb-3 border-b pb-2">
      {{ dataEntrySelectMode
        ? t('Forward or assign the selected questions')
        : t('Forward or assign the question') }}
    </AtHeading>
    <p class="mb-3 text-sm">
      {{ dataEntrySelectMode
        ? t('Forward these questions to a person outside of the Codio Impact platform, or assign them to a specific user already on the platform')
        : t('Forward this question to a person outside of the Codio Impact platform, or assign it to a specific user already on the platform.') }}
    </p>
    <div class="mt-2 grid grid-cols-4 gap-x-3">
      <AtInfoBox v-if="recipientModeOptions.multipleRecipients" class="col-span-4 flex py-3 mb-4">
        <span class="-ml-1">
          <LightBulbIcon class="w-5 text-primary" />
        </span>
        <span class="italic">
          <i18n-t
            keypath="{singleRecipient} means that there is only one answer to this question, and forwarding it in this way will overwrite any of the answers (the answer to the person forwarded last will be saved). If there are {multipleRecipients}, data from each will be displayed and summed."
          >
            <template #singleRecipient>
              <span class="font-semibold">
                {{ t('Single recipient') }}
              </span>
            </template>
            <template #multipleRecipients>
              <span class="font-semibold">
                {{ t('multiple recipients') }}
              </span>
            </template>
          </i18n-t>
        </span>
      </AtInfoBox>
      <div v-show="recipientModeOptions.multipleRecipients" class="col-span-4 mb-4">
        <MlSelect v-model="recipientMode" variant="inline" :options="recipientModeOptions" />
      </div>
      <span class="col-span-4 text-sm font-medium text-gray-700 mb-1">
        {{ t('Forward or assign by email') }}
      </span>
      <div class="col-span-4">
        <div v-for="number in emailAddressCount" :key="number" class=" mb-2 flex">
          <MlAutocomplete
            v-model="emailAddresses[number - 1]"
            :placeholder="t('Enter an email address or a name')"
            :options="emailAddressOptions"
            canCreate
            @update:modelValue="addEmailAddressToOptions"
          />
          <button
            v-if="recipientMode === 'multipleRecipients'"
            @click.stop="removeElement(number - 1)"
          >
            <XIcon class="w-4 ml-2 mr-5 cursor-pointer hover:text-primary" />
          </button>
        </div>
      </div>
      <button
        v-if="recipientMode === 'multipleRecipients'"
        aria-label="add row"
        class="flex text-gray-400 text-xs hover:text-primary mt-2"
        @click.stop="addElement"
      >
        <PlusIcon class="h-4 mr-2" />
        {{ t('Add row') }}
      </button>
      <div
        v-if="forwardingLink && delegations.length === 1 && !isInternalDelegation"
        class="my-2 col-span-4 flex cursor-pointer"
        @click="copyUrlToClipboard"
      >
        <div class="w-full">
          <AtInput v-model="forwardingLink" :label="t('Copy link')" :disabled="true" />
        </div>
        <AtButton
          variant="text"
          class="flex ml-2 cursor-pointer items-center justify-center text-gray-400 w-[65px] h-[38px] self-end"
          :loading="copiedToClipboard"
          :disabled="isEmptyEmailAddress"
        >
          <DocumentDuplicateIcon v-if="isCopying" class="w-6 cursor-pointer" />
        </AtButton>
      </div>
      <div v-if="forwardingDateMessage && emailAddresses.includes(delegations[0]?.user.email ?? '')" class="text-teal-600 flex col-span-4 mb-2">
        <CheckCircleIcon class="w-5 mr-2" />
        <span>
          {{ forwardingDateMessage }}
        </span>
      </div>
      <div class="flex items-center justify-between col-span-4 mt-5">
        <AtButton
          variant="outline"
          @click="emit('cancel')"
        >
          {{ t('Cancel') }}
        </AtButton>
        <AtButton
          :loading="delegateDataPointRequestsLoading"
          @click="forwardDataPointRequest"
        >
          {{ forwardingDateMessage && forwardingLink && emailAddresses.includes(delegations[0]?.user.email ?? '')
            ? t('Remind')
            : isInternalDelegation
              ? t('Assign')
              : t('Forward') }}
        </AtButton>
      </div>
    </div>
  </MlModal>
</template>
