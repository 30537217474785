<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import type { PgTaxonomyResultsQuery } from '@/__generated__/types';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import type { TDataTableHeader } from '@/components/organisms/OgDataTable/types';
import { formatNumberForTable, formatPercentage } from '@/components/pages/Modules/Taxonomy/util';

type Props = {
  project: PgTaxonomyResultsQuery['getTaxonomyProjects'][number];
};

type Item =
  | {
  title: string;
  turnover: string;
  capex: string;
  opex: string;
  isBusinessActivities: false;
}
  | {
  title: string;
  turnover: {
    value: string;
    percentage: string;
  };
  capex: {
    value: string;
    percentage: string;
  };
  opex: {
    value: string;
    percentage: string;
  };
  isBusinessActivities: true;
  type: 'aligned' | 'eligible' | 'noneligible';
};

const props = defineProps<Props>();

const { t } = useI18n();
const route = useRoute();

const headers = computed<TDataTableHeader[]>(() => [
  {
    text: '',
    value: 'title',
    sortable: false,
    filterable: false,
  },
  {
    text: t('Turnover'),
    value: 'turnover',
    sortable: false,
    filterable: false,
  },
  {
    text: t('Capex'),
    value: 'capex',
    sortable: false,
    filterable: false,
  },
  {
    text: t('Opex'),
    value: 'opex',
    sortable: false,
    filterable: false,
  },
]);

const items = computed<Item[]>(() => {
  if (!props.project.assessments[0]) {
    return [];
  }

  const assessment = props.project.assessments[0];
  const allActivities = props.project.assessments[0].businessActivities;

  return [
    {
      title: t('Taxonomy-aligned activities'),
      turnover: formatNumberForTable(assessment.alignedTurnover),
      capex: formatNumberForTable(assessment.alignedCapex),
      opex: formatNumberForTable(assessment.alignedOpex),
      isBusinessActivities: false,
    },
    ...allActivities.filter((item) => item.minimumStandardsRespected).map((item) => ({
      title: `${item.activity.number} ${t(item.activity.name)}`,
      turnover: {
        value: formatNumberForTable(item.alignedTurnover),
        percentage: formatPercentage(item.alignedTurnover, assessment.turnover),
      },
      capex: {
        value: formatNumberForTable(item.alignedCapex),
        percentage: formatPercentage(item.alignedCapex, assessment.capex),
      },
      opex: {
        value: formatNumberForTable(item.alignedOpex),
        percentage: formatPercentage(item.alignedOpex, assessment.opex),
      },
      isBusinessActivities: true,
      type: 'aligned',
    })),
    {
      title: t('Taxonomy-eligible activities'),
      turnover: formatNumberForTable(assessment.eligibleTurnover),
      capex: formatNumberForTable(assessment.eligibleCapex),
      opex: formatNumberForTable(assessment.eligibleOpex),
      isBusinessActivities: false,
    },
    // atm all activities that can be selected are eligible
    ...allActivities.filter(() => true).map((item) => ({
      title: `${item.activity.number} ${t(item.activity.name)}`,
      turnover: {
        value: formatNumberForTable(item.eligibleTurnover),
        percentage: formatPercentage(item.eligibleTurnover, assessment.turnover),
      },
      capex: {
        value: formatNumberForTable(item.eligibleCapex),
        percentage: formatPercentage(item.eligibleCapex, assessment.capex),
      },
      opex: {
        value: formatNumberForTable(item.eligibleOpex),
        percentage: formatPercentage(item.eligibleOpex, assessment.opex),
      },
      isBusinessActivities: true,
      type: 'eligible',
    })),
    {
      title: t('Non taxonomy-eligible activities'),
      turnover: formatNumberForTable(assessment.nonEligibleTurnover),
      capex: formatNumberForTable(assessment.nonEligibleCapex),
      opex: formatNumberForTable(assessment.nonEligibleOpex),
      isBusinessActivities: false,
    },
    {
      title: t('TOTAL'),
      turnover: formatNumberForTable(assessment.turnover),
      capex: formatNumberForTable(assessment.capex),
      opex: formatNumberForTable(assessment.opex),
      isBusinessActivities: false,
    },
  ] as Item[];
});

const projectTable = ref<HTMLElement>();

const slideToCurrent = () => {
  const isSelected = props.project._id === route.params.projectId;
  if (isSelected) {
    projectTable.value?.scrollIntoView({ behavior: 'smooth' });
  }
};

onMounted(() => {
  slideToCurrent();
});

watch(() => route.params.projectId, () => {
  slideToCurrent();
});

</script>

<template>
  <div
    ref="projectTable"
    class="bg-gray-50 rounded-md p-4 mb-6"
  >
    <p class="max-w-[75vw] py-2 text-lg font-medium leading-6 sm:max-w-lg">
      {{ props.project.name }}
    </p>

    <OgDataTable
      :headers="headers"
      :items="items"
      hasTotal
      controlsHidden
      variant="dark-gray"
    >
      <template #item-title="item: Item">
        <div
          :class="{
            'font-bold': !item.isBusinessActivities,
            'pl-6': item.isBusinessActivities,
          }"
        >
          {{ item.title }}
        </div>
      </template>
      <template #item-turnover="item: Item">
        <template v-if="item.isBusinessActivities">
          <div>
            {{ item.turnover.value }}
          </div>
          <div>
            {{ item.turnover.percentage }}
          </div>
        </template>
        <div v-else>
          {{ item.turnover }}
        </div>
      </template>
      <template #item-capex="item: Item">
        <template v-if="item.isBusinessActivities">
          <div>
            {{ item.capex.value }}
          </div>
          <div>
            {{ item.capex.percentage }}
          </div>
        </template>
        <div v-else>
          {{ item.capex }}
        </div>
      </template>
      <template #item-opex="item: Item">
        <template v-if="item.isBusinessActivities">
          <div>
            {{ item.opex.value }}
          </div>
          <div>
            {{ item.opex.percentage }}
          </div>
        </template>
        <div v-else>
          {{ item.opex }}
        </div>
      </template>
    </OgDataTable>
  </div>
</template>
