import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import type { SetCustomQuestionnaireNameMutation, SetCustomQuestionnaireNameMutationVariables } from '@/__generated__/types';

export const COMPLETE_QUESTIONNAIRE = gql`
  mutation SetCustomQuestionnaireName($customQuestionnaireId: String!, $inputData: SetQuestionnaireNameInput!) {
    setQuestionnaireName(customQuestionnaireId: $customQuestionnaireId, inputData: $inputData) {
      _id
    }
  }
`;

type Options = UseMutationOptions<SetCustomQuestionnaireNameMutation, SetCustomQuestionnaireNameMutationVariables>

const useSetCustomQuestionnaireNameMutation = (options?: Options) => useMutation<
  SetCustomQuestionnaireNameMutation,
  SetCustomQuestionnaireNameMutationVariables
>(COMPLETE_QUESTIONNAIRE, options);

export default useSetCustomQuestionnaireNameMutation;
