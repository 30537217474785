import { gql } from '@apollo/client/core';

export default gql`
  query OgSelectTargetStep {
    getTargets {
      _id
      name
      number
      isRecommended

      suggestedDataPointTypes {
        _id
        friendlyName
        question
        name
        entity {
          _id
        }
        activeReportingFramework {
          _id
          category
          subcategory
        }
        valueDataType
        valueUnit
        valueUnitDivisor
      }

      goal {
        _id
        reportingFramework
      }
    }
  }
`;
