<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { CheckIcon } from '@heroicons/vue/outline';
import { useQuery } from '@vue/apollo-composable';
import MlDialog from '@/components/molecules/MlDialog.vue';
import OgRestrictionsModal from '@/components/organisms/OgRestrictionsModal/OgRestrictionsModal.vue';
import type { OgCreateQuestionnaireQuery } from '@/__generated__/types';
import OG_CREATE_QUESTIONNAIRE_QUERY
  from '@/components/pages/PgProjects/PgProject/OgProjectSidebar/OgCreateQuestionnaire/OgCreateQuestionnaire.query';
import useRestrictions from '@/utils/composables/useRestrictions/useRestrictions';
import type { Project } from '../types';
import OgProjectForm, { type Values as ProjectFormValues } from './OgProjectForm.vue';
import OgQuestionnaireName, { type Values as ProjectQuestionnaireNameValues } from './OgQuestionnaireName/OgQuestionnaireName.vue';
import OgTemplate, { type Values as ProjectTemplateValues } from './OgTemplate.vue';

enum AddProjectStep {
  NEW_PROJECT_FORM,
  CHOOSING_TEMPLATE,
  CUSTOMIZING,
}

type Step = {
  id: AddProjectStep;
  order: 1 | 2 | 3;
  title: string;
  isActive: boolean;
  isDone: boolean;
};

export type TemplateType = 'custom' | 'default';

export type AddProjectData = {
  name: string;
  parent: string | null;
  template: TemplateType;
  questionnaireName: string | null;
  category: string | null;
};

type AddProjectValues = {
  name: string;
  parent: string | null;
  template: TemplateType | null;
  questionnaireName: string;
  category: string | null;
};

type Props = {
  isOpen: boolean;
  projects: Project[];
  existingProjectsNames: string[];
};

defineProps<Props>();

const emit = defineEmits<{
  cancel: [];
  save: [data: AddProjectData];
}>();

const { t } = useI18n();
const { restrictions } = useRestrictions();
const canCreateCustomCategory = ref(true);
const showRestrictionModal = ref(false);

const activeStep = ref(AddProjectStep.NEW_PROJECT_FORM);

const { result } = useQuery<OgCreateQuestionnaireQuery>(OG_CREATE_QUESTIONNAIRE_QUERY);

watch(
  [
    () => restrictions.value?.numberOfQuestionnaires ?? -1,
    () => result.value?.getOrganizationQuestionnairesCount ?? 0,
  ],
  ([newNumberOfQuestionnairesRestriction, newOrganizationQuestionnaireCount]) => {
    if (newNumberOfQuestionnairesRestriction > -1 && newOrganizationQuestionnaireCount >= newNumberOfQuestionnairesRestriction) {
      canCreateCustomCategory.value = false;
    }
  },
  { immediate: true },
);

const values = reactive<AddProjectValues>({
  name: '',
  parent: null,
  template: null,
  questionnaireName: '',
  category: null,
});

const steps = computed<Step[]>(() => [
  {
    id: AddProjectStep.NEW_PROJECT_FORM,
    order: 1,
    title: t('Name the project'),
    isActive: activeStep.value === AddProjectStep.NEW_PROJECT_FORM,
    isDone: [AddProjectStep.CHOOSING_TEMPLATE, AddProjectStep.CUSTOMIZING].includes(activeStep.value),
  },
  {
    id: AddProjectStep.CHOOSING_TEMPLATE,
    order: 2,
    title: t('Choose the data'),
    isActive: activeStep.value === AddProjectStep.CHOOSING_TEMPLATE,
    isDone: activeStep.value === AddProjectStep.CUSTOMIZING,
  },
  {
    id: AddProjectStep.CUSTOMIZING,
    order: 3,
    title: t('Customize'),
    isActive: activeStep.value === AddProjectStep.CUSTOMIZING,
    isDone: false,
  },
]);

function resetValues() {
  activeStep.value = AddProjectStep.NEW_PROJECT_FORM;

  values.name = '';
  values.parent = null;
  values.template = null;
  values.questionnaireName = '';
  values.category = null;
}

function saveProject() {
  emit('save', {
    name: values.name,
    parent: values.parent || null,
    template: values.template!,
    questionnaireName: values.questionnaireName || null,
    category: values.category || null,
  });

  resetValues();
}

function handleCancel() {
  emit('cancel');

  resetValues();
}

function handleFormNext(event: ProjectFormValues) {
  activeStep.value = AddProjectStep.CHOOSING_TEMPLATE;

  values.name = event.name;
  values.parent = event.parent;
}

function handleTemplateNext(event: ProjectTemplateValues) {
  values.template = event.template;

  if (event.template === 'custom') {
    if (canCreateCustomCategory.value) {
      activeStep.value = AddProjectStep.CUSTOMIZING;
    } else {
      showRestrictionModal.value = true;
    }
  } else {
    saveProject();
  }
}
function handleTemplatePrevious(event: ProjectTemplateValues) {
  activeStep.value = AddProjectStep.NEW_PROJECT_FORM;

  values.template = event.template;
}

function handleQuestionnaireNameNext(event: ProjectQuestionnaireNameValues) {
  values.questionnaireName = event.name;
  values.category = event.category;

  saveProject();
}
function handleQuestionnaireNamePrevious(event: ProjectQuestionnaireNameValues) {
  activeStep.value = AddProjectStep.CHOOSING_TEMPLATE;

  values.questionnaireName = event.name;
  values.category = event.category;
}
</script>

<template>
  <MlDialog
    :isOpen="isOpen"
    :closeOnOutside="false"
    :isTitleStyled="false"
    @close="handleCancel"
  >
    <template #title>
      <ol class="mb-6 flex flex-wrap">
        <li
          v-for="step in steps"
          :key="step.id"
          class="flex flex-col items-center w-36 relative px-1"
        >
          <div
            class="flex items-center justify-center w-6 h-6 rounded-full border-2 text-sm font-bold before:content-[''] before:absolute before:h-px before:bg-gray-200 before:left-0 before:right-[calc(50%+12px)] after:content-[''] after:absolute after:h-px after:bg-gray-200 after:right-0 after:left-[calc(50%+12px)]"
            :class="{
              'text-blue-600 border-blue-600': step.isActive && !step.isDone,
              'text-teal-600 border-teal-600': step.isDone,
              'text-gray-200 border-gray-200': !step.isActive && !step.isDone,
            }"
          >
            <template v-if="step.isDone">
              <CheckIcon class="w-4 h-4" />
            </template>
            <template v-else>
              {{ step.order }}
            </template>
          </div>
          <h3 class="text-sm">
            {{ step.title }}
          </h3>
        </li>
      </ol>
    </template>

    <template #default="{ close }">
      <div class="px-6">
        <OgProjectForm
          v-if="activeStep === AddProjectStep.NEW_PROJECT_FORM"
          :projects="projects"
          :existingProjectsNames="existingProjectsNames"
          :initialName="values.name"
          :initialParent="values.parent"
          @cancel="close"
          @next="handleFormNext"
        />
        <OgTemplate
          v-else-if="activeStep === AddProjectStep.CHOOSING_TEMPLATE"
          :initialTemplate="values.template"
          @previous="handleTemplatePrevious"
          @next="handleTemplateNext"
        />
        <OgQuestionnaireName
          v-else
          :initialName="values.questionnaireName"
          :initialCategory="values.category"
          @previous="handleQuestionnaireNamePrevious"
          @next="handleQuestionnaireNameNext"
        />
      </div>

      <OgRestrictionsModal
        teleportTo="body"
        :title="t('Custom categories limit reached')"
        :text="t('You have reached the maximum number of custom questionnaires in your current plan. Upgrade to to be able to add topics.')"
        :isRevealed="showRestrictionModal"
        @cancel="showRestrictionModal = false"
      />
    </template>
  </MlDialog>
</template>
