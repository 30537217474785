// eslint-disable-next-line no-restricted-imports
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isBetween from 'dayjs/plugin/isBetween';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import calendar from 'dayjs/plugin/calendar';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// eslint-disable-next-line no-restricted-imports
export type { Dayjs, QUnitType } from 'dayjs';

dayjs.extend(duration);
dayjs.extend(calendar);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(quarterOfYear);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);
dayjs.locale(localStorage.codioImpactLang);
dayjs.tz.setDefault('UTC');

export default dayjs;
