<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import DownloadIcon from '@heroicons/vue/solid/DownloadIcon';
import ChartSquareBarIcon from '@heroicons/vue/outline/ChartSquareBarIcon';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import { useStore } from '../../store';
import AtEmptyCard from '../../AtEmptyCard.vue';
import MlInfo from '../../MlInfo.vue';
import OgImpactMateriality from './OgImpactMateriality.vue';
import OgFinancialMateriality from './OgFinancialMateriality.vue';
import OgDoubleMateriality from './OgDoubleMateriality.vue';

const { t } = useI18n();
const store = useStore();

const showCharts = computed(() => {
  return store.value.iroImpacts.length > 0 || store.value.iroRisksAndOpportunities.length > 0;
});
</script>

<template>
  <MlInfo v-if="showCharts" type="warning" class="mb-6">
    {{ t('This matrix is a visual representation of the results of your double materiality assessment. Please keep in mind that this is a cumulative score of your own ratings and all surveys. If you want to learn more about our methodology, please go to the “Start > Guidance” site on the main overview.') }}
  </MlInfo>

  <div class="flex justify-end items-center space-x-4 mb-4">
    <AtButton
      variant="outline"
      type="button"
      disabled
      :icon="DownloadIcon"
    >
      {{ t('Download as Excel') }}
    </AtButton>
    <AtButton
      type="button"
      disabled
      :icon="DownloadIcon"
    >
      {{ t('Download as PDF') }}
    </AtButton>
  </div>

  <div v-if="showCharts" class="grid gap-6 grid-cols-2">
    <div class="col-span-2">
      <OgDoubleMateriality />
    </div>
    <div>
      <OgImpactMateriality />
    </div>
    <div>
      <OgFinancialMateriality />
    </div>
  </div>

  <AtEmptyCard
    v-else
    class="mb-8"
    variant="center"
    :icon="ChartSquareBarIcon"
    :title="t('Double materiality assessment matrix')"
  >
    <p>
      {{ t('Once you enter data in the Topics part, your assessment will show here.') }}
    </p>
  </AtEmptyCard>
</template>
