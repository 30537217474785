import { gql } from '@apollo/client/core';

export default gql`
  query MlDelegateQuestionModal {
    getTeamUsers {
      _id
      firstName
      lastName
      email
    }
  }
`;
