<script setup lang="ts">
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/vue/solid';

type TProps = {
  isOpen: boolean
  title?: string
  count?: number
  titleClass?: string
  wrapperClass?: Record<string, unknown> | string
}

const props = defineProps<TProps>();
const emit = defineEmits(['toggle']);

</script>

<script lang="ts">
export default { inheritAttrs: false };
</script>

<template>
  <div class="flex cursor-pointer items-center" :class="props.wrapperClass" @click.stop="emit('toggle')">
    <ChevronRightIcon v-if="!isOpen" class="w-5" />
    <ChevronDownIcon v-else class="w-5" />
    <slot name="title">
      <div :class="[props.titleClass, { '!text-blue-600': props.isOpen }] ?? 'bg-gray-50 px-1.5 mr-2.5 rounded-sm font-bold text-center justify-center'">
        {{ props.title }}
      </div>
    </slot>

    <div class="text-xs">
      {{ count }}
    </div>
  </div>
</template>
