import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { AssignUsersToDoubleMaterialityMutation, AssignUsersToDoubleMaterialityMutationVariables } from '@/__generated__/types';

const ASSIGN_USERS_TO_DOUBLE_MATERIALITY_MUTATION = gql`
  mutation AssignUsersToDoubleMateriality($userIds: [String!]!) {
    assignUserToDoubleMateriality(userIds: $userIds) {
      _id
      firstName
      lastName
      jobTitle
      jobDepartment
      email
      role
      language
      assignments {
        _id
        doubleMateriality
      }
      entities {
        _id
        name
      }
      invitedBy {
        _id
      }
    }
  }
`;

type TOptions = UseMutationOptions<AssignUsersToDoubleMaterialityMutation, AssignUsersToDoubleMaterialityMutationVariables>

const useAssignUsersToDoubleMaterialityMutation = (options?: TOptions) => useMutation<
  AssignUsersToDoubleMaterialityMutation,
  AssignUsersToDoubleMaterialityMutationVariables
>(ASSIGN_USERS_TO_DOUBLE_MATERIALITY_MUTATION, options);

export default useAssignUsersToDoubleMaterialityMutation;
