import { i18n } from '@/lib/i18n';

const { locale } = i18n.global;

export function formatToSymbolNumber(num: number, digits: number) {
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ];

  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup.slice().reverse().find((_item) => {
    return num >= _item.value;
  });

  return item ? (num / item.value).toLocaleString(locale.value === 'en' ? 'en-US' : 'de-DE', {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).replace(rx, '$1') + item.symbol : '0';
}
