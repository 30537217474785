<script setup lang="ts">
import { computed, ref } from 'vue';
import type { HTMLAttributes } from 'vue';
import { useI18n } from 'vue-i18n';
import { type ErrorObject } from '@vuelidate/core';
import { InformationCircleIcon } from '@heroicons/vue/outline';
import { v4 as uuid } from 'uuid';
import Datepicker, { type DatePickerInstance, type ModelValue } from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';
import dayjs from '@/lib/dayjs/config';
import unwrapVuelidateErrorMessages from '@/utils/helpers/unwrapVuelidateErrorMessages';
import AtTooltipIcon, { type TTooltipOptions } from '@/components/atoms/AtTooltipIcon.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

// Documentation: https://vue3datepicker.com/api/

export type MlDatePickerModelValue = ModelValue;

type Props = {
  modelValue?: MlDatePickerModelValue;
  wrapperClass?: HTMLAttributes['class'];
  labelClass?: HTMLAttributes['class'];
  label?: string;
  description?: string;
  placeholder?: string;
  tooltip?: TTooltipOptions;
  id?: string;
  errors?: string[] | ErrorObject[];
};

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  wrapperClass: '',
  labelClass: '',
  label: '',
  description: '',
  placeholder: 'Select Date',
  tooltip: undefined,
  id: () => uuid(),
  errors: () => [],
});

defineOptions({ inheritAttrs: false });

const datePicker = ref<DatePickerInstance | null>(null);

const emit = defineEmits<{
  'update:modelValue': [value: MlDatePickerModelValue];
  'apply': [];
}>();

const { t, locale } = useI18n();

const value = computed<MlDatePickerModelValue>({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update:modelValue', newValue);
  },
});

const actionRow = {
  showSelect: true,
  showCancel: false,
  showNow: false,
  showPreview: false,
};

const inputValue = ref<Date | [Date, Date]>();
function handleInputChange(date: Date | [Date, Date]) {
  inputValue.value = date;
}
// Select date/date range when user clicks outside the input field.
function handleBlur() {
  if (Array.isArray(inputValue.value)) {
    // inputValue.value dates are in local time, so convert them to UTC.
    // Hours, minutes and seconds are ignored.
    // Value is saved only if range is valid.
    const valueFrom = dayjs(inputValue.value[0]);
    const valueTo = dayjs(inputValue.value[1]);
    if (valueFrom.isValid() && valueTo.isValid() && valueTo.isSameOrAfter(valueFrom, 'day')) {
      const newDateFrom = dayjs.utc(valueFrom.format('YYYY-MM-DDT00:00:00.000+00:00'));
      const newDateTo = dayjs.utc(valueTo.format('YYYY-MM-DDT00:00:00.000+00:00'));
      if (newDateTo.isSameOrAfter(newDateFrom, 'day')) {
        value.value = [newDateFrom.toDate(), newDateTo.toDate()];
      }
    }
  } else {
    const singleValue = dayjs(inputValue.value);
    if (singleValue.isValid()) {
      value.value = dayjs.utc(singleValue.format('YYYY-MM-DDT00:00:00.000+00:00')).toDate();
    }
  }
}

function handleApplyClick(selectDate: () => void) {
  selectDate();
  emit('apply');
}
</script>

<template>
  <div :class="props.wrapperClass">
    <label
      :for="props.id"
      :class="props.labelClass"
      class="mb-1 flex text-sm font-medium text-gray-700"
    >
      {{ t(props.label) }}

      <AtTooltipIcon
        v-if="props.tooltip"
        class="ml-1 cursor-pointer"
        :tooltip="props.tooltip"
        :triggers="['click', 'touch']"
        autoHide
      />
    </label>
    <p
      v-if="props.description"
      class="mb-4 text-sm text-gray-700"
    >
      {{ t(props.description) }}
    </p>
    <Datepicker
      :id="props.id"
      ref="datePicker"
      v-model="value"
      class="datePicker min-w-[8rem] rounded-md"
      format="dd/MM/yyyy"
      sixWeeks
      textInput
      timezone="UTC"
      :actionRow="actionRow"
      :clearable="false"
      :enableTimePicker="false"
      :locale="locale"
      :placeholder="t(props.placeholder)"
      v-bind="$attrs"
      @blur="handleBlur"
      @internalModelChange="handleInputChange"
    >
      <template #action-row="{ selectDate }">
        <div class="flex items-center w-full p-2.5 justify-end">
          <AtButton variant="default" type="button" @click="handleApplyClick(selectDate)">
            {{ t('Apply') }}
          </AtButton>
        </div>
      </template>
      <template v-for="(_, name) in $slots" #[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </Datepicker>
    <p
      v-for="error in unwrapVuelidateErrorMessages(props.errors)"
      :key="error"
      class="mt-1 text-xs text-error"
    >
      <span class="flex">
        <InformationCircleIcon class="mr-1 w-3" />
        {{ t(error) }}
      </span>
    </p>
  </div>
</template>

<style lang="postcss" scoped>
.datePicker:deep(.dp__input) {
  @apply rounded-md;
}

.datePicker:deep(.dp__menu) {
  font-family: inherit;
}

.datePicker:deep(.dp__icon) {
  cursor: default;
}

.datePicker:deep([role="textbox"]) {
  @apply w-full;
}
</style>

<style>
.dp__theme_light {
  --dp-primary-color: theme(colors.blue.600);
  --dp-success-color-disabled: theme(colors.gray.200);
  --dp-highlight-color: theme(colors.blue.600);
}
</style>
