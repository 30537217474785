import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  GenerateFileUploadTokenExternalMutation,
  GenerateFileUploadTokenExternalMutationVariables,
} from '@/__generated__/types';

export const GENERATE_FILE_UPLOAD_TOKEN_EXTERNAL = gql`
  mutation GenerateFileUploadTokenExternal($entityId: String, $userId: String) {
    generateFileUploadTokenExternal(entityId: $entityId, userId: $userId) {
      _id
      preSignedUrl
      secretUpdateToken
    }
  }
`;

type TOptions = UseMutationOptions<GenerateFileUploadTokenExternalMutation, GenerateFileUploadTokenExternalMutationVariables>

const useGenerateFileUploadTokenExternalMutation = (options?: TOptions) => useMutation<
  GenerateFileUploadTokenExternalMutation,
  GenerateFileUploadTokenExternalMutationVariables
>(GENERATE_FILE_UPLOAD_TOKEN_EXTERNAL, options);

export default useGenerateFileUploadTokenExternalMutation;
