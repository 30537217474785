import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  AnswerDelegatedDataPointRequestMutation,
  AnswerDelegatedDataPointRequestMutationVariables,
} from '@/__generated__/types';

export const ANSWER_DELEGATED_DATAPOINT_REQUEST = gql`
  mutation AnswerDelegatedDataPointRequest(
    $answerDataPointRequestDto: AnswerDataPointRequestInput!, 
    $delegationEmailAddress: String!, 
    $shareToken: String!,
  ) {
    answerDelegatedDataPointRequest(
      AnswerDataPointRequestInput: $answerDataPointRequestDto, 
      delegationEmailAddress: $delegationEmailAddress,
      shareToken: $shareToken
    ) {
      _id
      status
      displayStatus
      value
      originalValue
      
      valueSource {
        name
        originalValue
        originalValueUnit
        emissionFactor
        emissionFactorCountry
      }
      
      dueDate
      comment
      file {
        _id
        filename
      }

      childs {
        _id
        dueDate
        from
        to
        value
        originalValue
        status
        displayStatus

        dataPointType {
          _id
          allowUploadProof
          question
          valueUnit
          valueUnitDivisor
          valueDataType
          valueDataTypeOptions
        }
        
        dataPointTypeFlat {
          subcategory
        }
      }
    }
  }
`;

type TOptions = UseMutationOptions<AnswerDelegatedDataPointRequestMutation, AnswerDelegatedDataPointRequestMutationVariables>

const useAnswerDelegatedDataPointRequestMutation = (options?: TOptions) => useMutation<
  AnswerDelegatedDataPointRequestMutation,
  AnswerDelegatedDataPointRequestMutationVariables
>(ANSWER_DELEGATED_DATAPOINT_REQUEST, options);

export default useAnswerDelegatedDataPointRequestMutation;
