<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { type PgTaxonomyResultsQuery, TaxonomyAssessmentStatusEnum } from '@/__generated__/types';
import grayBarsImg from '@/assets/grayBars.svg';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import PG_TAXONOMY_RESULTS_QUERY from '@/components/pages/Modules/Taxonomy/PgResults/PgResults.query';
import { router } from '@/router';
import AtFinancialValuesWarning from '../AtFinancialValuesWarning.vue';
import OgProjectTable from './OgProjectTable.vue';

const { t } = useI18n();
const { result, refetch, loading } = useQuery<PgTaxonomyResultsQuery>(PG_TAXONOMY_RESULTS_QUERY);

onMounted(() => refetch());

const items = computed(() => result.value?.getTaxonomyProjects ?? []);

const showWarning = computed(() => items.value.some((project) => {
  return project.assessments.some((assessment) => {
    return assessment.status !== TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED_FINAL
      && (assessment.turnoverCompleteLater || assessment.capexCompleteLater || assessment.opexCompleteLater);
  });
}));
</script>

<template>
  <MlEmptyStateCard
    v-if="!items.length && !loading"
    :title="t('No results yet')"
    :description="t('Complete your first assessment to see your Taxonomy results here.')"
    :buttonText="t('Start assessment')"
    @buttonClick="router.push({ name: 'taxonomyProjects' })"
  >
    <img
      class="mb-7"
      :src="grayBarsImg"
      alt="Graph"
    >
  </MlEmptyStateCard>
  <div v-else>
    <AtFinancialValuesWarning v-if="showWarning" type="results" />
    <OgProjectTable
      v-for="(project, projectIndex) in items"
      :key="projectIndex"
      :project="project"
    />
  </div>
</template>
