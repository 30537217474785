<script setup lang="ts">
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import type { OgDoubleMaterialityLayoutQuery, OgDoubleMaterialityLayoutQueryVariables } from '@/__generated__/types';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import AtProgressNav, { type Item as ProgressNavItem } from '@/components/atoms/AtProgressNav.vue';
import { useCreateStore, useIsStoreReady } from './store';
import OG_DOUBLE_MATERIALITY_LAYOUT_QUERY from './OgDoubleMaterialityLayout.query';

const { t } = useI18n();
const route = useRoute();

const { result } = useQuery<OgDoubleMaterialityLayoutQuery, OgDoubleMaterialityLayoutQueryVariables>(OG_DOUBLE_MATERIALITY_LAYOUT_QUERY);

const id = computed(() => result.value?.getOrCreateDoubleMateriality._id ?? '');

useCreateStore(id);

const isReady = useIsStoreReady();

const headerData = computed<{ title: string; description: string; }>(() => {
  const doesMatch = (name: string) => route.matched.some((item) => item.name === name);

  if (doesMatch('doubleMaterialityTopics')) {
    return {
      title: t('Topics'),
      description: t('Select relevant sustainability matters from the sub-topic and sub-sub-topic lists to serve as the foundation for your double materiality assessment, companies typically choose 10 to 20 matters. Ensure your selections cover different ESRS chapters and include company-specific matters by creating or modifying existing ones to reflect your specific situation. Please note that all changes will be auto-saved.'),
    };
  }

  if (doesMatch('doubleMaterialityIROs')) {
    return {
      title: t('Impacts, Risks and Opportunities (IROs)'),
      description: t('Define and score IROs for all the sustainability matters you have selected in the previous step These IROs will be used to determine which of the sustainability matters are material for your company. You can only start this step once you entered certain relevant topics in the previous one.'),
    };
  }

  if (doesMatch('doubleMaterialityStakeholders')) {
    return {
      title: t('Stakeholders'),
      description: t('Select and engage with your company’s key stakeholders to gain understanding of how these stakeholders may be impacted by the company and to assess, validate and ensure the completeness of the outcome of your materiality assessment.'),
    };
  }

  if (doesMatch('doubleMaterialityResults')) {
    return {
      title: t('Results'),
      description: t('These are your company’s material sustainability matters, based on your double materiality assessment.'),
    };
  }

  return {
    title: t('Double Materiality Assessment'),
    description: t('Decide what is relevant for your company based on the EU CSRD double materiality assessment.'),
  };
});

const progressItems = computed<ProgressNavItem[]>(() => {
  // Initially only set the route that is currently active as "current" and other routes to "past".
  function getInitialStatus(name: string): ProgressNavItem['status'] {
    if (route.matched.some((item) => item.name === name)) {
      return 'current';
    }

    return 'past';
  }

  const items: ProgressNavItem[] = [
    {
      name: 'doubleMaterialityStart',
      label: t('Start'),
      status: getInitialStatus('doubleMaterialityStart'),
      hasActiveChild: false,
      children: [],
    },
    {
      name: 'doubleMaterialityTopics',
      label: t('Topics'),
      status: getInitialStatus('doubleMaterialityTopics'),
      hasActiveChild: false,
      children: [],
    },
    {
      name: 'doubleMaterialityIROs',
      label: t('IROs'),
      status: getInitialStatus('doubleMaterialityIROs'),
      hasActiveChild: false,
      children: [
        {
          name: 'doubleMaterialityIROsScoreActualNegative',
          label: t('Negative actual impacts'),
          isActive: getInitialStatus('doubleMaterialityIROsScoreActualNegative') === 'current',
        },
        {
          name: 'doubleMaterialityIROsScorePotentialNegative',
          label: t('Negative potential impacts'),
          isActive: getInitialStatus('doubleMaterialityIROsScorePotentialNegative') === 'current',
        },
        {
          name: 'doubleMaterialityIROsScoreActualPositive',
          label: t('Positive actual impacts'),
          isActive: getInitialStatus('doubleMaterialityIROsScoreActualPositive') === 'current',
        },
        {
          name: 'doubleMaterialityIROsScorePotentialPositive',
          label: t('Positive potential impacts'),
          isActive: getInitialStatus('doubleMaterialityIROsScorePotentialPositive') === 'current',
        },
        {
          name: 'doubleMaterialityIROsScoreRisksAndOpportunities',
          label: t('Risks and opportunities'),
          isActive: getInitialStatus('doubleMaterialityIROsScoreRisksAndOpportunities') === 'current',
        },
      ],
    },
    {
      name: 'doubleMaterialityStakeholders',
      label: t('Stakeholders'),
      status: getInitialStatus('doubleMaterialityStakeholders'),
      hasActiveChild: false,
      children: [],
    },
    {
      name: 'doubleMaterialityResults',
      label: t('Results'),
      status: getInitialStatus('doubleMaterialityResults'),
      hasActiveChild: false,
      children: [],
    },
  ];

  // Because routes are ordered sequentially, item after the item with status set to "current" gets its status set to "next".
  // Items after "next" item are set to "future".
  // Item has "hasActiveChild" set to true in case one child route is currently active.
  for (let index = 0; index < items.length; index += 1) {
    if (items[index].status === 'current' && index < items.length - 1) {
      for (let bar = index + 1; bar < items.length; bar += 1) {
        items[bar].status = bar === index + 1 ? 'next' : 'future';
      }
    }

    items[index].hasActiveChild = items[index].children.length > 0 ? items[index].children.some((child) => child.isActive) : false;
  }

  return items;
});
</script>

<template>
  <div class="flex flex-col min-h-full">
    <AtHeading type="h1" class="pt-5 px-6 mb-2">
      {{ headerData.title }}
    </AtHeading>
    <p class="px-6 text-gray-500 mb-6">
      {{ headerData.description }}
    </p>
    <div class="flex-1 flex space-x-8 px-6">
      <div class="flex-0">
        <AtProgressNav :items="progressItems" />
      </div>
      <div class="flex-1 flex flex-col">
        <router-view v-if="isReady" />
      </div>
    </div>
  </div>
</template>
