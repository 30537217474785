<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import { type PgTaxonomyAssessmentQuery, TaxonomyAssessmentStatusEnum } from '@/__generated__/types';
import type { TPartialRepositoryFile } from '@/components/molecules/MlFilePicker/types';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import useUpdateTaxonomyAssessment from '@/api/mutations/Taxonomy/updateTaxonomyAssessment.mutation';
import useUpdateTaxonomyAssessmentBusinessActivity from '@/api/mutations/Taxonomy/updateTaxonomyAssessmentBusinessActivity.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInfoBox from '@/components/atoms/AtInfoBox/AtInfoBox.vue';
import AtYesNoInput from '@/components/atoms/AtYesNoInput.vue';
import OgDocumentComment from '../OgDocumentComment.vue';
import OgActivityMenu from './OgActivityMenu.vue';

type MSFormData = Record<string, {
  value: boolean | null;
  file: TPartialRepositoryFile | null;
  comment: string;
}>;

const props = defineProps<{
  assessment: PgTaxonomyAssessmentQuery['getTaxonomyAssessment'];
}>();

const { t } = useI18n();
const router = useRouter();
const { confirmViaDialog } = useConfirmViaDialog();

const { mutate: updateTaxonomyAssessment } = useUpdateTaxonomyAssessment();
const { mutate: updateTaxonomyAssessmentBusinessActivity } = useUpdateTaxonomyAssessmentBusinessActivity();

const isSaving = ref(false);
const formData = reactive<MSFormData>({});

watch(() => props.assessment.businessActivities, (newBusinessActivities) => {
  if (Object.keys(formData).length === 0) {
    newBusinessActivities
      .filter((businessActivity) => (['circular', 'adaptation', 'mitigation', 'pollution', 'biodiversity', 'water'] as const).every((key) => !!businessActivity.doesNotHarm[key]))
      .forEach((businessActivity) => {
        formData[businessActivity._id] = {
          value: businessActivity.minimumStandardsRespected ?? null,
          file: businessActivity.minimumStandardsRespectedDocument?.file ?? null,
          comment: businessActivity.minimumStandardsRespectedComment ?? '',
        };
      });
  }
}, { immediate: true });

const items = computed(() => props
  .assessment
  .businessActivities
  .filter((businessActivity) => (['circular', 'adaptation', 'mitigation', 'pollution', 'biodiversity', 'water'] as const).every((key) => !!businessActivity.doesNotHarm[key]))
  .map((businessActivity) => ({
    _id: businessActivity._id,
    businessActivity,
  })));

function handleBackClick() {
  router.push({ name: 'taxonomyAssessmentDoNoSignificantHarmChecklist' });
}

async function handleContinueClick() {
  let isConfirmed: boolean;

  const areAllAnswered = Object.keys(formData).every((key) => formData[key].value === true);

  if (!areAllAnswered) {
    isConfirmed = await confirmViaDialog({
      title: t('You failed to confirm adherence to minimum safeguards'),
      text: `<p>${t('In this activity, you did not confirm that all activities adhere to minimum safeguards set out under UNGP, OECD recommendations and ILO conventions. This means that such activity (or activities) will no longer be deemed aligned with the EU-Taxonomy assessment and we will remove it from the next steps of the process.')}</p><br /><p>${t('Are you sure you want to proceed without confirming the conditions?')}</p>`,
      renderHTML: true,
      confirmLabel: t('Yes, proceed'),
      cancelLabel: t('No, go back'),
      titleClass: 'text-rose-600',
      modalClass: 'bg-rose-50',
    });
  } else {
    isConfirmed = true;
  }

  if (isConfirmed) {
    try {
      isSaving.value = true;

      for await (const [key, value] of Object.entries(formData)) {
        await updateTaxonomyAssessmentBusinessActivity({
          assessmentId: props.assessment._id,
          businessActivityId: key,
          input: {
            minimumStandardsRespected: value.value ?? false,
            minimumStandardsRespectedComment: value.comment || null,
            minimumStandardsRespectedFileId: value.file?._id ?? null,
          },
        });
      }

      const result = await updateTaxonomyAssessment({
        assessmentId: props.assessment._id,
        input: {
          status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_DOCUMENTS,
        },
      }, { update: (store) => { store.evict({ fieldName: 'getTaxonomyProjects' }); store.evict({ fieldName: 'getTaxonomyAssessment' }); } });

      const isAssessmentCompleted = !result?.data?.updateTaxonomyAssessment
        .businessActivities.some((activity) => activity.minimumStandardsRespected);

      if (isAssessmentCompleted) {
        await updateTaxonomyAssessment({
          assessmentId: props.assessment._id,
          input: {
            status: TaxonomyAssessmentStatusEnum.TAXONOMY_ASSESSMENT_COMPLETED,
          },
        }, { update: (store) => { store.evict({ fieldName: 'getTaxonomyProjects' }); store.evict({ fieldName: 'getTaxonomyAssessment' }); } });
      }
      router.push({ name: 'taxonomyAssessmentDocuments' });
    } catch {
      isSaving.value = false;

      notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
    }
  }
}
</script>

<template>
  <div>
    <p class="font-semibold mb-4">
      {{ t('Are the minimum safeguards related to UNGP, OECD recommendations and ILO conventions respected?') }}
    </p>
    <p class="mb-4">
      {{ t('Please confirm compliance for each of the activities by ticking the “Yes” box next to them.') }}
    </p>
    <div class="mb-16">
      <AtInfoBox>
        <p class="mb-1 font-semibold text-sm">
          {{ t('Minimum safeguards') }}
        </p>
        <p class="font-sm">
          <i18n-t keypath="According to Article 18 of the Taxonomy Regulation (EU) 2020/852, the minimum safeguards referred to in {link} shall be procedures implemented by an undertaking that is carrying out an economic activity to ensure the alignment with the OECD Guidelines for Multinational Enterprises and the UN Guiding Principles on Business and Human Rights, including the principles and rights set out in the eight fundamental conventions identified in the Declaration of the International Labour Organisation on Fundamental Principles and Rights at Work and the International Bill of Human Rights.">
            <template #link>
              <a
                href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex:32020R0852"
                rel="noopener noreferrer"
                target="_blank"
                class="underline"
              >{{ t('point (c) of Article 3') }}</a>
            </template>
          </i18n-t>
        </p>
      </AtInfoBox>
    </div>
    <div>
      <div
        v-for="item of items"
        :key="item._id"
        class="grid grid-cols-10 border-b"
      >
        <div class="col-span-8 pl-10">
          <div class="py-4 border-b group-last:border-b-0">
            <p class="mb-3 text-sm">
              {{ item.businessActivity.activity.number }}: {{ t(item.businessActivity.activity.name) }}
            </p>
            <OgDocumentComment
              v-model:comment="formData[item._id].comment"
              v-model:file="formData[item._id].file"
              showRecentFiles
            />
          </div>
        </div>
        <div class="border-b group-last:border-b-0">
          <div class="flex items-center justify-center space-x-6">
            <AtYesNoInput v-model="formData[item._id].value" />
          </div>
        </div>
        <div class="border-b group-last:border-b-0 pt-3 flex justify-center">
          <OgActivityMenu
            :assessmentId="assessment._id"
            :businessActivity="item.businessActivity"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-between pt-10">
    <AtButton type="button" :disabled="isSaving" variant="outline" @click="handleBackClick">
      {{ t('Back') }}
    </AtButton>

    <AtButton type="button" :loading="isSaving" @click="handleContinueClick">
      {{ t('Continue') }}
    </AtButton>
  </div>
</template>
