import dayjs from '@/lib/dayjs/config';
import { i18n } from '@/lib/i18n';

const { locale } = i18n.global;

export function switchTranslation(lang: typeof locale.value) {
  locale.value = lang;
  window.localStorage.setItem('codioImpactLang', lang);
  dayjs.locale(lang);
}
