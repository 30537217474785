import { gql } from '@apollo/client/core';

export default gql`
  query TmChartView($adminMode: Boolean!, $type: String) {
    isAnyDataPointApprovedAndNumeric
    getOwnUser {
      _id
      pinnedDataPointTypes {
        _id
        entity {
          _id
        }
        dataPointType {
          _id
          name
          friendlyName
          defaultChartType
          valueUnit
          valueUnitDivisor
          valueDataType
          summarizingMethod
          reportingFrameworks {
            _id
            framework
            groups
          }
        }
      }
      entity {
        _id
        kpis {
          _id
          startingDate
          comparisonValue
          comparisonValueType
          comparisonOperator
          dueDate
          dataPointType {
            _id
          }
        }
        locations {
          _id
          name
        }
      }
    }
    
    getCurrentUserEntities @include(if: $adminMode){
      _id
      name
    }

    getPinnedDataPoints @skip(if: $adminMode) {
      _id
      value
      displayValue
      displayValueUnit
      from
      to

      entity {
        _id
        name
      }

      location {
        _id
        name
      }

      dataPointType {
        _id
        name
        friendlyName
        valueUnit
        valueDataType
        summarizingMethod
        activeReportingFramework {
          _id
          category
          subcategory
          framework
          groups
        }

        reportingFrameworks {
          _id
          framework
          groups
          category
          subcategory
        }
      }
    }

    getPinnedDataPointsAdmin(type: $type) @include(if: $adminMode) {
      value
      displayValue
      displayValueUnit
      from
      to

      entity {
        _id
        name
      }

      location {
        _id
        name
      }

      dataPointType {
        _id
        name
        friendlyName
        valueUnit
        valueDataType
        summarizingMethod
        activeReportingFramework {
          _id
          category
          subcategory
          framework
          groups
        }

        reportingFrameworks {
          _id
          framework
          groups
          category
          subcategory
        }
      }
    }
  }
`;
