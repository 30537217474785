<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import MlTabs from '@/components/molecules/MlTabs/MlTabs.vue';
import AtTooltipIcon from '@/components/atoms/AtTooltipIcon.vue';
import OgRestrictionsLanding from '@/components/organisms/OgRestrictionsLanding.vue';
import useRestrictions from '@/utils/composables/useRestrictions/useRestrictions';
import { RestrictionsFeaturesEnum } from '@/__generated__/types';

const { t, locale } = useI18n();
const route = useRoute();
const router = useRouter();

const tabs = computed(() => ([
  { name: t('Overview'), value: 'taxonomyOverview' },
  { name: t('Projects'), value: 'taxonomyProjects' },
  { name: t('Results'), value: 'taxonomyResults' },
  { name: t('Data room'), value: 'taxonomyDataRoom' },
]));

const {
  restrictions,
  loading: loadingRestrictions,
} = useRestrictions();

</script>

<template>
  <template v-if="restrictions?.restrictedFeatures.includes(RestrictionsFeaturesEnum.TAXONOMY) && !loadingRestrictions">
    <OgRestrictionsLanding>
      <template #pageTitle>
        {{ t('EU Taxonomy') }}
      </template>
      <template #title>
        {{ t('Measure and improve your EU Taxonomy score') }}
      </template>
      <template #text>
        {{ t('Calculate your Taxonomy-eligible and -aligned turnover, CapEx and OpEx per business activity. You can involve as many teams as you need through our extensive collaboration functionalities.') }}
      </template>
      <template #image>
        <img
          v-if="locale === 'de'"
          alt="Taxonomie"
          src="@/assets/images/taxonomy-de.png"
        />
        <img
          v-else
          alt="Taxonomy"
          src="@/assets/images/taxonomy.png"
        >
      </template>
    </OgRestrictionsLanding>
  </template>
  <div v-else>
    <section class="pt-5 px-6">
      <div>
        <h2 class="text-xl font-medium text-gray-900">
          {{ t('EU Taxonomy') }}
        </h2>
        <p class="mt-1 text-sm text-gray-500">
          {{ t('Calculate your Taxonomy-eligible and -aligned KPIs per business activity.') }}

          <AtTooltipIcon
            :triggers="['click', 'touch']"
            :delay="0"
            autoHide
            class="cursor-pointer"
            :tooltip="t('The EU Taxonomy is a framework to classify sustainable economic activities. This assessment is based on the Taxonomy Regulation - 2020/852/EU.')"
            @click.stop
          />
        </p>
      </div>

      <MlTabs
        class="mt-5"
        :modelValue="String(route.name)"
        :tabs="tabs"
        isEdgeToEdge
        @update:modelValue="router.push({ name: $event.value, query: $event.query })"
      />

      <section class="mt-5">
        <router-view />
      </section>
    </section>
  </div>
</template>
