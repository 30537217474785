import { createI18n } from 'vue-i18n';
import deLong from './deLong.json';
import de from './de.json';
import en from './en.json';

export const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('codioImpactLang') ?? (typeof navigator !== 'undefined' && navigator.language === 'de-DE' ? 'de' : 'en'),
  fallbackLocale: 'en',
  fallbackWarn: true,
  missingWarn: true,
  formatFallbackMessages: true,
  silentTranslationWarn: false,
  messages: { de: { ...de, ...deLong }, en },
});
