<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import isNil from 'lodash/isNil';
import MlModal from '@/components/molecules/MlModal.vue';
import MlTextarea from '@/components/molecules/MlTextarea.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useUpdateEntityKPIMutation from '@/api/mutations/EntityKPI/updateEntityKpi.mutation';
import useCreateEntityCommentKPIMutation from '@/api/mutations/EntityKPI/createEntityKpiComment.mutation';
import PG_KPI_QUERY from '../PgKPI/PgKPI.query';
import type { TKpiItem } from '../types';
import AtCreateKPIModalTitle from './AtCreateKPIModalTitle.vue';

const props = defineProps<{
  isRevealed: boolean,
  kpi: TKpiItem,
  targetId: string,
  goalId: string,
}>();

const emit = defineEmits<{
  (e: 'update:isRevealed', isRevealed: boolean): void
  (e: 'updated'): void
}>();

const kpiQueryVariables = {
  goal: props.goalId,
  target: props.targetId,
  kpi: props.kpi.kpi._id,
};

const { mutate: updateKPI, loading: updateKpiLoading } = useUpdateEntityKPIMutation();
const { mutate: createComment, loading: createCommentLoading } = useCreateEntityCommentKPIMutation({
  refetchQueries: [{ query: PG_KPI_QUERY, variables: kpiQueryVariables }],
});

const startingValue = ref(`${props.kpi.kpi.startingValue}`);

const status = ref(props.kpi.kpi.status);
const comment = ref('');

const { t } = useI18n();

const handleUpdateKPI = async () => {
  try {
    await updateKPI({
      updateEntityKPIInput: {
        _id: props.kpi.kpi._id,
        startingValue: Number(startingValue.value),
        status: status.value,
      },
    });

    if (comment.value.trim()) {
      await createComment({
        createEntityKPICommentInput: {
          entityKPI: props.kpi.kpi._id,
          text: comment.value.trim(),
        },
      });
    }

    notify({ type: 'success', text: t('KPI has been updated.') });
  } catch {
    notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
  }
};
</script>

<template>
  <MlModal
    class="!w-[90%] !max-w-[53rem] p-8"
    :overlayDismiss="false"
    :isRevealed="props.isRevealed"
    @update:isRevealed="emit('update:isRevealed', $event)"
  >
    <AtCreateKPIModalTitle
      :title="t('Update KPI')"
      :subtitle="t('Write a status update and add information about the KPI’s progress.')"
    />

    <p class="mt-4 text-sm font-semibold">
      {{ t(props.kpi.kpi?.dataPointType.friendlyName ?? '') }}
    </p>
    <p class="mt-1 text-sm text-info">
      {{ t(props.kpi.kpi?.dataPointType.question ?? '') }}
    </p>

    <MlTextarea
      v-model="comment"
      wrapperClass="mt-4"
      :label="t('What’s new? (optional, will be displayed in the comments section)')"
      :placeholder="t('Write your text here.')"
    />

    <div class="flex mt-40">
      <AtButton
        class="ml-auto"
        :loading="updateKpiLoading || createCommentLoading"
        :disabled="isNil(startingValue)"
        @click="handleUpdateKPI"
      >
        {{ t('Save') }}
      </AtButton>
    </div>
  </MlModal>
</template>
