import { gql } from '@apollo/client/core';

export default gql`
  query PgExternalDataEntry($emailAddress: String!, $shareToken: String!, $statusFilter: DataPointRequestStatusEnum) {
    getCurrentExternalUserId(emailAddress: $emailAddress, shareToken: $shareToken)

    getDelegatedDataPointRequests(emailAddress: $emailAddress, shareToken: $shareToken, statusFilter: $statusFilter) {
      _id
      
      parent {
        _id
      }

      ...dprFieldsExternal
      ...childsExternal
    }
  }
  
  fragment childsExternal on DataPointRequest {
    _id
    
    childs {
      _id
      
      ...dprFieldsExternal
      
      childs {
        _id
        
        ...dprFieldsExternal
      }
    }
  }
  
  fragment dprFieldsExternal on DataPointRequest {
    _id
    dueDate
    from
    to
    value
    comment
    status
    originalValueUnit
    originalValue
    isHistoric
    isReopenRequestPending
    updatedAt

    override {
      _id
      datapointtype {
        _id
        order
      }
      refreshInterval
      valueUnit
      isSplit
      emissionFactors {
        _id
        year
        factor
      }
    }
    
    displayStatus
    
    valueSource {
      name
      originalValue
      originalValueUnit
    }

    file {
      _id
      filename
    }

    entity {
      _id
    }

    location {
      _id
      name
    }
    
    dataPointTypeFlat {
      category
      subcategory
    }

    delegations {
      _id
    }
    
    rejectedAt
    rejectReason
    rejectedBy {
      _id
      firstName
      lastName
    }

    dataPointType {
      _id
      allowUploadProof
      question
      questionHelp
      valueUnit
      valueUnitDivisor
      valueDataType
      valueDataTypeOptions
      emissionSubcategory
      order

      
      refreshInterval
      # activeReportingFramework {
      #   framework
      #   groups
      # }
      reportingFrameworks {
        _id
        framework
        groups
      }
      emissionFactors {
        _id
        year
        factor
      }

      calculatedDataPointTypes {
        _id
        name
        friendlyName
        formular
        formularDependencies {
          _id
          name
          friendlyName
        }
      }
    }
  }
`;
