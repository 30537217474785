import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  ResetDataPointRequestMutation,
  ResetDataPointRequestMutationVariables,
} from '@/__generated__/types';

export const RESET_DATAPOINT_REQUEST = gql`
  mutation ResetDataPointRequest($dataPointRequestId: String!) {
    resetDataPointRequest(dataPointRequestId: $dataPointRequestId) {
      _id
      status
      value
      displayStatus
      originalValue
      valueSource {
        name
        originalValue
        originalValueUnit
        emissionFactor
        emissionFactorCountry
      }
    }
  }
`;

type TOptions = UseMutationOptions<ResetDataPointRequestMutation, ResetDataPointRequestMutationVariables>

const useResetDataPointRequestMutation = (options?: TOptions) => useMutation<
  ResetDataPointRequestMutation,
  ResetDataPointRequestMutationVariables
>(RESET_DATAPOINT_REQUEST, options);

export default useResetDataPointRequestMutation;
