<script setup lang="ts">
import { type LanguageType, NuvoImporter, type OnResults, type SettingsAPI } from 'nuvo-vuejs';
import useUploadQuestionsJsonMutation from '@/api/mutations/CustomQuestionnaire/uploadQuestionsJson.mutation';

type Props = {
  customQuestionnaireId: string;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  uploaded: [],
}>();

const { mutate: addQuestionsJson } = useUploadQuestionsJsonMutation();

const settings: SettingsAPI = {
  developerMode: window.location.hostname !== 'app.codioimpact.com',
  identifier: 'questions',
  modal: false,
  language: localStorage.getItem('codioImpactLang') as LanguageType ?? 'en',
  columns:
    [
      {
        key: 'name',
        label: 'Name of DataPoint',
        description: "This should be a descriptive name of the datapoint. Given the question is 'specify the number of cars in your company', the name could be 'Company cars'",
        example: 'Screening for IROs water and marine resources',
        columnType: 'string',
      },
      {
        key: 'question',
        label: 'Question',
        description: 'This is the actual question that the user will answer',
        example: 'How many company cars do you have?',
        columnType: 'string',
        // validations: [
        //   {
        //     validate: 'required',
        //     errorMessage: 'A question is required',
        //   },
        // ],
      },
      {
        key: 'type',
        label: 'Question type',
        description: 'Type of question',
        columnType: 'category',
        dropdownOptions: [
          {
            label: 'Number',
            value: 'numeric',
            type: 'string',
          },
          {
            label: 'Long text (more than a sentence)',
            value: 'text_long',
            type: 'string',
          },
          {
            label: 'Short text (words until max 1 sentence)',
            value: 'text',
            type: 'string',
          },
          {
            label: 'Multiple numbers',
            value: 'numeric_split',
            type: 'string',
          },
          {
            label: 'Multiple Choice',
            value: 'multiple_choice',
            type: 'string',
          },
          {
            label: 'Choice',
            value: 'choice',
            type: 'string',
          },
        ],
        isMultiSelect: false,
      },
      {
        key: 'choices',
        label: 'Choices',
        description: "Only required if 'choice' or 'multiple_choice' is used as question type",
        columnType: 'string',
      },
      {
        key: 'questionHelp',
        label: 'Help text',
        description: 'An optional help text describing the question',
        columnType: 'string',
      },
    ] };

const nuvoLicenseKey = import.meta.env.VITE_NUVO_LICENSE;

const onResults: OnResults = async (results, errors, complete) => {
  const newRows = [];

  let lastQuestionIndex = -1;
  for (const row of results) {
    if (row.question) {
      newRows.push(row);
      lastQuestionIndex = newRows.length - 1;
    }

    if (!row.question && row.choices && lastQuestionIndex >= 0) {
      newRows[lastQuestionIndex].type = 'choice';

      if (!newRows[lastQuestionIndex].choices) {
        newRows[lastQuestionIndex].choices = '';
        newRows[lastQuestionIndex].questionHelp = '';
      } else {
        newRows[lastQuestionIndex].choices += '###SEPARATOR###';
        newRows[lastQuestionIndex].questionHelp += '\r\n\r\n';
      }

      newRows[lastQuestionIndex].choices += `${row.choices}`;
      newRows[lastQuestionIndex].questionHelp += `${row.questionHelp}`;
    }
  }

  const data = [];

  for (const row of newRows) {
    const choices = (row.choices as string)?.split('###SEPARATOR###') ?? [];

    data.push({
      ...row,
      type: choices.length ? 'multiple_choice' : row.type || 'text_long',
      choices,
      description: row.questionHelp,
    });
  }

  await addQuestionsJson({ customQuestionnaireId: props.customQuestionnaireId, questions: JSON.stringify(data) }, {
    update: (store) => {
      store.evict({
        fieldName: 'getCustomQuestionnaire',
      });
    },
  });

  emit('uploaded');

  if (complete) {
    complete();
  }
};

</script>

<template>
  <NuvoImporter
    :settings="settings"
    :licenseKey="nuvoLicenseKey"
    :onResults="onResults"
  />
</template>
