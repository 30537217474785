import { computed, ref, watch, inject } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import type { Dayjs } from '@/lib/dayjs/config';
import type {
  UseReportingPeriodQuery,
  UseReportingPeriodQueryVariables,
} from '@/__generated__/types';
import dayjs from '@/lib/dayjs/config';
import USE_REPORTING_PERIOD_QUERY from './useReportingPeriod.query';

export type DateRange = {
  from: Dayjs,
  to: Dayjs,
}

const defaultEntityReportingPeriod: { from: Dayjs, to: Dayjs } = {
  from: dayjs(`${dayjs().year() - 1}-01-01T00:00:00-00:00`, 'YYYY-MM-DDTHH:mm:ssZ'),
  to: dayjs(`${dayjs().year() - 1}-12-31T00:00:00-00:00`, 'YYYY-MM-DDTHH:mm:ssZ'),
};
const beDateFormat = 'YYYY-MM-DD HH:mm:ss';
const storageDateFormat = 'YYYY-MM-DD 00:00:00';

const storage = window.localStorage;

export function resetTime(date: Dayjs) {
  return date.utc().hour(0).minute(0).second(0).millisecond(0);
}

function setup() {
  const storageFrom = storage.getItem('reportingPeriodDateFrom');
  const storageTo = storage.getItem('reportingPeriodDateTo');
  const defaultDateFrom = defaultEntityReportingPeriod.from.clone();
  const defaultDateTo = defaultEntityReportingPeriod.to.clone();

  let dateFrom = dayjs.utc(storageFrom, storageDateFormat, true);
  if (!storageFrom || !dateFrom.isValid()) {
    dateFrom = defaultDateFrom.clone();
    storage.setItem('reportingPeriodDateFrom', dateFrom.format(storageDateFormat));
  }

  let dateTo = dayjs.utc(storageTo, storageDateFormat, true);
  if (!storageFrom || !dateTo.isValid() || dateTo.isSameOrBefore(dateFrom, 'day')) {
    dateTo = defaultDateTo.clone();
    storage.setItem('reportingPeriodDateTo', dateTo.format(storageDateFormat));
  }

  return {
    from: dateFrom,
    to: dateTo,
  };
}

export function formatDateRangeForBE(dateFrom: Dayjs | Date | string, dateTo: Dayjs | Date | string) {
  /**
   * Example:
   * For arguments
   *   dateFrom = dayjs('2022-01-01')
   *   dateTo = dayjs('2022-12-31')
   * returned value will be
   *   {
   *     dateFrom: '2022-01-01 00:00:00',
   *     dateTo: '2023-01-01 00:00:00',
   *   }
   */
  return {
    dateFrom: dayjs(dateFrom).format(beDateFormat),
    // Whole dateTo is included into the range. That is why 1 day is added as well.
    dateTo: dayjs(dateTo).add(1, 'day').format(beDateFormat),
  };
}

export function useReportingPeriod() {
  const isExternal = inject('isExternal', computed(() => false));

  const initialValues = setup();
  const dateFrom = ref<DateRange['from']>(initialValues.from);
  const dateTo = ref<DateRange['to']>(initialValues.to);
  const dateRange = computed<DateRange>({
    get() {
      return {
        from: dateFrom.value,
        to: dateTo.value,
      };
    },
    set(newValue) {
      dateFrom.value = resetTime(newValue.from);
      dateTo.value = resetTime(newValue.to);
    },
  });

  watch(dateFrom, (newValue) => {
    storage.setItem('reportingPeriodDateFrom', newValue.format(beDateFormat));
  });
  watch(dateTo, (newValue) => {
    storage.setItem('reportingPeriodDateTo', newValue.format(beDateFormat));
  });

  const formatForBE = () => formatDateRangeForBE(dateRange.value.from, dateRange.value.to);

  const { result } = useQuery<UseReportingPeriodQuery, UseReportingPeriodQueryVariables>(USE_REPORTING_PERIOD_QUERY, {
    isExternal: isExternal.value,
  });
  const entityReportingPeriod = computed(() => result.value?.getOwnUser?.entity.entitySettings.activeReportingPeriod);
  watch(entityReportingPeriod, () => {
    // Update only if reporting period isn't already saved in the storage.
    if (entityReportingPeriod.value && (!storage.getItem('reportingPeriodDateFrom') || !storage.getItem('reportingPeriodDateTo'))) {
      dateRange.value = {
        from: entityReportingPeriod.value.from,
        to: entityReportingPeriod.value.to,
      };
    }
  });

  return {
    dateRange,
    formatForBE,
  };
}
