<script setup lang="ts">
import dayjs from 'dayjs/esm';
import { computed, ref } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import {
  type PgDataRoomFiltersQuery,
  type PgDataRoomQuery,
  type PgDataRoomQueryVariables, TaxonomyDocumentCategoryEnum,
} from '@/__generated__/types';
import folderImg from '@/assets/folder.svg';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import OgServerDataTable from '@/components/organisms/OgDataTable/OgServerDataTable.vue';
import type {
  FilterBy,
  ServerPaginationOptions,
  ServerSortOptions,
  TServerDataTableHeader,
} from '@/components/organisms/OgDataTable/types';
import { getCategoryName, getFilterBy, getLimit, getSortOrder } from './utils';
import PG_DATA_ROOM_QUERY from './PgDataRoom.query';
import PG_DATA_ROOM_FILTERS_QUERY from './PgDataRoomFilters.query';

type TableItem = {
  category: string;
  addedBy: string;
  project: string;
  date: string;
  file: {
    filename: string;
    downloadUrl: string;
  };
}

const { t } = useI18n();

const { loading: filtersLoading, result: filtersResult } = useQuery<PgDataRoomFiltersQuery>(PG_DATA_ROOM_FILTERS_QUERY);

const sortOptions = ref<ServerSortOptions>({
  sortBy: undefined,
  sortType: undefined,
});
const paginationOptions = ref<ServerPaginationOptions>({
  page: 1,
  rowsPerPage: 25,
});
const filterBy = ref<FilterBy>([{ field: '', criteria: [] }]);

const queryVariables = computed<PgDataRoomQueryVariables>(() => ({
  offset: (paginationOptions.value.page - 1) * paginationOptions.value.rowsPerPage,
  limit: getLimit(paginationOptions.value.rowsPerPage),
  sortBy: sortOptions.value.sortBy || '',
  sortOrder: getSortOrder(sortOptions.value.sortType),
  filterBy: getFilterBy(filterBy.value),
}));

const { result, loading: queryLoading } = useQuery<PgDataRoomQuery, PgDataRoomQueryVariables>(
  PG_DATA_ROOM_QUERY,
  queryVariables,
  {
    fetchPolicy: 'network-only',
  },
);

const isLoading = computed(() => queryLoading.value || filtersLoading.value);

const documents = computed(() => result.value?.getTaxonomyDocuments.items || []);

const items = computed<TableItem[]>(() => documents.value.map((item) => ({
  dataPoint: item.dataPoint ? t(item.dataPoint.name) : '',
  category: t(getCategoryName(item.category)),
  // eslint-disable-next-line no-nested-ternary
  addedBy: item.addedBy ? (item.addedBy.firstName ? `${item.addedBy.firstName} ${item.addedBy.lastName}` : item.addedBy.email) : '-',
  date: dayjs(item.updatedAt).format(' MMM, YYYY'),
  project: item.project?.name ?? '-',
  file: {
    filename: item.file.filename,
    downloadUrl: item.file.downloadUrl,
  },
})));

const showEmptyStateCard = computed(() => !isLoading.value && items.value.length === 0);

const headers = computed<TServerDataTableHeader[]>(() => [
  {
    text: t('Datapoint'),
    value: 'dataPoint',
    sortable: true,
    filterable: true,
    filterOptions: filtersResult.value?.getTaxonomyDocumentsFilters.dataPoints.reduce<Record<string, string>>((acc, dataPoint) => {
      acc[dataPoint._id] = dataPoint.name;
      return acc;
    }, {}) || {},
  },
  {
    text: t('Category'),
    value: 'category',
    sortable: true,
    filterable: true,
    filterOptions: {
      [TaxonomyDocumentCategoryEnum.CORE_DATA]: t(getCategoryName(TaxonomyDocumentCategoryEnum.CORE_DATA)),
      [TaxonomyDocumentCategoryEnum.DNSH]: t(getCategoryName(TaxonomyDocumentCategoryEnum.DNSH)),
      [TaxonomyDocumentCategoryEnum.GENERAL]: t(getCategoryName(TaxonomyDocumentCategoryEnum.GENERAL)),
      [TaxonomyDocumentCategoryEnum.MINIMUM_SAFEGUARDS]: t(getCategoryName(TaxonomyDocumentCategoryEnum.MINIMUM_SAFEGUARDS)),
      [TaxonomyDocumentCategoryEnum.SUBSTANTIAL_CONTRIBUTION]: t(getCategoryName(TaxonomyDocumentCategoryEnum.SUBSTANTIAL_CONTRIBUTION)),
    },
  },
  {
    text: t('Added by'),
    value: 'addedBy',
    sortable: true,
    filterable: true,
    filterOptions: filtersResult.value?.getTaxonomyDocumentsFilters.addedBy.reduce<Record<string, string>>((acc, addedBy) => {
      acc[addedBy._id] = `${addedBy.firstName} ${addedBy.lastName}`;
      return acc;
    }, {}) || {},
  },
  {
    text: t('Project'),
    value: 'project',
    sortable: true,
    filterable: true,
    filterOptions: filtersResult.value?.getTaxonomyDocumentsFilters.projects.reduce<Record<string, string>>((acc, project) => {
      acc[project._id] = project.name;
      return acc;
    }, {}) || {},
  },
  {
    text: t('Date'),
    value: 'date',
    sortable: true,
    filterable: true,
    filterOptions: filtersResult.value?.getTaxonomyDocumentsFilters.dates.reduce<Record<string, string>>((acc, item) => {
      acc[JSON.stringify({ from: item.from, to: item.to })] = dayjs(item.from).format('MMM, YYYY');
      return acc;
    }, {}) || {},
  },
  {
    text: t('Download'),
    value: 'file',
    sortable: false,
    filterable: false,
  },
]);
</script>

<template>
  <MlEmptyStateCard
    v-if="showEmptyStateCard"
    :title="t('No documents available yet')"
    noButton
  >
    <img
      class="mb-7"
      :src="folderImg"
      alt="Graph"
    >
  </MlEmptyStateCard>
  <OgServerDataTable
    v-else
    v-model:sortOptions="sortOptions"
    v-model:paginationOptions="paginationOptions"
    v-model:filterBy="filterBy"
    :headers="headers"
    :items="items"
    :serverItemsLength="result?.getTaxonomyDocuments.totalCount || 0"
    :loading="isLoading"
  >
    <template #item-file="row: TableItem">
      <a
        :href="row.file.downloadUrl"
        class="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
        rel="noopener noreferrer"
        target="_blank"
      >
        {{ row.file.filename }}
      </a>
    </template>
  </OgServerDataTable>
</template>
