export const getSuggestedSubcategories = (selectedIndustry: string) => {
  switch (selectedIndustry) {
    case 'agriculture_agriculture_and_farming_forestry':
      return ['emissions', 'waste', 'diversity', 'employment', 'health_and_safety', 'suppliers_and_customers'];
    case 'construction_construction_engineering':
      return ['emissions', 'energy', 'materials', 'waste', 'water', 'diversity', 'health_and_safety', 'training_and_education',
        'ethical_behaviour'];
    case 'energy_energy_production_utilities':
      return ['emissions', 'energy', 'diversity', 'health_and_safety', 'training_and_education', 'compliance'];
    case 'financial_institutions_financial_services_capital_markets_banking_insurance':
      return ['emissions', 'diversity', 'health_and_safety', 'training_and_education', 'compliance', 'ethical_behaviour', 'organization', 'tax'];
    case 'health_care_services_healthcare':
      return ['energy', 'waste', 'employment', 'health_and_safety', 'local_communities', 'training_and_education', 'ethical_behaviour'];
    case 'hospitality_food_beverage_services_hotels_lodging_leisure_travel':
      return ['emissions', 'energy', 'waste', 'diversity', 'health_and_safety', 'suppliers_and_customers', 'training_and_education'];
    case 'manufacturing_aerospace_defence_automobiles_construction_materials_electronics_etc':
      return ['emissions', 'energy', 'materials', 'waste', 'water', 'diversity', 'health_and_safety', 'suppliers_and_customers',
        'training_and_education', 'organization'];
    case 'mining_coal_operations_mining_oil_gas':
      return ['emissions', 'waste', 'water', 'diversity', 'employment', 'health_and_safety', 'human_rights', 'local_communities',
        'ethical_behaviour'];
    case 'real_estate_real_estate_services':
      return ['emissions', 'energy', 'diversity', 'employment', 'ethical_behaviour'];
    case 'services_advertising_marketing_media_creative_industries_education_professional_commerical_services':
      return ['emissions', 'diversity', 'employment', 'suppliers_and_customers', 'ethical_behaviour'];
    case 'technology_media_communications_telecommunications':
      return ['emissions', 'waste', 'diversity', 'employment', 'training_and_education'];
    case 'transportation_transport_logistics':
      return ['emissions', 'energy', 'waste', 'health_and_safety', 'training_and_education'];
    case 'waste_management_utilities_water_waste_services':
      return ['emissions', 'energy', 'pollution', 'water', 'diversity', 'health_and_safety'];
    case 'wholesale_and_retail_wholesale_retail_trade_e_commerce':
      return ['emissions', 'waste', 'diversity', 'health_and_safety', 'suppliers_and_customers', 'training_and_education'];
    default: return [];
  }
};
