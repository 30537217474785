import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { AddEntityToUsersMutation, AddEntityToUsersMutationVariables } from '@/__generated__/types';

export const ADD_ENTITY_TO_USERS = gql`
  mutation AddEntityToUsers($usersIds: [String!]!, $entityId: String!) {
    addEntityToUsers(usersIds: $usersIds, entityId: $entityId) {
      _id
      email
      entities {
        _id
      }
    }
  }
`;

type TOptions = UseMutationOptions<AddEntityToUsersMutation, AddEntityToUsersMutationVariables>

const useAddEntityToUsersMutation = (options?: TOptions) => useMutation<
  AddEntityToUsersMutation,
  AddEntityToUsersMutationVariables
>(ADD_ENTITY_TO_USERS, options);

export default useAddEntityToUsersMutation;
