<script setup lang="ts">
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

onMounted(() => {
  localStorage.setItem('emailVerifiedCallback', 'OK');
  router.push({ name: 'logout' });
});
</script>

<template>
  <div />
</template>
