<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { TDataPointType } from '../../../types';

const props = withDefaults(defineProps<{
  modelValue?: TDataPointType[],
  options: TDataPointType[],
}>(), {
  modelValue: () => [],
});

const emit = defineEmits<{
  (e: 'update:modelValue', goals?: TDataPointType[]): void
}>();

const isChecked = (option: TDataPointType) => !!props.modelValue?.find(({ _id }) => _id === option._id);
const handleInput = (option: TDataPointType, i: number) => {
  let newModelValue = [...props.modelValue];

  if (isChecked(option)) {
    newModelValue = newModelValue.filter(({ _id }) => _id !== option._id);
  } else {
    newModelValue.splice(i, 0, option);
  }
  emit('update:modelValue', newModelValue);
};

const { t } = useI18n();
</script>

<template>
  <div class="divide-y">
    <label
      v-for="option, i in props.options"
      :key="option._id"
      class="grid grid-cols-[auto_1fr] items-center gap-x-6 gap-y-2 p-4 text-sm"
    >
      <input
        type="checkbox"
        class="checkbox-primary checkbox row-span-2"
        :checked="isChecked(option)"
        :value="option._id"
        @input="handleInput(option, i)"
      >
      <p>{{ t(option.friendlyName) }}</p>
      <p class="text-gray-500">
        {{ t(option.question) }}
      </p>
    </label>
  </div>
</template>
