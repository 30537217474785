<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import sum from 'lodash/sum';
import type { ChartOptions } from 'chart.js';
import chartImg from '@/assets/chart.svg';
import OgChart from '@/components/organisms/OgChart/OgChart.vue';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import type { TUniversalChartData } from '@/components/organisms/OgChart/types';
import { formatPercentage } from '../util';
import type { TaxonomyProject } from './types';

const { t } = useI18n();

const props = defineProps<Props>();
type Props = {
  taxonomyProjects: TaxonomyProject[];
  loading?: boolean
}

const eligibleTurnover = computed(() => sum(props.taxonomyProjects.map((project) => project.assessments[0].eligibleTurnover)));
const nonEligibleTurnover = computed(() => sum(props.taxonomyProjects.map((project) => project.assessments[0].nonEligibleTurnover)));
const turnover = computed(() => sum(props.taxonomyProjects.map((project) => project.assessments[0].turnover)));

const eligibleCapex = computed(() => sum(props.taxonomyProjects.map((project) => project.assessments[0].eligibleCapex)));
const nonEligibleCapex = computed(() => sum(props.taxonomyProjects.map((project) => project.assessments[0].nonEligibleCapex)));
const capex = computed(() => sum(props.taxonomyProjects.map((project) => project.assessments[0].capex)));

const eligibleOpex = computed(() => sum(props.taxonomyProjects.map((project) => project.assessments[0].eligibleOpex)));
const nonEligibleOpex = computed(() => sum(props.taxonomyProjects.map((project) => project.assessments[0].nonEligibleOpex)));
const opex = computed(() => sum(props.taxonomyProjects.map((project) => project.assessments[0].opex)));

const turnoverChartData = computed<TUniversalChartData>(() => ({
  datasets: [{
    backgroundColor: ['#FB923C', '#A8A29E'],
    data: [eligibleTurnover.value, nonEligibleTurnover.value],
  }],
  labels: [
    `${formatPercentage(eligibleTurnover.value, turnover.value)} ${t('Eligible')}`,
    `${formatPercentage(nonEligibleTurnover.value, turnover.value)} ${t('Not Eligible')}`,
  ],
}));

const capexChartData = computed<TUniversalChartData>(() => ({
  datasets: [{
    backgroundColor: ['#FB923C', '#A8A29E'],
    data: [eligibleCapex.value, nonEligibleCapex.value],
  }],
  labels: [
    `${formatPercentage(eligibleCapex.value, capex.value)} ${t('Eligible')}`,
    `${formatPercentage(nonEligibleCapex.value, capex.value)} ${t('Not Eligible')}`,
  ],
}));

const opexChartData = computed<TUniversalChartData>(() => ({
  datasets: [{
    backgroundColor: ['#FB923C', '#A8A29E'],
    data: [eligibleOpex.value, nonEligibleOpex.value],
  }],
  labels: [
    `${formatPercentage(eligibleOpex.value, opex.value)} ${t('Eligible')}`,
    `${formatPercentage(nonEligibleOpex.value, opex.value)} ${t('Not Eligible')}`,
  ],
}));

const chartOptions = computed<ChartOptions>(() => ({
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        usePointStyle: true,
        padding: 12,
      },
    },
    datalabels: {
      borderRadius: 3,
      display: false,
      color: ['#fff', '#fff'],
      backgroundColor: (context) => context?.dataset.backgroundColor as string,
    },
  },
}));

</script>

<template>
  <div>
    <h3 class="font-semibold leading-5 text-gray-900 mb-6">
      {{ t('Key statistics') }}
    </h3>
    <div class="flex flex-wrap gap-6 justify-evenly">
      <MlEmptyStateCard
        v-if="!eligibleTurnover && !nonEligibleTurnover && !loading"
        :title="t('Turnover')"
        :description="t('No data yet')"
        noButton
      >
        <img
          class="mb-7"
          :src="chartImg"
          alt="Graph"
        >
      </MlEmptyStateCard>
      <div
        v-else
        class="bg-gray-50 rounded-md max-w-xs"
      >
        <h4 class="p-4 pb-0 font-medium">
          {{ t('Turnover') }}
        </h4>
        <OgChart
          type="pie"
          :chartData="turnoverChartData"
          :chartOptions="chartOptions"
        />
      </div>
      <MlEmptyStateCard
        v-if="!eligibleCapex && !nonEligibleCapex && !loading"
        :title="t('Capex')"
        :description="t('No data yet')"
        noButton
      >
        <img
          class="mb-7"
          :src="chartImg"
          alt="Graph"
        >
      </MlEmptyStateCard>
      <div
        v-else
        class="bg-gray-50 rounded-md max-w-xs"
      >
        <h4 class="p-4 pb-0 font-medium">
          {{ t('Capex') }}
        </h4>
        <OgChart
          type="pie"
          :chartData="capexChartData"
          :chartOptions="chartOptions"
        />
      </div>
      <MlEmptyStateCard
        v-if="!eligibleOpex && !nonEligibleOpex && !loading"
        :title="t('Opex')"
        :description="t('No data yet')"
        noButton
      >
        <img
          class="mb-7"
          :src="chartImg"
          alt="Graph"
        >
      </MlEmptyStateCard>
      <div
        v-else
        class="bg-gray-50 rounded-md max-w-xs"
      >
        <h4 class="p-4 pb-0 font-medium">
          {{ t('Opex') }}
        </h4>
        <OgChart
          type="pie"
          :chartData="opexChartData"
          :chartOptions="chartOptions"
        />
      </div>
    </div>
  </div>
</template>
