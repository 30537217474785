import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import type { CreateCustomQuestionnaireMutation, CreateCustomQuestionnaireMutationVariables } from '@/__generated__/types';

export const CREATE_QUESTIONNAIRE = gql`
  mutation CreateCustomQuestionnaire($customQuestionnaireData: CreateCustomQuestionnaireInput!) {
    createCustomQuestionnaire(customQuestionnaireData: $customQuestionnaireData) {
      _id
    }
  }
`;

type Options = UseMutationOptions<CreateCustomQuestionnaireMutation, CreateCustomQuestionnaireMutationVariables>

const useCreateCustomQuestionnaireMutation = (options?: Options) => useMutation<
  CreateCustomQuestionnaireMutation,
  CreateCustomQuestionnaireMutationVariables
>(CREATE_QUESTIONNAIRE, options);

export default useCreateCustomQuestionnaireMutation;
