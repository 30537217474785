import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';

import type {
  UpdateTaxonomyAssessmentBusinessActivityMutation,
  UpdateTaxonomyAssessmentBusinessActivityMutationVariables,
} from '@/__generated__/types';

const UPDATE_TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITY_MUTATION = gql`
  mutation updateTaxonomyAssessmentBusinessActivity(
    $assessmentId: String!, 
    $businessActivityId: String!,
    $input: UpdateTaxonomyAssessmentBusinessActivityInput!
  ) {
    updateTaxonomyAssessmentBusinessActivity(assessmentId: $assessmentId, businessActivityId: $businessActivityId, input: $input) {
      _id
      
      activityTurnover
      activityCapex
      activityOpex
      
      activityFit
      objective
      isObjectiveAnswered
      objectiveComment
      objectiveDocument {
        _id
        file {
          _id
          filename
        }
      }
      
      doesNotHarm {
        _id
        water
        waterDocument {
          _id
          file {
            _id
            filename
          }
        }
        waterComment
        waterDelegation {
          _id
          user {
            _id
            firstName
            lastName
            email
          }
          shareToken
          objective
        }

        biodiversity
        biodiversityDocument {
          _id
          file {
            _id
            filename
          }
        }
        biodiversityComment
        biodiversityDelegation {
          _id
          user {
            _id
            firstName
            lastName
            email
          }
          shareToken
          objective
        }

        pollution
        pollutionDocument {
          _id
          file {
            _id
            filename
          }
        }
        pollutionComment
        pollutionDelegation {
          _id
          user {
            _id
            firstName
            lastName
            email
          }
          shareToken
          objective
        }

        adaptation
        adaptationDocument {
          _id
          file {
            _id
            filename
          }
        }
        adaptationComment
        adaptationDelegation {
          _id
          user {
            _id
            firstName
            lastName
            email
          }
          shareToken
          objective
        }

        mitigation
        mitigationDocument {
          _id
          file {
            _id
            filename
          }
        }
        mitigationComment
        mitigationDelegation {
          _id
          user {
            _id
            firstName
            lastName
            email
          }
          shareToken
          objective
        }

        circular
        circularDocument {
          _id
          file {
            _id
            filename
          }
        }
        circularComment
        circularDelegation {
          _id
          user {
            _id
            firstName
            lastName
            email
          }
          shareToken
          objective
        }
      }
      
      minimumStandardsRespected
      minimumStandardsRespectedDocument {
        _id
        file {
          _id
          filename
        }
      }
      minimumStandardsRespectedComment
      minimumStandardsDelegation {
        _id
        user {
          _id
          firstName
          lastName
          email
        }
        shareToken
      }

      substantialContributionDelegation {
        _id
        user {
          _id
          firstName
          lastName
          email
        }
        shareToken
      }
    }
  }
`;

type TOptions = UseMutationOptions<UpdateTaxonomyAssessmentBusinessActivityMutation,
  UpdateTaxonomyAssessmentBusinessActivityMutationVariables>

const useUpdateTaxonomyAssessmentBusinessActivity = (options?: TOptions) => useMutation<
  UpdateTaxonomyAssessmentBusinessActivityMutation,
  UpdateTaxonomyAssessmentBusinessActivityMutationVariables
>(UPDATE_TAXONOMY_ASSESSMENT_BUSINESS_ACTIVITY_MUTATION, options);

export default useUpdateTaxonomyAssessmentBusinessActivity;
