<template>
  <div
    class="relative flex"
    data-cy="PgHome"
  >
    <div class="flex-1">
      <router-view name="subview" />
    </div>
  </div>
</template>
