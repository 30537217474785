<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { DoubleMaterialityIroImpactType } from '@/__generated__/types';
import { notEmpty } from '@/utils/helpers/notEmpty';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import { BadgeType } from '@/components/atoms/AtBadge/types';
import AtBadge from '@/components/atoms/AtBadge/AtBadge.vue';
import type { IroImpact, Topic } from '../../types';
import { calculateMaterialityForImpact, calculateSeverityForImpact, calculateTotalScoreForImpact } from '../../utils';
import { useStore } from '../../store';
import OgTable from '../../OgTable.vue';

type RowItem = {
  _id: string;
  iroImpact: IroImpact;
  topic: Topic;
  severity: number | null;
  likelihood: number | null;
  totalScore: number | null;
  materiality: boolean | null;
};

const { t } = useI18n();
const store = useStore();
const { formatNumber } = useFormatNumber();

const headers = computed(() => [
  { text: t('Standard'), value: 'esrs', columnClasses: 'w-[200px]' },
  { text: t('Sustainability matter'), value: 'sustainabilityMatter' },
  { text: t('IRO'), value: 'iro' },
  { text: t('IRO type'), value: 'iroType', columnClasses: 'w-[215px]' },
  { text: t('Severity'), value: 'severity', columnClasses: 'w-[150px]' },
  { text: t('Likelihood'), value: 'likelihood', columnClasses: 'w-[150px]' },
  { text: t('Total score'), value: 'totalScore', columnClasses: 'w-[150px]' },
  { text: t('Materiality'), value: 'materiality', columnClasses: 'w-[100px]' },
]);

const items = computed<RowItem[]>(() => {
  return store.value.iroImpacts.map((iroImpact) => {
    const topic = store.value.topics.find((item) => item._id === iroImpact.topicId)!;

    if (!topic.topic || !topic.subTopic) {
      return null;
    }

    const severity = calculateSeverityForImpact(iroImpact.scale, iroImpact.scope, iroImpact.irremediability, iroImpact.type);
    const totalScore = calculateTotalScoreForImpact(severity, iroImpact.likelihood, iroImpact.type);
    const materiality = calculateMaterialityForImpact(
      iroImpact.scale,
      iroImpact.scope,
      iroImpact.irremediability,
      iroImpact.likelihood,
      iroImpact.type,
    );

    return {
      _id: iroImpact._id,
      topic,
      iroImpact,
      severity,
      likelihood: iroImpact.likelihood,
      totalScore,
      materiality,
    };
  })
    .filter((item) => notEmpty(item));
});
</script>

<template>
  <h3 class="text-lg font-medium mb-3">
    {{ t('Impact materiality') }}
  </h3>
  <OgTable
    :headers="headers"
    :items="items"
    :isRowRemovable="false"
  >
    <template #item-esrs="row: RowItem">
      <p>
        {{ row.topic.esrs }} {{ t(row.topic.topic) }}
      </p>
    </template>
    <template #item-sustainabilityMatter="row: RowItem">
      <p>
        {{ t(row.topic.subSubTopic) || t(row.topic.subTopic) || '' }}
      </p>
    </template>
    <template #item-iro="row: RowItem">
      <p>
        {{ row.iroImpact.impact }}
      </p>
    </template>
    <template #item-iroType="row: RowItem">
      <p>
        <template v-if="row.iroImpact.type === DoubleMaterialityIroImpactType.ACTUAL_NEGATIVE">
          {{ t('Actual negative impact') }}
        </template>
        <template v-else-if="row.iroImpact.type === DoubleMaterialityIroImpactType.POTENTIAL_NEGATIVE">
          {{ t('Potential negative impact') }}
        </template>
        <template v-else-if="row.iroImpact.type === DoubleMaterialityIroImpactType.ACTUAL_POSITIVE">
          {{ t('Actual positive impact') }}
        </template>
        <template v-else-if="row.iroImpact.type === DoubleMaterialityIroImpactType.POTENTIAL_POSITIVE">
          {{ t('Potential positive impact') }}
        </template>
        <template v-else>
          {{ t('n/a') }}
        </template>
      </p>
    </template>
    <template #item-severity="row: RowItem">
      <p>
        {{ typeof row.severity !== 'number' ? t('n/a') : formatNumber(row.severity, 0, 2) }}
      </p>
    </template>
    <template #item-likelihood="row: RowItem">
      <p>
        {{ row.likelihood ?? t('n/a') }}
      </p>
    </template>
    <template #item-totalScore="row: RowItem">
      <p>
        {{ typeof row.totalScore !== 'number' ? t('n/a') : formatNumber(row.totalScore, 0, 2) }}
      </p>
    </template>
    <template #item-materiality="row: RowItem">
      <AtBadge :type="row.materiality === true ? BadgeType.SUCCESS : (row.materiality === false ? BadgeType.WARNING : BadgeType.DEFAULT)">
        {{ row.materiality === true ? t('Material') : (row.materiality === false ? t('Not material') : t('Start')) }}
      </AtBadge>
    </template>
  </OgTable>
</template>
