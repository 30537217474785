<script setup lang="ts">
import { capitalize, computed } from 'vue';
import { useI18n } from 'vue-i18n';
import COUNTRIES from '@/constants/countries';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import {
  type DataPoint,
  type DataPointRequest,
  type DataPointType,
  DataPointTypeValueUnitEnum,
  ValueDataTypeEnum,
  SummarizingMethodEnum,
} from '@/__generated__/types';
import AtDataPointRequestDate from '@/components/atoms/AtDataPointRequestDate.vue';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';
import AtValueUnit from '@/components/atoms/AtValueUnit.vue';

export type TDataPointValueAndType = {
  dataPointType: Pick<DataPointType, 'valueDataType' | 'valueUnit' | 'valueUnitDivisor' | 'valueDataTypeOptions' | 'summarizingMethod'>
  value?: DataPointRequest['value'] | DataPoint['value']
  valueSource?: DataPointRequest['valueSource'] | DataPoint['valueSource']
  displayValue?: DataPointRequest['displayValue'] | DataPoint['displayValue']
  displayValueUnit?: DataPointRequest['displayValueUnit'] | DataPoint['displayValueUnit']
}

interface Props {
  dataPointValueAndType: TDataPointValueAndType;
  inline?: boolean
  useTooltip?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  inline: false,
  useTooltip: false,
});

const { t } = useI18n();
const { formatNumber } = useFormatNumber();

const { BOOLEAN, BOOLEAN_UNSURE, COUNTRY, DATE, DATE_RANGE, NUMERIC, MULTIPLE_CHOICE } = ValueDataTypeEnum;

const valueDataType = computed(() => props.dataPointValueAndType.dataPointType?.valueDataType);
const isDate = computed(() => valueDataType.value === DATE || valueDataType.value === DATE_RANGE);
const displayedValue = computed(() => {
  if (props.dataPointValueAndType.value === null || typeof props.dataPointValueAndType.value === 'undefined') {
    return null;
  }

  switch (valueDataType.value) {
    case BOOLEAN:
    case BOOLEAN_UNSURE: return capitalize(t(props.dataPointValueAndType.value));
    case COUNTRY: return COUNTRIES.find((country) => country['alpha-2'] === props.dataPointValueAndType.value)?.name;
    case NUMERIC: return formatNumber(props.dataPointValueAndType.displayValue ?? props.dataPointValueAndType.value);
    case MULTIPLE_CHOICE:
      return Array.isArray(props.dataPointValueAndType.value) ? props.dataPointValueAndType.value.map(
        (v: TDataPointValueAndType['value']) => props.dataPointValueAndType.dataPointType.valueDataTypeOptions[v] ?? v,
      ) : props.dataPointValueAndType.value;
    default: return props.dataPointValueAndType.value;
  }
});
</script>
<template>
  <div
    v-if="props.dataPointValueAndType.dataPointType"
    class="break-words"
    :class="{ inline: props.inline }"
  >
    <template v-if="ValueDataTypeEnum.CHOICE === valueDataType">
      {{ t(props.dataPointValueAndType.value) }}
    </template>

    <template v-else-if="ValueDataTypeEnum.MULTIPLE_CHOICE === valueDataType">
      <ul
        class="ml-5 list-disc"
      >
        <li
          v-for="(v, k) in Array.isArray(displayedValue)
            ? displayedValue
            : [displayedValue]"
          :key="k"
          class="capitalize"
        >
          {{ t(v) }}
        </li>
      </ul>
    </template>
    <AtDataPointRequestDate
      v-else-if="isDate"
      :from="Array.isArray(props.dataPointValueAndType.value) ? props.dataPointValueAndType.value[0] : props.dataPointValueAndType.value"
      :to="Array.isArray(props.dataPointValueAndType.value) ? props.dataPointValueAndType.value[1] : undefined"
    />
    <span v-else-if="([BOOLEAN, BOOLEAN_UNSURE] as Array<ValueDataTypeEnum>).includes(valueDataType as ValueDataTypeEnum)">
      {{ t(displayedValue) }}
    </span>
    <div
      v-else-if="props.dataPointValueAndType.valueSource && props.dataPointValueAndType.valueSource.length > 0"
    >
      <ul v-if="props.dataPointValueAndType.valueSource" class="mb-3 ml-3 list-disc">
        <li
          v-for="singleSourceValue in props.dataPointValueAndType.valueSource"
          :key="singleSourceValue.name"
        >
          <template v-if="dataPointValueAndType.dataPointType.valueDataType === ValueDataTypeEnum.EMISSIONS_SPLIT">
            {{ t(singleSourceValue.name) }}: {{ formatNumber(singleSourceValue.originalValue) }}
          </template>
          <template v-else>
            {{ singleSourceValue.name }}:
            <MlHtmlContent
              :class="{ '!line-clamp-4': props.useTooltip }"
              class="line-clamp-4 hover:line-clamp-none"
              :html="(formatNumber(singleSourceValue.originalValue) ?? '').toString()"
            />
          </template>

          <AtValueUnit
            v-if="singleSourceValue.originalValueUnit"
            :valueUnit="singleSourceValue.originalValueUnit"
          />
        </li>
      </ul>
      <template v-if="displayedValue">
        <span>
          {{ props.dataPointValueAndType.dataPointType.summarizingMethod === SummarizingMethodEnum.AVERAGE ? t('Average:') : t('Total:') }}
          <MlHtmlContent
            :class="{ '!line-clamp-4': props.useTooltip }"
            class="line-clamp-4 hover:line-clamp-none"
            :html="(formatNumber(displayedValue) ?? '').toString()"
          />
          {{ " " }}
        </span>
        <AtValueUnit
          v-if="props.dataPointValueAndType.dataPointType.valueUnit"
          class="inline"
          :valueUnit="props.dataPointValueAndType.displayValueUnit ?? props.dataPointValueAndType.dataPointType.valueUnit"
          :valueUnitDivisor="props.dataPointValueAndType.dataPointType.valueUnitDivisor"
        />
      </template>
    </div>
    <MlHtmlContent
      v-else-if="ValueDataTypeEnum.TEXT_LONG === (valueDataType as ValueDataTypeEnum)"
      :class="{ '!line-clamp-4': props.useTooltip }"
      class="line-clamp-4 hover:line-clamp-none"
      :html="props.dataPointValueAndType.value"
    />
    <span
      v-else
      :class="{ 'line-clamp-4 hover:line-clamp-none': !props.inline }"
    >
      {{ displayedValue }}
    </span>
    <AtValueUnit
      v-if="props.dataPointValueAndType.dataPointType.valueUnit
        && !props.dataPointValueAndType.valueSource?.length
        && props.dataPointValueAndType.dataPointType.valueUnit !== DataPointTypeValueUnitEnum.NUMBER"
      :class="{ inline: props.inline, 'ml-0.5': props.inline }"
      :valueUnit="props.dataPointValueAndType.displayValueUnit ?? props.dataPointValueAndType.dataPointType.valueUnit"
      :valueUnitDivisor="props.dataPointValueAndType.dataPointType.valueUnitDivisor"
    />
  </div>
</template>
