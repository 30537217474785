<script setup lang="ts">
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import { email, helpers, required } from '@vuelidate/validators';
import { notify } from '@kyvg/vue3-notification';
import useValidateBusinessActivityShareToken from '@/api/mutations/Taxonomy/validateBusinessActivityShareToken.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import AtNonAuthBackground from '@/components/atoms/AtNonAuthBackground.vue';
import OgLocaleChange from '@/components/organisms/OgLocaleChange.vue';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const { mutate: validate } = useValidateBusinessActivityShareToken();

const form = reactive({
  email: '',
});
const v$ = useVuelidate({
  email: {
    required: helpers.withMessage(t('Email is required.'), required),
    email: helpers.withMessage(t('Email must be valid email.'), email),
  },
}, form);

async function handleSubmit() {
  try {
    const shareToken = route.params.shareToken.toString();

    await validate({
      email: form.email,
      shareToken,
    });

    window.localStorage.setItem('taxonomyAssessmentEmail', form.email);

    router.push({
      name: 'externalTaxonomyAssessmentAnswer',
      params: {
        shareToken,
      },
    });
  } catch {
    notify({ type: 'error', text: t('The email address or the link used might be wrong or outdated. Please try again.') });
  }
}
</script>

<template>
  <div class="flex h-screen justify-center items-center">
    <div class="bg-white w-1/3 p-8 py-16 rounded-md text-center">
      <OgLocaleChange class="mr-6 h-full" />
      <AtHeading type="h1" class="mb-5 font-semibold">
        {{ t('Help us making sustainability measurable.') }}
      </AtHeading>

      <p class="text-sm mb-5">
        {{ t('You have now been asked to confirm that a certain business activity performed by your organization fulfills conditions set out under the EU-Taxonomy.') }}
      </p>

      <form @submit.prevent="handleSubmit">
        <AtInput
          v-model="form.email"
          labelClass="text-left"
          :label="t('Verify your email address')"
          class="mb-4"
          :placeholder="t('Enter email address')"
          type="email"
          required
          :errors="v$.email.$errors"
        />
      </form>

      <AtButton class="w-full">
        {{ t('Verify') }}
      </AtButton>
    </div>

    <AtNonAuthBackground class="absolute w-full h-full top-0 left-0 z-[-1]" />
  </div>
</template>
