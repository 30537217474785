<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import MinusIcon from '@heroicons/vue/solid/MinusIcon';
import PlusIcon from '@heroicons/vue/solid/PlusIcon';
import type { TPartialRepositoryFile } from '@/components/molecules/MlFilePicker/types';
import MlCommentBox from '@/components/molecules/MlCommentBox.vue';
import OgPdfS3FilePicker from '@/components/molecules/MlFilePicker/OgPdfS3FilePicker.vue';

const props = defineProps<{
  showRecentFiles: boolean;
  userId?: string;
}>();

const file = defineModel<TPartialRepositoryFile | null>('file', { required: true });
const comment = defineModel<string>('comment', { required: true });

const { t } = useI18n();

const isFilePickerShown = ref(!!file.value);

const dprDelegationInput = computed(() => props.userId ? ({
  userId: props.userId,
  entityId: null,
}) : undefined);
</script>

<template>
  <div class="flex space-x-4">
    <div class="shrink-0">
      <button
        type="button"
        aria-label="add document"
        class="flex text-gray-400 text-xs hover:text-primary"
        @click.prevent="isFilePickerShown = !isFilePickerShown"
      >
        <MinusIcon v-if="isFilePickerShown" class="h-4 mr-2" />
        <PlusIcon v-else class="h-4 mr-2" />
        {{ t('Add document') }}
      </button>
      <OgPdfS3FilePicker
        v-if="isFilePickerShown"
        v-model="file"
        class="row-span-2 max-w-xs"
        :placeholder="t('Select or drop proof document')"
        :showRecentFiles="showRecentFiles"
        :dprDelegationInput="dprDelegationInput"
        compact
      />
    </div>
    <MlCommentBox
      v-model="comment"
      iconSize="sm"
      class="!w-full text-xs text-gray-400"
    />
  </div>
</template>
