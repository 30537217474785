import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import type {
  RejectDataPointRequestsMutation,
  RejectDataPointRequestsMutationVariables,
} from '@/__generated__/types';

export const REJECT_DATAPOINT_REQUESTS = gql`
  mutation RejectDataPointRequests($rejectDataPointRequestsInput: RejectDataPointRequestsInput!) {
    rejectDataPointRequests(RejectDataPointRequestsInput: $rejectDataPointRequestsInput) {
        _id
      status
        rejectedBy {
          _id
            firstName
            lastName
        }
        rejectedAt
    }
  }
`;

type TOptions = UseMutationOptions<RejectDataPointRequestsMutation, RejectDataPointRequestsMutationVariables>

const useRejectDataPointRequestsMutation = (options?: TOptions) => useMutation<
  RejectDataPointRequestsMutation,
  RejectDataPointRequestsMutationVariables
>(REJECT_DATAPOINT_REQUESTS, options);

export default useRejectDataPointRequestsMutation;
