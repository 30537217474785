<script setup lang="ts">
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import type { ChartType } from 'chart.js';
import { useQuery } from '@vue/apollo-composable';
import { computed } from 'vue';
import dayjs from '@/lib/dayjs/config';
import OgChart from '@/components/organisms/OgChart/OgChart.vue';

import type { PgAnalyticsDashboardQuery } from '@/__generated__/types';
import ANALYTICS_DASHBOARD_QUERY from './PgAnalyticsDashboard.query';

const { result } = useQuery<PgAnalyticsDashboardQuery>(ANALYTICS_DASHBOARD_QUERY);

const chartPropsUsers = computed(() => {
  let numberOfCreatedUsersCummulative = 0;
  return {
    chartData: {
      datasets: [{
        type: 'bar' as ChartType,
        label: 'Newly created users',
        data: result.value?.getUserCreatedAnalytics.map(({ numberOfCreatedUsers, date }) => ({
          x: dayjs(date).date(1).add(1, 'month').subtract(1, 'day').toISOString().split('T')[0],
          y: numberOfCreatedUsers,
        })),
      },
      {
        type: 'line' as ChartType,
        label: 'Total number of users',
        pointRadius: 4,
        pointHoverRadius: 8,
        // eslint-disable-next-line no-return-assign
        data: result.value?.getUserCreatedAnalytics.map(({ numberOfCreatedUsers, date }) => ({
          x: dayjs(date).date(1).add(1, 'month').subtract(1, 'day').toISOString().split('T')[0],
          y: numberOfCreatedUsersCummulative += numberOfCreatedUsers,
        })),
        yAxisID: 'y2',
      },
      ],
    },
    chartOptions: {
      scales: {
        y: {},
        x: {},
        y2: { position: 'right' },
      },
    },
    type: 'bar' as ChartType,
    title: 'Newly created users',
    pinned: false,
  };
});

const chartPropsDatapoints = computed(() => {
  let numberOfCreatedDatapointsCummulative = 0;
  return {
    chartData: {
      datasets: [{
        type: 'bar' as ChartType,
        label: 'Newly created datapoints (approved)',
        data: result.value?.getDatapointsCreatedAnalytics.map(({ numberOfCreatedDatapoints, date }) => ({
          x: dayjs(date).date(1).add(1, 'month').subtract(1, 'day').toISOString().split('T')[0],
          y: numberOfCreatedDatapoints,
        })),
      },
      {
        type: 'line' as ChartType,
        label: 'Total number of datapoints (approved)',
        pointRadius: 4,
        pointHoverRadius: 8,
        // eslint-disable-next-line no-return-assign
        data: result.value?.getDatapointsCreatedAnalytics.map(({ numberOfCreatedDatapoints, date }) => ({
          x: dayjs(date).date(1).add(1, 'month').subtract(1, 'day').toISOString().split('T')[0],
          y: numberOfCreatedDatapointsCummulative += numberOfCreatedDatapoints,
        })),
        yAxisID: 'y2',
      },
      ],
    },
    chartOptions: {
      scales: {
        y: {},
        x: {},
        y2: { position: 'right' },
      },
    },
    type: 'bar' as ChartType,
    title: 'Newly created datapoints (approved)',
    pinned: false,
  };
});

const chartPropsDatapointRequests = computed(() => {
  let numberOfCreatedDatapointRequestsCummulative = 0;
  return {
    chartData: {
      datasets: [{
        type: 'bar' as ChartType,
        label: 'Newly answered datapoints (pending, approved or rejected)',
        data: result.value?.getDataPointRequestAnalytics.map(({ numberOfNonOpenDataPointsRequests, date }) => ({
          x: dayjs(date).date(1).add(1, 'month').subtract(1, 'day').toISOString().split('T')[0],
          y: numberOfNonOpenDataPointsRequests,
        })),
      },
      {
        type: 'line' as ChartType,
        label: 'Total number of answered datapoints (pending, approved or rejected)',
        pointRadius: 4,
        pointHoverRadius: 8,
        // eslint-disable-next-line no-return-assign
        data: result.value?.getDataPointRequestAnalytics.map(({ numberOfNonOpenDataPointsRequests, date }) => ({
          x: dayjs(date).date(1).add(1, 'month').subtract(1, 'day').toISOString().split('T')[0],
          y: numberOfCreatedDatapointRequestsCummulative += numberOfNonOpenDataPointsRequests,
        })),
        yAxisID: 'y2',
      },
      ],
    },
    chartOptions: {
      scales: {
        y: {},
        x: {},
        y2: { position: 'right' },
      },
    },
    type: 'bar' as ChartType,
    title: 'Newly updated datapoints (pending, approved or rejected)',
    pinned: false,
  };
});

const chartPropsProjects = computed(() => {
  let numberOfCreatedProjectsCummulative = 0;
  return {
    chartData: {
      datasets: [{
        type: 'bar' as ChartType,
        label: 'Created projects',
        data: result.value?.getEntityLocationAnalytics.map(({ numberOfCreatedProjects, date }) => ({
          x: dayjs(date).date(1).add(1, 'month').subtract(1, 'day').toISOString().split('T')[0],
          y: numberOfCreatedProjects,
        })),
      },
      {
        type: 'line' as ChartType,
        label: 'Total number of projects',
        pointRadius: 4,
        pointHoverRadius: 8,
        // eslint-disable-next-line no-return-assign
        data: result.value?.getEntityLocationAnalytics.map(({ numberOfCreatedProjects, date }) => ({
          x: dayjs(date).date(1).add(1, 'month').subtract(1, 'day').toISOString().split('T')[0],
          y: numberOfCreatedProjectsCummulative += numberOfCreatedProjects,
        })),
        yAxisID: 'y2',
      },
      ],
    },
    chartOptions: {
      scales: {
        y: {},
        x: {},
        y2: { position: 'right' },
      },
    },
    type: 'bar' as ChartType,
    title: 'Newly created projects',
    pinned: false,
  };
});

</script>

<template>
  <h1>Basic analytics dashboard</h1>

  <div class="mt-10 grid gap-10">
    <OgChart
      v-bind="chartPropsUsers"
    />

    <OgChart
      v-bind="chartPropsDatapoints"
    />

    <OgChart
      v-bind="chartPropsDatapointRequests"
    />

    <OgChart
      v-bind="chartPropsProjects"
    />
  </div>
</template>
