import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  GenerateQuestionnaireFileUploadTokenMutation,
  GenerateQuestionnaireFileUploadTokenMutationVariables,
} from '@/__generated__/types';

export const GENERATE_QUESTIONNAIRE_FILE_UPLOAD_TOKEN = gql`
  mutation GenerateQuestionnaireFileUploadToken($customQuestionnaireId: String!) {
    generateQuestionnaireFileUploadToken(customQuestionnaireId: $customQuestionnaireId) {
      _id
      preSignedUrl
      secretUpdateToken
    }
  }
`;

type TOptions = UseMutationOptions<GenerateQuestionnaireFileUploadTokenMutation, GenerateQuestionnaireFileUploadTokenMutationVariables>

const useGenerateQuestionnaireFileUploadToken = (options?: TOptions) => useMutation<
  GenerateQuestionnaireFileUploadTokenMutation,
  GenerateQuestionnaireFileUploadTokenMutationVariables
>(GENERATE_QUESTIONNAIRE_FILE_UPLOAD_TOKEN, options);

export default useGenerateQuestionnaireFileUploadToken;
