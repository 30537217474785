<script setup lang="ts">
import { computed, h, ref, watch, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { useCategoryTranslate } from '@/utils/composables/useCategoryTranslate/useCategoryTranslate';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import useSwitchActiveEntity from '@/utils/composables/useSwitchActiveEntity';
import type { OgProjectHeaderQuery } from '@/__generated__/types';

type Props = {
  activeProjectId: string | null;
  allProjects: OgProjectHeaderQuery['entityLocationSummaries'];
  category?: string;
  subcategory?: string;
};

const props = defineProps<Props>();

const { t } = useI18n();
const translateCategory = useCategoryTranslate();
const { activeEntity, currentUser } = useCurrentUser();
const router = useRouter();

// const projects = computed(() => {
//   if (!props.activeProjectId) {
//     return [];
//   }

//   const entityLocationSummaries = keyBy(props.allProjects, (item) => item._id);

//   const data: Project[] = [];
//   const iterator = (parentId: string) => {
//     const nextProject = entityLocationSummaries[parentId];
//     if (nextProject) {
//       data.push(nextProject);
//       const nextParentId = nextProject.parent;
//       if (nextParentId) {
//         iterator(nextParentId);
//       }
//     }
//   };
//   iterator(props.activeProjectId);

//   return data.reverse();
// });

const { switchActiveEntity } = useSwitchActiveEntity();

const selectedEntityId = ref('');
const selectedProjectId = ref('');

const entitiesOptions = computed<Record<string, string>>(
  () => currentUser.value?.entities.reduce((acc, curr) => ({ ...acc, [curr._id]: curr.name }), {}) ?? {});
const projectsOptions = computed<Record<string, string>>(
  () => props.allProjects.reduce((acc, curr) => ({ ...acc, [curr._id]: curr.name }), {}) ?? {});

watchEffect(() => {
  selectedProjectId.value = props.activeProjectId ?? '';
});
watchEffect(() => {
  selectedEntityId.value = activeEntity.value?._id ?? '';
});

function switchActiveLocation(locationId: string) {
  const selectedProject = props.allProjects.find((project) => project._id === selectedProjectId.value);
  const selectedProjectFirstSubcategory = (selectedProject?.assignments.categories ?? [])
    .filter((category) => category.isEnabled)[0].subcategories[0].subcategory;
  router.push({
    name: selectedProject?.hasChildren ? 'projectsProject' : 'projectsProjectDataEntry',
    params: { project: locationId },
    query: {
      subcategory: currentUser.value?.assignments.subcategories[0]?.subcategory ?? selectedProjectFirstSubcategory,
    },
  });
}

watch(selectedEntityId, () => {
  switchActiveEntity(selectedEntityId.value, '/projects');
});

const divider = h('div', { class: 'mx-3', innerHTML: '/' });

</script>

<template>
  <h1
    class="mb-2 text-sm font-normal leading-6 max-w-[75%] line-clamp-1 flex items-center"
    :class="{ 'pt-[12px]': props.activeProjectId }"
  >
    <template v-if="selectedEntityId && !props.activeProjectId">
      <MlSelect
        v-model="selectedEntityId"
        :title="entitiesOptions[selectedEntityId]"
        :options="entitiesOptions"
        class="!py-1 !line-clamp-1"
        truncate
        usePortal
      />
      <component :is="divider" />
    </template>
    <router-link :to="{ name: 'projects' }" class="hover:text-blue-600">
      {{ t('Projects') }}
    </router-link>
    <template v-if="selectedProjectId">
      <component :is="divider" />
      <MlSelect
        :modelValue="selectedProjectId"
        :title="projectsOptions[selectedProjectId]"
        :options="projectsOptions"
        class="!py-1 !w-36 !line-clamp-1"
        truncate
        usePortal
        @update:modelValue="switchActiveLocation"
      />
    </template>

    <!-- <template v-for="project in projects" :key="project._id">
      <template v-if="project._id !== activeProjectId">
        <router-link
          :to="{
            name: project.hasChildren ? 'projectsProject' : 'projectsProjectDataEntry',
            params: { project: project._id },
          }"
          class="hover:text-blue-600 line-clamp-1"
        >
          {{ project?.name }}
        </router-link>
      </template>
      <slot v-else name="project">
        <router-link
          :to="{
            name: project.hasChildren ? 'projectsProject' : 'projectsProjectDataEntry',
            params: { project: project._id },
          }"
          class="hover:text-blue-600 line-clamp-1"
        >
          {{ project?.name }}
        </router-link>
      </slot>
    </template> -->
    <template v-if="category">
      <component :is="divider" />
      <router-link
        :to="{
          name: 'projectsProjectDataEntry',
          params: { project: props.activeProjectId },
          query: { category },
        }"
        :title="translateCategory(category)"
        class="hover:text-blue-600 line-clamp-1 min-w-20"
      >
        {{ translateCategory(category) }}
      </router-link>
    </template>
    <template v-if="subcategory">
      <component :is="divider" />
      <slot name="subcategory">
        <span :title="translateCategory(category, subcategory)" class="line-clamp-1">
          {{ translateCategory(category, subcategory) }}
        </span>
      </slot>
    </template>
  </h1>
</template>
