<script setup lang="ts">
import { useGlobalMutationLoading, useQuery } from '@vue/apollo-composable';
import { computed, onBeforeUnmount, reactive, ref, onBeforeMount } from 'vue';
import { useI18n } from 'vue-i18n';
import useVuelidate from '@vuelidate/core';
import useUpdateEntitySettingsMutation from '@/api/mutations/EntitySettings/updateEntitySettings.mutation';
import useUpdateOrganizationMutation from '@/api/mutations/Organization/updateOrganization.mutation';
import TmStepCompany from '@/components/pages/PgOnboarding/TmStepCompany.vue';
import TmStepUser from '@/components/pages/PgOnboarding/TmStepUser.vue';
import {
  OnboardingStatusEnum,
  ReportingFrameworkEnum,
  UserRole,
  type PgOnboardingQuery,
  type UpdateEntitySettingsInput,
} from '@/__generated__/types';

import { router } from '@/router';
import useUpdateEntityMutation from '@/api/mutations/Entity/updateEntity.mutation';
import useUpdateEntityLocationMutation from '@/api/mutations/EntityLocation/updateEntityLocation.mutation';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import TmStepTopics from './TmStepTopics.vue';
import TmStepper from './TmStepper.vue';
import MlOnboardingTopBar from './MlOnboardingTopBar.vue';
import PG_ONBOARDING_QUERY from './PgOnboarding.query';
import MlNavLinks from './MlNavLinks.vue';
import type { TEntitySettings } from './types';

const { t } = useI18n();
const { currentUser, isAdminOrSuperAdmin, updateCurrentUser } = useCurrentUser();
const globalMutationLoading = useGlobalMutationLoading();
const { result } = useQuery<PgOnboardingQuery>(PG_ONBOARDING_QUERY);
const { mutate: updateEntitySettingsMutation } = useUpdateEntitySettingsMutation();
const { mutate: updateOrganizationMutation } = useUpdateOrganizationMutation();
const { mutate: updateEntityMutation } = useUpdateEntityMutation();
const { mutate: updateEntityLocationMutation } = useUpdateEntityLocationMutation();
const v$ = useVuelidate();

const entitySettings = computed(() => result.value?.getOwnUser.entity.entitySettings ?? {} as TEntitySettings);

const user = reactive({
  firstName: currentUser.value?.firstName || '',
  lastName: currentUser.value?.lastName || '',
  jobTitle: currentUser.value?.jobTitle || '',
  jobDepartment: currentUser.value?.jobDepartment || '',
  picture: currentUser.value?.picture?._id || null,
});

const userStepCompleted = computed(() => Object.values(user)
  .filter((el) => el !== user.picture)
  .every(Boolean),
);

const reportingFrameworksSelected = computed(() => result.value?.getOwnUser.entity.reportingFrameworks ?? []);

const companyStepCompleted = computed(
  () => !!entitySettings.value._id
    && !!entitySettings.value.companyName
    && !!entitySettings.value.industry
    && !!entitySettings.value.locationOfHeadquarter
    && !!entitySettings.value.nameOfHeadquarter,
);

const hasTopicsSelected = ref(false);
const topicsStepCompleted = computed(
  () => hasTopicsSelected.value
  || (reportingFrameworksSelected.value.length
    ? reportingFrameworksSelected.value.every((framework) => framework === ReportingFrameworkEnum.DNK)
    : false),
);

const steps = computed(() => {
  const step1 = {
    stepNumber: 1,
    title: t('Your profile information'),
    description: t('Add info to your account.'),
    isCompleted: userStepCompleted.value,
    isClickable: true,
  };
  const step2 = {
    stepNumber: 2,
    title: t('Company profile'),
    description: t('Add details about your business.'),
    isCompleted: companyStepCompleted.value,
    isClickable: userStepCompleted.value,
  };
  const step3 = {
    stepNumber: 3,
    title: t('Topic selection'),
    description: t('Choose sustainability topics.'),
    isCompleted: topicsStepCompleted.value,
    isClickable: userStepCompleted.value && companyStepCompleted.value,
  };
  switch (currentUser.value?.role) {
    case UserRole.SUPERADMIN: return [step1, step2, step3];
    default: return [step1];
  }
});

onBeforeMount(() => {
  if (currentUser.value?.onboardingStatus === OnboardingStatusEnum.COMPLETED) {
    router.push('home');
  }
});
onBeforeUnmount(() => {
  const { COMPLETED } = OnboardingStatusEnum;
  if (steps.value.every((step) => step.isCompleted)) updateCurrentUser({ onboardingStatus: COMPLETED });
});

const isCurrentStepCompleted = computed(() => steps.value[currentStepIndex.value].isCompleted);

const currentStepIndex = ref(0);

async function updateEntitySettings(payload: TEntitySettings) {
  const { companyName, industry, locationOfHeadquarter, nameOfHeadquarter, numberOfEmployees } = payload;
  const updateEntitySettingsInput: UpdateEntitySettingsInput = {
    companyName,
    industry,
    locationOfHeadquarter,
    nameOfHeadquarter,
    numberOfEmployees,
  };
  await updateEntitySettingsMutation(
    { updateEntitySettingsInput },
    { refetchQueries: [{ query: PG_ONBOARDING_QUERY }] },
  );
  if (companyName) {
    await updateEntityMutation({
      entityInput: {
        _id: currentUser.value?.entity._id,
        name: companyName },
    });
    await updateOrganizationMutation({
      organizationInput: {
        name: companyName },
    });
  }
  if (nameOfHeadquarter) {
    await updateEntityLocationMutation({
      updateEntityLocationInput: {
        _id: currentUser.value?.entity.locations.find((location) => location.isHeadquarters)?._id,
        name: nameOfHeadquarter },
    });
  }
}

function updateCurrentStepIndex(event: number) {
  v$.value.$touch();
  if (v$.value.$error) return;
  currentStepIndex.value = event;
}

const triggerSave = ref(false);
const finish = () => {
  if (currentStepIndex.value === 2 && isAdminOrSuperAdmin) {
    triggerSave.value = true;
  } else {
    router.push('/get-started');
  }
};

</script>

<template>
  <MlOnboardingTopBar />
  <div
    class="flex"
  >
    <TmStepper
      :currentStepIndex="currentStepIndex"
      :steps="steps"
      @changeCurrentStepIndex="currentStepIndex = $event"
    />
    <div class="h-full w-2/3 py-6">
      <TmStepTopics
        v-if="currentStepIndex === 2 && isAdminOrSuperAdmin"
        :selectedIndustry="entitySettings.industry"
        :reportingFrameworksSelected="reportingFrameworksSelected"
        data-cy="TmStepTopics"
        :triggerSave="triggerSave"
        @updateHasTopicsSelected="hasTopicsSelected = $event"
        @entityAssignmentsComplete="router.push('/get-started')"
      />
      <TmStepCompany
        v-else-if="currentStepIndex === 1 && isAdminOrSuperAdmin"
        :entitySettings="entitySettings"
        :reportingFrameworksSelected="reportingFrameworksSelected"
        data-cy="TmStepCompany"
        @updateValue="updateEntitySettings($event)"
      />
      <TmStepUser
        v-else
        :user="user"
        data-cy="TmStepUser"
        @updateUser="updateCurrentUser"
      />
      <MlNavLinks
        :modelValue="currentStepIndex"
        :isCurrentStepCompleted="isCurrentStepCompleted"
        :stepsLength="(steps.length)"
        :currentStepIndex="currentStepIndex"
        :globalMutationLoading="globalMutationLoading"
        :validationErrors="v$.$errors ?? []"
        :loading="triggerSave"
        @updateCurrentStepIndex="updateCurrentStepIndex"
        @clickedFinish="finish"
      />
    </div>
  </div>
</template>
