<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import MlModal from '@/components/molecules/MlModal.vue';

const { t } = useI18n();
interface TProps {
  isRevealed: boolean,
  title?: string;
  description?: string ;
}

const props = withDefaults(defineProps<TProps>(), {
  title: '',
  description: '',
});
const emit = defineEmits(['update:isRevealed', 'closeModal']);
</script>

<template>
  <MlModal
    :isRevealed="props.isRevealed"
    class="w-96"
    @close="emit('closeModal')"
    @update:isRevealed="emit('update:isRevealed', $event)"
  >
    <AtHeading type="h3">
      <slot name="title">
        {{ t(props.title) }}
      </slot>
    </AtHeading>
    <div class="mt-2">
      <p class="text-sm text-gray-900">
        <slot name="description">
          {{ t(props.description) }}
        </slot>
      </p>
    </div>
    <div class="mt-4">
      <slot name="buttons" />
    </div>
  </MlModal>
</template>
