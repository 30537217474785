<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import type {
  PgExternalTaxonomyAssessmentAnswerQuery,
  PgExternalTaxonomyAssessmentAnswerQueryVariables,
  UpdateExternallyTaxonomyAssessmentBusinessActivityInput,
} from '@/__generated__/types';
import useExternallyUpdateTaxonomyAssessmentBusinessActivity from '@/api/mutations/Taxonomy/updateExternallyTaxonomyAssessmentBusinessActivity.mutation';
import logo from '@/assets/logo.svg';
import AtNonAuthBackground from '@/components/atoms/AtNonAuthBackground.vue';
import OgLocaleChange from '@/components/organisms/OgLocaleChange.vue';
import OgStart from './OgStart.vue';
import OgAnswer from './OgAnswer.vue';
import OgDone from './OgDone.vue';
import PG_EXTERNAL_TAXONOMY_ASSESSMENT_ANSWER_QUERY from './PgExternalTaxonomyAssessmentAnswer.query';

enum Step {
  Start,
  Answer,
  Done,
}

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const email = window.localStorage.getItem('taxonomyAssessmentEmail') ?? '';
const shareToken = route.params.shareToken.toString();

const { result, error } = useQuery<
  PgExternalTaxonomyAssessmentAnswerQuery,
  PgExternalTaxonomyAssessmentAnswerQueryVariables
>(PG_EXTERNAL_TAXONOMY_ASSESSMENT_ANSWER_QUERY, {
  email,
  shareToken,
});
const { loading: isSaving, mutate } = useExternallyUpdateTaxonomyAssessmentBusinessActivity();

const step = ref(Step.Start);

const isWider = computed(() => step.value !== Step.Answer);

const data = computed(() => result.value?.getTaxonomyBusinessActivityDelegation ?? null);

watch(error, (newError) => {
  if (newError) {
    router.replace({
      name: 'signup',
    });
  }
});

function handleStart() {
  step.value = Step.Answer;
}

async function handleSave(input: UpdateExternallyTaxonomyAssessmentBusinessActivityInput) {
  try {
    await mutate({
      email,
      shareToken,
      input,
    });
    step.value = Step.Done;
  } catch {
    notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
  }
}
</script>

<template>
  <div class="h-screen">
    <div class="flex h-full">
      <div
        class="p-8 flex flex-col justify-between"
        :class="{
          'w-6/12': isWider,
          'w-[70%]': !isWider,
        }"
      >
        <div class="flex items-center justify-between space-x-4">
          <router-link :to="{ name: 'loginStart' }">
            <img
              class="h-12 w-auto"
              :src="logo"
              alt="Codio Impact GmbH"
            >
          </router-link>
          <div>
            <OgLocaleChange />
          </div>
        </div>
        <OgStart v-if="step === Step.Start" @start="handleStart" />
        <OgAnswer v-else-if="step === Step.Answer && data" :isSaving="isSaving" :data="data" @save="handleSave" />
        <OgDone v-else />
      </div>

      <AtNonAuthBackground class="relative hidden w-0 flex-1 lg:block" />
    </div>
  </div>
</template>
