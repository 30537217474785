<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import type { DataPointTypeRefreshIntervalEnum } from '@/__generated__/types';
import MlModal from '@/components/molecules/MlModal.vue';
import type { Crud, DataPointType } from '../types';
import OgCustomInsightForm from './OgCustomInsightForm.vue';
import AtCustomInsightModalTitle from './AtCustomInsightModalTitle.vue';
import type { CustomInsightEventPayload } from './types';

const props = defineProps<Props>();
type Props = {
  isRevealed: boolean
  state: Crud | null
  insight?: DataPointType
}

const emit = defineEmits<{
  (e: 'update:isRevealed', isRevealed: boolean): void
  (e: 'created', refreshIntervalSelected?: DataPointTypeRefreshIntervalEnum): void
}>();

const { t } = useI18n();

function handleCreate(payload: CustomInsightEventPayload) {
  emit('update:isRevealed', payload.isRepeating);
}

function handleUpdate(payload: CustomInsightEventPayload) {
  emit('update:isRevealed', payload.isRepeating);
}

const title = computed(() => {
  if (props.insight) {
    switch (props.state) {
      case 'read': return t('View insight: {friendlyName}', { friendlyName: props.insight?.friendlyName });
      case 'update': return t('Edit insight: {friendlyName}', { friendlyName: props.insight?.friendlyName });
      case 'delete': return t('Delete insight: {friendlyName}', { friendlyName: props.insight?.friendlyName });
      default: return t('Create new insight');
    }
  }
  return t('Create new insight');
});

</script>

<template>
  <div>
    <MlModal
      class="!w-[90%] !max-w-[83rem] px-[102px] py-11"
      :overlayDismiss="false"
      :isRevealed="props.isRevealed"
      v-bind="$attrs"
      @update:isRevealed="emit('update:isRevealed', $event)"
    >
      <AtCustomInsightModalTitle
        v-if="props.isRevealed"
        :title="title"
      >
        <template v-if="props.state === 'create'" #subtitle>
          <i18n-t keypath="Select two datapoints to create an insight (A / B = result of the insight). Only datapoints that share the same refresh interval can be selected. If you wish to create a new custom datapoint, please go to your {Projects}.">
            <template #Projects>
              <router-link
                :to="{ name: 'projects' }"
                variant="primary"
                class="text-primary underline"
              >
                {{ t('Projects') }}
              </router-link>
            </template>
          </i18n-t>
        </template>
      </AtCustomInsightModalTitle>
      <div class="divider" />

      <OgCustomInsightForm
        v-if="props.isRevealed && (props.state === 'create' || props.state === 'update')"
        :state="props.state"
        :insight="insight"
        @created="handleCreate"
        @updated="handleUpdate"
        @update:isRevealed="emit('update:isRevealed', $event)"
      />
    </MlModal>
  </div>
</template>
