import { gql } from '@apollo/client/core';

export default gql`
  query TmReportsCustomQuestionnaire($dataPointCategoryId: String!) {
    getLocationsForCustomCategory(dataPointCategoryId: $dataPointCategoryId) {
      _id
      name
    }
  }
`;
