import type { WritableComputedRef } from 'vue';
import type { UserLanguageEnum } from '@/__generated__/types';
import dayjs from '@/lib/dayjs/config';

const switchTranslation = (locale: WritableComputedRef<string>, newLocale: UserLanguageEnum) => {
  const lowerCasedNewLocale = newLocale.toLowerCase();

  if (locale.value === lowerCasedNewLocale) return;

  locale.value = lowerCasedNewLocale;
  window.localStorage.setItem('codioImpactLang', lowerCasedNewLocale); // needed for dayjs translation
  dayjs.locale(lowerCasedNewLocale);
};

export default switchTranslation;
