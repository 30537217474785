import { provideApolloClient, useLazyQuery } from '@vue/apollo-composable';
import {
  RepositoryFileTypeEnum,
  type GuidanceDocumentDownloadQuery,
  type GuidanceDocumentDownloadQueryVariables,
  type LibraryGuidanceDocumentEnum,
} from '@/__generated__/types';
import saveFile from '@/utils/helpers/saveFile';
import waitForApolloQuery from '@/utils/helpers/waitForApolloQuery';
import { apolloClient } from '@/api/apollo/client';
import GUIDANCE_DOCUMENT_DOWNLOAD_QUERY from './guidanceDocumentDownload.query';

export function useGuidanceDocumentDownload(file: LibraryGuidanceDocumentEnum) {
  const query = provideApolloClient(apolloClient)(
    () => useLazyQuery<GuidanceDocumentDownloadQuery, GuidanceDocumentDownloadQueryVariables>(
      GUIDANCE_DOCUMENT_DOWNLOAD_QUERY,
      undefined,
      { fetchPolicy: 'network-only' },
    ));

  const getFileData = async (fileType = RepositoryFileTypeEnum.PDF) => {
    const requestData: GuidanceDocumentDownloadQueryVariables = {
      file,
      fileType,
    };
    // eslint-disable-next-line no-unused-expressions
    query.load(undefined, requestData) || query.refetch(requestData);
    const result = await waitForApolloQuery(query);
    if (result && !(result instanceof Error)) {
      return result?.downloadRepositoryGuidanceDocument;
    }
  };

  const exportFile = async (fileType = RepositoryFileTypeEnum.PDF) => {
    const fileData = await getFileData(fileType);
    if (fileData) {
      saveFile(fileData.file, fileData.name, fileData.type);
    }
  };

  return {
    loading: query.loading,
    exportFile,
    getFileData,
  };
}
