import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { UpdateOrganizationMutation, UpdateOrganizationMutationVariables } from '@/__generated__/types';

export const UPDATE_ORGANIZATION = gql`
  mutation UpdateOrganization($organizationInput: OrganizationInput!) {
    updateOrganization(organizationInput: $organizationInput) {
      _id
      name
    }
  }
`;

type TOptions = UseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>

const useUpdateOrganizationMutation = (options?: TOptions) => useMutation<
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables
>(UPDATE_ORGANIZATION, options);

export default useUpdateOrganizationMutation;
