<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import MlAutocomplete from '@/components/molecules/MlAutocomplete.vue';
import type { SubTopicOptions } from '../../types';

const props = defineProps<{
  subTopic: string;
  subTopics: SubTopicOptions[];
}>();

const emit = defineEmits<{
  created: [event: string];
}>();

const model = defineModel<string>({ required: true });

const { t } = useI18n();

const subSubTopics = computed(() => props.subTopics.find((item) => item.subTopic === props.subTopic)?.subSubTopics ?? []);

const options = computed(() => subSubTopics.value.reduce<Record<string, string>>(
  (acc, item) => {
    acc[item] = t(item);
    return acc;
  },
  {},
));

function handleChange(event: string) {
  if (!subSubTopics.value.find((item) => item === event)) {
    emit('created', event);
  }

  model.value = event;
}
</script>

<template>
  <MlAutocomplete
    :modelValue="model"
    :placeholder="t('Enter or select')"
    :options="options"
    :disabled="!subTopic"
    canCreate
    hideSearchIcon
    @update:modelValue="handleChange"
  />
</template>
