import { CurrencyDisplay, type CurrencyInputOptions } from 'vue-currency-input';

export const currencyDefaultOptions: CurrencyInputOptions = {
  'currency': 'EUR',
  'currencyDisplay': CurrencyDisplay.hidden,
  'hideCurrencySymbolOnFocus': true,
  'hideGroupingSeparatorOnFocus': true,
  'hideNegligibleDecimalDigitsOnFocus': true,
  'autoDecimalDigits': false,
  'useGrouping': true,
  'accountingSign': false,
  'precision': {
    min: 0,
    max: 5,
  },
};
