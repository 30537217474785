import { useI18n } from 'vue-i18n';
import type { PreparedPerDate, PreparedPerDateSublevelItem } from '../../types';
import type { RowsPerProject, TableData } from './types';

type TranslateCallback = ReturnType<typeof useI18n>['t'];

export function prepareForTable(
  projects: PreparedPerDate['projects'],
  showCategories: boolean,
  t: TranslateCallback,
): TableData {
  const preparedProjects: TableData['projects'] = projects.map((project) => {
    const rowsPerProject: RowsPerProject = {
      id: project.id,
      name: project.name,
      rows: [],
    };

    if (!project.sublevels) {
      if (showCategories) {
        project.categories.forEach((category, categoryIndex) => {
          rowsPerProject.rows.push({
            id: project.id,
            name: '',
            // No need for parent ID because projects aren't collapsable.
            keys: new Set(),
            values: {},
            isCollapsible: false,
            categoryName: t(category.name),
            categoryValues: category.values,
            // Only show <td> when it is the fist mention of sublevel (first category).
            sublevelSpan: categoryIndex === 0 ? project.categories.length : 0,
          });
        });
      } else {
        rowsPerProject.rows.push({
          id: project.id,
          name: '',
          // No need for parent ID because projects aren't collapsable.
          keys: new Set(),
          values: project.dataPoints || project.total || {},
          isCollapsible: false,
          categoryName: '',
          categoryValues: {},
          sublevelSpan: 1,
        });
      }
    } else {
      const getSublevelsRows = (sublevels: PreparedPerDateSublevelItem[] | null, parentIds: string[]) => {
        if (sublevels) {
          sublevels.forEach((sublevel) => {
            if (showCategories) {
              sublevel.categories.forEach((category, categoryIndex) => {
                rowsPerProject.rows.push({
                  id: sublevel.id,
                  name: sublevel.name,
                  keys: new Set(parentIds),
                  values: {},
                  isCollapsible: !!sublevel.sublevels,
                  categoryName: t(category.name),
                  categoryValues: category.values,
                  // Only show <td> when it is the fist mention of sublevel (first category).
                  sublevelSpan: categoryIndex === 0 ? sublevel.categories.length : 0,
                });
              });
            } else {
              rowsPerProject.rows.push({
                id: sublevel.id,
                name: sublevel.name,
                keys: new Set(parentIds),
                values: sublevel.dataPoints || sublevel.total || {},
                isCollapsible: !!sublevel.sublevels,
                categoryName: '',
                categoryValues: {},
                sublevelSpan: 1,
              });
            }
            // It's not possible to be sublevel without parent ID.
            getSublevelsRows(sublevel.sublevels, [...parentIds, sublevel.id]);
          });
        }
      };

      project.sublevels.forEach((firstSublevel) => {
        if (showCategories) {
          firstSublevel.categories.forEach((category, categoryIndex) => {
            rowsPerProject.rows.push({
              id: firstSublevel.id,
              name: categoryIndex === 0 ? firstSublevel.name : '',
              keys: new Set([project.id]),
              values: {},
              isCollapsible: !!firstSublevel.sublevels,
              categoryName: t(category.name),
              categoryValues: category.values,
              // Only show <td> when it is the fist mention of sublevel (first category).
              sublevelSpan: categoryIndex === 0 ? firstSublevel.categories.length : 0,
            });
          });
        } else {
          rowsPerProject.rows.push({
            id: firstSublevel.id,
            name: firstSublevel.name,
            keys: new Set([project.id]),
            values: firstSublevel.dataPoints || firstSublevel.total || {},
            isCollapsible: !!firstSublevel.sublevels,
            categoryName: '',
            categoryValues: {},
            sublevelSpan: 1,
          });
        }
        // It's not possible to be sublevel without parent ID.
        getSublevelsRows(firstSublevel.sublevels, [project.id, firstSublevel.id]);
      });
    }

    return rowsPerProject;
  });

  return {
    projects: preparedProjects,
  };
}
