import { UserRole } from '../../__generated__/types';

export const hasRequiredRole = (requiredRoles: UserRole | UserRole[], currentRole: UserRole) => {
  const allowedRoles: Set<UserRole> = new Set();
  const { SUPERADMIN, ADMIN, MANAGER, CONTRIBUTOR, AUDITOR } = UserRole;

  const requiredRolesFrom = Array.isArray(requiredRoles) ? requiredRoles : [requiredRoles];

  requiredRolesFrom.forEach((requiredRole) => {
    switch (requiredRole) {
      case CONTRIBUTOR: [SUPERADMIN, ADMIN, MANAGER, CONTRIBUTOR].forEach((r) => allowedRoles.add(r)); break;
      case MANAGER: [SUPERADMIN, ADMIN, MANAGER].forEach((r) => allowedRoles.add(r)); break;
      case ADMIN: [SUPERADMIN, ADMIN].forEach((r) => allowedRoles.add(r)); break;
      case SUPERADMIN: [SUPERADMIN].forEach((r) => allowedRoles.add(r)); break;
      case AUDITOR: [AUDITOR].forEach((r) => allowedRoles.add(r)); break;
      default: break;
    }
  });

  return allowedRoles.has(currentRole);
};
