<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { ArchiveIcon } from '@heroicons/vue/outline';
import { useCategoryTranslate } from '@/utils/composables/useCategoryTranslate/useCategoryTranslate';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import { ReportingFrameworkEnum } from '@/__generated__/types';
import useDeleteDataPointTypeMutation from '@/api/mutations/DataPointRequest/deleteDataPointType.mutation';
import OgDataTableControls from '@/components/organisms/OgDataTable/OgDataTableControls.vue';
import AtIconUnarchive from '@/components/atoms/AtIconUnarchive.vue';
import type { Project, TArchivedQuestionDataTableItem } from '../../types';
import MlUnarchiveModal from './MlUnarchiveModal.vue';

const { t } = useI18n();
const translateCategory = useCategoryTranslate();
const route = useRoute();
const { isAdminOrSuperAdmin, assignedSubcategories } = useCurrentUser();

const props = defineProps<{
  projects: Project[],
}>();

const { mutate: deleteDataPointType, loading: deleteDataPointTypeLoading } = useDeleteDataPointTypeMutation({
  update: (store) => {
    store.evict({ id: 'ROOT_QUERY', fieldName: 'getMyDataPointRequests' });
    store.evict({ id: 'ROOT_QUERY', fieldName: 'entityLocationSummaries' });
  },
});

const project = computed(() => props.projects.find(({ _id }) => _id === String(route.params.project)));
const unSuspendLoadingItemIds = ref<string[]>([]);
const deletionLoadingItemIds = ref<string[]>([]);

const archivedQuestions = computed(() => {
  return project.value?.suspensions.datapointtypes;
});

const headers = computed(() => [
  { text: t('Question'), value: 'question', sortable: true },
  { text: t('Topic'), value: 'subcategory', sortable: true, filterable: true },
  { text: t('Actions'), value: 'actions', sortable: false, filterable: false },
]);

const items = computed<TArchivedQuestionDataTableItem[]>(() => archivedQuestions.value
  ?.filter((dpt) => dpt.activeReportingFramework.subcategory
    && (isAdminOrSuperAdmin.value || assignedSubcategories.value?.includes(dpt.activeReportingFramework.subcategory)))
  .map((dpt) => ({
    id: dpt._id,
    question: t(dpt.question ?? ''),
    subcategory: translateCategory(dpt.activeReportingFramework.category, dpt.activeReportingFramework.subcategory ?? ''),
    framework: dpt.activeReportingFramework.framework,
  })) ?? []);

const filteredAndSortedItems = ref(items.value);
watch(items, () => { filteredAndSortedItems.value = items.value; });

const isUnarchiveModalRevealed = ref(false);
const unarchiveDatatPointTypeId = ref('');

const handleShowUnarchiveModal = async (dptId: string) => {
  isUnarchiveModalRevealed.value = !isUnarchiveModalRevealed.value;
  unarchiveDatatPointTypeId.value = dptId;
  unSuspendLoadingItemIds.value.push(dptId);
};

const handleDeletion = async (dptId: string) => {
  deletionLoadingItemIds.value.push(dptId);
  await deleteDataPointType({ dataPointTypeId: dptId });
  deletionLoadingItemIds.value.splice(
    deletionLoadingItemIds.value.findIndex((deletionLoadingItemId) => deletionLoadingItemId === dptId),
    1);
};

function handleUnarchiveConfirm() {
  isUnarchiveModalRevealed.value = false;
  unSuspendLoadingItemIds.value.splice(
    unSuspendLoadingItemIds.value.findIndex((unSuspendLoadingItemId) => unSuspendLoadingItemId === unarchiveDatatPointTypeId.value),
    1);
}

</script>

<template>
  <div>
    <Teleport to="#projectLayoutControls">
      <div class="flex items-end">
        <OgDataTableControls
          v-if="items.length > 0"
          v-model="filteredAndSortedItems"
          :headers="headers"
          :items="items"
        />
      </div>
    </Teleport>
    <MlUnarchiveModal
      :isShown="isUnarchiveModalRevealed"
      :project="project"
      :dataPointTypeId="unarchiveDatatPointTypeId"
      @cancel="isUnarchiveModalRevealed = false"
      @confirm="handleUnarchiveConfirm"
    />
    <MlEmptyStateCard
      v-if="!items.length"
      :title="t('No archived questions')"
      :description="t('Archive irrelevant questions in the data entry page.')"
      noButton
    >
      <ArchiveIcon class="mb-8 h-24" />
    </MlEmptyStateCard>
    <OgDataTable
      v-else
      controlsHidden
      :headers="headers"
      :items="filteredAndSortedItems"
      wrapperClass="!pt-0 !border-b-0 px-6"
    >
      <template #item-actions="item">
        <div class="flex flex-wrap gap-2 ">
          <AtButton
            variant="text"
            class="text-gray-700 hover:text-primary"
            :icon="AtIconUnarchive"
            @click="handleShowUnarchiveModal(item.id)"
          >
            {{ t('Unarchive') }}
          </AtButton>
          <AtButton
            v-if="item.framework === ReportingFrameworkEnum.CUSTOM"
            variant="destructive"
            :loading="deleteDataPointTypeLoading && deletionLoadingItemIds.includes(item.id)"
            @click="handleDeletion(item.id)"
          >
            {{ t('Permanently delete') }}
          </AtButton>
        </div>
      </template>
    </OgDataTable>
  </div>
</template>
