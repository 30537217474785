import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  CreateEntityMutation,
  CreateEntityMutationVariables,
} from '@/__generated__/types';

const CREATE_ENTITY_MUTATION = gql`
  mutation CreateEntity($entityInput: EntityInput!) {
    createEntity(entityInput: $entityInput) {
      _id
      name
    }
  }
`;

type TOptions = UseMutationOptions<CreateEntityMutation, CreateEntityMutationVariables>

const useCreateEntityMutation = (options?: TOptions) => useMutation<
  CreateEntityMutation,
  CreateEntityMutationVariables
>(CREATE_ENTITY_MUTATION, options);

export default useCreateEntityMutation;
