import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  FollowDataPointRequestMutation,
  FollowDataPointRequestMutationVariables,
} from '@/__generated__/types';

export const FOLLOW_DATAPOINT_REQUEST = gql`
  mutation FollowDataPointRequest($dataPointRequestId: String!) {
    followDataPointRequest(dataPointRequestId: $dataPointRequestId) {
      _id
      followers {
        _id
      }
    }
  }
`;

type TOptions = UseMutationOptions<FollowDataPointRequestMutation, FollowDataPointRequestMutationVariables>

const useFollowDataPointRequestMutation = (options?: TOptions) => useMutation<
  FollowDataPointRequestMutation,
  FollowDataPointRequestMutationVariables
>(FOLLOW_DATAPOINT_REQUEST, options);

export default useFollowDataPointRequestMutation;
