<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, reactive, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { ArrowDownIcon, ArrowUpIcon, ArrowRightIcon } from '@heroicons/vue/solid';
import dayjs from '@/lib/dayjs/config';
import MlTextarea from '@/components/molecules/MlTextarea.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import MlDatePicker from '@/components/molecules/MlDatePicker.vue';
import OgDataPointTypeSelect, { type OgDataPointTypeSelectModelValue } from '@/components/organisms/OgDataPointTypeSelect/OgDataPointTypeSelect.vue';
import { type OgSetupKpiStepQuery, ComparisonValueTypeEnum, ComparisonOperatorEnum } from '@/__generated__/types';
import startingValueTooltip from '../../tooltips/startingValue.tooltip.vue';
import measurementTooltip from '../../tooltips/measurement.tooltip.vue';
import AtCreateKPIModalTitle from '../../AtCreateKPIModalTitle.vue';
import type { TCreateKPIFormData } from '../../../types';
import OG_SETUP_KPI_QUERY from './OgSetupKpiStep.query';

const { t } = useI18n();

const props = withDefaults(defineProps<{
  modelValue?: TCreateKPIFormData & { dataPointType: OgDataPointTypeSelectModelValue | null },
  loading?: boolean,
}>(), {
  modelValue: () => ({
    dataPointType: null,
    description: '',
    user: '',
    comparisonOperator: undefined as ComparisonOperatorEnum | undefined,
    comparisonValue: undefined as number | undefined,
    comparisonValueType: '' as ComparisonValueTypeEnum,
    startingDate: null as Date | null,
    startingValue: undefined as number | undefined,
    dueDate: null as Date | null,
  }),
});

const emit = defineEmits<{
  (e: 'update:modelValue', formData: TCreateKPIFormData): void,
}>();

const formData = reactive(props.modelValue);
watch(formData, () => emit('update:modelValue', formData));
const rules = computed(() => ({
  dataPointType: { required },
  description: { required },
  user: { required },
  comparisonValueType: { required },
  startingDate: { required },
  dueDate: { required },
  ...(formData.comparisonValueType !== ComparisonValueTypeEnum.YES_NO ? {
    comparisonOperator: { required },
    comparisonValue: { required },
  } : {}),
}));
const v = useVuelidate(rules, formData);

const { result } = useQuery<OgSetupKpiStepQuery>(OG_SETUP_KPI_QUERY);

const userOptions = computed(() => result.value?.getTeamUsers
  ?.reduce<Record<string, string>>((acc, { _id, firstName, lastName }) => ({ ...acc, [_id]: `${firstName} ${lastName}` }), {}));

const comparisonOperatorOptions = computed(() => ({
  [ComparisonOperatorEnum.GT]: t('Increase'),
  [ComparisonOperatorEnum.EQ]: t('Equals'),
  [ComparisonOperatorEnum.LT]: t('Decrease'),
}));

const comparisonOperatorOptionsIcons = computed(() => ({
  [ComparisonOperatorEnum.GT]: ArrowUpIcon,
  [ComparisonOperatorEnum.EQ]: ArrowRightIcon,
  [ComparisonOperatorEnum.LT]: ArrowDownIcon,
}));

const ComparisonValueTypeOptions = computed(() => ({
  [ComparisonValueTypeEnum.NUMBER]: t('# Numeric'),
  [ComparisonValueTypeEnum.PERCENT]: t('% Percent'),
  [ComparisonValueTypeEnum.YES_NO]: t('Yes/No'),
}));

const targetUnit = computed(() => ({
  unit: formData.comparisonValueType === ComparisonValueTypeEnum.PERCENT ? '%' : formData.dataPointType?.valueUnit,
  unitDivisor: formData.comparisonValueType === ComparisonValueTypeEnum.PERCENT ? '' : formData.dataPointType?.valueUnitDivisor,
}));
</script>

<template>
  <div>
    <AtCreateKPIModalTitle
      class="mt-12"
      :title="t('Set up KPI')"
      :subtitle="t('Convert this data point into a KPI to keep track of the progress over time.')"
    />

    <form class="mt-6 flex grid-cols-12 flex-col gap-6 xl:grid">
      <OgDataPointTypeSelect
        class="col-span-9"
        :invalid="v.dataPointType?.$error"
        selectClassWrapper="!max-w-[384px]"
        @update:modelValue="formData.dataPointType = $event ?? null"
      />
      <MlTextarea
        v-model.trim="formData.description"
        wrapperClass="col-span-9"
        :class="{ 'ring-2 ring-error': v.description.$error }"
        :label="t('Description')"
        :disabled="props.loading"
      />
      <MlSelect
        v-model="formData.user"
        wrapperClass="col-span-3"
        :class="{ 'ring-2 ring-error': v.user.$error }"
        :label="'Assignee'"
        :options="userOptions"
        :disabled="props.loading"
      />

      <MlSelect
        v-model="formData.comparisonValueType"
        wrapperClass="col-span-3"
        :class="{ 'ring-2 ring-error': v.comparisonValueType.$error }"
        :label="t('Measurement')"
        :options="ComparisonValueTypeOptions"
        :tooltip="measurementTooltip"
        :disabled="props.loading"
      />

      <template v-if="formData.comparisonValueType !== ComparisonValueTypeEnum.YES_NO">
        <AtInput
          v-model.number="formData.startingValue"
          wrapperClass="col-span-3"
          type="number"
          :disabled="props.loading"
          :tooltip="startingValueTooltip"
          label="Starting value (optional)"
          :unit="formData.dataPointType?.valueUnit"
          :unitDivisor="formData.dataPointType?.valueUnitDivisor"
        />
        <AtInput
          v-model.number="formData.comparisonValue"
          wrapperClass="col-span-3"
          :class="{ 'ring-2 ring-error': v.comparisonValue?.$error }"
          type="number"
          :disabled="props.loading"
          label="Target value"
          :unit="targetUnit.unit"
          :unitDivisor="targetUnit.unitDivisor"
        />
        <MlSelect
          v-model="formData.comparisonOperator"
          wrapperClass="col-span-3"
          :class="{ 'ring-2 ring-error': v.comparisonOperator?.$error }"
          :options="comparisonOperatorOptions"
          :optionIcons="comparisonOperatorOptionsIcons"
          :disabled="props.loading"
          sortedOptions
          label="Target action"
        />
      </template>

      <MlDatePicker
        v-model="formData.startingDate"
        wrapperClass="col-span-3"
        label="Start date"
        :disabled="props.loading"
        :yearRange="[(new Date()).getFullYear() - 6, (new Date()).getFullYear() + 3]"
        :class="{ 'ring-2 ring-error': v.startingDate.$error }"
        :tooltip="t('Select a date from which you want the data to be compared with your goal. You can select a date in the past or in the future.')"
      />
      <MlDatePicker
        v-model="formData.dueDate"
        wrapperClass="col-span-3"
        label="Due date"
        :yearRange="[(new Date()).getFullYear(), (new Date()).getFullYear() + 10]"
        :class="{ 'ring-2 ring-error': v.dueDate.$error }"
        :minDate="dayjs().add(1, 'day').toDate()"
        :disabled="props.loading"
      />
      <span class="col-span-4" />
    </form>
  </div>
</template>
