import { computed, inject } from 'vue';
import { useLazyQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import {
  type CsvReportQuery,
  type CsvReportQueryVariables,
  type PdfReportQuery,
  type PdfReportQueryVariables,
  type DocxReportQuery,
  type DocxReportQueryVariables,
  PdfReportTypeEnum,
  DataPointTypeEmissionTypeEnum,
} from '@/__generated__/types';
import dayjs from '@/lib/dayjs/config';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import generateReport from '@/utils/helpers/generateReport';
import saveFile from '@/utils/helpers/saveFile';
import waitForApolloQuery from '@/utils/helpers/waitForApolloQuery';
import CSV_REPORT_QUERY from './CsvReport.query';
import DOCX_REPORT_QUERY from './DocxReport.query';
import PDF_REPORT_QUERY from './PdfReport.query';

export function useCsvExport() {
  const { currentUser } = useCurrentUser();

  const query = useLazyQuery<CsvReportQuery, CsvReportQueryVariables>(
    CSV_REPORT_QUERY,
    undefined,
    { fetchPolicy: 'network-only' },
  );

  const exportFile = async (
    dataPointTypeIds: string[],
    dataPointTypeNames: string[],
    dateFrom: string,
    dateTo: string,
    locationId: string,
  ) => {
    const requestData: CsvReportQueryVariables = {
      dataPointTypeIds,
      dateFrom,
      dateTo,
      locationId,
    };

    // eslint-disable-next-line no-unused-expressions
    query.load(undefined, requestData) || query.refetch(requestData);
    const result = await waitForApolloQuery(query);

    if (result && !(result instanceof Error)) {
      generateReport(
        result.getDataPointsEncodedReport,
        currentUser.value?.lastName,
        'xlsx',
        dataPointTypeNames,
      );
    }
  };

  return {
    loading: query.loading,
    exportFile,
  };
}

export function useDocxExport() {
  const { currentUser } = useCurrentUser();

  const query = useLazyQuery<DocxReportQuery, DocxReportQueryVariables>(
    DOCX_REPORT_QUERY,
    undefined,
    { fetchPolicy: 'network-only' },
  );

  const exportFile = async (
    dataPointTypeIds: string[],
    dataPointTypeNames: string[],
    dateFrom: string,
    dateTo: string,
    locationId: string,
  ) => {
    const requestData: DocxReportQueryVariables = {
      dataPointTypeIds,
      dateFrom,
      dateTo,
      locationId,
      format: 'docx',
    };

    // eslint-disable-next-line no-unused-expressions
    query.load(undefined, requestData) || query.refetch(requestData);
    const result = await waitForApolloQuery(query);

    if (result && !(result instanceof Error)) {
      generateReport(
        result.getDataPointsEncodedReport,
        currentUser.value?.lastName,
        'docx',
        dataPointTypeNames,
      );
    }
  };

  return {
    loading: query.loading,
    exportFile,
  };
}

export function usePdfExport() {
  const adminMode = inject('adminMode', computed(() => false));
  const { t } = useI18n();
  const { currentUser } = useCurrentUser();

  const query = useLazyQuery<PdfReportQuery, PdfReportQueryVariables>(
    PDF_REPORT_QUERY,
    undefined,
    { fetchPolicy: 'network-only' },
  );

  const exportFile = async (
    reportType: PdfReportTypeEnum,
    dataPointTypeIds: string[],
    dateFrom: string,
    dateTo: string,
    selectedCategories: string[],
    projectId?: string,
    workspaceIds?: string[],
    scope2EmissionTypeExcluded?: DataPointTypeEmissionTypeEnum,
  ) => {
    const requestData: PdfReportQueryVariables = {
      isAdmin: adminMode.value,
      reportType,
      dataPointTypeIds,
      dateFrom,
      dateTo,
      projectId,
      workspaceIds,
      scope2EmissionTypeExcluded,
    };

    // eslint-disable-next-line no-unused-expressions
    query.load(undefined, requestData) || query.refetch(requestData);
    const result = await waitForApolloQuery(query);

    if (result && !(result instanceof Error)) {
      const pdfData = adminMode.value
        ? result.getDataPointsEncodedAdminPDFReport
        : result.getDataPointsEncodedPDFReport;
      if (pdfData) {
        const company = currentUser.value?.entity.name || '';
        const date = dayjs().format('DD-MM-YYYY');
        const categories = selectedCategories.map((category) => t(category)).join('_');
        const name = `${company}_Preliminary_report_${categories}_${date}.pdf`;

        saveFile(pdfData, name, 'application/pdf');
      }
    }
  };

  return {
    loading: query.loading,
    exportFile,
  };
}
