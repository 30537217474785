import { gql } from '@apollo/client/core';

export const GET_USER_REPOSITORY_FILES = gql`
  query GetUserRepositoryFiles {
    getUserRepositoryFiles {
      _id
      filename
    }
  }
`;
