<script setup lang="ts">
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { DotsHorizontalIcon, MinusIcon, PlusIcon } from '@heroicons/vue/outline';
import { DataPointTypeValueUnitEnum } from '@/__generated__/types';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import { CheckboxSize } from '@/components/atoms/AtCheckbox/types';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import AtMenuItem from '@/components/molecules/MlMenu/AtMenuItem.vue';
import type { TPartialRepositoryFile } from '@/components/molecules/MlFilePicker/types';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import OgPdfS3FilePicker from '@/components/molecules/MlFilePicker/OgPdfS3FilePicker.vue';

export type CoreDataType = 'turnover' | 'capEx' | 'opEx';

const props = defineProps<{
  type: CoreDataType;
  isCompleteLater: boolean;
}>();

const value = defineModel<number | null>('value');
const shouldApply = defineModel<boolean>('shouldApply');
const file = defineModel<TPartialRepositoryFile | null>('file');
const isLoading = defineModel<boolean>('isLoading');

const emit = defineEmits<{
  completeLater: [type: CoreDataType];
}>();

const { t } = useI18n();

const isFilePickerShown = ref(!!file.value);

const shouldApplyLabel = computed(() => {
  switch (props.type) {
    case 'turnover':
      return t('Apply to projects that have custom values in Turnover');
    case 'capEx':
      return t('Apply to projects that have custom values in CapEx');
    case 'opEx':
      return t('Apply to projects that have custom values in OpEx');
    default:
      throw new Error('Invalid type!');
  }
});

const inputLabel = computed(() => {
  switch (props.type) {
    case 'turnover':
      return t('Turnover');
    case 'capEx':
      return t('CapEx');
    case 'opEx':
      return t('OpEx');
    default:
      throw new Error('Invalid type!');
  }
});

async function handleCompleteLaterClick() {
  emit('completeLater', props.type);
}
</script>

<template>
  <div class="flex space-x-4">
    <AtInput
      v-model.number="value"
      type="number"
      labelClass="mb-3"
      wrapperClass="mb-1"
      :unit="DataPointTypeValueUnitEnum.EUR"
      :label="inputLabel"
      :placeholder="t(isCompleteLater ? 'Saved for later - complete now by typing in this field' : 'Enter value')"
    />
    <MlMenu class="mt-10">
      <button type="button" class="group flex items-center">
        <DotsHorizontalIcon class="h-5 w-5 group-disabled:opacity-50" />
      </button>
      <template #menuItems>
        <AtMenuItem class="justify-center" @click="handleCompleteLaterClick">
          {{ t('Complete later') }}
        </AtMenuItem>
      </template>
    </MlMenu>
    <AtCheckbox
      squared
      :label="shouldApplyLabel"
      :checked="shouldApply"
      :size="CheckboxSize.XS"
      @toggleCheckbox="shouldApply = !shouldApply"
    />
  </div>
  <button
    type="button"
    class="flex text-gray-400 text-xs hover:text-primary items-center space-x-2"
    @click.prevent="isFilePickerShown = !isFilePickerShown"
  >
    <MinusIcon v-if="isFilePickerShown" class="h-5" />
    <PlusIcon v-else class="h-5" />
    <span>{{ t('Add document') }}</span>
  </button>
  <OgPdfS3FilePicker
    v-if="isFilePickerShown"
    v-model="file"
    v-model:uploading="isLoading"
    class="row-span-2 max-w-xs"
    :placeholder="t('Select or drop proof document')"
    showRecentFiles
    compact
  />
</template>
