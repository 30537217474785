<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import {
  TaxonomyAssessmentBusinessActivityObjectiveEnum,
  type UpdateExternallyTaxonomyAssessmentBusinessActivityInput,
} from '@/__generated__/types';
import type { TPartialRepositoryFile } from '@/components/molecules/MlFilePicker/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtYesNoInput from '@/components/atoms/AtYesNoInput.vue';
import OgDocumentComment from '../../Assessment/OgDocumentComment.vue';
import OgRequestHelp from './OgRequestHelp.vue';

defineProps<{
  userId: string;
  isSaving: boolean;
  projectName: string;
  activityName: string;
  objective: TaxonomyAssessmentBusinessActivityObjectiveEnum;
  objectiveDescription: string;
}>();

const emit = defineEmits<{
  save: [input: UpdateExternallyTaxonomyAssessmentBusinessActivityInput];
}>();

const { t } = useI18n();

const value = ref<boolean | null>(null);
const comment = ref('');
const file = ref<TPartialRepositoryFile | null>(null);

function handleConfirm() {
  if (value.value !== null) {
    emit('save', {
      minimumStandardsRespected: value.value,
      minimumStandardsRespectedFileId: file.value?._id ?? null,
      minimumStandardsRespectedComment: comment.value || null,
    });
  }
}
</script>

<template>
  <div>
    <p class="font-bold mb-2">
      {{ t('Project') }} / {{ projectName }}
    </p>

    <p class="mb-1 text-gray-500">
      {{ t('Business activity') }}: {{ activityName }}
    </p>

    <p class="mb-4 text-gray-500">
      {{ t('EU-Taxonomy objective') }}: {{ t(objective) }}
    </p>

    <p class="text-lg font-medium mb-3">
      {{ t('Are the minimum safeguards related to UNGP, OECD recommendations and ILO conventions respected?') }}
    </p>

    <div class="flex space-x-4 justify-between mb-4">
      <p class="whitespace-pre-line break-normal pt-5">
        {{ activityName }}
      </p>
      <AtYesNoInput v-model="value" />
    </div>

    <OgDocumentComment
      v-model:comment="comment"
      v-model:file="file"
      :showRecentFiles="false"
      :userId="userId"
    />
  </div>

  <div class="flex justify-end items-center space-x-3">
    <OgRequestHelp />
    <AtButton type="button" :disabled="isSaving || value === null" @click="handleConfirm">
      {{ t('Save') }}
    </AtButton>
  </div>
</template>
