<script setup lang="ts">
import { computed, type Component, type HTMLAttributes } from 'vue';
import {
  type AsTag,
  SplitterGroup,
  // type SplitterGroupEmits,
  // type SplitterGroupProps,
  useForwardPropsEmits,
} from 'radix-vue';
import type { Direction } from 'radix-vue/dist/Splitter/utils/types';
import type { PanelGroupStorage } from 'radix-vue/dist/Splitter/SplitterGroup';
import { cn } from '@/components/ui/utils';

/* vue compiler sfc error: https://github.com/radix-vue/shadcn-vue/issues/207
* installing TS last version does not sovle the issue: https://github.com/radix-vue/shadcn-vue/issues/207#issuecomment-1912039593
* Copy-paste each prop and emit manually instead.
*/
// const props = defineProps<SplitterGroupProps & { class?: HTMLAttributes['class'] }>{
const props = defineProps<{
  asChild?: boolean;
  as?: AsTag | Component;

  /** Group id; falls back to `useId` when not provided. */
  id?: string | null;
  /** Unique id used to auto-save group arrangement via `localStorage`. */
  autoSaveId?: string | null;
  /** The group orientation of splitter. */
  direction: Direction;
  /** Step size when arrow key was pressed. */
  keyboardResizeBy?: number | null;
  /** Custom storage API; defaults to localStorage */
  storage?: PanelGroupStorage;
  class?: HTMLAttributes['class']
}>();

// const emits = defineEmits<SplitterGroupEmits>{
const emits = defineEmits<{
  /** Event handler called when group layout changes */
  layout: [val: number[]];
}>();

const delegatedProps = computed(() => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { class: _, ...delegated } = props;
  return delegated;
});

const forwarded = useForwardPropsEmits(delegatedProps, emits);
</script>

<template>
  <SplitterGroup v-bind="forwarded" :class="cn('flex h-full w-full data-[panel-group-direction=vertical]:flex-col', props.class)">
    <slot />
  </SplitterGroup>
</template>
