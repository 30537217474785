<script setup lang="ts">
import { useQuery } from '@vue/apollo-composable';
import { computed, inject, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { type TmInsightsViewQuery, UserRole } from '@/__generated__/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import { featureFlags } from '@/lib/flagsmith';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import MlTabs, { type Tab } from '@/components/molecules/MlTabs/MlTabs.vue';
import TmInsightDemoCharts from '@/components/pages/PgStatistics/TmInsightsView/TmInsightDemoCharts.vue';
import TM_INSIGHTS_VIEW_QUERY from './TmInsightsView.query';
import OgInsightsCard from './OgInsightsCard.vue';
import TmCustomInsightModal from './TmCustomInsightModal/TmCustomInsightModal.vue';
import type { Crud, DataPointsByDataPointType } from './types';
import { useDeleteInsight } from './useDeleteInsight';

const { t } = useI18n();
const adminMode = inject('adminMode', computed(() => false));

const { result, loading, refetch } = useQuery<TmInsightsViewQuery>(TM_INSIGHTS_VIEW_QUERY, {
  adminMode: adminMode.value,
});

const { deleteInsight } = useDeleteInsight(
  { refetchQueries: [{ query: TM_INSIGHTS_VIEW_QUERY, variables: { adminMode: adminMode.value } }] },
);

const projects = computed(() => (adminMode.value ? result.value?.getCurrentUserEntities : result.value?.entityLocationSummaries) ?? []);
const dataPoints = computed(() => (adminMode.value ? result.value?.getInsightDataPointsAdmin : result.value?.getInsightDataPoints) ?? []);
const insightDataPointTypes = computed(() => (adminMode.value
  ? result.value?.getInsightDataPointTypesAdmin : result.value?.getInsightDataPointTypes) ?? []);
const insightDataPointTypesFiltered = computed(
  () => insightDataPointTypes.value.filter((dpt) => !!dataPoints.value.find((dp) => dp.dataPointType._id === dpt._id)),
);
const insightDataPointTypesEditable = computed(
  () => insightDataPointTypesFiltered.value.filter((dpt) => !!dpt.formularDependencies.length),
);
const isInsightsModalRevealed = ref(false);
const openedInsightId = ref<string | null>(null);

const currentUserRole = computed(() => result.value?.getOwnUser.role ?? UserRole.CONTRIBUTOR);
const isEmpty = computed(() => !insightDataPointTypesFiltered.value.length);
const dataPointsByDataPointTypes = computed(
  () => insightDataPointTypesFiltered.value.map<DataPointsByDataPointType>((dataPointType) => ({
    dataPointType,
    dataPoints: dataPoints.value.filter((dp) => dp.dataPointType._id === dataPointType._id),
  })),
);

const insightModalState = ref<Crud | null>(null);

function resetModal() {
  insightModalState.value = null;
  openedInsightId.value = null;
  isInsightsModalRevealed.value = false;
}

function openModal(crud: Crud, insightId?: string) {
  resetModal();
  if (insightId) {
    openedInsightId.value = insightId;
    if (crud === 'delete') {
      return deleteInsight(
        openedInsightId.value,
        { text: insightDataPointTypes.value.find((insight) => insight._id === openedInsightId.value as string)?.friendlyName },
      );
    }
  }
  insightModalState.value = crud;
  isInsightsModalRevealed.value = true;
}

const showSiemensBenchmarks = computed(() => result.value?.getOwnUser.email.includes('siemens'));

const tabs: Tab[] = [
  { name: 'External benchmarking', value: 'external' },
  { name: 'Internal insights', value: 'internal' },
];
const activeTab = ref<Tab>(tabs[1]);

watch(showSiemensBenchmarks, () => {
  if (showSiemensBenchmarks.value && activeTab.value.value !== 'external') {
    // eslint-disable-next-line prefer-destructuring
    activeTab.value = tabs[0];
  }
});

</script>
<template>
  <div class="pt-5 px-6 flex flex-col">
    <div class="sticky top-0 flex items-end justify-between bg-white mb-10 z-10">
      <div>
        <h1
          class="text-lg font-medium leading-6 mb-3"
          data-cy="insightsName"
        >
          {{ t('Insights') }}
        </h1>
        <p class="text-gray-500 text-sm">
          {{ t('Compare and contrast data points to discover hidden trends.') }}
        </p>
      </div>
      <div class="flex">
        <!-- <AtButton
          v-if="featureFlags.insights"
          class="ml-auto"
          :icon="DownloadIcon"
          variant="outline"
        >
          {{ t('Download PDF') }}
        </AtButton> -->
        <AtButton
          v-if="featureFlags.insights && !adminMode"
          v-rolefrom="UserRole.MANAGER"
          class="ml-2"
          @click.stop="openModal('create')"
        >
          {{ t('Create') }}
        </AtButton>
      </div>
    </div>

    <MlTabs
      v-if="showSiemensBenchmarks"
      :modelValue="activeTab"
      :tabs="tabs"
      class="mb-1 ml-6"
      isEdgeToEdge
      @update:modelValue="activeTab = $event"
    />

    <div v-if="activeTab.value === 'external'">
      <TmInsightDemoCharts />
    </div>

    <div v-if="activeTab.value === 'internal'">
      <MlEmptyStateCard
        v-if="isEmpty && !loading && currentUserRole !== UserRole.CONTRIBUTOR && !adminMode"
        :title="t('No insights yet')"
        :description="t('Start by creating your first insight.')"
        :buttonText="t('Create insight')"
        @buttonClick="openModal('create')"
      />
      <MlEmptyStateCard
        v-else-if="isEmpty && !loading && (currentUserRole === UserRole.CONTRIBUTOR || adminMode)"
        :title="t('No insights yet')"
        noButton
      />
      <div v-else>
        <OgInsightsCard
          v-for="dataPointsByDataPointType in dataPointsByDataPointTypes"
          :key="dataPointsByDataPointType.dataPointType._id"
          :dataPointsByDataPointType="dataPointsByDataPointType"
          :projects="projects"
          :isEditable="!!insightDataPointTypesEditable.find((insight) => insight._id === dataPointsByDataPointType.dataPointType._id)"
          @openModal="openModal($event.crud, $event.insightId)"
        />
      </div>
      <TmCustomInsightModal
        v-model:isRevealed="isInsightsModalRevealed"
        v-rolefrom="UserRole.CONTRIBUTOR"
        :state="insightModalState"
        :insight="insightDataPointTypesEditable.find((insight) => insight._id === openedInsightId)"
        @update:isRevealed="!isEmpty ? refetch() : console.log('no refetch')"
      />
    </div>
  </div>
</template>
