import { gql } from '@apollo/client/core';

export default gql`
query CategoriesWithSubcategories {
  getCategoriesWithSubcategories {
    _id
    slug
    organization {
      _id
    }
    entity {
      _id
    }
    location {
      _id
    }
    createdAt
    createdBy {
      _id
      firstName
      lastName
    }
    subcategories {
      _id
      slug
      orderPosition
    }
  }
}
`;
