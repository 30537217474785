<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { notify } from '@kyvg/vue3-notification';
import { auth0 } from '@/api/auth/auth';

const { t, locale } = useI18n();
const route = useRoute();

const email = ref(route.query.email as string);
const password = ref('');
const passwordConfirmation = ref('');
const isDone = ref(false);

const next = (e: Event) => {
  if (!(e.target as HTMLFormElement).reportValidity()) {
    return;
  }

  if (password.value !== passwordConfirmation.value) {
    notify({ type: 'error', text: t("Passwords don't match") });
    return;
  }

  auth0.signup({
    connection: 'Username-Password-Authentication',
    email: email.value,
    password: password.value,
    userMetadata: {
      language: locale.value,
    },
  }, (err) => {
    if (err) {
      if (err.code === 'invalid_password') {
        notify({ type: 'error', text: t('Password must be at least 8 characters and contain at least one lower case letter, one upper case letter, a number and a special character. Passwords are case sensitive.') });
      } else {
        // eslint-disable-next-line no-console
        console.error(err);
        notify({ type: 'error', text: err.description });
      }

      return;
    }

    isDone.value = true;
  });

  return false;
};
</script>

<template>
  <div
    id="box-4"
    class="box mt-8"
  >
    <div>
      <div>
        <form
          v-if="!isDone"
          @submit.prevent="next"
        >
          <p id="error-message-password" />

          <div class="">
            <div class="my-2">
              <label
                for="password-login-email"
                class="block text-sm font-medium text-gray-700"
              >{{ t('Email address') }}</label>
              <div class="mt-1">
                <input
                  id="password-login-email"
                  v-model="email"
                  name="password-login-email"
                  type="email"
                  placeholder="max@mustermann.de"
                  autocomplete="email"
                  required
                  class="block w-full appearance-none rounded-md border border-gray-400 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                >
              </div>
            </div>

            <div
              id="password-box"
              class="my-2"
            >
              <label
                for="password"
                class="block text-sm font-medium text-gray-700"
              >{{ t('Password') }}</label>
              <div class="mt-1">
                <input
                  id="password"
                  v-model="password"
                  name="password"
                  type="password"
                  required
                  class="block w-full appearance-none rounded-md border border-gray-400 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                >
              </div>
            </div>

            <div
              id="passwordConfirmation-box"
              class="my-2"
            >
              <label
                for="passwordConfirmation"
                class="block text-sm font-medium text-gray-700"
              >{{ t('Confirm password') }}</label>
              <div class="mt-1">
                <input
                  id="passwordConfirmation"
                  v-model="passwordConfirmation"
                  name="passwordConfirmation"
                  type="password"
                  required
                  class="block w-full appearance-none rounded-md border border-gray-400 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
                >
              </div>
            </div>
          </div>

          <div class="mt-8">
            <button
              id="btn-verify-password"
              type="submit"
              class="flex w-full justify-center rounded-md border border-transparent bg-blue-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
            >
              {{ t('Sign up') }}
            </button>
          </div>
        </form>
        <div v-if="isDone">
          <h1 class="mb-4 text-2xl">
            {{ t('Check your inbox') }}
          </h1>

          <p>{{ t('Almost there! Please click the link in the email we just sent you to verify it’s you.') }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
