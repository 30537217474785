<script setup lang="ts">
import { computed } from 'vue';
import { ProgressStatus } from './types';

const {
  percent,
  status = ProgressStatus.DEFAULT,
  showProgressionColors = false,
  percentDecimals = false,
  size = 'sm',
  contrast = 'low',
  foreground,
} = defineProps<{
  percent?: number;
  status?: `${ProgressStatus}`;
  showPercentNumber?: boolean;
  foreground?: boolean;
  showProgressionColors?: boolean;
  percentDecimals?: boolean;
  size?: 'sm' | 'md' | 'lg';
  contrast?: 'low' | 'high';
}>();

const classObject = computed(() => ({
  'progress h-1': true,
  'bg-gray-50': contrast === 'low',
  'bg-gray-200': contrast === 'high',
  'h-1': size === 'sm',
  'h-2': size === 'md',
  'h-6': size === 'lg',
  'progress-primary text-blue-50': status === ProgressStatus.DEFAULT,
  'progress-warning text-yellow-50': status === ProgressStatus.WARNING,
  'progress-error text-red-100': status === ProgressStatus.ERROR,
  'progress-success text-green-800': status === ProgressStatus.SUCCESS,
  'progress-success': percent && percent === 100 && showProgressionColors,
  'progress-warning': percent && percent >= 50 && percent < 100 && showProgressionColors,
  'progress-error': percent && percent >= 0 && percent < 50 && showProgressionColors,
  'bg-transparent': foreground,
}));
</script>

<template>
  <div
    class="flex items-center justify-between gap-2"
    :class="{ 'absolute left-0 top-0 z-20 w-full': foreground }"
  >
    <progress
      :class="classObject"
      :value="percent"
      max="100"
      v-bind="$attrs"
    />
    <div
      v-if="showPercentNumber"
      class="shrink-0 w-11 text-2xs text-center font-medium leading-none"
    >
      <template v-if="percentDecimals">
        {{ percent?.toFixed(2) }}%
      </template>
      <template v-if="!percentDecimals">
        {{ percent?.toFixed(0) }}%
      </template>
    </div>
  </div>
</template>
