import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import type { CompleteCustomQuestionnaireMutation, CompleteCustomQuestionnaireMutationVariables } from '@/__generated__/types';

export const COMPLETE_QUESTIONNAIRE = gql`
  mutation CompleteCustomQuestionnaire($customQuestionnaireId: String!) {
    completeCustomQuestionnaire(customQuestionnaireId: $customQuestionnaireId) {
      _id
      category {
        _id
        slug
      }
      subcategory {
        _id
        slug
      }
    }
  }
`;

type Options = UseMutationOptions<CompleteCustomQuestionnaireMutation, CompleteCustomQuestionnaireMutationVariables>

const useCompleteCustomQuestionnaireMutation = (options?: Options) => useMutation<
  CompleteCustomQuestionnaireMutation,
  CompleteCustomQuestionnaireMutationVariables
>(COMPLETE_QUESTIONNAIRE, options);

export default useCompleteCustomQuestionnaireMutation;
