<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { useQuery } from '@vue/apollo-composable';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import type { OgCoreDataDialogQuery } from '@/__generated__/types';
import useUpdateTaxonomyCoreDataMutation from '@/api/mutations/Organization/updateTaxonomyCoreData.mutation';
import useConfirmViaDialog from '@/utils/composables/useConfirmViaDialog';
import type { TPartialRepositoryFile } from '@/components/molecules/MlFilePicker/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import { CheckboxSize } from '@/components/atoms/AtCheckbox/types';
import MlDialog from '@/components/molecules/MlDialog.vue';
import MlCoreDataInput, { type CoreDataType } from './MlCoreDataInput.vue';
import OG_CORE_DATA_DIALOG_QUERY from './OgCoreDataDialog.query';

type CoreDataForm = {
  turnover: number | null;
  turnoverFile: TPartialRepositoryFile | null;
  turnoverCompleteLater: boolean;
  turnoverShouldApply: boolean;
  capEx: number | null;
  capExFile: TPartialRepositoryFile | null;
  capExCompleteLater: boolean;
  capExShouldApply: boolean;
  opEx: number | null;
  opExFile: TPartialRepositoryFile | null;
  opExCompleteLater: boolean;
  minimumSafeguards: boolean | null;
  opExShouldApply: boolean;
};

const emit = defineEmits<{
  close: [],
}>();

const { t } = useI18n();
const { confirmViaDialog } = useConfirmViaDialog();

const isSaved = ref(false);
const hideDialog = ref(false);

const { result, loading } = useQuery<OgCoreDataDialogQuery>(OG_CORE_DATA_DIALOG_QUERY);

const { mutate, loading: saving } = useUpdateTaxonomyCoreDataMutation();

const isDisabled = computed(() => loading.value);
const isSubmitting = computed(() => saving.value);

const form = reactive<CoreDataForm>({
  turnover: null,
  turnoverFile: null,
  turnoverCompleteLater: false,
  turnoverShouldApply: false,
  capEx: null,
  capExFile: null,
  capExCompleteLater: false,
  capExShouldApply: false,
  opEx: null,
  opExFile: null,
  opExCompleteLater: false,
  opExShouldApply: false,
  minimumSafeguards: null,
});

watch(() => result?.value?.getTaxonomyCoreData, (newTaxonomyCoreData) => {
  if (newTaxonomyCoreData) {
    form.turnover = newTaxonomyCoreData.turnover ?? null;
    form.turnoverFile = newTaxonomyCoreData.turnoverFile?.file ?? null;
    form.turnoverCompleteLater = newTaxonomyCoreData.turnoverCompleteLater ?? false;
    form.capEx = newTaxonomyCoreData.capEx ?? null;
    form.capExFile = newTaxonomyCoreData.capExFile?.file ?? null;
    form.capExCompleteLater = newTaxonomyCoreData.capExCompleteLater ?? false;
    form.opEx = newTaxonomyCoreData.opEx ?? null;
    form.opExFile = newTaxonomyCoreData.opExFile?.file ?? null;
    form.opExCompleteLater = newTaxonomyCoreData.opExCompleteLater ?? false;
    form.minimumSafeguards = newTaxonomyCoreData.minimumSafeguards ?? null;
  }
}, { immediate: true });

watch(() => form.turnover, (newTurnoverValue) => {
  if (typeof newTurnoverValue === 'number') {
    form.turnoverCompleteLater = false;
  }
});
watch(() => form.capEx, (newCapExValue) => {
  if (typeof newCapExValue === 'number') {
    form.capExCompleteLater = false;
  }
});
watch(() => form.opEx, (newOpExValue) => {
  if (typeof newOpExValue === 'number') {
    form.opExCompleteLater = false;
  }
});

function handleClose() {
  emit('close');
}

async function handleCompleteLater(field: CoreDataType) {
  hideDialog.value = true;

  const isConfirmed = await confirmViaDialog({
    title: t('Complete this input later'),
    text: `<p>${t('Marking this field means you want to add your financial data later, while going through other stages of your EU Taxonomy assessment. You will be reminded to add the data and you will not be able to complete the assessments without it.')}</p><br /><p>${t('Would you like to continue?')}</p>`,
    renderHTML: true,
    confirmLabel: t('Confirm'),
    cancelLabel: t('Go back'),
  });

  if (isConfirmed) {
    switch (field) {
      case 'turnover':
        form.turnoverCompleteLater = true;
        form.turnover = null;
        break;
      case 'capEx':
        form.capExCompleteLater = true;
        form.capEx = null;
        break;
      case 'opEx':
        form.opExCompleteLater = true;
        form.opEx = null;
        break;
      default:
        throw new Error('Invalid field');
    }
  }

  hideDialog.value = false;
}

async function handleSubmit() {
  try {
    await mutate({
      input: {
        turnover: form.turnover,
        turnoverFileId: form.turnoverFile?._id ?? null,
        turnoverCompleteLater: form.turnoverCompleteLater,
        turnoverShouldApply: form.turnoverShouldApply,
        capEx: form.capEx,
        capExFileId: form.capExFile?._id ?? null,
        capExCompleteLater: form.capExCompleteLater,
        capExShouldApply: form.capExShouldApply,
        opEx: form.opEx,
        opExFileId: form.opExFile?._id ?? null,
        opExCompleteLater: form.opExCompleteLater,
        opExShouldApply: form.opExShouldApply,
        minimumSafeguards: form.minimumSafeguards,
      },
    }, {
      update: (store) => {
        store.evict({ fieldName: 'getTaxonomyProjects' });
      },
    });
    isSaved.value = true;
  } catch {
    notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
  }
}
</script>

<template>
  <MlDialog
    :isOpen="hideDialog === false"
    :class="{
      '!bg-blue-50': isSaved,
    }"
    :closeOnOutside="false"
    @close="handleClose"
  >
    <template #title>
      {{ isSaved ? t('Success!') : t('Assign, inspect or amend the core values') }}
    </template>

    <template #default="{ close }">
      <form v-if="!isSaved" :key="loading.toString()" class="min-w-[600px] pt-9" @submit.prevent="handleSubmit">
        <div class="max-w-md mb-6">
          <div class="mb-4">
            <MlCoreDataInput
              v-model:value="form.turnover"
              v-model:file="form.turnoverFile"
              v-model:isLoading="isSubmitting"
              v-model:shouldApply="form.turnoverShouldApply"
              type="turnover"
              :isCompleteLater="form.turnoverCompleteLater"
              @completeLater="handleCompleteLater"
            />
          </div>
          <div class="mb-4">
            <MlCoreDataInput
              v-model:value="form.capEx"
              v-model:file="form.capExFile"
              v-model:isLoading="isSubmitting"
              v-model:shouldApply="form.capExShouldApply"
              type="capEx"
              :isCompleteLater="form.capExCompleteLater"
              @completeLater="handleCompleteLater"
            />
          </div>
          <div>
            <MlCoreDataInput
              v-model:value="form.opEx"
              v-model:file="form.opExFile"
              v-model:isLoading="isSubmitting"
              v-model:shouldApply="form.opExShouldApply"
              type="opEx"
              :isCompleteLater="form.opExCompleteLater"
              :label="t('OpEx')"
              @completeLater="handleCompleteLater"
            />
          </div>
        </div>

        <div class="mb-14">
          <p class="mb-3 font-medium">
            {{ t('Are the minimum safeguards related to UNGP, OECD recommendations and ILO conventions respected? ') }}
          </p>
          <p class="mb-4">
            <i18n-t keypath="According to Article 18 of the Taxonomy Regulation (EU) 2020/852, the minimum safeguards referred to in {link} shall be procedures implemented by an undertaking that is carrying out an economic activity to ensure the alignment with the OECD Guidelines for Multinational Enterprises and the UN Guiding Principles on Business and Human Rights, including the principles and rights set out in the eight fundamental conventions identified in the Declaration of the International Labour Organisation on Fundamental Principles and Rights at Work and the International Bill of Human Rights.">
              <template #link>
                <a
                  href="https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=celex:32020R0852"
                  rel="noopener noreferrer"
                  target="_blank"
                  class="underline"
                >{{ t('point (c) of Article 3') }}</a>
              </template>
            </i18n-t>
          </p>
          <AtCheckbox
            squared
            :label="t('Yes, I confirm that the minimum safeguards are respected for all activities in the EU Taxonomy assessment.')"
            :checked="form.minimumSafeguards === true"
            :size="CheckboxSize.XS"
            @toggleCheckbox="form.minimumSafeguards = true"
          />
          <AtCheckbox
            squared
            :label="t('No, the minimum safeguards are not respected for all activities in the EU Taxonomy assessment. I want to assess this individually for each of the EU Taxonomy projects.')"
            :checked="form.minimumSafeguards === false"
            :size="CheckboxSize.XS"
            @toggleCheckbox="form.minimumSafeguards = false"
          />
        </div>

        <div class="flex space-x-2 justify-between">
          <AtButton type="button" variant="outline" @click="close">
            {{ t('Cancel') }}
          </AtButton>
          <AtButton type="submit" :disabled="isDisabled">
            {{ t('Save') }}
          </AtButton>
        </div>
      </form>

      <div v-else class="pt-4 max-w-md">
        <p class="text-sm mb-8">
          {{ t('You have successfully saved your core data. You can always inspect and change the values on this page.') }}
        </p>
        <div class="flex justify-end">
          <AtButton type="button" variant="default" @click="close">
            {{ t('Finish and close') }}
          </AtButton>
        </div>
      </div>
    </template>
  </MlDialog>
</template>
