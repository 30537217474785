import sortBy from 'lodash/sortBy';
import sum from 'lodash/sum';
import { useI18n } from 'vue-i18n';
import { type TmDashboardChartSelectionQuery, ValueDataTypeEnum, SummarizingMethodEnum, type TmChartSelectionQuery } from '@/__generated__/types';
import type { TStringOfDigits } from '@/types/types';
import type { TDataPointItem } from '@/components/pages/PgStatistics/PgResults/types';

export const groupDataPointsByProject = ({ dataPointsByType, items }: {
    dataPointsByType:
       TmDashboardChartSelectionQuery['getAdminDataPointsByType']
     | TmChartSelectionQuery['getDataPointsByType']
     | TmChartSelectionQuery['getAdminDataPointsByType']
     ;
    items: TDataPointItem[]
}) => {
  const { t } = useI18n();
  const itemsReduced = items
    .reduce((acc: TDataPointItem[], curr: TDataPointItem, currIndex: number) => {
      const key = curr.projectId;
      const sameProjectDataPoint = acc.find((el) => el.projectId === key);
      if (sameProjectDataPoint) {
        const sameProjectDataPointIndex = acc.indexOf(sameProjectDataPoint);
        acc.splice(currIndex, 1, { ...sameProjectDataPoint, ...curr });
        acc.splice(sameProjectDataPointIndex, 1);
      } else {
        acc.push(curr);
      }
      return sortBy(acc, [(a) => a.project.toLowerCase()]);
    }, []);

  // Create total row if value is numeric
  if (
    dataPointsByType != null
    && dataPointsByType.length > 0
      && dataPointsByType[0].dataPointType.valueDataType === ValueDataTypeEnum.NUMERIC
      && dataPointsByType[0].dataPointType.summarizingMethod
      && itemsReduced.length > 0
  ) {
    const columnHeaders = [
      ...new Set(dataPointsByType.map((dpByType) => `${dpByType.from}${dpByType.to}`)),
    ] as TStringOfDigits[];

    const totalsPerColumn = [
      ...itemsReduced,
      columnHeaders.reduce((acc, curr) => {
        const reducedItemWithDpt = itemsReduced.find((r) => r[curr]?.dataPointType);

        if (!reducedItemWithDpt) return acc;

        const summarizingMethod = reducedItemWithDpt[curr]?.dataPointType.summarizingMethod ?? SummarizingMethodEnum.SUM;
        const translatedTotalColumnTitle = summarizingMethod === SummarizingMethodEnum.SUM ? t('Total (Sum)') : t('Total (Avg)');

        return {
          ...acc,
          project: translatedTotalColumnTitle,
          projectId: `allProjects-${reducedItemWithDpt.projectId}`,
          dataPointTypeId: reducedItemWithDpt.dataPointTypeId,
          header: reducedItemWithDpt.header,
          [curr]: {
            dataPointType: reducedItemWithDpt[curr]?.dataPointType,
            value: summarizingMethod === SummarizingMethodEnum.AVERAGE
              ? sum(items
                .filter((it) => it.header.value === curr)
                .map((_it) => _it[_it.header.value].value),
              ) / itemsReduced.length
              : sum(items
                .filter((it) => it.header.value === curr)
                .map((_it) => _it[_it.header.value].value),
              ),
          },
        };
      }, {}),
    ];

    return totalsPerColumn;
  }
  return itemsReduced;
};
