<script setup lang="ts">
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { boxed } from '@syncedstore/core';
import { useVuelidate } from '@vuelidate/core';
import { helpers, minValue, required } from '@vuelidate/validators';
import { DataPointTypeValueUnitEnum } from '@/__generated__/types';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import { useStore } from '../../../store';
import OgDocument, { type UploadedDocument } from '../../../OgDocument.vue';

type PotentialMagnitudeKey =
  | 'criticallyRelevantFinancialEffect'
  | 'significantFinancialEffect'
  | 'relevantFinancialEffect'
  | 'lowFinancialEffect'
  | 'minorFinancialEffect'
  | 'almostNoFinancialEffect';

type RowItem = {
  label: string;
  key: PotentialMagnitudeKey;
  inputSuffix: string;
};

const { t } = useI18n();
const store = useStore();

const { currentUser } = useCurrentUser();

const readOnlyForm = computed(() => ({
  criticallyRelevantFinancialEffect: {
    value: store.value.potentialMagnitude.criticallyRelevantFinancialEffectValue,
  },
  significantFinancialEffect: {
    value: store.value.potentialMagnitude.significantFinancialEffectValue,
  },
  relevantFinancialEffect: {
    value: store.value.potentialMagnitude.relevantFinancialEffectValue,
  },
  lowFinancialEffect: {
    value: store.value.potentialMagnitude.lowFinancialEffectValue,
  },
  minorFinancialEffect: {
    value: store.value.potentialMagnitude.minorFinancialEffectValue,
  },
  almostNoFinancialEffect: {
    value: store.value.potentialMagnitude.almostNoFinancialEffectValue,
  },
}));
const validationRules = computed(() => ({
  criticallyRelevantFinancialEffect: {
    value: {
      required: helpers.withMessage(t('Value is required.'), required),
      minValue: helpers.withMessage(
        ({ $params }) => t('Minimum value is {min}.', { min: $params.min }),
        minValue(0),
      ),
    },
  },
  significantFinancialEffect: {
    value: {
      required: helpers.withMessage(t('Value is required.'), required),
      minValue: helpers.withMessage(
        ({ $params }) => t('Minimum value is {min}.', { min: $params.min }),
        minValue(0),
      ),
    },
  },
  relevantFinancialEffect: {
    value: {
      required: helpers.withMessage(t('Value is required.'), required),
      minValue: helpers.withMessage(
        ({ $params }) => t('Minimum value is {min}.', { min: $params.min }),
        minValue(0),
      ),
    },
  },
  lowFinancialEffect: {
    value: {
      required: helpers.withMessage(t('Value is required.'), required),
      minValue: helpers.withMessage(
        ({ $params }) => t('Minimum value is {min}.', { min: $params.min }),
        minValue(0),
      ),
    },
  },
  minorFinancialEffect: {
    value: {
      required: helpers.withMessage(t('Value is required.'), required),
      minValue: helpers.withMessage(
        ({ $params }) => t('Minimum value is {min}.', { min: $params.min }),
        minValue(0),
      ),
    },
  },
  almostNoFinancialEffect: {
    value: {
      required: helpers.withMessage(t('Value is required.'), required),
      minValue: helpers.withMessage(
        ({ $params }) => t('Minimum value is {min}.', { min: $params.min }),
        minValue(0),
      ),
    },
  },
}));
const v$ = useVuelidate(validationRules, readOnlyForm);

watch(readOnlyForm, (newPotentialMagnitudeValue) => {
  if (Object.keys(newPotentialMagnitudeValue).length > 0) {
    ([
      'criticallyRelevantFinancialEffect',
      'significantFinancialEffect',
      'relevantFinancialEffect',
      'lowFinancialEffect',
      'minorFinancialEffect',
      'almostNoFinancialEffect',
    ] as const)
      .forEach((key) => {
        if (newPotentialMagnitudeValue[key].value !== null && newPotentialMagnitudeValue[key].value !== undefined) {
          v$.value[key].$touch();
        }
      });
  }
}, { immediate: true });

const headers = computed(() => [
  { text: t('Value of potential magnitude'), value: 'potentialMagnitudeValue' },
  { text: t('Financial value'), value: 'financialValue' },
  { text: t('Comment'), value: 'comment' },
]);

const items = computed<RowItem[]>(() => [
  {
    label: t('6 - Critically relevant financial effect'),
    key: 'criticallyRelevantFinancialEffect',
    inputSuffix: t('is equal or greater than'),
  },
  {
    label: t('5 - Significant financial effect'),
    key: 'significantFinancialEffect',
    inputSuffix: t('is equal or less than'),
  },
  {
    label: t('4 - Relevant financial effect'),
    key: 'relevantFinancialEffect',
    inputSuffix: t('is equal or less than'),
  },
  {
    label: t('3 - Low financial effect'),
    key: 'lowFinancialEffect',
    inputSuffix: t('is equal or less than'),
  },
  {
    label: t('2 - Minor financial effect'),
    key: 'minorFinancialEffect',
    inputSuffix: t('is equal or less than'),
  },
  {
    label: t('1 - Almost no financial effect'),
    key: 'almostNoFinancialEffect',
    inputSuffix: t('is equal or less than'),
  },
]);

function handleFileChange(key: PotentialMagnitudeKey, event: UploadedDocument) {
  store.value.potentialMagnitude[`${key}Document`] = boxed(event);
}
</script>

<template>
  <p class="font-bold">
    {{ t('Definition of potential magnitude') }}
  </p>
  <p class="text-gray-500">
    {{ t('Please define the scales of the potential magnitude of financial effects in the short-, medium-, and long-term which shall be used during the financial materiality assessment.') }}
  </p>
  <form class="mb-14" @submit.prevent="">
    <OgDataTable
      :headers="headers"
      :items="items"
      wrapperClass="og-potential-magnitude-table"
      controlsHidden
      noPagination
    >
      <template #item-potentialMagnitudeValue="row: RowItem">
        <div class="flex items-center space-x-2.5 mb-3">
          <AtInput
            wrapperClass="min-w-[294px]"
            type="text"
            readonly
            disabled
            :value="row.label"
          />
          <p class="text-gray-700 italic">
            {{ row.inputSuffix }}
          </p>
        </div>
        <div class="-ml-2">
          <OgDocument
            :userId="currentUser?._id!"
            :file="(store.potentialMagnitude[`${row.key}Document`] ?? null) as unknown as UploadedDocument"
            @update:file="handleFileChange(row.key, $event)"
          />
        </div>
      </template>
      <template #item-financialValue="row: RowItem">
        <AtInput
          v-model.number="store.potentialMagnitude[`${row.key}Value`]"
          type="number"
          :placeholder="t('Enter value')"
          :unit="DataPointTypeValueUnitEnum.EUR"
          :errors="v$[row.key].value.$errors"
          @blur="v$[row.key].value.$touch"
        />
      </template>
      <template #item-comment="row: RowItem">
        <AtInput
          v-model.trim="store.potentialMagnitude[`${row.key}Comment`]"
          type="text"
          :placeholder="t('Enter')"
        />
      </template>
    </OgDataTable>
  </form>
</template>

<style lang="postcss" scoped>
.og-potential-magnitude-table:deep(tbody td) {
  vertical-align: top;
}
</style>
