import * as Sentry from '@sentry/vue';
import type { App } from 'vue';
import type { Router } from 'vue-router';
import { watch } from 'vue';
import { currentUser } from '@/utils/composables/useCurrentUser/useCurrentUser';

export const registerSentry = (app: App<Element>, router: Router) => {
  const enabled = import.meta.env.VITE_SENTRY_ENABLED;
  const dsn = import.meta.env.VITE_SENTRY_DSN;

  if (enabled === 'true' && dsn) {
    Sentry.init({
      app,
      environment: import.meta.env.MODE,
      dsn,
      release: import.meta.env.VITE_SENTRY_RELEASE,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      // Capture Replay for 10% of all sessions,
      // plus for 100% of sessions with an error
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    });

    watch(currentUser, () => {
      if (!currentUser.value) return;

      Sentry.setUser({
        id: currentUser.value._id,
        email: currentUser.value.email,
      });
    }, { immediate: true });
  }
};
