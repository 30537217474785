<script setup lang="ts">
import { h, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { GlobeIcon } from '@heroicons/vue/outline';
import EmissionSplitInput from '@/components/atoms/EmissionSplitInput.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import useFormatNumber from '@/utils/composables/useFormatNumber';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

const { t } = useI18n();
const { formatNumber } = useFormatNumber();

const DIESEL_PER_KM = 35.8 / 100;
const KWH_PER_LITER_DIESEL = 9.96;

const emissionInputs = ref([] as {name: string, value: number, emissionFactor: number}[]);
const component = h(AtInput);
const emissionResult = ref<number | null>(null);

const kWhResult = ref<number>(0);
const emissionInputsKey = ref(0);
const showEnergy = ref(false);

const calculate = () => {
  emissionResult.value = emissionInputs.value.reduce((acc, curr) => acc + (curr.value * curr.emissionFactor), 0);
  kWhResult.value = emissionInputs.value.reduce((acc, curr) => acc + (curr.value * DIESEL_PER_KM), 0);
  showEnergy.value = emissionInputs.value.every((i) => i.name.startsWith('articulated_33t'));
};

const clear = () => {
  emissionResult.value = null;
  kWhResult.value = 0;
  emissionInputs.value = [];
  emissionInputsKey.value += 1;
};

</script>

<template>
  <div>
    <EmissionSplitInput
      :key="emissionInputsKey"
      v-model="emissionInputs"
      :component="component"
      emissionSubcategory="HGVS_(DISTANCE-BASED_METHOD)"
    />

    <div
      id="actionAddRow_0"
      class="shrink-0"
    />

    <AtButton :disabled="!emissionInputs.length" class="mb-8 mt-3" @click="calculate">
      {{ t('Calculate') }}
    </AtButton>

    <h3 class="text-gray-700 font-bold">
      {{ t('Your result') }}
    </h3>

    <div class="my-5">
      <div v-if="emissionResult === null">
        <div class="bg-gray-50 p-12 px-24 w-fit grid justify-items-center">
          <GlobeIcon class="w-16 mb-3" />
          <p>{{ t('No data yet') }}</p>
        </div>
      </div>
      <div v-else class="flex gap-4">
        <div class="bg-gray-50 p-12 px-24 w-fit text-center">
          <div class="text-teal-600 text-6xl font-bold mb-4">
            {{ formatNumber(emissionResult * 1000) }}
          </div>
          <div class="text-gray-900 text-3xl">
            kgCO2eq
          </div>
        </div>
        <div v-if="showEnergy" class="bg-gray-50 p-12 px-24 w-fit text-center">
          <div class="text-teal-600 text-6xl font-bold mb-4">
            {{ formatNumber(kWhResult * KWH_PER_LITER_DIESEL) }}
          </div>
          <div class="text-gray-900 text-3xl">
            kWh<br />
            <span class="text-xs">{{ t('(based on {DIESEL_PER_KM}l diesel per km, with a factor of {KWH_PER_LITER_DIESEL} kWh per liter diesel)', { DIESEL_PER_KM: formatNumber(DIESEL_PER_KM, 0, 4), KWH_PER_LITER_DIESEL: formatNumber(KWH_PER_LITER_DIESEL) }) }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="grid justify-items-end">
      <AtButton :disabled="!emissionInputs.length" class="mb-8 mt-3" @click="clear">
        {{ t('Clear calculator') }}
      </AtButton>
    </div>
  </div>
</template>
