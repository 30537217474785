<script setup lang="ts">
const props = defineProps<{
  title: string,
  subtitle?: string,
}>();
</script>

<template>
  <div>
    <h2 class="font-bold">
      {{ props.title }}
    </h2>
    <p class="mt-2 text-sm text-gray-700">
      <span v-if="props.subtitle">
        {{ props.subtitle }}
      </span>
      <slot
        v-else
        name="subtitle"
      />
    </p>
  </div>
</template>
