import type { Router } from 'vue-router';
import { UserRole } from '../../__generated__/types';

const getRouteRoleRequirements = (router: Router, href: string): UserRole | UserRole[] | undefined => {
  const routeRecord = router.resolve(href);

  if (routeRecord.meta?.roleFrom) {
    return routeRecord.meta.roleFrom;
  }

  return undefined;
};

export default getRouteRoleRequirements;
