<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import MlModal from '@/components/molecules/MlModal.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import useUnsuspendDataPointTypeByEntityMutation from '@/api/mutations/DataPointType/unsuspendDataPointTypeByEntity.mutation';
import useUnsuspendDataPointTypeByLocationMutation from '@/api/mutations/DataPointType/unsuspendDataPointTypeByLocation.mutation';
import type { Project } from '../../types';

const { t } = useI18n();
const { currentUser } = useCurrentUser();
const props = defineProps<Props>();
type Props = {
  isShown: boolean;
  project?: Project;
  dataPointTypeId: string;
}

const emit = defineEmits<Emits>();
type Emits = {
  (e: 'cancel'): void
  (e : 'confirm'): void
};

const { mutate: unsuspendByLocation, loading: unsuspendByLocationLoading } = useUnsuspendDataPointTypeByLocationMutation({
  update: (store) => {
    store.evict({ id: 'ROOT_QUERY', fieldName: 'getMyDataPointRequests' });
    store.evict({ id: 'ROOT_QUERY', fieldName: 'entityLocationSummaries' });
  },
});

const { mutate: unsuspendByEntity, loading: unsuspendByEntityLoading } = useUnsuspendDataPointTypeByEntityMutation({
  update: (store) => {
    store.evict({ id: 'ROOT_QUERY', fieldName: 'getMyDataPointRequests' });
    store.evict({ id: 'ROOT_QUERY', fieldName: 'entityLocationSummaries' });
  },
});

async function handleUnsuspendByLocation() {
  try {
    await unsuspendByLocation({
      locationId: props.project?._id ?? '',
      dataPointTypeId: props.dataPointTypeId,
    });

    emit('confirm');
    notify({ type: 'success', text: t('The datapoint was unarchived in {locationName}', { locationName: props.project?.name }) });
  } catch (err) {
    notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
  }
}
async function handleUnsuspendByEntity() {
  try {
    await unsuspendByEntity({
      dataPointTypeId: props.dataPointTypeId,
    });
    emit('confirm');
    notify({ type: 'success', text: t('The datapoint was unarchived accross {entityName}', { entityName: currentUser.value?.entity.name }) });
  } catch (err) {
    notify({ type: 'error', text: t('Something went wrong, try again later :(.') });
  }
}

</script>

<template>
  <MlModal :isRevealed="props.isShown" class="max-w-xl" @close="emit('cancel')">
    <h2 class="text-xl mb-3 border-b pb-2">
      {{ t('Apply to all projects?') }}
    </h2>
    <p class="mb-3 text-sm">
      {{ t('Would you like to unarchive this data point in all projects in this workspace?') }}
    </p>
    <div class="mt-2 grid grid-cols-4 gap-x-3">
      <div class="flex items-center justify-between col-span-4 mt-5">
        <AtButton
          variant="outline"
          :disabled="unsuspendByLocationLoading"
          :loading="unsuspendByLocationLoading"
          @click="handleUnsuspendByLocation"
        >
          {{ t('No, apply only to this project') }}
        </AtButton>
        <AtButton
          :disabled="unsuspendByEntityLoading"
          :loading="unsuspendByEntityLoading"
          @click="handleUnsuspendByEntity"
        >
          {{ t('Yes, apply to all') }}
        </AtButton>
      </div>
    </div>
  </MlModal>
</template>
