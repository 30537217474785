<script setup lang="ts">

import { useRoute, useRouter } from 'vue-router';
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import useCreateTaxonomyAssessmentMutation from '@/api/mutations/Taxonomy/createTaxonomyAssessment.mutation';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const { mutate: createTaxonomyAssessment } = useCreateTaxonomyAssessmentMutation();

onMounted(async () => {
  if (!route.params.projectId) {
    return router.push({ name: 'taxonomyProjects' });
  }

  const taxonomyAssessment = await createTaxonomyAssessment({ projectId: String(route.params.projectId) });

  if (taxonomyAssessment?.data?.createTaxonomyAssessment._id) {
    router.push({
      name: 'taxonomyAssessment',
      params: {
        projectId: route.params.projectId,
        assessmentId: String(taxonomyAssessment.data.createTaxonomyAssessment._id),
      },
    });
  }
});

</script>

<template>
  <h1>{{ t('Please wait..') }}</h1>
</template>
