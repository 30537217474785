import { saveAs } from 'file-saver';

/**
 * Convert base64 to Blob.
 */
function base64ToBlob(data: string, contentType: string) {
  const sliceSize = 512;
  const byteCharacters = atob(data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, {
    type: contentType,
  });
}

/**
 * Download base64 encoded file.
 *
 * @param data - base64 string
 * @param name - Name of the file to be downloaded, including extension
 * @param contentType - Content type of the file
 */
function saveFile(data: string, name: string, contentType: string) {
  saveAs(base64ToBlob(data, contentType), name);
}

export default saveFile;
