<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { DoubleMaterialityDownloadDocumentEnum } from '@/__generated__/types';
import { useDocumentDownload } from '../../composables/useDocumentDownload';
import AtCard from '../../../../../atoms/AtCard.vue';

type ContentItem = {
  color: string;
  order: string;
  title: string;
  rows: number;
  children: {
    label: string;
    route: string;
    children: {
      text: string;
      route: string;
    }[];
  }[];
};

const { t } = useI18n();

const generalOverviewDownload = useDocumentDownload(DoubleMaterialityDownloadDocumentEnum.GENERAL_OVERVIEW);
const longListDownload = useDocumentDownload(DoubleMaterialityDownloadDocumentEnum.LONG_LIST);
const dmaDefinitionsDownload = useDocumentDownload(DoubleMaterialityDownloadDocumentEnum.DMA_DEFINITIONS);
const dmaProcessForUsers = useDocumentDownload(DoubleMaterialityDownloadDocumentEnum.DMA_PROCESS_FOR_USERS);

const content = computed<ContentItem[]>(() => [
  {
    color: 'bg-blue-50',
    order: '1',
    title: t('Start page'),
    rows: 2,
    children: [
      {
        label: t('Team assignation'),
        route: 'doubleMaterialityStartTeam',
        children: [
          {
            text: t('Invite your team and anyone who needs to participate in the double materiality assessment process.'),
            route: 'doubleMaterialityStartTeam',
          },
        ],
      },
      {
        label: t('Scales and core data'),
        route: 'doubleMaterialityStartScalesAndCoreData',
        children: [
          {
            text: t('Define values for scoring which will be applied to scoring of impacts, risks and opportunities.'),
            route: 'doubleMaterialityStartScalesAndCoreData',
          },
        ],
      },
    ],
  },
  {
    color: 'bg-teal-50',
    order: '2',
    title: t('Topics'),
    rows: 1,
    children: [
      {
        label: t('Sustainability matters'),
        route: 'doubleMaterialityTopicsSelection',
        children: [
          {
            text: t('Based on ESRS topics, define the sustainability matters that may be relevant for your company.'),
            route: 'doubleMaterialityTopicsSelection',
          },
        ],
      },
    ],
  },
  {
    color: 'bg-orange-50',
    order: '3',
    title: t('Impacts, risks and Opportunities'),
    rows: 4,
    children: [
      {
        label: t('Impacts'),
        route: 'doubleMaterialityIROsImpacts',
        children: [
          {
            text: t('Define all impacts on sustainability matters.'),
            route: 'doubleMaterialityIROsImpacts',
          },
          {
            text: t('Score the importance of impacts based on prescribed criteria.'),
            route: 'doubleMaterialityIROsScoreActualNegative',
          },
        ],
      },
      {
        label: t('Risks and opportunities'),
        route: 'doubleMaterialityIROsRisksAndOpportunities',
        children: [
          {
            text: t('Define risks and opportunities on both sustainability matters and impacts.'),
            route: 'doubleMaterialityIROsRisksAndOpportunities',
          },
          {
            text: t('Score the importance of risks and opportunities based on prescribed criteria.'),
            route: 'doubleMaterialityIROsScoreRisksAndOpportunities',
          },
        ],
      },
    ],
  },
  {
    color: 'bg-violet-50',
    order: '4',
    title: t('Stakeholders'),
    rows: 2,
    children: [
      {
        label: t('Defining stakeholders'),
        route: 'doubleMaterialityStakeholdersStakeholders',
        children: [
          {
            text: t('Define relevant stakeholders and choose how you want to interact with them.'),
            route: 'doubleMaterialityStakeholdersStakeholders',
          },
        ],
      },
      {
        label: t('Sending surveys'),
        route: 'doubleMaterialityStakeholdersSurveys',
        children: [
          {
            text: t('Here you can send out the survey to all necessary stakeholders.'),
            route: 'doubleMaterialityStakeholdersSurveys',
          },
        ],
      },
    ],
  },
  {
    color: 'bg-rose-50',
    order: '5',
    title: t('Results'),
    rows: 2,
    children: [
      {
        label: t('Results'),
        route: 'doubleMaterialityResultsList',
        children: [
          {
            text: t('Finally, on this page you can see your results as well as double materiality matrix.'),
            route: 'doubleMaterialityResultsList',
          },
        ],
      },
      {
        label: t('ESRS recommendations'),
        route: 'doubleMaterialityResultsRecommendedESRS',
        children: [
          {
            text: t('On this page you can see the list of ESRS topics that are applicable for your company.'),
            route: 'doubleMaterialityResultsRecommendedESRS',
          },
        ],
      },
    ],
  },
]);
</script>

<template>
  <p class="mb-5">
    {{ t('This is a short overview and library of all supporting documents and guidance you may need to undertake the double materiality process on our platform.') }}
  </p>

  <div class="mb-9">
    <div
      v-for="item of content"
      :key="item.order"
      class="grid gap-3 mb-3 last:mb-0 grid-cols-[56px_128px_160px_1fr]"
      :class="{
        'grid-rows-[repeat(2,minmax(48px,1fr))]': item.rows === 2,
        'grid-rows-[repeat(4,minmax(48px,1fr))]': item.rows === 4,
      }"
    >
      <div
        class="rounded flex items-center justify-center"
        :class="[item.color, {
          'row-span-2': item.rows === 2,
          'row-span-4': item.rows === 4,
        }]"
      >
        {{ item.order }}
      </div>
      <div
        class="rounded p-3 flex items-center"
        :class="[item.color, {
          'row-span-2': item.rows === 2,
          'row-span-4': item.rows === 4,
        }]"
      >
        {{ item.title }}
      </div>
      <template v-for="childWithChildren in item.children" :key="childWithChildren.route">
        <RouterLink
          v-if="childWithChildren.label"
          class="rounded px-2 py-1.5 flex items-center w-full"
          :class="[item.color, {
            'row-span-2': childWithChildren.children.length === 2,
          }]"
          :to="{ name: childWithChildren.route }"
        >
          {{ childWithChildren.label }}
        </RouterLink>
        <RouterLink
          v-for="child of childWithChildren.children"
          :key="child.route"
          class="rounded px-2.5 py-1.5 flex items-center w-full"
          :class="[item.color]"
          :to="{ name: child.route }"
        >
          {{ child.text }}
        </RouterLink>
      </template>
    </div>
  </div>

  <div>
    <p class="mb-5 font-bold">
      {{ t('Downloads of more detailed guidance:') }}
    </p>
    <div class="grid grid-cols-2 gap-11">
      <AtCard
        :title="t('Step plan for the Double Materiality Assessment')"
        :action="t('Download')"
        :loading="generalOverviewDownload.loading.value"
        @click="generalOverviewDownload.exportFile"
      >
        <p>
          {{ t('This is a step plan of the DMA as performed on the platform, including the relevant background files for each step.') }}
        </p>
      </AtCard>
      <AtCard
        :title="t('Long list of ESRS topics')"
        :action="t('Download')"
        :loading="longListDownload.loading.value"
        @click="longListDownload.exportFile"
      >
        <p>
          {{ t('Find here the long list of topics that you should consider in your analysis.') }}
        </p>
      </AtCard>
      <AtCard
        :title="t('Double Materiality Assessment - Process and approach')"
        :action="t('Download')"
        :loading="dmaProcessForUsers.loading.value"
        @click="dmaProcessForUsers.exportFile"
      >
        <p>
          {{ t('This is a full overview of how the double materiality assessment is conducted on the Codio Impact platform, and why this specific approach was chosen.') }}
        </p>
      </AtCard>
      <AtCard
        :title="t('Definitions of the DMA')"
        :action="t('Download')"
        :loading="dmaDefinitionsDownload.loading.value"
        @click="dmaDefinitionsDownload.exportFile"
      >
        <p>
          {{ t('This file explains and defines all terms used throughout the DMA on the platform.') }}
        </p>
      </AtCard>
    </div>
  </div>
</template>
