<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { onMounted, ref } from 'vue';
import { GetStartedStatusEnum, OnboardingStatusEnum } from '@/__generated__/types';
import useSignUpMutation from '@/api/mutations/User/signUp.mutation';
import { auth } from '@/api/auth/auth';

import { router } from '@/router';

const { t } = useI18n();

const { mutate: signup } = useSignUpMutation();

const shownErrorMessage = ref('');

onMounted(async () => {
  const token = router.currentRoute.value.query.apiKey as string;

  if (!token) {
    shownErrorMessage.value = 'no token provided';
    return;
  }

  const signupResponse = await signup({
    signupInput: { token, sub: '' },
  });

  if (signupResponse && signupResponse.data) {
    const user = signupResponse.data.signup;
    const getStartedSteps = Object.values(GetStartedStatusEnum);

    auth.setToken(token);

    switch (user.onboardingStatus) {
      case OnboardingStatusEnum.INCOMPLETE:
        // eslint-disable-next-line no-console
        console.log('triggering reditrect');
        await router.push('/onboarding');
        break;

      case OnboardingStatusEnum.COMPLETED:
        if (user.getStartedStepsCompleted.length === getStartedSteps.length) {
          await router.push('/get-started');
        } else {
          await router.push('/');
        }
        break;
      default: break;
    }
  }
});
</script>

<template>
  <div class="text-center">
    <h1 class="my-5 text-2xl">
      {{ t('Please wait... Authentication in progress') }}
    </h1>

    <div
      v-if="shownErrorMessage"
      class="text-xl text-red-600"
    >
      {{ shownErrorMessage }}
    </div>
  </div>
</template>
