<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import UsersIcon from '@heroicons/vue/outline/UsersIcon';
import OgActionPanel from '@/components/organisms/OgActionPanel/OgActionPanel.vue';
import type { User } from './types';
import OgAssignUser from './OgAssignUser.vue';
import OgUserForm from './OgUserForm.vue';

defineProps<{
  user: User | null;
  allExistingUsers: User[];
  noAccessUsers: User[];
}>();

const emit = defineEmits<{
  close: [];
}>();

const { t } = useI18n();

function handleClosePanel() {
  emit('close');
}
</script>

<template>
  <OgActionPanel class="min-w-[24rem]" isOpened hasIcon hasPaddingX @closePanel="handleClosePanel">
    <template #icon>
      <UsersIcon
        class="h-6 w-6 text-green-600"
        aria-hidden="true"
      />
    </template>
    <template #title>
      <div>
        <template v-if="user">
          {{ t('Edit team members') }}
        </template>
        <template v-else>
          {{ t('Add team members') }}
        </template>
      </div>
    </template>

    <OgAssignUser v-if="!user" :users="noAccessUsers" />

    <OgUserForm :user="user" :allExistingUsers="allExistingUsers" @close="handleClosePanel" />
  </OgActionPanel>
</template>
