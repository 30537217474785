<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { PlusIcon } from '@heroicons/vue/solid';
import { XIcon, FilterIcon } from '@heroicons/vue/outline';
import get from 'lodash/get';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import type { TPlacement } from '@/components/molecules/MlMenu/types';
import type { TDataTableFilter, TDataTableHeader, TDataTableItem } from './types';

class Filter {
  name?: string = '';
  field = '';
  comparison?: (value: unknown, criteria: string[]) => boolean;
  criteria: string[] = [];
  sortedOptions?: boolean;
}

const props = withDefaults(defineProps<{
  modelValue: TDataTableFilter[],
  headers?: TDataTableHeader[],
  items?: TDataTableItem[],
  customFilters?: TDataTableFilter[],
  placement?: TPlacement,
}>(), {
  modelValue: () => [],
  headers: () => [],
  items: () => [],
  customFilters: () => [],
  placement: 'auto',
});

const emit = defineEmits<{
  (e: 'update:modelValue', filters: TDataTableFilter[]): void,
}>();

const getFilterFieldOptions = (filter?: Filter) => {
  const headersBasedFilterOptions = props.headers
    .filter(({ value, filterable }) => {
      if (!filterable) return false;

      return filter?.field === value || !filters.value.find(({ field }) => field === value);
    })
    .reduce<Record<string, string>>((acc, { text, value }) => ({ ...acc, [value]: text }), {});

  const customFiltersBasedFiltersOptions = props.customFilters
    .reduce<Record<string, string>>((acc, { name, field }) => ({ ...acc, [field]: name || field }), {});

  return { ...headersBasedFilterOptions, ...customFiltersBasedFiltersOptions };
};

const isEveryFilterSelected = computed(() => !Object.values(getFilterFieldOptions()).length);

const getFilterValueOptions = (field: string) => {
  const isCustomFilter = !!props.customFilters.find((filter) => filter.field === field);
  const getCustomFiltersBasedFilterOptions = () => props.customFilters
    .find((filter) => filter.field === field)?.criteria
    .reduce<Record<string, string>>((acc, value) => ({ ...acc, [value]: value }), {});

  const getItemsBasedFilterOptions = () => props.items
    .map((item) => get(item, field))
    .reduce<Record<string, string>>((acc, value) => ({ ...acc, [value]: value }), {});

  return isCustomFilter ? getCustomFiltersBasedFilterOptions() : getItemsBasedFilterOptions();
};

const getFilterSortedOptionsBoolean = (field: string) => {
  return props.customFilters.find((filter) => filter.field === field)?.sortedOptions;
};

const filters = ref([new Filter()]);

const resetFilters = () => { filters.value = [new Filter()]; };

const handleRemoveFilter = (index: number) => {
  if (filters.value.length === 1) {
    resetFilters();
  } else {
    filters.value.splice(index, 1);
  }
};

watch(filters, () => emit('update:modelValue', filters.value), { deep: true });

watch(() => props.modelValue, () => {
  if (!props.modelValue.length) {
    filters.value = [new Filter()];
  } else {
    filters.value = props.modelValue;
  }
}, { immediate: true });

const { t } = useI18n();

const handleFilterFieldSelect = (filter: Filter, field: string) => {
  filter.field = field;
  filter.comparison = props.customFilters.find((f) => f.field === field)?.comparison;
  filter.criteria = [];
};
</script>

<template>
  <MlMenu :placement="props.placement">
    <div class="items-end align-bottom font-medium">
      <button
        v-if="!filters[0].criteria.length"
        v-close-popper
        class="text-sm items-end text-primary cursor-pointer flex"
      >
        <FilterIcon class="h-4 mr-1 mb-0.5" />
        {{ t('Filter') }}
      </button>
      <button
        v-else
        v-close-popper
        class="flex items-center rounded bg-blue-50 px-3 py-1 text-sm text-blue-600"
        type="submit"
      >
        <FilterIcon class="h-4 pr-2 mb-0.5" />
        {{ t('Filter') }}:
        {{ filters.flatMap(({ criteria }) => criteria).join(', ') }}
      </button>
    </div>

    <template #menuItems>
      <div class="p-6">
        <div
          v-for="filter, i in filters"
          :key="i"
          class="mb-3 bg-gray-50 px-2 pb-2 pt-5 rounded flex items-center gap-4 relative"
        >
          <MlSelect
            :modelValue="filter.field"
            wrapperClass="mr-3 w-56"
            :options="getFilterFieldOptions(filter)"
            @update:modelValue="handleFilterFieldSelect(filter, $event)"
          />
          <MlSelect
            v-model="filter.criteria"
            wrapperClass="w-56"
            :options="getFilterValueOptions(filter.field)"
            :disabled="!filter.field"
            :sortedOptions="getFilterSortedOptionsBoolean(filter.field)"
            multiple
          />
          <XIcon
            :title="t('Remove')"
            class="absolute h-4 top-1 right-1 cursor-pointer text-gray-400 hover:text-primary"
            :icon="XIcon"
            @click="handleRemoveFilter(i)"
          />
        </div>
        <div class="flex gap-6">
          <AtButton
            class="mr-auto"
            variant="text"
            :icon="PlusIcon"
            :disabled="isEveryFilterSelected"
            @click="filters.push(new Filter())"
          >
            {{ t('Add Filter') }}
          </AtButton>
          <AtButton
            v-close-popper
            variant="text"
            @click="resetFilters"
          >
            {{ t('Clear') }}
          </AtButton>
        </div>
      </div>
    </template>
  </MlMenu>
</template>
