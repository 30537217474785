<script setup lang="ts">
import type { Component } from 'vue';
import MlSubPageNav from './MlSubPageNav.vue';

const props = defineProps<{
  routeName?: string
  icon: Component
  label: string
}>();

const emit = defineEmits(['itemClick']);

</script>

<template>
  <router-link
    class="p-4 text-gray-900 hover:text-primary w-full max-w-[207px]"
    :to="{ name: props.routeName }"
    :data-cy="`router-link-${props.routeName}`"
    activeClass="text-primary rounded-md"
    :ariaExpanded="false"
  >
    <slot name="precontent" />
    <div class="group/link font-normal">
      <div class="inline-flex w-full justify-start items-center gap-x-2">
        <component
          :is="props.icon"
          class="h-6 w-6 col-span-1 shrink-0"
        />
        <span class="overflow-hidden invisible max-w-0 group-hover:visible group-hover:max-w-full text-sm break-words line-clamp-1">{{ props.label }}</span>
      </div>
      <div
        v-if="props.routeName"
        class="overflow-hidden duration-150 transition ease-out group-hover/link:translate-y-0 -translate-y-6 max-h-0 group-hover/link:max-h-full opacity-0 group-hover/link:opacity-100"
      >
        <MlSubPageNav
          class="hidden group-hover/link:block rounded-md pl-3 text-left text-gray-900 hover:text-primary relative py-0 border-l ml-3"
          :parentRouteName="props.routeName"
          @itemClick="emit('itemClick')"
          @click.stop
        />
      </div>
      <slot name="postcontent" />
    </div>
  </router-link>
</template>
