import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import { gql } from '@apollo/client/core';
import type { StartCustomQuestionnaireFileProcessingMutation, StartCustomQuestionnaireFileProcessingMutationVariables } from '@/__generated__/types';

export const START_FILE_PROCESSING = gql`
  mutation StartCustomQuestionnaireFileProcessing($customQuestionnaireId: String!) {
    startFileProcessing(customQuestionnaireId: $customQuestionnaireId)
  }
`;

type Options = UseMutationOptions<StartCustomQuestionnaireFileProcessingMutation, StartCustomQuestionnaireFileProcessingMutationVariables>

const useStartCustomQuestionnaireFileProcessingMutation = (options?: Options) => useMutation<
  StartCustomQuestionnaireFileProcessingMutation,
  StartCustomQuestionnaireFileProcessingMutationVariables
>(START_FILE_PROCESSING, options);

export default useStartCustomQuestionnaireFileProcessingMutation;
