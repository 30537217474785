import { gql } from '@apollo/client/core';

export default gql`
  query OgQuestionnaireName {
    getCustomCategoriesWithSubcategoriesForEntity {
      _id
      name
    }
  }
`;
