<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import OgS3FilePicker from './OgS3FilePicker.vue';

const { t } = useI18n();

const MAX_FILE_SIZE_IN_MB = 10;
const MAX_FILE_SIZE_IN_BYTES = MAX_FILE_SIZE_IN_MB * 1024 * 1024;
const PDF_FILE_TYPE = 'application/pdf';

function handleFilesValidation(files: File[]) {
  if (files.some((file) => file.type !== PDF_FILE_TYPE)) {
    return t('Upload failed. Only PDF files can be uploaded.');
  }

  if (files.some((file) => file.size > MAX_FILE_SIZE_IN_BYTES)) {
    return t('Upload failed. Maximum allowed file size is {maxSizeInMB} MB.', {
      maxSizeInMB: MAX_FILE_SIZE_IN_MB,
    });
  }

  return null;
}
</script>

<template>
  <OgS3FilePicker
    :description="t('{fileTypes} up to {maxSizeInMB} MB.', { fileTypes: 'PDF', maxSizeInMB: MAX_FILE_SIZE_IN_MB })"
    :validate="handleFilesValidation"
    :accept="PDF_FILE_TYPE"
  />
</template>
