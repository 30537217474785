import { gql } from '@apollo/client/core';

export default gql`
  query DocxReport(
    $dataPointTypeIds: [String!],
    $dateFrom: String!,
    $dateTo: String!,
    $locationId: String,
    $format: String
  ) {
    getDataPointsEncodedReport(
      dataPointTypeIds: $dataPointTypeIds,
      dateFrom: $dateFrom,
      dateTo: $dateTo,
      locationId: $locationId,
      format: $format
    )
  }
`;
