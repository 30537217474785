<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
  TransitionRoot,
  TransitionChild,
} from '@headlessui/vue';
import { XIcon } from '@heroicons/vue/outline';
import AtIconButton from '@/components/atoms/AtIconButton.vue';

type Props = {
  isOpen: boolean;
  hasCloseButton?: boolean;
  closeOnOutside?: boolean;
  isTitleStyled?: boolean;
};

const props = withDefaults(defineProps<Props>(), {
  hasCloseButton: true,
  closeOnOutside: true,
  isTitleStyled: true,
});

const emit = defineEmits<{
  (event: 'close'): void
}>();

defineOptions({ inheritAttrs: false });

const { t } = useI18n();

function handleClose() {
  emit('close');
}

function handleRootClose() {
  if (props.closeOnOutside) {
    handleClose();
  }
}
</script>

<template>
  <TransitionRoot
    :show="isOpen"
    as="template"
  >
    <Dialog class="relative z-200" @close="handleRootClose">
      <TransitionChild
        enter="duration-200 ease-in-out"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="duration-200 ease-in-out"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div class="fixed inset-0 bg-modalBackdrop/40" aria-hidden="true" />
      </TransitionChild>

      <div class="fixed inset-0 flex w-screen items-center justify-center px-4 py-10">
        <TransitionChild
          enter="duration-200 ease-in-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-200 ease-in-out"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <DialogPanel
            class="dialog-panel bg-white p-6 shadow-2xl overscroll-contain relative grid w-fit rounded-lg max-w-4xl max-h-[calc(100vh-5rem)] overflow-y-auto"
            v-bind="$attrs"
          >
            <AtIconButton
              v-if="hasCloseButton"
              :icon="XIcon"
              :title="t('Close')"
              class="absolute right-3 top-3 h-6"
              @click="handleClose"
            />
            <DialogTitle
              v-if="$slots.title"
              :as="isTitleStyled ? 'h3' : 'div'"
              :class="{
                'text-xl font-medium': isTitleStyled,
              }"
            >
              <slot name="title" :close="handleClose" />
            </DialogTitle>
            <DialogDescription v-if="$slots.title">
              <slot name="description" :close="handleClose" />
            </DialogDescription>

            <slot :close="handleClose" />
          </DialogPanel>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
