import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  ApproveDataPointRequestMutation,
  ApproveDataPointRequestMutationVariables,
} from '@/__generated__/types';

export const APPROVE_DATAPOINT_REQUEST = gql`
  mutation ApproveDataPointRequest($approveDataPointRequestInput: ApproveDataPointRequestInput!) {
    approveDataPointRequest(ApproveDataPointRequestInput: $approveDataPointRequestInput) {
        _id
      status
    }
  }
`;

type TOptions = UseMutationOptions<ApproveDataPointRequestMutation, ApproveDataPointRequestMutationVariables>

const useApproveDataPointRequestMutation = (options?: TOptions) => useMutation<
  ApproveDataPointRequestMutation,
  ApproveDataPointRequestMutationVariables
>(APPROVE_DATAPOINT_REQUEST, options);

export default useApproveDataPointRequestMutation;
