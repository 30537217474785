<script setup lang="ts">
import { computed, reactive } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import { MAXIMUM_NESTING_DEPTH } from '@/constants/projects';
import { CheckboxSize } from '@/components/atoms/AtCheckbox/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtCheckbox from '@/components/atoms/AtCheckbox/AtCheckbox.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import MlDialog from '@/components/molecules/MlDialog.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import type { Project } from './types';

type AddProjectForm = {
  name: string;
  shouldNest: boolean;
  parent: string;
};

export type AddProjectData = {
  name: string;
  parent: string | null;
};

type Props = {
  isOpen: boolean;
  projects: Project[];
  existingProjectsNames: string[];
};

const props = defineProps<Props>();

const emit = defineEmits<{
  cancel: [],
  save: [data: AddProjectData],
}>();

const { t } = useI18n();

const form = reactive<AddProjectForm>({
  name: '',
  shouldNest: false,
  parent: '',
});

const validationRules = computed(() => {
  const existingNames = new Set(props.existingProjectsNames);

  return {
    name: {
      required: helpers.withMessage(t('Project name is required.'), required),
      unique: helpers.withMessage(
        t('A project with this name already exists.'),
        (name: string) => !existingNames.has(name),
      ),
    },
    parent: {
      requiredIf: helpers.withMessage(t('Project is required.'), requiredIf(() => form.shouldNest)),
    },
  };
});
const v$ = useVuelidate(validationRules, form);

const availableProjects = computed(() => props.projects.filter((project) => project.depth < MAXIMUM_NESTING_DEPTH));
const isNestable = computed(() => availableProjects.value.length > 0);
const projectsOptions = computed(() => availableProjects.value.reduce((acc, item) => ({
  ...acc,
  [item._id]: item.name,
}), {}));

function handleCancel() {
  emit('cancel');
  resetForm();
}

function handleSubmit() {
  emit('save', {
    name: form.name,
    parent: form.shouldNest ? form.parent : null,
  });
  resetForm();
}

function resetForm() {
  form.name = '';
  form.shouldNest = false;
  form.parent = '';

  v$.value.$reset();
}
</script>

<template>
  <MlDialog
    :isOpen="isOpen"
    :closeOnOutside="false"
    @close="handleCancel"
  >
    <template #title>
      {{ t('Add new project') }}
    </template>

    <template #default="{ close }">
      <form class="pt-6" @submit.prevent="handleSubmit">
        <div class="mb-6">
          <AtInput
            v-model="form.name"
            wrapperClass="w-96 mb-4"
            :label="t('Name')"
            :errors="v$.name.$errors"
            @blur="v$.name.$touch"
          />
          <div v-if="isNestable" class="flex items-center space-x-2 mb-1">
            <AtCheckbox
              squared
              :checked="form.shouldNest"
              :size="CheckboxSize.XS"
              @toggleCheckbox="form.shouldNest = !form.shouldNest"
            >
              <template #label>
                <p class="text-sm font-medium text-gray-700">
                  {{ t('Nest project under:') }}
                </p>
              </template>
            </AtCheckbox>
          </div>
          <MlSelect
            v-if="isNestable"
            v-model="form.parent"
            usePortal
            :disabled="!form.shouldNest"
            :options="projectsOptions"
            :errors="v$.parent.$errors"
            @blur="v$.parent.$touch"
          />
        </div>

        <div class="flex space-x-4 justify-between items-center">
          <div class="flex-0">
            <AtButton type="button" variant="outline" @click="close">
              {{ t('Cancel') }}
            </AtButton>
          </div>
          <div class="flex-0">
            <AtButton type="submit" :disabled="v$.$invalid">
              {{ t('Confirm') }}
            </AtButton>
          </div>
        </div>
      </form>
    </template>
  </MlDialog>
</template>
