<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import ViewListIcon from '@heroicons/vue/outline/ViewListIcon';
import AtEmptyCard from '../../AtEmptyCard.vue';

const { t } = useI18n();
</script>

<template>
  <AtEmptyCard
    variant="center"
    :icon="ViewListIcon"
    :title="t('Material impacts, risks and opportunities')"
  >
    <p>
      {{ t('Once your stakeholders respond to sent out surveys, the results of the stakeholder assessment will show here.') }}
    </p>
  </AtEmptyCard>
</template>
