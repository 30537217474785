<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { ChevronRightIcon, ChevronDownIcon, TrashIcon } from '@heroicons/vue/outline';
import { useI18n } from 'vue-i18n';
import intersection from 'lodash/intersection';
import AtIconButton from '@/components/atoms/AtIconButton.vue';
import type { TGoalItem } from '../types';
import MlTarget from './MlTarget.vue';
import PG_GOALS_OVERVIEW_QUERY from './PgGoalsOverview.query';
import { useDeleteKPIs } from './useDeleteKPIs';

const props = defineProps<{
  goal: TGoalItem
  newKpiIds: string[]
}>();

const { t } = useI18n();

const isGoalExpanded = ref(false);

const hasTargets = computed(() => !!props.goal.targets.length);

const kpiIds = computed(() => props.goal.targets.flatMap(({ kpis }) => kpis.map(({ kpi }) => kpi._id)));

const { deleteKPIs } = useDeleteKPIs({ refetchQueries: [{ query: PG_GOALS_OVERVIEW_QUERY }] });
const handleDeleteGoal = () => deleteKPIs(kpiIds.value, {
  title: t('Are you sure you want to delete this goal?'),
  text: t('This will automatically delete all subordinate Targets and KPIs as well.'),
});

watch(() => props.newKpiIds, (newKpiIds) => {
  if (intersection(newKpiIds, kpiIds.value).length) {
    isGoalExpanded.value = true;
  }
}, { immediate: true });
</script>

<template>
  <div
    class="grid grid-cols-[1fr_3fr_1fr_1fr_1fr_0.7fr] gap-2 items-center justify-center border-b px-4 py-6 hover:bg-gray-200"
    :class="{ 'cursor-pointer': hasTargets }"
    @click="hasTargets ? isGoalExpanded = !isGoalExpanded : null"
  >
    <div class="flex items-center">
      {{ t('Goal') }}
      <template v-if="hasTargets">
        <ChevronDownIcon
          v-if="isGoalExpanded"
          class="h-5 ml-4"
        />
        <ChevronRightIcon
          v-else
          class="h-5 ml-4"
        />
      </template>
    </div>
    {{ t(props.goal.title) }}
    <span />
    <span />
    <span />
    <AtIconButton
      class="w-fit ml-auto text-gray-400"
      :icon="TrashIcon"
      :title="t('Delete')"
      @click.stop="handleDeleteGoal"
    />
  </div>

  <template v-if="isGoalExpanded">
    <MlTarget
      v-for="target in props.goal.targets"
      :key="target.title"
      :target="target"
      :goalId="goal.goal._id ?? ''"
      :newKpiIds="props.newKpiIds"
    />
  </template>
</template>
