import { useI18n } from 'vue-i18n';
import { UserLanguageEnum } from '@/__generated__/types';

const HELP_DOCUMENTATION = {
  [UserLanguageEnum.DE]: 'https://intercom.help/codio-impact/de/',
  [UserLanguageEnum.EN]: 'https://intercom.help/codio-impact/de/',
};

export default function useOpenHelpDocumentation() {
  const { locale } = useI18n();

  return () => {
    window.open(HELP_DOCUMENTATION[locale.value?.toUpperCase() as UserLanguageEnum]);
  };
}
