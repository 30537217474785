<script setup lang="ts">
import type { ChartData, ChartOptions } from 'chart.js';
import OgChart from '@/components/organisms/OgChart/OgChart.vue';

const chartPropsEnergyRenewable: {
  type: 'bar',
  title: string,
  pinned: boolean,
  chartOptions: ChartOptions<'bar'>,
  chartData: ChartData<'bar', { x: string, y: number}[]>
} = {
  chartData: {
    datasets: [
      {
        type: 'bar',
        label: 'Schneider Electric',
        backgroundColor: '#3B82F6',
        data: [
          { x: '2023', y: 707033 },
          { x: '2022', y: 688474 },
          { x: '2021', y: 670287 },
        ],
      },
      {
        type: 'bar',
        label: 'Hitachi',
        backgroundColor: '#FB923C',
        data: [
          { x: '2022', y: 706000 },
          { x: '2021', y: 193000 },
          { x: '2020', y: 138000 },
        ],
      },
      {
        type: 'bar',
        label: 'GE Vernova',
        backgroundColor: '#FB7185',
        data: [
          { x: '2022', y: 141000 },
          { x: '2021', y: 63100 },
          { x: '2020', y: 53000 },
        ],
      },
      {
        type: 'bar',
        label: 'Siemens Energy',
        backgroundColor: '#0D9488',
        data: [
          { x: '2023', y: 821750 },
          { x: '2022', y: 805555.55 },
          { x: '2021', y: 703333.33 },
          { x: '2020', y: 627777.78 },
        ],
      },
    ],
  },
  chartOptions: {
    scales: {
      y: {},
      x: {},
    },
  },
  type: 'bar',
  title: 'Electricity (renewable sources) MWh',
  pinned: false,
};

const chartPropsEnergy: {
  type: 'bar',
  title: string,
  pinned: boolean,
  chartOptions: ChartOptions<'bar'>,
  chartData: ChartData<'bar', { x: string, y: number}[]>
} = {
  chartData: {
    datasets: [
      {
        type: 'bar',
        label: 'Schneider Electric',
        backgroundColor: '#3B82F6',
        data: [
          { x: '2023', y: 1124327 },
          { x: '2022', y: 1201276 },
          { x: '2021', y: 1325491 },
          { x: '2020', y: 1216845 },
        ],
      },
      {
        type: 'bar',
        label: 'Hitachi',
        backgroundColor: '#FB923C',
        data: [
          { x: '2022', y: 5387000 },
          { x: '2021', y: 9957000 },
          { x: '2020', y: 9674000 },
        ],
      },
      {
        type: 'bar',
        label: 'GE Vernova',
        backgroundColor: '#FB7185',
        data: [
          { x: '2022', y: 5670000 },
          { x: '2021', y: 5990000 },
          { x: '2020', y: 5870000 },
        ],
      },
      {
        type: 'bar',
        label: 'Siemens Energy',
        backgroundColor: '#0D9488',
        data: [
          { x: '2023', y: 1441667 },
          { x: '2022', y: 1611111 },
          { x: '2021', y: 1758333 },
          { x: '2020', y: 1611111 },
        ],
      },
    ],
  },
  chartOptions: {
    scales: {
      y: {},
      x: {},
    },
  },
  type: 'bar',
  title: 'Energy (total) MWh',
  pinned: false,
};

const chartPropsScope1: {
  type: 'bar',
  title: string,
  pinned: boolean,
  chartOptions: ChartOptions<'bar'>,
  chartData: ChartData<'bar', { x: string, y: number}[]>
} = {
  chartData: {
    datasets: [
      {
        type: 'bar',
        label: 'Schneider Electric',
        backgroundColor: '#3B82F6',
        data: [
          { x: '2023', y: 112792 },
          { x: '2022', y: 119447 },
          { x: '2021', y: 140718 },
          { x: '2020', y: 142388 },
        ],
      },
      {
        type: 'bar',
        label: 'Hitachi',
        backgroundColor: '#FB923C',
        data: [
          { x: '2022', y: 459000 },
          { x: '2021', y: 1245000 },
          { x: '2020', y: 1202000 },
        ],
      },
      {
        type: 'bar',
        label: 'GE Vernova',
        backgroundColor: '#FB7185',
        data: [
          { x: '2022', y: 670000 },
          { x: '2021', y: 740000 },
          { x: '2020', y: 730000 },
        ],
      },
      {
        type: 'bar',
        label: 'Siemens Energy',
        backgroundColor: '#0D9488',
        data: [
          { x: '2023', y: 163000 },
          { x: '2022', y: 188000 },
          { x: '2021', y: 206000 },
          { x: '2020', y: 221000 },
        ],
      },
    ],
  },
  chartOptions: {
    scales: {
      y: {},
      x: {},
    },
  },
  type: 'bar',
  title: 'Scope 1 tCO2eq',
  pinned: false,
};

const chartPropsScope2: {
  type: 'bar',
  title: string,
  pinned: boolean,
  chartOptions: ChartOptions<'bar'>,
  chartData: ChartData<'bar', { x: string, y: number}[]>
} = {
  chartData: {
    datasets: [
      {
        type: 'bar',
        label: 'Schneider Electric',
        backgroundColor: '#3B82F6',
        data: [
          { x: '2023', y: 89440 },
          { x: '2022', y: 109730 },
          { x: '2021', y: 153115 },
          { x: '2020', y: 145207 },
        ],
      },
      {
        type: 'bar',
        label: 'Hitachi',
        backgroundColor: '#FB923C',
        data: [
          { x: '2022', y: 1079000 },
          { x: '2021', y: 2139000 },
          { x: '2020', y: 2094000 },
        ],
      },
      {
        type: 'bar',
        label: 'GE Vernova',
        backgroundColor: '#FB7185',
        data: [
          { x: '2022', y: 960000 },
          { x: '2021', y: 1070000 },
          { x: '2020', y: 1160000 },
        ],
      },
      {
        type: 'bar',
        label: 'Siemens Energy',
        backgroundColor: '#0D9488',
        data: [
          { x: '2023', y: 18000 },
          { x: '2022', y: 27000 },
          { x: '2021', y: 67000 },
          { x: '2020', y: 71000 },
        ],
      },
    ],
  },
  chartOptions: {
    scales: {
      y: {},
      x: {},
    },
  },
  type: 'bar',
  title: 'Scope 2 tCO2eq',
  pinned: false,
};

const chartPropsEmployees: {
  type: 'bar',
  title: string,
  pinned: boolean,
  chartOptions: ChartOptions<'bar'>,
  chartData: ChartData<'bar', { x: string, y: number}[]>
} = {
  chartData: {
    datasets: [
      {
        type: 'bar',
        label: 'Schneider Electric',
        backgroundColor: '#3B82F6',
        data: [
          { x: '2023', y: 153121 },
          { x: '2022', y: 149812 },
          { x: '2021', y: 147468 },
          { x: '2020', y: 147349 },
        ],
      },
      {
        type: 'bar',
        label: 'Hitachi',
        backgroundColor: '#FB923C',
        data: [
          { x: '2022', y: 322525 },
          { x: '2021', y: 368247 },
          { x: '2020', y: 350864 },
        ],
      },
      {
        type: 'bar',
        label: 'GE Vernova',
        backgroundColor: '#FB7185',
        data: [
          { x: '2022', y: 125000 },
          { x: '2021', y: 172000 },
          { x: '2020', y: 168000 },
        ],
      },
      {
        type: 'bar',
        label: 'Siemens Energy',
        backgroundColor: '#0D9488',
        data: [
          { x: '2023', y: 94000 },
          { x: '2022', y: 91000 },
          { x: '2021', y: 91000 },
          { x: '2020', y: 91000 },
        ],
      },
    ],
  },
  chartOptions: {
    scales: {
      y: {},
      x: {},
    },
  },
  type: 'bar',
  title: 'Total number of employees',
  pinned: false,
};

const chartPropsEmployeeTurnover: {
  type: 'bar',
  title: string,
  pinned: boolean,
  chartOptions: ChartOptions<'bar'>,
  chartData: ChartData<'bar', { x: string, y: number}[]>
} = {
  chartData: {
    datasets: [
      {
        type: 'bar',
        label: 'Schneider Electric',
        backgroundColor: '#3B82F6',
        data: [
          { x: '2023', y: 14.6 },
          { x: '2022', y: 16.6 },
          { x: '2021', y: 18.1 },
          { x: '2020', y: 16.1 },
        ],
      },
      {
        type: 'bar',
        label: 'Hitachi',
        backgroundColor: '#FB923C',
        data: [
          { x: '2022', y: 8.5 },
          { x: '2021', y: 7.5 },
          { x: '2020', y: 4.3 },
        ],
      },
      {
        type: 'bar',
        label: 'GE Vernova',
        backgroundColor: '#FB7185',
        data: [
        ],
      },
      {
        type: 'bar',
        label: 'Siemens Energy',
        backgroundColor: '#0D9488',
        data: [
          { x: '2023', y: 9.6 },
          { x: '2022', y: 10.6 },
          { x: '2021', y: 10.6 },
          { x: '2020', y: 9.2 },
        ],
      },
    ],
  },
  chartOptions: {
    scales: {
      y: {},
      x: {},
    },
  },
  type: 'bar',
  title: 'Employee Turnover %',
  pinned: false,
};

</script>
<template>
  <div>
    <div class="mt-10 grid gap-10 grid-cols-2">
      <OgChart
        v-bind="chartPropsEnergyRenewable"
      />

      <OgChart
        v-bind="chartPropsEnergy"
      />

      <OgChart
        v-bind="chartPropsScope1"
      />

      <OgChart
        v-bind="chartPropsScope2"
      />

      <OgChart
        v-bind="chartPropsEmployees"
      />

      <OgChart
        v-bind="chartPropsEmployeeTurnover"
      />
    </div>
  </div>
</template>
