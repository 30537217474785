import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type {
  ApproveDataPointRequestsMutation,
  ApproveDataPointRequestsMutationVariables,
} from '@/__generated__/types';

export const APPROVE_DATAPOINT_REQUESTS = gql`
  mutation approveDataPointRequests($approveDataPointRequestsInput: ApproveDataPointRequestsInput!) {
    approveDataPointRequests(approveDataPointRequestsInput: $approveDataPointRequestsInput) {
        _id
      status
    }
  }
`;

type TOptions = UseMutationOptions<ApproveDataPointRequestsMutation, ApproveDataPointRequestsMutationVariables>

const useApproveDataPointRequestsMutation = (options?: TOptions) => useMutation<
  ApproveDataPointRequestsMutation,
  ApproveDataPointRequestsMutationVariables
>(APPROVE_DATAPOINT_REQUESTS, options);

export default useApproveDataPointRequestsMutation;
