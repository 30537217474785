import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { CreateUserMutation, CreateUserMutationVariables } from '@/__generated__/types';

export const CREATE_USER = gql`
  mutation CreateUser($createUserInput: CreateUserInput!) {
    createUser(user: $createUserInput) {
      _id
      firstName
      lastName
      jobTitle
      jobDepartment
      email
      role
      language
      assignments {
        _id
        doubleMateriality
      }
      entities {
        _id
        name
      }
      invitedBy {
        _id
      }
    }
  }
`;

type TOptions = UseMutationOptions<CreateUserMutation, CreateUserMutationVariables>

const useCreateUserMutation = (options?: TOptions) => useMutation<
  CreateUserMutation,
  CreateUserMutationVariables
>(CREATE_USER, options);

export default useCreateUserMutation;
