<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import useUpdateEntityLocationMutation from '@/api/mutations/EntityLocation/updateEntityLocation.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';

type Project = {
  _id: string;
  name: string;
};

type Props = {
  project: Project;
  existingProjectsNames: string[];
};

const props = defineProps<Props>();

const emit = defineEmits<{
  close: [],
}>();

const { t } = useI18n();

const { mutate } = useUpdateEntityLocationMutation({
  update: (store) => {
    // Refetch projects list after project name is updated.
    store.evict({
      id: 'ROOT_QUERY',
      fieldName: 'entityLocationSummaries',
    });
  },
});

const form = reactive({
  name: '',
});
watch(() => props.project.name, (newProjectName) => {
  form.name = newProjectName;
}, { immediate: true });

const validationRules = computed(() => {
  const existingNames = new Set(props.existingProjectsNames);

  return {
    name: {
      required: helpers.withMessage(t('Project name is required.'), required),
      unique: helpers.withMessage(
        t('A project with this name already exists.'),
        (name: string) => !existingNames.has(name),
      ),
    },
  };
});
const v$ = useVuelidate(validationRules, form);

const formRef = ref<HTMLElement>();
onClickOutside(formRef, handleClose);

function handleSubmit() {
  mutate({
    updateEntityLocationInput: {
      _id: props.project._id,
      name: form.name,
    },
  });
  handleClose();
}

function handleClose() {
  emit('close');
}
</script>

<template>
  <form
    ref="formRef"
    class="inline-flex gap-2"
    data-cy="projectRenameForm"
    @submit.prevent="handleSubmit"
  >
    <AtInput
      v-model="form.name"
      :errors="v$.name.$errors"
      @blur="v$.name.$touch"
    />
    <AtButton
      class="self-start"
      :disabled="v$.$invalid"
    >
      {{ t('Apply') }}
    </AtButton>
    <AtButton
      class="self-start"
      variant="text"
      type="button"
      @click="handleClose"
    >
      {{ t('Cancel') }}
    </AtButton>
  </form>
</template>
