<script setup lang="ts">
import { computed } from 'vue';
import OgProjects from './OgProjects.vue';

type Props = {
  project?: string;
}

const props = defineProps<Props>();

const projectId = computed(() => {
  if (props.project && !Array.isArray(props.project)) {
    return props.project;
  }

  return undefined;
});
</script>

<template>
  <OgProjects :projectId="projectId" />
</template>
