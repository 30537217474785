import orderBy from 'lodash/orderBy';
import type { Project } from '../types';

export type ProjectsTree = {
  id: string;
  name: string;
  entityId?: string;
  entityName?: string;
  hasParentInfo: boolean;
  children: ProjectsTree[];
}

function sortProjectsTrees(projectsTrees: ProjectsTree[]): ProjectsTree[] {
  const sortedTree = orderBy(
    projectsTrees,
    [
      (projectTree) => projectTree.hasParentInfo,
      (projectTree) => projectTree.name,
    ],
    ['desc', 'asc'],
  )
    .map((projectTree) => {
      if (projectTree.children) {
        return {
          ...projectTree,
          children: sortProjectsTrees(projectTree.children),
        };
      }

      return projectTree;
    });
  if (projectsTrees.find((projectTree) => projectTree.entityName)) {
    return orderBy(sortedTree, [(projectTree) => projectTree.entityName], ['asc']);
  }
  return sortedTree;
}

export function getProjectsTrees(projects: Project[]): ProjectsTree[] {
  const hashTable = Object.create(null);
  projects.forEach((project) => {
    hashTable[project._id] = {
      id: project._id,
      name: project.name,
      entityId: project.entityId,
      entityName: project.entityName,
      hasParentInfo: !!project.hasParentInfo,
      children: [],
    };
  });

  const projectsTrees: ProjectsTree[] = [];
  projects.forEach((project) => {
    if (!project.parent || !hashTable[project.parent]) {
      projectsTrees.push(hashTable[project._id]);
    } else {
      hashTable[project.parent].children.push(hashTable[project._id]);
    }
  });

  return sortProjectsTrees(projectsTrees);
}
