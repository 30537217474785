<script setup lang="ts">
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

type Items = { label: string, number: number, color: string }[]

const props = defineProps<{
  items: Items,

}>();

</script>

<template>
  <div class="grid grid-cols-4 gap-4 text-center">
    <div v-for="item, i in props.items" :key="i" class="flex items-center justify-center gap-4 py-3 rounded-md" :class="item.color">
      <span class="text-2xl">{{ item.number }}</span> {{ t(item.label) }}
    </div>
  </div>
</template>
