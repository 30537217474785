import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';

import type { UpdateTaxonomyAssessmentActivitiesMutation, UpdateTaxonomyAssessmentActivitiesMutationVariables } from '../../../__generated__/types';

const UPDATE_TAXONOMY_ASSESSMENT_ACTIVITIES_MUTATION = gql`
  mutation updateTaxonomyAssessmentActivities($assessmentId: String!, $activityIds: [String!]! ) {
    updateTaxonomyAssessmentActivities(assessmentId: $assessmentId, activityIds: $activityIds) {
      _id
      
      businessActivities {
        _id
        activity {
          _id
          name
          number
        }
      }
    }
  }
`;

type TOptions = UseMutationOptions<UpdateTaxonomyAssessmentActivitiesMutation, UpdateTaxonomyAssessmentActivitiesMutationVariables>

const useUpdateTaxonomyAssessmentActivities = (options?: TOptions) => useMutation<
  UpdateTaxonomyAssessmentActivitiesMutation,
  UpdateTaxonomyAssessmentActivitiesMutationVariables
>(UPDATE_TAXONOMY_ASSESSMENT_ACTIVITIES_MUTATION, options);

export default useUpdateTaxonomyAssessmentActivities;
