<script setup lang="ts">

import { ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { notify } from '@kyvg/vue3-notification';
import MlModal from '@/components/molecules/MlModal.vue';
import AtHeading from '@/components/atoms/AtHeading/AtHeading.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import { getDPRDateString } from '@/utils/helpers/dprDates';
import useResetDataPointRequestMutation from '@/api/mutations/DataPointRequest/resetDataPointRequest.mutation';
import { apolloClient } from '@/api/apollo/client';
import type { TQuestionDataTableItem } from '../../../types';

const { t } = useI18n();

const props = defineProps<Props>();
type Props = {
  isShown: boolean
  items: TQuestionDataTableItem[];
}

const emit = defineEmits<Emits>();
type Emits = {
  (e: 'cancel'): void
  (e : 'confirm'): void
};

const selectedDprIds = ref<string[]>([]);
const intervalOptions = ref<{_id: string}>();

watchEffect(() => {
  selectedDprIds.value = props.items.map((item) => item.dpr._id);
  intervalOptions.value = props.items.reduce(
    (acc, curr) => ({ ...acc, [curr.dpr._id]: getDPRDateString(curr.dpr.from, curr.dpr.to) }), {} as {_id: string});
});

const {
  mutate: resetDataPointRequest,
  loading: resetDataPointRequestLoading,
  error: resetDataPointRequestError,
} = useResetDataPointRequestMutation();

async function handleResetDataPointRequests() {
  try {
    const results = selectedDprIds.value.map((dataPointRequestId) => resetDataPointRequest({ dataPointRequestId }));
    await Promise.all(results);

    apolloClient.cache.evict({ fieldName: 'getMyDataPointRequests' });

    notify({ type: 'success', text: t('The values has been successfully removed.') });
    emit('confirm');
  } catch (err) {
    if (resetDataPointRequestError.value) {
      notify({ type: 'error', text: t(resetDataPointRequestError.value?.message) });
    }
  }
}

</script>

<template>
  <MlModal :isRevealed="props.isShown" class="max-w-xl" @close="emit('cancel')">
    <AtHeading type="h2" class="mb-3 border-b pb-2">
      {{ t('Remove value for interval') }}
    </AtHeading>
    <p class="mb-3 text-sm">
      {{ t('Select dates for which you wish to reset the values.') }}
    </p>
    <div class="mt-2 grid grid-cols-4 gap-x-3">
      <div class="col-span-4">
        <MlSelect v-model="selectedDprIds" :options="intervalOptions" multiple />
      </div>

      <div class="flex items-center justify-between col-span-4 mt-5">
        <AtButton
          variant="outline"
          @click="emit('cancel')"
        >
          {{ t('Cancel') }}
        </AtButton>
        <AtButton
          :disabled="resetDataPointRequestLoading"
          :loading="resetDataPointRequestLoading"
          @click="handleResetDataPointRequests"
        >
          {{ t('Confirm') }}
        </AtButton>
      </div>
    </div>
  </MlModal>
</template>
