<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import DownloadIcon from '@heroicons/vue/solid/DownloadIcon';
import ViewListIcon from '@heroicons/vue/outline/ViewListIcon';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import { useStore } from '../../store';
import AtEmptyCard from '../../AtEmptyCard.vue';
import MlInfo from '../../MlInfo.vue';
import OgImpactsTable from './OgImpactsTable.vue';
import OgRisksAndOpportunitiesTable from './OgRisksAndOpportunitiesTable.vue';

const { t } = useI18n();
const store = useStore();

const showImpactsTable = computed(() => store.value.iroImpacts.length > 0);
const showRisksAndOpportunitiesTable = computed(() => store.value.iroRisksAndOpportunities.length > 0);
</script>

<template>
  <MlInfo v-if="showImpactsTable || showRisksAndOpportunitiesTable" type="warning" class="mb-6">
    {{ t('Please keep in mind that this is a cumulative score of your own rating and all stakeholder surveys. If you want to learn more about our methodology, please go to the “Start > Guidance” site on the main overview.') }}
  </MlInfo>

  <div class="flex justify-end items-center space-x-4 mb-4">
    <AtButton variant="outline" type="button" disabled :icon="DownloadIcon">
      {{ t('Download as Excel') }}
    </AtButton>
    <AtButton type="button" disabled :icon="DownloadIcon">
      {{ t('Download as PDF') }}
    </AtButton>
  </div>

  <AtEmptyCard
    v-if="!showImpactsTable"
    class="mb-8"
    variant="center"
    :icon="ViewListIcon"
    :title="t('Material impacts, risks and opportunities')"
  >
    <p>
      {{ t('Once you enter data in the Topics part, your assessment will show here.') }}
    </p>
  </AtEmptyCard>
  <OgImpactsTable v-else />

  <AtEmptyCard
    v-if="!showRisksAndOpportunitiesTable"
    class="mb-6"
    variant="center"
    :icon="ViewListIcon"
    :title="t('Non-material impacts, risks and opportunities')"
  >
    <p>
      {{ t('Once you enter data in the Topics part, your assessment will show here.') }}
    </p>
  </AtEmptyCard>
  <OgRisksAndOpportunitiesTable v-else />
</template>
