import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';

import type { CreateTaxonomyProjectMutation, CreateTaxonomyProjectMutationVariables } from '../../../__generated__/types';

const CREATE_TAXONOMY_PROJECT_MUTATION = gql`
  mutation CreateTaxonomyProject($createTaxonomyProjectInput: CreateTaxonomyProjectInput!) {
    createTaxonomyProject(createTaxonomyProjectInput: $createTaxonomyProjectInput) {
      _id
      name
      assignees {
        _id
        firstName
        lastName
      }
    }
  }
`;

type TOptions = UseMutationOptions<CreateTaxonomyProjectMutation, CreateTaxonomyProjectMutationVariables>

const useCreateTaxonomyProjectMutation = (options?: TOptions) => useMutation<
  CreateTaxonomyProjectMutation,
  CreateTaxonomyProjectMutationVariables
>(CREATE_TAXONOMY_PROJECT_MUTATION, options);

export default useCreateTaxonomyProjectMutation;
