import { getDPRDateString } from '@/utils/helpers/dprDates';
import type { TmDashboardChartSelectionQuery, TmChartSelectionQuery } from '@/__generated__/types';
import type { TStringOfDigits } from '@/types/types';
import type { TDataPointItem } from '@/components/pages/PgStatistics/PgResults/types';

export const normalizeDatapointsByType = ({
  chartSelectionDataPoints,
  isAdmin,
}: {
  chartSelectionDataPoints?:
  TmDashboardChartSelectionQuery['getAdminDataPointsByType']
  | TmChartSelectionQuery['getDataPointsByType']
  | TmChartSelectionQuery['getAdminDataPointsByType'],
  isAdmin: boolean,
}) => {
  if (chartSelectionDataPoints == null || chartSelectionDataPoints.length === 0) return [];
  return chartSelectionDataPoints.map(
    (dp) => {
      return {
      // don't change this to getDPRTimeStamp (dp.from + dp.to), this will break in edge cases!!
        [(`${dp.from}${dp.to}`)]: {
          dataPointType: {
            _id: dp.dataPointType._id,
            valueDataType: dp.dataPointType.valueDataType,
            valueUnit: dp.dataPointType.valueUnit,
            valueUnitDivisor: dp.dataPointType.valueUnitDivisor,
            summarizingMethod: dp.dataPointType.summarizingMethod,
          },
          value: dp.value,
          from: dp.from,
          to: dp.to,
        },
        project: isAdmin ? dp.entity?.name : dp.location?.name ?? '',
        projectId: isAdmin ? dp.entity?._id : dp.location?._id,
        dataPointTypeId: dp.dataPointType._id,
        header: {
          text: getDPRDateString(dp.from, dp.to),
          value: `${dp.from}${dp.to}` as TStringOfDigits,
          sortingValue: new Date(dp.to).getTime(),
        },
      } as TDataPointItem;
    },
  );
};
