<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, onMounted, ref } from 'vue';
import { GetStartedStatusEnum, UserRole } from '@/__generated__/types';
import AtProgress from '@/components/atoms/AtProgress/AtProgress.vue';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import MlStepCard from './MlStepCard.vue';

const { t } = useI18n();
const { currentUser, updateCurrentUser, entityAssigmentTotals } = useCurrentUser();

const user = computed(() => ({
  email: currentUser.value?.email,
  firstName: currentUser.value?.firstName,
  lastName: currentUser.value?.lastName,
  jobTitle: currentUser.value?.jobTitle,
  jobDepartment: currentUser.value?.jobDepartment,
}));

const getStartedStepsCompleted = computed(() => currentUser.value?.getStartedStepsCompleted ?? []);

// TODO: Make component step different depending on user role
const stepsCompletedPercentage = computed(
  () => (steps.value.filter((step) => step.isCompleted).length * 100) / steps.value.length,
);

const isProfileStepCompleted = computed(() => Object.values(user.value).every((val) => !!val));

onMounted(() => {
  if (isProfileStepCompleted.value) updateUserGetStartedSteps(GetStartedStatusEnum.PROFILE);
});

function updateUserGetStartedSteps(stepName: GetStartedStatusEnum) {
  updateCurrentUser({
    getStartedStepsCompleted: [
      ...new Set([
        ...getStartedStepsCompleted.value,
        stepName,
      ]),
    ],
  });
}

const step1 = ref({
  stepNumber: 1,
  name: GetStartedStatusEnum.PROFILE,
  title: computed(() => t('Add your profile details')),
  description: computed(() => t('Finish setting up your profile.')),
  path: '/settings/profile',
  isCompleted: isProfileStepCompleted.value
    || computed(() => getStartedStepsCompleted.value.includes(GetStartedStatusEnum.PROFILE)),
  disabled: false,
});
const step2 = ref({
  stepNumber: 2,
  name: GetStartedStatusEnum.TEAM,
  title: computed(() => t('Set up your teams')),
  description: computed(() => t('Invite and assign your colleagues to help you make your reports.')),
  path: '/settings/team',
  isCompleted: computed(() => getStartedStepsCompleted.value.includes(GetStartedStatusEnum.TEAM)),
  disabled: false,
});
const step3 = ref({
  stepNumber: 3,
  name: GetStartedStatusEnum.TOPICS,
  title: computed(() => t('Assign topics')),
  description: computed(() => `${`${t('Assign topics to colleagues to submit datapoints.')} ${t('Assigned topics:')} ${entityAssigmentTotals.value?.assignedTopicsTotal}`} / ${entityAssigmentTotals.value?.assigmentsTotal}`),
  path: `/projects/${currentUser.value?.entity.locations[0]._id}/data-entry/?getstarted=topics`,
  isCompleted: computed(() => getStartedStepsCompleted.value.includes(GetStartedStatusEnum.TOPICS)), // && topicsCount === topicsTotal ),
  disabled: false,
});
const step4 = ref({
  stepNumber: 4,
  name: GetStartedStatusEnum.LOCATIONS,
  title: computed(() => t('Set up projects')),
  description: computed(() => t('Add projects to report on.')),
  path: '/projects',
  isCompleted: computed(() => getStartedStepsCompleted.value.includes(GetStartedStatusEnum.LOCATIONS)),
  disabled: false,
});

const steps = computed(() => {
  switch (currentUser.value?.role) {
    case UserRole.SUPERADMIN:
    case UserRole.ADMIN: return [step1.value, step2.value, step3.value, step4.value];
    case UserRole.MANAGER: return [step1.value, step2.value, step3.value];
    default: return [step1.value];
  }
});

const activeStepName = computed(() => steps.value.filter((step) => !step.isCompleted)[0]?.name);

</script>

<template>
  <div
    class="px-6 lg:mt-24 lg:pl-14"
    data-cy="PgGetStarted"
  >
    <div class="mb-5 flex items-center justify-between">
      <div>
        <h1 class="mb-4 mt-0 text-4xl font-semibold text-gray-900">
          {{ t('Welcome {firstName}', { firstName: user?.firstName }) }}
        </h1>
        <p
          v-if="stepsCompletedPercentage !== 100"
          class="text-base"
        >
          {{ t('Finish these tasks to start reporting.') }}
        </p>
      </div>
      <div class="flex items-center">
        <div class="inline-block w-24 px-3">
          <AtProgress
            :percent="stepsCompletedPercentage"
            :status="stepsCompletedPercentage ? 'success' : 'default'"
          />
        </div>
        <span class="text-sm text-gray-500">
          {{ t('{stepsCompletedPercentage} % tasks completed', { stepsCompletedPercentage: Math.round(stepsCompletedPercentage) }) }}
        </span>
      </div>
    </div>

    <div
      data-cy="GetStartedSteps"
      class="grid grid-cols-1 gap-y-6"
    >
      <MlStepCard
        v-for="(step, index) in steps"
        :key="step.name"
        :stepIndex="index"
        :isActive="activeStepName === step.name"
        :step="step"
        :data-cy="`MlStepCard-${step.name}`"
        @completeStep="updateUserGetStartedSteps(step.name)"
      />
    </div>
  </div>
</template>
