import type { TmDashboardChartSelectionQuery, TmChartSelectionQuery, DataPointType, TmDashboardQuery } from '@/__generated__/types';

export const normalizeChartData = ({
  availableDataPointsGroupedByType,
  selectedDataPointTypeName,
  kpis,
}: {
    availableDataPointsGroupedByType:
       TmDashboardQuery['getAvailableDataPointsGroupedByType']
     | TmChartSelectionQuery['getAvailableDataPointsGroupedByType'];
    selectedDataPointTypeName: string;
    kpis:
       TmDashboardChartSelectionQuery['getCurrentEntity']['kpis']
     | TmChartSelectionQuery['getOwnUser']['entity']['kpis'];
}) => {
  const selectedDataPointType = availableDataPointsGroupedByType
    .find(({ dataPointType }) => dataPointType.name === selectedDataPointTypeName)
    ?.dataPointType ?? {} as DataPointType;

  const kpisOfDataPointType = kpis.filter((kpi) => kpi.dataPointType._id === selectedDataPointType._id);

  return {
    selectedDataPointType,
    kpisOfDataPointType,
  };
};
