<script setup lang="ts">
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useVuelidate } from '@vuelidate/core';
import { between, helpers, minValue, required } from '@vuelidate/validators';
import { boxed } from '@syncedstore/core';
import useCurrentUser from '@/utils/composables/useCurrentUser/useCurrentUser';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import OgDataTable from '@/components/organisms/OgDataTable/OgDataTable.vue';
import { useStore } from '../../../store';
import OgDocument, { type UploadedDocument } from '../../../OgDocument.vue';

type TimeHorizonsKey =
  | 'shortTerm'
  | 'mediumTerm'
  | 'longTerm';

type RowItem = {
  timeHorizon: string;
  inequalityLabel: string;
  key: TimeHorizonsKey;
};

const { t } = useI18n();
const store = useStore();

const { currentUser } = useCurrentUser();

const readOnlyForm = computed(() => ({
  shortTerm: {
    value: store.value.timeHorizons.shortTermValue,
  },
  mediumTerm: {
    value: store.value.timeHorizons.mediumTermValue,
  },
  longTerm: {
    value: store.value.timeHorizons.longTermValue,
  },
}));

const shortTermMaxValue = computed(() => {
  return (!readOnlyForm.value.mediumTerm.value || readOnlyForm.value.mediumTerm.value > 0) ? (readOnlyForm.value.mediumTerm.value ?? 5) : 5;
});
const mediumTermMinValue = computed(() => {
  return readOnlyForm.value.shortTerm.value ?? 5;
});
const mediumTermMaxValue = computed(() => {
  return readOnlyForm.value.longTerm.value ?? 5;
});
const longTermMinValue = computed(() => {
  return readOnlyForm.value.mediumTerm.value ?? 5;
});

const validationRules = computed(() => ({
  shortTerm: {
    value: {
      required: helpers.withMessage(t('Value is required.'), required),
      between: helpers.withMessage(
        ({ $params }) => t('Value must be between {min} and {max}.', { min: $params.min, max: $params.max }),
        between(0, shortTermMaxValue),
      ),
    },
  },
  mediumTerm: {
    value: {
      required: helpers.withMessage(t('Value is required.'), required),
      between: helpers.withMessage(
        ({ $params }) => t('Value must be between {min} and {max}.', { min: $params.min, max: $params.max }),
        between(mediumTermMinValue, mediumTermMaxValue),
      ),
    },
  },
  longTerm: {
    value: {
      required: helpers.withMessage(t('Value is required.'), required),
      minValue: helpers.withMessage(
        ({ $params }) => t('Minimum value is {min}.', { min: $params.min }),
        minValue(longTermMinValue),
      ),
    },
  },
}));
const v$ = useVuelidate(validationRules, readOnlyForm);

watch(readOnlyForm, (newTimeHorizonsValue) => {
  if (Object.keys(newTimeHorizonsValue).length > 0) {
    ([
      'shortTerm',
      'mediumTerm',
      'longTerm',
    ] as const)
      .forEach((key) => {
        if (newTimeHorizonsValue[key].value !== null && newTimeHorizonsValue[key].value !== undefined) {
          v$.value[key].$touch();
        }
      });
  }
}, { immediate: true });

const headers = computed(() => [
  { text: t('Time horizon'), value: 'timeHorizon' },
  { text: t('Time value'), value: 'timeValue' },
  { text: t('Comment'), value: 'comment' },
]);

const items = computed<RowItem[]>(() => [
  { timeHorizon: t('Short-term time horizon'), inequalityLabel: t('is equal or less than'), key: 'shortTerm' },
  { timeHorizon: t('Medium-term time horizon'), inequalityLabel: t('is equal or less than'), key: 'mediumTerm' },
  { timeHorizon: t('Long-term time horizon'), inequalityLabel: t('is more than'), key: 'longTerm' },
]);

function handleFileChange(key: TimeHorizonsKey, event: UploadedDocument) {
  store.value.timeHorizons[`${key}Document`] = boxed(event);
}
</script>

<template>
  <p class="font-bold">
    {{ t('Definition of time horizons') }}
  </p>
  <p class="text-gray-500">
    {{ t('Please define the short-term time horizon, this must be the same as the reporting period defined in the company’s financial statements. The medium-term and long-term time horizons are defined by ESRS 1, these can only be changed if these time horizons result in non-relevant information due to industry-specific characteristics.') }}
  </p>
  <form @submit.prevent="">
    <OgDataTable
      :headers="headers"
      :items="items"
      wrapperClass="og-time-horizons-table"
      controlsHidden
      noPagination
    >
      <template #item-timeHorizon="row: RowItem">
        <div class="flex items-center space-x-2.5">
          <AtInput
            wrapperClass="min-w-[294px]"
            type="text"
            readonly
            disabled
            :value="row.timeHorizon"
          />
          <p class="text-gray-700 italic">
            {{ row.inequalityLabel }}
          </p>
        </div>
        <div class="-ml-2">
          <OgDocument
            :userId="currentUser?._id!"
            :file="(store.timeHorizons[`${row.key}Document`] ?? null) as unknown as UploadedDocument"
            @update:file="handleFileChange(row.key, $event)"
          />
        </div>
      </template>
      <template #item-timeValue="row: RowItem">
        <AtInput
          v-model.number="store.timeHorizons[`${row.key}Value`]"
          type="number"
          :unit="t('year(s)')"
          :placeholder="t('Enter value')"
          :errors="v$[row.key].value.$errors"
          @blur="v$[row.key].value.$touch"
        />
      </template>
      <template #item-comment="row: RowItem">
        <AtInput
          v-model.number="store.timeHorizons[`${row.key}Comment`]"
          type="text"
          :placeholder="t('Enter')"
        />
      </template>
    </OgDataTable>
  </form>
</template>

<style lang="postcss" scoped>
.og-time-horizons-table:deep(tbody td) {
  vertical-align: top;
}
</style>
