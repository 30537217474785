<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import ViewListIcon from '@heroicons/vue/outline/ViewListIcon';
import AtEmptyCard from '../../AtEmptyCard.vue';

const { t } = useI18n();
</script>

<template>
  <AtEmptyCard
    variant="center"
    :icon="ViewListIcon"
    :title="t('No stakeholders yet')"
  >
    <p>
      {{ t('Once you decide on which of your stakeholders will be engaged by the stakeholder surveys, you can send your surveys via this page.') }}
    </p>
  </AtEmptyCard>
</template>
