import { gql } from '@apollo/client/core';

export default gql`
  query TmDashboardStatistics( $isExternal: Boolean!, $adminMode: Boolean!, $entityId: String, $shareToken: String, 
    $sharePassword: String) {
    
    getNumberOfUsers (adminMode: $adminMode) @skip(if: $isExternal)
    getNumberOfUsersExternal (adminMode: $adminMode, entityId: $entityId, shareToken: $shareToken,
      sharePassword: $sharePassword) @include(if: $isExternal)
    
    getNumberOfEntities @skip(if: $isExternal)
    getNumberOfEntitiesExternal (entityId: $entityId, shareToken: $shareToken, sharePassword: $sharePassword) @include(if: $isExternal)
    
    getNumberOfLocations @skip(if: $isExternal)
    getNumberOfLocationsExternal (entityId: $entityId, shareToken: $shareToken, sharePassword: $sharePassword) @include(if: $isExternal)

    getNumberOfEntityKpis @skip(if: $isExternal)
    getNumberOfEntityKpisExternal (entityId: $entityId, shareToken: $shareToken, sharePassword: $sharePassword) @include(if: $isExternal)
  }

`;
