<script setup lang="ts">
import { computed } from 'vue';
import type { ErrorObject } from '@vuelidate/core';
import MlAutocomplete from '@/components/molecules/MlAutocomplete.vue';
import COUNTRIES from '@/constants/countries';

type Props = {
  label?: string | null,
  modelValue: string;
  errors?: string[] | ErrorObject[];
  codeAsLabel?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  errors: () => [],
  label: null,
  codeAsLabel: false,
});
const emit = defineEmits(['update:modelValue']);

const countryOptions = computed(() => COUNTRIES.reduce<Record<string, string>>((newCountries, country) => {
  newCountries[country['alpha-2']] = props.codeAsLabel ? country['alpha-2'] : country.name;
  return newCountries;
}, {}));
</script>

<template>
  <MlAutocomplete
    :label="props.label"
    :options="countryOptions"
    :errors="props.errors"
    :modelValue="props.modelValue"
    sortedOptions
    :hideSearchIcon="props.codeAsLabel"
    wrapperClass="w-3"
    @update:modelValue="emit('update:modelValue', $event)"
  />
</template>
