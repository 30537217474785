<script setup lang="ts">
import { computed, reactive, ref, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import useUpdateDataPointCustomSubcategoriesMutation from '@/api/mutations/DataPointCategory/updateDataPointCustomSubcategories.mutation';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';

type Subcategory = {
  _id: string;
  name: string;
  categoryId: string;
};

const props = defineProps<{
  subcategory: Subcategory;
  existingSubcategoryNames: string[];
}>();

const emit = defineEmits<{
  close: [],
}>();

const { t } = useI18n();

const { mutate } = useUpdateDataPointCustomSubcategoriesMutation({
  update: (store) => {
    store.evict({
      id: 'ROOT_QUERY',
      fieldName: 'getCategoriesWithSubcategories',
    });
    store.evict({
      id: 'ROOT_QUERY',
      fieldName: 'getAllCategoriesForEntity',
    });
  },
});

const form = reactive({
  name: '',
});
watch(() => props.subcategory.name, (newSubcategoryName) => {
  form.name = newSubcategoryName;
}, { immediate: true });

const validationRules = computed(() => {
  const existingNames = new Set(props.existingSubcategoryNames);

  return {
    name: {
      required: helpers.withMessage(t('Subcategory name is required.'), required),
      unique: helpers.withMessage(
        t('A subcategory with this name already exists.'),
        (name: string) => !existingNames.has(name),
      ),
    },
  };
});
const v$ = useVuelidate(validationRules, form);

const formRef = ref<HTMLElement>();
onClickOutside(formRef, handleClose);

function handleSubmit() {
  mutate({
    categoryId: props.subcategory.categoryId,
    subcategories: [{
      _id: props.subcategory._id,
      name: form.name.trim(),
    }],
  });
  handleClose();
}

function handleClose() {
  emit('close');
}
</script>

<template>
  <form
    ref="formRef"
    class="inline-flex gap-2"
    @submit.prevent="handleSubmit"
  >
    <AtInput
      v-model="form.name"
      :errors="v$.name.$errors"
      @blur="v$.name.$touch"
    />
    <AtButton
      class="self-start"
      :disabled="v$.$invalid"
    >
      {{ t('Apply') }}
    </AtButton>
    <AtButton
      class="self-start"
      variant="text"
      type="button"
      @click="handleClose"
    >
      {{ t('Cancel') }}
    </AtButton>
  </form>
</template>
