<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useQuery } from '@vue/apollo-composable';
import { PlusIcon } from '@heroicons/vue/outline';
import dayjs from '@/lib/dayjs/config';
import type { PgGoalsOverviewQuery } from '@/__generated__/types';
import { getUserName } from '@/utils/helpers/getUserName';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import MlDataTableHeader from '@/components/organisms/OgDataTable/MlDataTableHeader.vue';
import OgDataTableControls from '@/components/organisms/OgDataTable/OgDataTableControls.vue';
import MlEmptyStateCard from '@/components/molecules/MlEmptyStateCard.vue';
import useRestrictions from '@/utils/composables/useRestrictions/useRestrictions';
import OgRestrictionsLanding from '@/components/organisms/OgRestrictionsLanding.vue';
import { RestrictionsFeaturesEnum } from '@/__generated__/types';
import type { TTargetItem, TGoalItem } from '../types';
import PG_GOALS_OVERVIEW_QUERY from './PgGoalsOverview.query';
import TmCreateKPIBottomUpModal from './TmCreateKPIBottomUpModal/TmCreateKPIBottomUpModal.vue';
import TmCreateKPITopDownModal from './TmCreateKPITopDownModal/TmCreateKPITopDownModal.vue';
import MlGoal from './MlGoal.vue';
import OgApproachModal from './OgApproachModal.vue';

const { t, locale } = useI18n();

const { result, refetch, loading } = useQuery<PgGoalsOverviewQuery>(PG_GOALS_OVERVIEW_QUERY);
const KPIs = computed(() => result.value?.getEntityKPIs ?? []);
const goals = computed(() => result.value?.getGoals ?? []);

const headers = computed(() => [
  { text: t('Type'), value: 'type' },
  { text: t('Title'), value: 'title' },
  { text: t('Assignee'), value: 'assignee' },
  { text: t('Deadline'), value: 'deadline' },
  { text: t('Status'), value: 'status' },
]);

const {
  restrictions,
  loading: loadingRestrictions,
} = useRestrictions();

const goalItems = computed(() => {
  const targetsWithKPI = KPIs.value.flatMap((kpi) => kpi.targets.map((target) => ({ ...target, kpi })));

  const targetMap = targetsWithKPI.reduce<Record<string, TTargetItem>>((acc, target) => {
    acc[target._id] ??= {
      target,
      goal: target.goal,
      title: target.name,
      statusPercentage: 100,
      kpis: [],
    };

    const kpiStatusPercentage = target.kpi.completionPercent;

    if (acc[target._id].statusPercentage > kpiStatusPercentage) {
      acc[target._id].statusPercentage = kpiStatusPercentage;
    }

    acc[target._id].kpis.push({
      kpi: target.kpi,
      title: target.kpi.dataPointType.friendlyName,
      assignee: getUserName(target.kpi.user),
      deadline: dayjs(target.kpi.dueDate).format('DD MMM, YYYY'),
      statusPercentage: kpiStatusPercentage,
    });

    return acc;
  }, {});

  const goalMap = Object.values(targetMap).reduce<Record<string, TGoalItem>>((acc, target) => {
    acc[target.goal._id] ??= {
      goal: target.goal,
      title: target.goal.name,
      targets: [],
    };

    acc[target.goal._id].targets.push(target);

    return acc;
  }, {});

  return Object.values(goalMap);
});

const filteredAndSortedGoalItems = ref(goalItems.value);
watch(goalItems, () => { filteredAndSortedGoalItems.value = goalItems.value; });

const isApproachModalRevealed = ref(false);
const isCreateKPIBottomUpModalRevealed = ref(false);
const isCreateKPITopDownModalRevealed = ref(false);

const newKpiIds = ref<string[]>([]);
const handleNewKPIs = async (kpiIds: string[] = []) => {
  await refetch();
  newKpiIds.value = kpiIds;
};
</script>

<template>
  <template v-if="loadingRestrictions">
    <div>Loading...</div>
  </template>
  <template v-else-if="restrictions?.restrictedFeatures.includes(RestrictionsFeaturesEnum.GOALS)">
    <OgRestrictionsLanding>
      <template #pageTitle>
        {{ t('Goals') }}
      </template>
      <template #title>
        {{ t('Set and achieve sustainability goals') }}
      </template>
      <template #text>
        {{ t('Connect your sustainability goals and data collection in one place. You can see progress to keep teams on track.') }}<br />
        {{ t('You can see progress to keep teams on track. Automatically let goals update or update goals manually.') }}
      </template>
      <template #image>
        <img
          v-if="locale === 'de'"
          alt="Ziele"
          src="@/assets/images/goals-de.png"
        />
        <img
          v-else
          alt="Goals"
          src="@/assets/images/goals.png"
        >
      </template>
    </OgRestrictionsLanding>
  </template>
  <div v-else class="pt-5 px-6 flex flex-col">
    <div>
      <h2 class="text-lg font-medium leading-6">
        {{ t('Goals') }}
      </h2>

      <AtButton
        v-if="!loading && goalItems.length"
        class="float-right mt-5"
        :icon="PlusIcon"
        @click="isApproachModalRevealed = true"
      >
        {{ t('Add') }}
      </AtButton>

      <p class="mt-3 text-sm text-gray-500">
        {{ t('Set your sustainability goals and track your contributions and progress') }}
      </p>
    </div>

    <MlEmptyStateCard
      v-if="!loading && !goalItems.length"
      class="mt-4"
      :title="t('No goals yet')"
      :description="t('Start by adding first goal.')"
      :buttonText="t('Add Goal')"
      @buttonClick="isApproachModalRevealed = true"
    />

    <template v-else-if="!loading">
      <OgDataTableControls
        v-model="filteredAndSortedGoalItems"
        class="mt-8"
        :headers="headers"
        :items="goalItems"
        sortHidden
        filterHidden
      />
      <MlDataTableHeader
        class="mt-6 grid-cols-[1fr_3fr_1fr_1fr_1fr_0.7fr] gap-2 !mb-0"
        :headers="headers"
        lastColumnAlignLeft
      />
      <MlGoal
        v-for="goalItem in filteredAndSortedGoalItems"
        :key="goalItem.title"
        :goal="goalItem"
        :newKpiIds="newKpiIds"
      />
    </template>

    <OgApproachModal
      v-model:isRevealed="isApproachModalRevealed"
      @bottomUp="isCreateKPIBottomUpModalRevealed = true"
      @topDown="isCreateKPITopDownModalRevealed = true"
    />
    <TmCreateKPIBottomUpModal
      v-model:isRevealed="isCreateKPIBottomUpModalRevealed"
      :goals="goals"
      :kpis="KPIs"
      @created="handleNewKPIs"
    />
    <TmCreateKPITopDownModal
      v-model:isRevealed="isCreateKPITopDownModalRevealed"
      :goals="goals"
      :kpis="KPIs"
      @created="handleNewKPIs"
    />
  </div>
</template>
