<script setup lang="ts">
import remixiconUrl from 'remixicon/fonts/remixicon.symbol.svg';

export type TMenueItem = {
  icon: string
  title: string
  action: CallableFunction,
  // eslint-disable-next-line vue/require-default-prop
  isActive?: CallableFunction,
  loading?: boolean
}

const props = withDefaults(defineProps<TMenueItem>(), {
  loading: false,
});
</script>

<template>
  <button
    class="h-5 w-5"
    :class="{ 'animate-spin': props.loading }"
    :title="props.title"
    type="submit"
    @click.prevent="props.action"
  >
    <svg class="menueitem h-5 w-5 fill-[#6B7280]">
      <use :xlink:href="`${remixiconUrl}#ri-${props.icon}`" />
    </svg>
  </button>
</template>
