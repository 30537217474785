<script setup lang="ts">
import { computed } from 'vue';
import useBreakpoints from '@/utils/composables/useBreakpoints';

const { lg } = useBreakpoints();

// Key used to force rerender of the content. It is needed to force content to
// be shown again because OgPageNav is only rendered on larger screen sizes,
const key = computed(() => lg.value ? 'show' : 'hide');
</script>

<template>
  <Teleport
    :key="key"
    to="#page-subnav"
  >
    <slot />
  </Teleport>
</template>
