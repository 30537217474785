import { watch } from 'vue';
import { currentUser } from '@/utils/composables/useCurrentUser/useCurrentUser';

export const initIntercom = () => {
  const anyWindow = window as unknown as {Intercom: (action: string, payload: object) => void};

  if (!anyWindow.Intercom) return;

  watch(currentUser, (user) => {
    if (!user) return;

    anyWindow.Intercom('update', {
      firstname: user.firstName,
      lastname: user.lastName,
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      languageOverride: user.language,
    });
  }, { immediate: true });
};
