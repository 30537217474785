<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import type { ErrorObject } from '@vuelidate/core';
import MlSelect from '@/components/molecules/MlSelect/MlSelect.vue';
import { ReportingFrameworkEnum } from '@/__generated__/types';

type Props = {
  modelValue: string[];
  errors?: string[] | ErrorObject[];
  isDisabled: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  errors: () => [],
});
const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();

const standardsOptions = [ReportingFrameworkEnum.ESRS, ReportingFrameworkEnum.GRI, ReportingFrameworkEnum.DNK]
  .reduce(
    (acc: Record<string, string>, curr: string) => ({ ...acc, [curr]: ReportingFrameworkEnum[curr as ReportingFrameworkEnum] }), {},
  );
</script>

<template>
  <MlSelect
    :label="t('Standards')"
    :description="t('Under which standard does your company want to report? (We recommend GRI or ESRS)')"
    :errors="props.errors"
    :options="standardsOptions"
    data-cy="companyDprSelectReportingFrameworks"
    :disabled="isDisabled"
    :modelValue="props.modelValue"
    @update:modelValue="emit('update:modelValue', $event)"
  />
</template>
