import { gql } from '@apollo/client/core';

export default gql`
  query TmSettingsMateriality {
    getCategoriesWithSubcategories {
      _id
      slug
      framework
      organization {
        _id
      }
      entity {
        _id
      }
      location {
        _id
      }
      subcategories {
        _id
        slug
        orderPosition
        topic
      }
    }

    getOwnUser {
      _id
      role
      assignments {
        _id
        subcategories {
          subcategory
          location {
            _id
          }
        }
      }
      entity {
        _id
        reportingFrameworks
        locations {
          _id
          name

          assignments {
            _id
            categories {
              _id
              category
              subcategories {
                _id
                subcategory
              }
            }
          }
        }
      }
    }

    getTeamUsers {
      _id
      firstName
      lastName
      role
      email

      assignments {
        _id
        categories {
          category
          role
          location {
            _id
            name
          }
        }
        subcategories {
          subcategory
          role
          location {
            _id
            name
          }
        }
        datapointtypes {
          dataPointType {
            _id
            name
          }
          role
          location {
            _id
            name
          }
        }
      }
    }
  }
`;
