<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { DoubleMaterialityIroImpactType } from '@/__generated__/types';
import { calculateMaterialityForActualPositive } from '../../../utils';
import { useStore } from '../../../store';
import OgScore, { type RowItem } from '../OgScore.vue';

const { t } = useI18n();
const store = useStore();

const headers = computed(() => [
  { text: t('Impacts'), value: 'impact' },
  { text: t('Scale'), value: 'scale', columnClasses: 'w-[150px]' },
  { text: t('Scope'), value: 'scope', columnClasses: 'w-[150px]' },
  { text: t('Materiality'), value: 'materiality', columnClasses: 'w-[100px]' },
]);

const items = computed<RowItem[]>(() => store
  .value
  .iroImpacts
  .filter((iroImpact) => iroImpact.type === DoubleMaterialityIroImpactType.ACTUAL_POSITIVE)
  .map((iroImpact) => ({
    _id: iroImpact._id,
    impact: iroImpact,
    riskAndOpportunity: null,
    topic: store.value.topics.find((topic) => topic._id === iroImpact.topicId)!,
    materiality: calculateMaterialityForActualPositive(iroImpact.scale, iroImpact.scope),
  })));
</script>

<template>
  <OgScore
    :headers="headers"
    :items="items"
    :title="t('Identified positive actual impacts:')"
    infoType="success"
    goBackRoute="doubleMaterialityIROsScorePotentialNegative"
    nextRoute="doubleMaterialityIROsScorePotentialPositive"
  >
    <p class="mb-4">
      {{ t('For positive actual impacts, materiality is determined by the scale and scope of the impact. Please rate these 2 criteria from 1 (being the lowest) to 5 (being the highest).') }}
    </p>
    <ul class="list-disc pl-6">
      <li>
        {{ t('The scale: how grave or beneficial the impact is, e.g. does the impact lead to non-compliance with laws or regulations?') }}
      </li>
      <li>
        {{ t('The scope: how widespread the impact is, e.g. the number of individuals or countries affected.') }}
      </li>
    </ul>
  </OgScore>
</template>
