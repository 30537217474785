import { gql } from '@apollo/client/core';

export default gql`
  query PgQualitativeView($dateFrom: String!, $dateTo: String!, $workspaceIds: [String!]) {
    entitySummaries {
      _id
      name
      entityId
      entityName
      parent
      hasParentInfo
    }

    getCurrentUserEntities {
      _id
      name
    }

    getAvailableSubcategories {
      _id
      category
      subcategory
    }

    getSummaryDataPointsByProjectAndDateRangeAdmin(dateFrom: $dateFrom, dateTo: $dateTo, workspaceIds: $workspaceIds) {
      value
      valueUnit
      displayValue
      displayValueUnit

      valueSource {
        name
        originalValueUnit
        originalValue
      }

      type
      from
      to
      comment

      dataPointRequest {
        _id
        delegations {
          _id
        }
      }

      dataPointType {
        _id
        name
        friendlyName
        question
        questionHelp
        valueUnit
        valueUnitDivisor
        valueDataType
        valueDataTypeOptions
        refreshInterval
        summarizingMethod

        emissionType
        emissionCategory
        emissionSubcategory

        emissionFactors {
          _id
          year
          factor
        }

        activeReportingFramework {
          _id
          category
          subcategory
          framework
          groups
          order
        }
        reportingFrameworks {
          _id
          framework
          groups
          order
        }
      }
    }
    
    getQualitativeDataPointsByProjectAndDateRangeAdmin(dateFrom: $dateFrom, dateTo: $dateTo, workspaceIds: $workspaceIds) {
      value
      valueUnit
      displayValue
      displayValueUnit

      valueSource {
        name
        value
        originalValueUnit
        originalValue
      }
      valueUnit

      type
      from
      to
      comment

      entity {
        _id
        name
      }

      location {
        _id
        name
      }

      dataPointRequest {
        _id
        delegations {
          _id
        }
      }

      dataPointType {
        _id
        name
        friendlyName
        question
        questionHelp
        valueUnit
        valueUnitDivisor
        valueDataType
        valueDataTypeOptions
        refreshInterval
        summarizingMethod

        emissionType
        emissionCategory
        emissionSubcategory

        emissionFactors {
          _id
          year
          factor
        }

        activeReportingFramework {
          _id
          category
          subcategory
          framework
          groups
          order
        }
        reportingFrameworks {
          _id
          framework
          groups
          order
        }
      }
    }
  }
`;
