import { gql } from '@apollo/client/core';

export default gql`
  query TmDataEntry(
    $statusFilter: DataPointRequestStatusEnum,
    $categoryFilter: [String!],
    $subcategoryFilter: [String!],
    $locationId: String!,
    $includeChilds: Boolean,
  ) {

    getEntityTags {
      _id
      name
      color
      entity {
        _id
      }
    }

    getEntityCustomQuestionnaires {
      _id
      name
    }

    entityLocationSubcategorySummaries(locationId: $locationId) {
      subcategory
      category
      progress
      overrides {
        _id
        datapointtype {
          _id
        }
        refreshInterval
        valueUnit
        isSplit
        emissionFactors {
          _id
          year
          factor
        }
        customConversionFactor
        tags {
          _id
          name
          color
          entity { 
            _id
          }
        }
      }
      assignees {
        _id
        firstName
        lastName
        email
        picture {
          _id
          downloadUrl
        }
      }
    }

    getMyDataPointRequests(
      statusFilter: $statusFilter,
      categoryFilter: $categoryFilter, 
      subcategoryFilter: $subcategoryFilter,
      location: $locationId,
      includeChilds: $includeChilds
    ) {
      _id

      parent {
        _id
      }

      ...dprFields
      ...childs
    }
  }
  
  fragment childs on DataPointRequest {
    _id
    
    childs {
      _id
      
      ...dprFields
      
      childs {
        _id
        
        ...dprFields
      }
    }
  }
  
  fragment dprFields on DataPointRequest {
    _id
    dueDate
    from
    to
    value
    comment
    chatComments {
      _id
      text
      updatedAt
      user {
        _id
        firstName
        lastName
        email
        picture {
          _id
          downloadUrl
        }
      }
    }
    status
    originalValueUnit
    originalValue
    isHistoric
    isReopenRequestPending

    followers {
      _id
    }

    override {
      _id
      guidanceComments {
        _id
        text
        updatedAt
        user {
          _id
          firstName
          lastName
          email
          picture {
            _id
            downloadUrl
          }
        }
      }
    }

    displayStatus

    valueSource {
      name
      value
      originalValue
      originalValueUnit
      emissionFactor
      emissionFactorCountry
    }

    file {
      _id
      filename
    }

    location {
      _id
    }

    dataPointTypeFlat {
      category
      subcategory
      conditionValue
    }

    delegations {
      _id
      shareToken
      user {
        _id
        email
        firstName
        lastName
        isExternal
        picture {
          _id
          downloadUrl
        }
      }
      reminder {
        _id
        sendAt
      }
    }

    rejectedAt
    rejectReason
    rejectedBy {
      _id
      firstName
      lastName
    }

    dataPointType {
      _id
      allowUploadProof
      friendlyName
      question
      questionHelp
      valueUnit
      valueUnitDivisor
      valueDataType
      valueDataTypeOptions
      summarizingMethod

      entity {
        _id
      }

      location {
        _id
      }

      organization {
        _id
      }

      refreshInterval
      activeReportingFramework {
        _id
        framework
        groups
        required
        boundary
        phasein
      }
      reportingFrameworks {
        _id
        framework
        groups
      }

      emissionSubcategory

      emissionFactors {
        _id
        year
        factor
      }

      calculatedDataPointTypes {
        _id
        name
        friendlyName
        formular
        formularDependencies {
          _id
          name
          friendlyName
        }
      }
    }
  }
  
`;

// export const CALCULATION_QUERY = gql`
//   query TmDataEntryCalculationQuery($dataPointRequestId: String!, $dataPointRequestValue: JSON!) {
//     getCalculatedDatapoints(dataPointRequestId: $dataPointRequestId, dataPointRequestValue: $dataPointRequestValue){
//       name
//       formular
//       result
//       variables
//     }
//   }
//   `;
