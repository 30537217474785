<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { computed, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { auth0 } from '@/api/auth/auth';
import { router } from '@/router';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

const { t } = useI18n();

const hasVerifiedEmailPreviously = computed(() => {
  const item = localStorage.getItem('emailVerifiedCallback');

  if (item) {
    localStorage.removeItem('emailVerifiedCallback');
  }

  return item;
});
const googleLogin = () => {
  auth0.authorize({
    connection: 'google-oauth2',
    connection_scope: 'openid email profile',
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    responseType: 'token',
    redirectUri: `${document.location.origin}/loginCallback`,
  });
};

const email = ref('');
const route = useRoute();

onMounted(() => {
  if (route.query.email) {
    email.value = route.query.email.toString();
  }
});

const next = (e: Event) => {
  if (!(e.target as HTMLFormElement).reportValidity()) {
    return;
  }
  router.push({ name: 'loginChoose', query: { email: email.value } });

  return false;
};

const signup = () => router.push({ name: 'signup', query: { email: email.value } });

</script>

<template>
  <div
    id="box-1"
    class="box mt-8"
  >
    <div>
      <div>
        <p
          v-if="hasVerifiedEmailPreviously"
          class="mb-8 text-xl"
        >
          {{ t('Thanks for verifying your account. Please log in below.') }}
        </p>

        <p class="text-sm font-medium text-gray-700">
          {{ t('Log in with') }}
        </p>

        <div class="mt-1 grid">
          <div>
            <a
              href="#"
              class="login-google inline-flex w-full justify-center rounded-md border border-gray-400 bg-white px-4 py-2 text-sm font-medium text-gray-500 shadow-sm hover:bg-gray-50"
              @click="googleLogin()"
            >
              <span class="sr-only">{{ t('Login at Codio Impact') }}</span>

              <img
                alt="Codio Impact Logo"
                class="h-5 w-5"
                src="data:image/svg+xml,%3Csvg class='h-5 w-5' fill='currentColor' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' viewBox='0 0 48 48'%3E%3Cdefs%3E%3Cpath id='a' d='M44.5 20H24v8.5h11.8C34.7 33.9 30.1 37 24 37c-7.2 0-13-5.8-13-13s5.8-13 13-13c3.1 0 5.9 1.1 8.1 2.9l6.4-6.4C34.6 4.1 29.6 2 24 2 11.8 2 2 11.8 2 24s9.8 22 22 22c11 0 21-8 21-22 0-1.3-.2-2.7-.5-4z'/%3E%3C/defs%3E%3CclipPath id='b'%3E%3Cuse xlink:href='%23a' overflow='visible'/%3E%3C/clipPath%3E%3Cpath clip-path='url(%23b)' fill='%23FBBC05' d='M0 37V11l17 13z'/%3E%3Cpath clip-path='url(%23b)' fill='%23EA4335' d='M0 11l17 13 7-6.1L48 14V0H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%2334A853' d='M0 37l30-23 7.9 1L48 0v48H0z'/%3E%3Cpath clip-path='url(%23b)' fill='%234285F4' d='M48 48L17 24l-4-3 35-10z'/%3E%3C/svg%3E"
              >
            </a>
          </div>
        </div>
      </div>

      <div class="relative mt-6">
        <div
          class="absolute inset-0 flex items-center"
          aria-hidden="true"
        >
          <div class="w-full border-t border-gray-400" />
        </div>
        <div class="relative flex justify-center text-sm">
          <span class="bg-white px-2 text-gray-500">{{ t('or continue with') }}</span>
        </div>
      </div>
    </div>

    <div class="mt-6">
      <form
        @submit.prevent="next"
      >
        <div>
          <label
            for="email"
            class="block text-sm font-medium text-gray-700"
          >{{ t('Email address') }}</label>
          <div class="mt-1">
            <input
              id="email"
              v-model="email"
              name="email"
              type="email"
              placeholder="max@mustermann.de"
              autocomplete="email"
              required
              class="block w-full appearance-none rounded-md border border-gray-400 px-3 py-2 shadow-sm placeholder:text-gray-400 focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm"
            >
          </div>
        </div>

        <div class="captcha-container form-group" />

        <div class="mt-5">
          <AtButton class="w-full">
            {{ t('Continue') }}
          </AtButton>
        </div>

        <div class="mt-5">
          <a
            class="cursor-pointer text-base text-blue-600"
            @click="signup"
          >
            {{ t('No account? Sign up') }}
          </a>
        </div>
      </form>
    </div>
  </div>
</template>
