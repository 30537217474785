import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { UpdateEntityLocationMutation, UpdateEntityLocationMutationVariables } from '@/__generated__/types';

export const UPDATE_ENTITY_LOCATION = gql`
  mutation UpdateEntityLocation($updateEntityLocationInput: UpdateEntityLocationInput!) {
    updateEntityLocation(entityLocation: $updateEntityLocationInput) {
      _id
      name
      isHeadquarters
      areCategoriesAppliedOnAllProjects
      areCustomCategoriesAppliedOnAllProjects
    }
  }
`;

type TOptions = UseMutationOptions<UpdateEntityLocationMutation, UpdateEntityLocationMutationVariables>

const useUpdateEntityLocationMutation = (options?: TOptions) => useMutation<
  UpdateEntityLocationMutation,
  UpdateEntityLocationMutationVariables
>(UPDATE_ENTITY_LOCATION, options);

export default useUpdateEntityLocationMutation;
