import { gql } from '@apollo/client/core';

export default gql`
  query TmLibraryUploads($adminMode: Boolean!) {
    getEntityRepositoryFiles(type: "evidence") @skip(if: $adminMode) {
      _id
      ...tmLibraryUploadsFields
    }

    getEntityRepositoryFilesAdmin(type: "evidence") @include(if: $adminMode) {
      _id
      ...tmLibraryUploadsFields
    }
  }

  fragment tmLibraryUploadsFields on RepositoryFile{
    _id
    filename

    entity {
      _id
      name
    }
  }
`;
