import { gql } from '@apollo/client/core';

export default gql`
  query TmSettingsEntities {
    getCurrentUserEntities {
      _id
      name
    }
  }
`;
