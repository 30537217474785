<script setup lang="ts">
import { computed, ref } from 'vue';
import { useLink } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { UserRole } from '@/__generated__/types';
import AtProgress from '@/components/atoms/AtProgress/AtProgress.vue';
import type { Project } from '../types';
import { useDeleteProject } from '../common/composables';
import MlProjectMenu from '../common/MlProjectMenu.vue';
import MlProjectCardAssignee from './MlProjectCardAssignee.vue';
import MlProjectCardRename from './MlProjectCardRename.vue';

type Props = {
  project: Project
};
const props = defineProps<Props>();

const { t } = useI18n();

const url = useLink({
  to: {
    name: props.project.hasChildren ? 'projectsProject' : 'projectsProjectDataEntry',
    params: {
      project: props.project._id,
    },
  },
});

const deleteProject = useDeleteProject(false);

const isActive = ref(false);
const isRenaming = ref(false);

const assigneesToShow = computed(() => props.project.assignees.length > 5 ? props.project.assignees.slice(1, 6) : props.project.assignees);

function handleActiveToggle() {
  isActive.value = !isActive.value;
}

function handleCopy() {
  navigator.clipboard.writeText(`${window.location.origin}${url.href.value}`);
}

function handleRenameToggle() {
  isRenaming.value = !isRenaming.value;
}

function handleDelete() {
  deleteProject(props.project._id, props.project.name);
}

</script>

<template>
  <component
    :is="isRenaming ? 'div' : 'router-link'"
    :to="{
      name: project.hasChildren ? 'projectsProject' : 'projectsProjectDataEntry',
      params: {
        project: project._id,
      },
    }"
  >
    <div
      class="group flex h-48 w-48 cursor-pointer flex-col rounded-lg p-3 ring-primary"
      :class="{
        'hover:ring-1': !isRenaming,
        'ring-1': isActive,
        'bg-rose-50': project.isHeadquarters,
        'bg-gray-200': !project.isHeadquarters,
      }"
      :data-cy="`MlProjectCard-${project.name}`"
    >
      <div class="mb-auto flex space-x-1">
        <div class="pl-3 pt-3 flex-1">
          <p
            v-if="!isRenaming"
            class="w-full text-left line-clamp-3 group-hover:text-primary break-word"
            style="overflow-wrap: anywhere;"
            :class="{
              'text-primary': isActive,
            }"
            :title="project.name"
          >
            {{ project.name }}
          </p>
          <MlProjectCardRename
            v-else
            :projectId="project._id"
            :projectName="project.name"
            :existingProjectsNames="[]"
            @close="handleRenameToggle"
          />
        </div>
        <MlProjectMenu
          v-if="!isRenaming"
          v-rolefrom="UserRole.ADMIN"
          class="shrink-0 invisible group-hover:visible text-gray-400"
          :buttonClass="`transition-colors hover:text-primary ${isActive ? ' text-primary' : 'text-gray-400'}`"
          :class="{
            visible: isActive,
            invisible: !isActive,
          }"
          :canDelete="!project.isHeadquarters"
          :canRenameSubcategory="false"
          @copy="handleCopy"
          @renameProject="handleRenameToggle"
          @delete="handleDelete"
          @click.stop.prevent="handleActiveToggle"
          @hide="handleActiveToggle"
        />
      </div>
      <div class="px-3 pb-3">
        <AtProgress
          class="w-full mb-2"
          :class="{
            'group-hover:text-primary': !isRenaming,
            'text-primary': isActive,
          }"
          :progressClass="`group-hover:bg-white${isActive ? ' bg-white' : ''}`"
          :percent="Math.floor(project.progress)"
          showPercentNumber
        />
        <template v-if="!project.hasChildren">
          <div
            v-if="project.assignees.length > 0"
            class="mt-2 flex h-7 items-center gap-x-2"
          >
            <div class="avatar-group -space-x-2.5">
              <MlProjectCardAssignee
                v-for="assignee in assigneesToShow"
                :key="assignee._id"
                :assignee="assignee"
              />
            </div>
            <span
              v-if="project.assignees.length > 5"
              class="text-xs text-gray-400"
            >
              + {{ project.assignees.length - 5 }}
            </span>
          </div>
          <div
            v-else
            class="h-7 text-gray-400"
          >
            {{ t('No users assigned.') }}
          </div>
        </template>
        <div
          v-else
          class="h-7 text-gray-400"
        >
          {{ t('Group') }}
        </div>
      </div>
    </div>
  </component>
</template>
