<script setup lang="ts">
import { ref, useSlots } from 'vue';
import { useI18n } from 'vue-i18n';
import { v4 } from 'uuid';
import { DocumentIcon, InformationCircleIcon } from '@heroicons/vue/outline';
import AtProgress from '@/components/atoms/AtProgress/AtProgress.vue';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

const props = withDefaults(defineProps<TProps>(), {
  modelValue: () => [],
  id: () => v4(),
  progress: 0,
  description: '',
  placeholder: '',
  large: false,
  validate: () => null,
});
interface TProps {
  modelValue?: File[];
  id?: string;
  loading?: boolean;
  progress?: number;
  disabled?: boolean;
  multiple?: boolean;
  description?: string;
  placeholder?: string;
  compact?: boolean;
  large?: boolean,
  validate?: (files: File[]) => string | null,
}

const emit = defineEmits<TEmits>();
type TEmits = {
  (e: 'update:modelValue', files: File[]): void,
  (e: 'uploadCancel'): void,
};

const slots = useSlots();
const { t } = useI18n();

const dropzoneHighlight = ref(false);

const error = ref<string | null>(null);

type FileInputEvent = InputEvent & { target: { files: FileList | null, value: '' | null } };

const handleFileInput = (e: FileInputEvent) => {
  error.value = null;

  dropzoneHighlight.value = false;
  const files = Array.from(e.target.files ?? []);

  if (!files.length) {
    return;
  }

  const invalidMessage = props.validate(files);
  if (invalidMessage) {
    error.value = invalidMessage;
  } else {
    emit('update:modelValue', props.multiple ? [...props.modelValue, ...files] : files);

    // chrome workaround to allow picking the same file if it was previously removed
    e.target.value = null;
  }
};

const handleFileRemoval = (fileIndex: number) => {
  error.value = null;

  const newFiles = [...props.modelValue];
  newFiles.splice(fileIndex, 1);
  emit('update:modelValue', newFiles);
};

const handleCancel = () => {
  error.value = null;

  emit('update:modelValue', []);
  emit('uploadCancel');
};
</script>

<template>
  <div :class="large ? 'w-full' : 'w-64'">
    <div class="relative">
      <input
        :id="props.id"
        class="top peer absolute z-10 h-full w-full cursor-pointer opacity-0 disabled:pointer-events-none"
        type="file"
        :disabled="props.disabled"
        :multiple="props.multiple"
        v-bind="$attrs"
        data-cy="MlFilePickerInput"
        @dragenter="dropzoneHighlight = true"
        @dragleave="dropzoneHighlight = false"
        @input="handleFileInput($event as FileInputEvent)"
      >
      <div
        :for="props.id"
        class="outline-3 relative flex flex-col justify-center rounded-md border-2 border-dashed border-gray-400 text-gray-500 outline-primary peer-hover:border-primary peer-focus-visible:outline peer-disabled:pointer-events-none peer-disabled:opacity-50"
        :class="{
          'border-primary': dropzoneHighlight,
          'min-h-[4rem]': props.compact && !large,
          'min-h-[9rem]': !props.compact && !large,
          'h-64': large,
          'p-6': !props.compact,
          'p-2': props.compact,
        }"
      >
        <div
          v-if="props.loading"
          class="flex w-full flex-col"
        >
          <AtProgress
            class="pointer-events-none"
            :percent="props.progress"
          />
          <AtButton
            class="relative z-20 mt-2"
            variant="text"
            @click.stop.prevent="handleCancel"
          >
            {{ t('Cancel') }}
          </AtButton>
        </div>
        <div
          v-else-if="!modelValue?.length"
          class="flex items-center justify-center text-center"
          :class="{
            'flex-col': !props.compact,
            'gap-4': props.compact,
          }"
        >
          <DocumentIcon
            v-if="!props.compact"
            class="mb-2 h-10"
          />
          <div>
            <span
              class="flex text-sm text-gray-700"
              :class="{
                'font-medium': large,
              }"
            >
              {{ props.placeholder || t('Select or drop files') }}
            </span>
            <p class="mt-2 text-xs">
              {{ props.description || t('Files up to 10 MB') }}
            </p>
          </div>
          <div
            v-if="!props.compact && slots.default"
            class="relative z-20 mt-2 w-full"
          >
            <slot v-if="!props.compact" />
          </div>
        </div>

        <ul
          v-else
          class="text-center"
        >
          <li
            v-for="(file, i) in modelValue"
            :key="i"
          >
            {{ file.name }}
            <AtButton
              data-cy="AtButtonRemoveFile"
              class="relative z-20 cursor-pointer hover:underline"
              variant="link"
              @click.stop.prevent="handleFileRemoval(i)"
            >
              {{ t('remove') }}
            </AtButton>
          </li>
        </ul>
      </div>

      <p
        v-if="error"
        class="mt-1 text-xs text-error"
      >
        <span class="flex">
          <InformationCircleIcon class="mr-1 w-3" />
          {{ t(error) }}
        </span>
      </p>
    </div>
    <slot v-if="props.compact" />
  </div>
</template>
