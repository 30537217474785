import { gql } from '@apollo/client/core';

export default gql`
  query PgCustomQuestionnaire($id: String!) {
    getCustomQuestionnaire(id: $id) {
      _id
      entity {
        _id
        name
      }
      initiallyCreatedForEntityLocation {
        _id
        name
      }
      status
      category {
        _id
        slug
      }
      subcategory {
        _id
        slug
      }
      questions {
        _id
        type
        title
        question
        questionHelp
        refreshInterval
        frameworks
        frameworkGroup
        allowUploadProof
        extraOptions {
          __typename
          ... on CustomQuestionnaireQuestionNumericOptions {
            valueUnit
          }
          ... on CustomQuestionnaireQuestionChoiceOptions {
            options
          }
          ... on CustomQuestionnaireQuestionNumericSplitOptions {
            unitGroup
          }
        }
      }
    }
  }
`;
