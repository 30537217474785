import { gql } from '@apollo/client/core';

export default gql`
  query PgDataEntryUpload {
    entityLocationSummaries {
      _id
      name
    }
  }
`;
