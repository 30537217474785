<script setup lang="ts">

import { useGlobalQueryLoading } from '@vue/apollo-composable';
import OgFooter from '@/components/organisms/OgFooter.vue';
import AtProgress from '@/components/atoms/AtProgress/AtProgress.vue';

const loading = useGlobalQueryLoading();

</script>

<template>
  <div class="relative bg-white">
    <notifications position="bottom right" />
    <AtProgress
      v-if="loading"
      foreground
    />

    <div id="topbar" />

    <div class="flex h-full min-h-screen flex-col">
      <div class="mx-auto flex w-full max-w-[1920px] flex-1">
        <div class="flex flex-1 flex-col justify-between z-0">
          <main class="ml-10 flex flex-col lg:pr-12">
            <router-view />
          </main>
          <OgFooter />
        </div>
      </div>
    </div>
  </div>
</template>
