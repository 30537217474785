<script setup lang="ts">
import type { Component, HTMLAttributes } from 'vue';
import {
  Primitive,
  type AsTag,
  // type PrimitiveProps
} from 'radix-vue';
import { cn } from '@/components/ui/utils';
import { type ButtonVariants, buttonVariants } from '.';

/* PrimitiveProps cannot be extends.
error: [@vue/compiler-sfc] Failed to resolve extends base type.
*/
// interface Props extends PrimitiveProps
interface Props {
  // primitive props copied from PrimitiveProps
  asChild?: boolean;
  as?: AsTag | Component;

  // other props
  variant?: ButtonVariants['variant']
  size?: ButtonVariants['size']
  class?: HTMLAttributes['class']
}

const props = withDefaults(defineProps<Props>(), {
  as: 'button',
  variant: undefined,
  size: undefined,
  class: undefined,
});
</script>

<template>
  <Primitive
    :as="as"
    :asChild="asChild"
    :class="cn(buttonVariants({ variant, size }), props.class)"
  >
    <slot />
  </Primitive>
</template>
