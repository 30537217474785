import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { ValidateBusinessActivityShareTokenMutation, ValidateBusinessActivityShareTokenMutationVariables } from '@/__generated__/types';

export const VALIDATE_BUSINESS_ACTIVITY_SHARE_TOKEN = gql`
  mutation ValidateBusinessActivityShareToken($email: String!, $shareToken: String!) {
    validateBusinessActivityShareToken(email: $email, shareToken: $shareToken)
  }
`;

type TOptions = UseMutationOptions<ValidateBusinessActivityShareTokenMutation, ValidateBusinessActivityShareTokenMutationVariables>

const useValidateBusinessActivityShareToken = (options?: TOptions) => useMutation<
  ValidateBusinessActivityShareTokenMutation,
  ValidateBusinessActivityShareTokenMutationVariables
>(VALIDATE_BUSINESS_ACTIVITY_SHARE_TOKEN, options);

export default useValidateBusinessActivityShareToken;
