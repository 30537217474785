<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { ChevronRightIcon } from '@heroicons/vue/solid';
import { computed } from 'vue';
import { useCategoryTranslate } from '@/utils/composables/useCategoryTranslate/useCategoryTranslate';
import MlDialog from '@/components/molecules/MlDialog.vue';
import AtInfoBox from '@/components/atoms/AtInfoBox/AtInfoBox.vue';
import OgCustomDataPointTypeForm from './OgCustomDataPointTypeForm/OgCustomDataPointTypeForm.vue';
import AtCustomDataPointTypeModalTitle from './AtCustomDataPointTypeModalTitle.vue';
import type { Crud } from './types';

const emit = defineEmits<{
  close: [];
  created: [];
  updated: [];
}>();

const { t } = useI18n();
const translateCategory = useCategoryTranslate();
const route = useRoute();

const props = defineProps<Props>();
type Props = {
  crud: Crud
  dataPointTypeId?: string
}

const crudContent = computed(() => {
  switch (props.crud) {
    case 'update': return {
      title: t('Edit custom data point'),
      subtitle: t('Edit your custom data points that support the business of your company.'),
    };
    default: return {
      title: t('Add custom data point'),
      subtitle: t('Create and set up your custom data points that support the business of your company.'),
    };
  }
});

function handleCreate(isRepeating: boolean) {
  emit('created');
  if (!isRepeating) {
    emit('close');
  }
}

function handleUpdate() {
  emit('updated');
  emit('close');
}

</script>

<template>
  <MlDialog
    class="w-[90%] max-w-[83rem] px-[102px] py-11 scrollbar-stable"
    :closeOnOutside="false"
    isOpen
    v-bind="$attrs"
    @close="emit('close')"
  >
    <!-- insert props: category > subcategory -->
    <div class="mb-11 flex">
      {{ translateCategory(String(route.query.category)) }}
      <ChevronRightIcon class="w-6" />
      {{ translateCategory(String(route.query.category), String(route.query.subcategory)) }}
    </div>
    <AtCustomDataPointTypeModalTitle
      :title="crudContent.title"
      :subtitle="crudContent.subtitle"
    />
    <div class="divider" />
    <AtInfoBox :content="t('Note: Your custom data point will be displayed in the language in which it is written.')" />
    <OgCustomDataPointTypeForm
      :category="String(route.query.category)"
      :subcategory="String(route.query.subcategory)"
      :locationId="String(route.params.project)"
      :dataPointTypeId="props.dataPointTypeId"
      :crud="props.crud"
      @created="handleCreate"
      @updated="handleUpdate"
      @close="emit('close')"
    />
  </MlDialog>
</template>
