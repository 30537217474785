<script setup lang="ts">
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import PlusIcon from '@heroicons/vue/solid/PlusIcon';
import { DoubleMaterialityDownloadDocumentEnum } from '@/__generated__/types';
import AtButton from '@/components/atoms/AtButton/AtButton.vue';
import AtInput from '@/components/atoms/AtInput/AtInput.vue';
import { useDocumentDownload } from '../../composables/useDocumentDownload';
import { useStore, useStoreMethods } from '../../store';
import MlInfo from '../../MlInfo.vue';
import OgTable from '../../OgTable.vue';
import MlSelect from './MlSelect.vue';

type RowItem = {
  _id: string;
  index: number;
};

const { t } = useI18n();
const router = useRouter();
const guidanceDownload = useDocumentDownload(DoubleMaterialityDownloadDocumentEnum.STAKEHOLDER_ENGAGEMENT);
const store = useStore();
const {
  addStakeholder,
  createStakeholderOption,
  createStakeholderEngageOption,
  removeStakeholder,
} = useStoreMethods();

const headers = computed(() => [
  { text: t('Stakeholder'), value: 'stakeholder', columnClasses: 'w-[300px]' },
  { text: t('Description'), value: 'description' },
  { text: t('Reasons for choosing'), value: 'reasons' },
  { text: t('How to engage'), value: 'engage', columnClasses: 'w-[300px]' },
]);

const items = computed<RowItem[]>(() => store.value.stakeholders.map((stakeholder, index) => ({
  _id: stakeholder._id,
  index,
})));

function handleRemoveRow(event: number) {
  removeStakeholder(event);
}

function handleAddRowClick() {
  addStakeholder();
}

function handleStakeholderCreated(event: string) {
  createStakeholderOption(event);
}

function handleEngageCreated(event: string) {
  createStakeholderEngageOption(event);
}
</script>

<template>
  <MlInfo type="warning" class="mb-4">
    {{ t("Please select the most important stakeholder groups from the options below (typically companies choose 3 to 5 groups). Your company needs to collect feedback from these key stakeholders on your initial DMA results, this feedback will be integrated into your final results. Stakeholders include individuals, groups, or organizations that impact or are impacted by your company's actions, whether internal or external. You can add more stakeholder groups by clicking “+ add rows.” For each group, provide a brief description, explain why they were chosen, and decide on the best method to request their feedback.") }}
  </MlInfo>

  <OgTable
    :headers="headers"
    :items="items"
    @removeRow="handleRemoveRow"
  >
    <template #item-stakeholder="row: RowItem">
      <MlSelect
        v-model="store.stakeholders[row.index].stakeholder"
        :options="store.stakeholderOptions"
        @created="handleStakeholderCreated"
      />
    </template>
    <template #item-description="row: RowItem">
      <AtInput
        v-model.trim="store.stakeholders[row.index].description"
        type="text"
        :placeholder="t('Describe group')"
      />
    </template>
    <template #item-reasons="row: RowItem">
      <AtInput
        v-model.trim="store.stakeholders[row.index].reasons"
        type="text"
        :placeholder="t('Explain why is this group relevant')"
      />
    </template>
    <template #item-engage="row: RowItem">
      <MlSelect
        v-model="store.stakeholders[row.index].engage"
        :options="store.stakeholderEngageOptions"
        @created="handleEngageCreated"
      />
    </template>
    <template #footer>
      <button
        type="button"
        aria-label="add rows"
        class="flex text-gray-400 text-xs space-x-2 hover:text-primary"
        @click.prevent="handleAddRowClick"
      >
        <PlusIcon class="h-4" />
        {{ t('Add rows') }}
      </button>
    </template>
  </OgTable>

  <div class="mt-auto flex items-center justify-between">
    <div class="flex space-x-3">
      <AtButton
        type="button"
        variant="outline"
        @click="router.push({ name: 'doubleMaterialityIROsScoreRisksAndOpportunities' })"
      >
        {{ t('Go back') }}
      </AtButton>
      <AtButton
        type="button"
        variant="outline"
        :loading="guidanceDownload.loading.value"
        @click="guidanceDownload.exportFile"
      >
        {{ t('Download guidance') }}
      </AtButton>
    </div>
    <AtButton
      type="button"
      @click="router.push({ name: 'doubleMaterialityStakeholdersSurveys' })"
    >
      {{ t('Next') }}
    </AtButton>
  </div>
</template>
