<script setup lang="ts">
// https://floating-vue.starpad.dev/guide/

import { InformationCircleIcon } from '@heroicons/vue/outline';
import omit from 'lodash/omit';
import { type Component, computed } from 'vue';
import MlHtmlContent from '@/components/molecules/MlHtmlContent.vue';

export type TTooltipContent = string | number | Component;
export type TTooltipOptions = TTooltipContent | Record<string, unknown>;

const props = defineProps<{
  tooltip?: TTooltipOptions;
  renderHTML?: boolean;
}>();

const content = computed(() => {
  if (typeof props.tooltip === 'object' && 'content' in props.tooltip) {
    return props.tooltip?.content as TTooltipContent;
  }

  return props.tooltip as TTooltipContent;
});

const options = computed(() => {
  if (typeof props.tooltip === 'object' && 'content' in props.tooltip) {
    return omit(props.tooltip, 'content');
  }

  return {};
});

</script>

<template>
  <VTooltip
    v-bind="{ ...$attrs, ...options }"
    class="inline shrink-0 align-baseline text-gray-400 hover:text-gray-500"
  >
    <InformationCircleIcon class="inline h-5 w-5 outline-0 focus-visible:outline-2" />
    <slot />
    <template #popper="popperSlotProps">
      <slot
        name="tooltip"
        v-bind="popperSlotProps"
      >
        <template v-if="content">
          <component
            :is="content"
            v-if="typeof content === 'object'"
          />
          <template v-else>
            <MlHtmlContent
              v-if="props.renderHTML"
              :html="content.toString()"
            />
            <template v-else>
              {{ content }}
            </template>
          </template>
        </template>
      </slot>
    </template>
  </VTooltip>
</template>
