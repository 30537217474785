<script setup lang="ts">
import { useMounted } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';
import { MenuIcon } from '@heroicons/vue/outline';
import AtIconButton from '../atoms/AtIconButton.vue';

const isMounted = useMounted();
const { t } = useI18n();

const checked = ref(false);
const show = () => { checked.value = true; };
const hide = () => { checked.value = false; };
</script>

<template>
  <slot
    name="button"
    v-bind="{ show, hide }"
  >
    <AtIconButton
      class="!h-10"
      :icon="MenuIcon"
      :title="t('Menu')"
      data-cy="drawer-button"
      @click="checked = !checked"
    />
  </slot>

  <Teleport
    v-if="isMounted"
    to="#app"
  >
    <div
      class="drawer fixed top-0"
      :class="{ 'pointer-events-none -left-10': !checked }"
    >
      <input
        id="og-drawer"
        v-model="checked"
        type="checkbox"
        class="drawer-toggle"
      >
      <div class="drawer-side">
        <div
          class="drawer-overlay"
          @click="hide"
        />
        <div class="w-fit">
          <slot
            name="content"
            v-bind="{ show, hide }"
          />
        </div>
      </div>
    </div>
  </Teleport>
</template>
