<script setup lang="ts">
import { v4 } from 'uuid';
import { useI18n } from 'vue-i18n';

interface Props {
  label?: string;
  modelValue?: string[] | string | null | boolean;
  disabled?: boolean;
  options?: {
    name: string,
    value: unknown,
    label: string,
  }[];
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  label: '',
  options: () => [],
});
const emit = defineEmits(['update:modelValue']);

const { t } = useI18n();

const uniqueId = v4();
</script>

<template>
  <div>
    <p
      v-if="props.label"
      class="mb-4 block text-sm font-medium text-gray-700"
    >
      {{ t(props.label) }}
    </p>

    <div class="mt-1 space-x-4 flex">
      <div
        v-for="(option, index) in options"
        :key="'radio-' + uniqueId + index"
        class="flex items-center"
        @click="
          props.disabled === false
            ? emit('update:modelValue', option.value)
            : ''
        "
      >
        <input
          :id="'radio-' + uniqueId + index"
          :disabled="props.disabled"
          :name="uniqueId"
          :value="option.value"
          :checked="option.value === modelValue"
          type="radio"
          class="radio-primary radio h-4 w-4"
        >
        <label
          :for="'labelradio-' + uniqueId + index"
          class="ml-3 block text-sm text-gray-700"
        >{{ t(option.label) }}</label>
      </div>
    </div>
  </div>
</template>
