import { gql } from '@apollo/client/core';
import { useMutation, type UseMutationOptions } from '@vue/apollo-composable';
import type { GenerateDataPointRequestsForPastDatesMutation, GenerateDataPointRequestsForPastDatesMutationVariables } from '@/__generated__/types';

export const GENERATE_DATAPOINTREQUESTS_FOR_PAST_DATES = gql`
  mutation GenerateDataPointRequestsForPastDates(
    $pastDatesInput: PastDatesInput!
    $projectId: String!
    $dataPointTypeId: String!
  ) {
    generateDataPointRequestsForPastDates(
      pastDatesInput: $pastDatesInput,
      dataPointTypeId: $dataPointTypeId,
      projectId: $projectId,
    ) {
      _id
    }
  }
`;

type TOptions = UseMutationOptions<
  GenerateDataPointRequestsForPastDatesMutation,
  GenerateDataPointRequestsForPastDatesMutationVariables
>

const useGenerateDataPointRequestsForPastDatesMutation = (options?: TOptions) => useMutation<
  GenerateDataPointRequestsForPastDatesMutation,
  GenerateDataPointRequestsForPastDatesMutationVariables
>(GENERATE_DATAPOINTREQUESTS_FOR_PAST_DATES, options);

export default useGenerateDataPointRequestsForPastDatesMutation;
