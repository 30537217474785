import type { ServerOptions } from 'vue3-easy-data-table';
import type { FilterBy } from '@/components/organisms/OgDataTable/types';
import {
  PaginationLimitEnum,
  PaginationSortingEnum, TaxonomyDocumentCategoryEnum,
  TaxonomyDocumentsFilterableFieldEnum,
  type TaxonomyDocumentsFilterByInput,
} from '@/__generated__/types';

export function getLimit(limit: ServerOptions['rowsPerPage']): PaginationLimitEnum {
  switch (limit) {
    case 50:
      return PaginationLimitEnum.FIFTY;
    case 100:
      return PaginationLimitEnum.HUNDRED;
    case 200:
      return PaginationLimitEnum.TWO_HUNDRED;
    default:
      return PaginationLimitEnum.TWENTY_FIVE;
  }
}

export function getSortOrder(order: ServerOptions['sortType']): PaginationSortingEnum {
  switch (order) {
    case 'asc':
      return PaginationSortingEnum.ASC;
    case 'desc':
      return PaginationSortingEnum.DESC;
    default:
      return PaginationSortingEnum.NONE;
  }
}

export function getFilterBy(filters: FilterBy): TaxonomyDocumentsFilterByInput[] {
  const result: TaxonomyDocumentsFilterByInput[] = [];

  filters.forEach((filter) => {
    let field: TaxonomyDocumentsFilterByInput['field'] | undefined;
    switch (filter.field) {
      case 'category':
        field = TaxonomyDocumentsFilterableFieldEnum.CATEGORY;
        break;
      case 'project':
        field = TaxonomyDocumentsFilterableFieldEnum.PROJECT;
        break;
      case 'addedBy':
        field = TaxonomyDocumentsFilterableFieldEnum.ADDED_BY;
        break;
      case 'date':
        field = TaxonomyDocumentsFilterableFieldEnum.DATE;
        break;
      case 'dataPoint':
        field = TaxonomyDocumentsFilterableFieldEnum.DATA_POINT;
        break;
      default:
    }

    if (field && filter.criteria.length > 0) {
      result.push({
        field,
        criteria: filter.criteria,
      });
    }
  });

  return result;
}

const categoryNamesMap: Record<TaxonomyDocumentCategoryEnum, string> = {
  [TaxonomyDocumentCategoryEnum.CORE_DATA]: 'Core data',
  [TaxonomyDocumentCategoryEnum.DNSH]: 'DNSH',
  [TaxonomyDocumentCategoryEnum.GENERAL]: 'General',
  [TaxonomyDocumentCategoryEnum.MINIMUM_SAFEGUARDS]: 'Minimum safeguards',
  [TaxonomyDocumentCategoryEnum.SUBSTANTIAL_CONTRIBUTION]: 'Substantial contribution',
};

export function getCategoryName(category: TaxonomyDocumentCategoryEnum) {
  return categoryNamesMap[category];
}
