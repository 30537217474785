<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import { computed } from 'vue';
import { type Goal, ReportingFrameworkEnum } from '@/__generated__/types';

const props = defineProps<{
  goal: Pick<Goal, 'name' | 'number' | 'reportingFramework'>,
  size: 16 | 20 | 24 | 36;
  fontSize?: 'text-xs' | 'text-sm' | 'text-base' | 'text-2xl';
}>();
const { t } = useI18n();

const imageModules = computed(() => {
  if (props.goal.reportingFramework === ReportingFrameworkEnum.ESRS) {
    return import.meta.glob('@/assets/goals/ESRS/*.png', { query: '?url', import: 'default', eager: true });
  }

  return import.meta.glob('@/assets/goals/GRI/*.svg', { query: '?url', import: 'default', eager: true });
});
const goalImages = computed(() => Object.values(imageModules.value).map((module) => module));

const sizeClasses = computed(() => {
  switch (props.size) {
    case 16: return 'w-16 h-16';
    case 20: return 'w-20 h-20';
    case 24: return 'w-24 h-24';
    case 36:
    default:
      return 'w-36 h-36';
  }
});
const fontSizeClasses = computed(() => {
  if (props.fontSize) {
    return props.fontSize;
  }
  switch (props.size) {
    case 16: return 'text-xs';
    case 20: return 'text-sm';
    case 24: return 'text-base';
    case 36:
    default:
      return 'text-2xl';
  }
});

const lineClamp = computed(() => {
  const fontSize = fontSizeClasses.value;
  switch (fontSize) {
    case 'text-xs': return 'line-clamp-5';
    case 'text-sm': return 'line-clamp-4';
    case 'text-base':
    case 'text-2xl':
    default:
      return 'line-clamp-3';
  }
});

const name = computed(() => props.goal.name.split(':')[0]);
</script>

<template>
  <img
    v-if="goalImages[props.goal.number - 1]"
    class="rounded-lg"
    :class="sizeClasses"
    :src="(goalImages[props.goal.number - 1] as string | undefined)"
    :alt="t(props.goal.name)"
    :title="t(props.goal.name)"
  >
  <div
    v-else
    class="bg-gray-200 row-span-2 rounded-md flex items-center justify-center p-4"
    :class="[fontSizeClasses, sizeClasses]"
    :title="t(props.goal.name)"
  >
    <p :class="lineClamp">
      {{ t(name) }}
    </p>
  </div>
</template>
