import { gql } from '@apollo/client/core';

export default gql`
  query useCategoryTranslate($entityId: String!) {
    getAllCategoriesForEntity(entityId: $entityId) {
      _id
      name
      slug
      subcategories {
        _id
        slug
        name
      }
    }
  }
`;
