<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import InformationCircleIcon from '@heroicons/vue/solid/InformationCircleIcon';
import XIcon from '@heroicons/vue/outline/XIcon';
import AtIconButton from '@/components/atoms/AtIconButton.vue';

defineProps<{
  type: 'warning' | 'error' | 'success';
}>();

const { t } = useI18n();

const showInfo = ref(true);

function handleCloseInfo() {
  showInfo.value = false;
}
</script>

<template>
  <div
    v-if="showInfo"
    class="w-full flex space-x-5 rounded border px-2 pt-1.5 pb-8"
    :class="{
      'border-orange-400 bg-orange-50 text-gray-500': type === 'warning',
      'border-rose-400 bg-rose-50 text-gray-900': type === 'error',
      'border-teal-400 bg-teal-50 text-gray-900': type === 'success',
    }"
  >
    <InformationCircleIcon
      class="h-11 w-11 mt-2 ml-3"
      :class="{
        'text-orange-400': type === 'warning',
        'text-rose-400': type === 'error',
        'text-teal-400': type === 'success',
      }"
    />
    <div class="flex-1 pt-2">
      <slot />
    </div>
    <AtIconButton
      :icon="XIcon"
      :title="t('Close')"
      class="h-6"
      @click="handleCloseInfo"
    />
  </div>
</template>
