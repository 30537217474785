<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { DotsHorizontalIcon } from '@heroicons/vue/outline';
import { UserRole } from '@/__generated__/types';
import MlMenu from '@/components/molecules/MlMenu/MlMenu.vue';
import AtMenuItem from '@/components/molecules/MlMenu/AtMenuItem.vue';

type Props = {
  isDisabled?: boolean;
  canDelete: boolean;
  buttonClass?: string;
  canRenameSubcategory: boolean;
};

withDefaults(defineProps<Props>(), {
  isDisabled: false,
  buttonClass: '',
});

defineEmits<{
  copy: [];
  renameProject: [];
  renameSubcategory: [];
  delete: [];
}>();

const { t } = useI18n();
</script>

<template>
  <MlMenu
    data-cy="projectMenu"
  >
    <button
      type="button"
      class="group flex items-center"
      :class="buttonClass"
      :disabled="isDisabled"
    >
      <DotsHorizontalIcon class="h-5 w-5 group-disabled:opacity-50" />
    </button>
    <template #menuItems>
      <AtMenuItem
        class="justify-center"
        @click="$emit('copy')"
      >
        {{ t('Copy Link') }}
      </AtMenuItem>
      <AtMenuItem
        v-rolefrom="UserRole.MANAGER"
        class="justify-center"
        data-cy="projectMenu-rename"
        @click="$emit('renameProject')"
      >
        {{ t('Rename') }}
      </AtMenuItem>
      <AtMenuItem
        v-if="canRenameSubcategory"
        class="justify-center"
        data-cy="projectMenu-rename"
        @click="$emit('renameSubcategory')"
      >
        {{ t('Rename subcategory') }}
      </AtMenuItem>
      <AtMenuItem
        v-rolefrom="UserRole.ADMIN"
        class="justify-center bg-error text-white hover:hover:bg-error hover:opacity-90"
        :disabled="!canDelete"
        data-cy="projectMenu-delete"
        @click="$emit('delete')"
      >
        {{ t('Delete') }}
      </AtMenuItem>
    </template>
  </MlMenu>
</template>
