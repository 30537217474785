<script setup lang="ts">
import AtButton from '@/components/atoms/AtButton/AtButton.vue';

interface TProps {
  title?: string,
  description?: string
  buttonText?: string
  noButton?: boolean
  buttonClass?: string
}

const props = defineProps<TProps>();

const emit = defineEmits<{
  (e: 'buttonClick'): void
}>();
</script>

<template>
  <div class="flex flex-col items-center rounded bg-gray-50 p-5">
    <span class="text-gray-200">
      <slot />
    </span>
    <h3 class="text-sm font-semibold">
      {{ props.title }}
    </h3>
    <slot name="description">
      <p class="mt-1 text-sm text-gray-500 text-center">
        {{ props.description }}
      </p>
    </slot>
    <AtButton
      v-if="!noButton"
      class="mt-5"
      :class="props.buttonClass"
      @click="emit('buttonClick')"
    >
      <slot name="buttonContent">
        {{ props.buttonText }}
      </slot>
    </AtButton>
  </div>
</template>
